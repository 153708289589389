import { ProviderType, UnitType } from "../enums";

const measures = {
  amountSaved: {
    displayName: "Amount Saved",
    providerType: ProviderType.GCP,
    schemaName: "amountSaved",
    unit: UnitType.CURRENCY,
  },
  hourlyCommittedAmount: {
    displayName: "Hourly Committed Amount",
    providerType: ProviderType.GCP,
    schemaName: "hourlyCommittedAmount",
    unit: UnitType.CURRENCY,
  },
  potentialCreditedAmount: {
    displayName: "Potential Credited Amount",
    providerType: ProviderType.GCP,
    schemaName: "potentialCreditedAmount",
    unit: UnitType.CURRENCY,
  },
  reservedMemoryGiB: {
    displayName: "Reserved Memory GiB",
    providerType: ProviderType.GCP,
    schemaName: "reservedMemoryGiB",
    unit: UnitType.CURRENCY,
  },
  reservedSlots: {
    displayName: "Reserved Slots",
    providerType: ProviderType.GCP,
    schemaName: "reservedSlots",
    unit: UnitType.CURRENCY,
  },
  reservedVCPU: {
    displayName: "Reserved VCPU",
    providerType: ProviderType.GCP,
    schemaName: "reservedVCPU",
    unit: UnitType.CURRENCY,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
  utilizationPercent: {
    displayName: "Utilization Percent",
    providerType: ProviderType.GCP,
    schemaName: "utilizationPercent",
    unit: UnitType.PERCENTAGE,
  },
} as const;

const dimensions = {
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountId",
  },
  instanceFamily: {
    displayName: "Instance Family",
    providerType: ProviderType.GCP,
    schemaName: "instanceFamily",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  service: {
    displayName: "Service",
    providerType: ProviderType.GCP,
    schemaName: "service",
  },
} as const;

export const gcpCombinedCudUtilizationSchema = {
  dimensions,
  measures,
};
