import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import {
  Dimension,
  Filter,
  Measure,
} from "@ternary/api-lib/analytics/ui/types";

export type Input<T = unknown> = {
  value: T;
  hasChanged?: boolean;
  isValid: boolean;
};

export const RECOMMENDATION_KIND_ANOMALY = "anomaly";
export const RECOMMENDATION_KIND_DEFAULT = "recommendation";

export type ReportStateConfig = {
  dataSource: DataSource;
  dimensions: Dimension[];
  durationType: DurationType;
  endDate: string;
  filters: Filter[];
  measures: Measure[];
  startDate: string;
};
