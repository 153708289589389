import { ProviderType, UnitType } from "../enums";

const measures = {
  totalDistinctBuckets: {
    displayName: "Total Distinct Buckets",
    providerType: ProviderType.GCP,
    schemaName: "totalDistinctBuckets",
    unit: UnitType.STANDARD,
  },
  totalReceivedBytes: {
    displayName: "Total Received Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalReceivedBytes",
    unit: UnitType.BYTES,
  },
  totalRequestCount: {
    displayName: "Total Request Count",
    providerType: ProviderType.GCP,
    schemaName: "totalRequestCount",
    unit: UnitType.STANDARD,
  },
  totalSentBytes: {
    displayName: "Total Sent Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalSentBytes",
    unit: UnitType.BYTES,
  },
  totalStoredBytes: {
    displayName: "Total Stored Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalStoredBytes",
    unit: UnitType.BYTES,
  },
  totalStoredByteSeconds: {
    displayName: "Total Stored Byte Seconds",
    providerType: ProviderType.GCP,
    schemaName: "totalStoredByteSeconds",
    unit: UnitType.STANDARD,
  },
  totalStoredObjectDays: {
    displayName: "Total Stored Object Days",
    providerType: ProviderType.GCP,
    schemaName: "totalStoredObjectDays",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  bucketID: {
    displayName: "Bucket ID",
    providerType: ProviderType.GCP,
    schemaName: "bucketId",
  },
  lifecycleRules: {
    displayName: "Lifecycle Rules",
    providerType: ProviderType.GCP,
    schemaName: "lifecycleRules",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.GCP,
    schemaName: "location",
  },
  method: {
    displayName: "Method",
    providerType: ProviderType.GCP,
    schemaName: "method",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  storageClass: {
    displayName: "Storage Class",
    providerType: ProviderType.GCP,
    schemaName: "storageClass",
  },
};

export const gcpStorageUsageSchema = { dimensions, measures };
