import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import { azureSqlVisibilitySchema } from "@ternary/api-lib/analytics/schemas/azureSqlVisibility";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { floor } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AzureDatabaseInstanceEntity } from "../types";

type Props = {
  instances: AzureDatabaseInstanceEntity[];
  isLoading: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureDatabaseInstanceEntity>();

export default function AzureDatabaseInstanceTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const columns = useMemo(
    () => [
      ...(
        [
          [azureSqlVisibilitySchema.dimensions.instanceName.schemaName, 120],
          [azureSqlVisibilitySchema.dimensions.databaseEngine.schemaName, 160],
          [azureSqlVisibilitySchema.dimensions.skuTier.schemaName, 160],
          [azureSqlVisibilitySchema.dimensions.skuName.schemaName, 160],
          [azureSqlVisibilitySchema.dimensions.computeTier.schemaName, 120],
          [azureSqlVisibilitySchema.dimensions.billingMethod.schemaName, 160],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureDatabaseTableInstanceHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),

      columnHelper.accessor("usageAmount", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header: copyText.azureDatabaseTableInstanceHeader_usageAmount,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureDatabaseTableInstanceHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("connections", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceHeader_connections,
        meta: { align: "center" },
      }),
      ...(
        [
          [
            azureSqlVisibilitySchema.dimensions.backupRetentionPeriodDays
              .schemaName,
            100,
          ],
          [
            azureSqlVisibilitySchema.dimensions.backupStorageRedundancy
              .schemaName,
            120,
          ],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureDatabaseTableInstanceHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              {getValue() || copyText.azureKubernetesTableNull}
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("highAvailabilityEnabled", {
        cell: ({ getValue }) => {
          return <Text>{`${getValue()}`}</Text>;
        },
        header:
          copyText.azureDatabaseTableInstanceHeader_highAvailabilityEnabled,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("cores", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceHeader_cores,
        meta: { align: "center" },
      }),
      columnHelper.accessor("coreUsage", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureDatabaseTableInstanceHeader_coreUsage,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cpuPercentAvg", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceHeader_cpuPercentAvg,
        meta: { align: "center" },
      }),
      columnHelper.accessor("cpuPercentMax", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceHeader_cpuPercentMax,
        meta: { align: "center" },
      }),
      columnHelper.accessor("memoryGB", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureDatabaseTableInstanceHeader_memoryGB,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("memoryBytesUsage", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.azureDatabaseTableInstanceGroupHeader_memoryBytesUsage,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("memoryPercentAvg", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceHeader_memoryPercentAvg,
        meta: { align: "center" },
      }),
      columnHelper.accessor("memoryPercentMax", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceHeader_memoryPercentMax,
        meta: { align: "center" },
      }),
      columnHelper.accessor("storageCapacityGB", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header: copyText.azureDatabaseTableInstanceHeader_storageCapacityGB,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("storagePercent", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureDatabaseTableInstanceHeader_storagePercent,
        meta: { align: "center" },
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.instances}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoading}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
