import { DurationType } from "@ternary/api-lib/analytics/enums";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { locationStateKeyedByPath } from "../constants/paths";
import { useGlobalDateStore } from "../context/GlobalDateProvider";
import { useMatchPath } from "../lib/react-router";

export const DEFAULT_GLOBAL_DURATION_TYPE = DurationType.LAST_THIRTY_DAYS;

export type GlobalDateResult =
  | {
      date: Date[];
      durationType: DurationType;
      enabled: true;
    }
  | {
      date: null;
      durationType: null;
      enabled: false;
    };

export default function useAvailableGlobalDate(): GlobalDateResult {
  const currentPath = useMatchPath();
  const globalDate = useGlobalDateStore();

  const locationState = locationStateKeyedByPath[currentPath];

  if (
    locationState &&
    locationState.enableGlobalDate &&
    (globalDate.dateRange || globalDate.durationType)
  ) {
    const durationType =
      globalDate.durationType ?? DEFAULT_GLOBAL_DURATION_TYPE;

    const dateRange = globalDate.dateRange
      ? globalDate.dateRange
      : getCubeDateRangeFromDurationType(durationType);

    return {
      date: dateRange,
      durationType: durationType,
      enabled: true,
    };
  }

  return {
    date: null,
    durationType: null,
    enabled: false,
  };
}
