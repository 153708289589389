import { ProviderType, UnitType } from "../enums";

const measures = {
  absoluteCredits: {
    displayName: "Absolute Credits",
    providerType: ProviderType.AZURE,
    schemaName: "absoluteCredits",
    unit: UnitType.CURRENCY,
  },
  blobSizeBytes: {
    displayName: "Blob Size Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "blobSizeBytes",
    unit: UnitType.BINARY_BYTES,
  },
  bytesDownloaded: {
    displayName: "Bytes Downloaded",
    providerType: ProviderType.AZURE,
    schemaName: "bytesDownloaded",
    unit: UnitType.BYTES,
  },
  bytesUploaded: {
    displayName: "Bytes Uploaded",
    providerType: ProviderType.AZURE,
    schemaName: "bytesUploaded",
    unit: UnitType.BYTES,
  },
  containerCount: {
    displayName: "Container Count",
    providerType: ProviderType.AZURE,
    schemaName: "containerCount",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AZURE,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  transactions: {
    displayName: "Transactions",
    providerType: ProviderType.AZURE,
    schemaName: "transactions",
    unit: UnitType.STANDARD,
  },
  usageAmount: {
    displayName: "Usage Amount",
    providerType: ProviderType.AZURE,
    schemaName: "usageAmount",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  accountID: {
    displayName: "Account ID",
    providerType: ProviderType.AZURE,
    schemaName: "accountId",
  },
  pricingModel: {
    displayName: "Pricing Model",
    providerType: ProviderType.AZURE,
    schemaName: "pricingModel",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.AZURE,
    schemaName: "projectId",
  },
  resourceID: {
    displayName: "Resource ID",
    providerType: ProviderType.AZURE,
    schemaName: "resourceId",
  },
  skuDescription: {
    displayName: "SKU Description",
    providerType: ProviderType.AZURE,
    schemaName: "skuDescription",
  },
  storageAccount: {
    displayName: "Storage Account",
    providerType: ProviderType.AZURE,
    schemaName: "storageAccount",
  },
} as const;

export const azureStorageVisibilitySchema = { dimensions, measures };
