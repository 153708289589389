import { Operator } from "@ternary/api-lib/analytics/enums";
import copyText from "../common.copyText";

export const ANALYTICS_QUERY_GC_TIME = 60_000 * 5; // 5 Minutes

export const operatorOptions = [
  {
    label: copyText.operatorOptionEqualsLabel,
    value: Operator.EQUALS,
  },
  {
    label: copyText.operatorOptionNotEqualsLabel,
    value: Operator.NOT_EQUALS,
  },
  {
    label: copyText.operatorOptionContainsLabel,
    value: Operator.CONTAINS,
  },
  {
    label: copyText.operatorOptionNotContainsLabel,
    value: Operator.NOT_CONTAINS,
  },
  {
    label: copyText.operatorOptionSetLabel,
    value: Operator.SET,
  },
  {
    label: copyText.operatorOptionNotSetLabel,
    value: Operator.NOT_SET,
  },
];

export const lineageMap = {
  AWS_BILLING_EXPORT_FIELD: "AWS Billing Export Field",
  AWS_USER_DEFINED_TAG: "AWS User-Defined Tag",
  AWS_GENERATED_TAG: "AWS-Generated Tag",
  ALI_BILLING_EXPORT_FIELD: "Ali Billing Export Field",
  ALI_CLOUD_TAG: "Ali Cloud Tag",
  AZURE_BILLING_EXPORT_FIELD: "Azure Billing Export Field",
  AZURE_TAG: "Azure Tag",
  FOCUS_SPEC_FIELD: "FOCUS Spec Field",
  GCP_BILLING_EXPORT_FIELD: "GCP Billing Export Field",
  GCP_LABEL: "GCP Label",
  GCP_PROJECT_LABEL: "GCP Project Label",
  GCP_SYSTEM_LABEL: "GCP System Label",
  GCP_TAG: "GCP Tag",
  KUBERNETES_POD_LABEL: "Kubernetes Pod Label",
  MONGODB_BILLING_EXPORT_FIELD: "MongoDB Billing Export Field",
  MONGODB_TAG: "MongoDB Tag",
  OCI_BILLING_EXPORT_FIELD: "OCI Billing Export Field",
  OCI_TAG: "OCI Tag",
  SNOWFLAKE_BILLING_EXPORT_FIELD: "Snowflake Billing Export Field",
  SNOWFLAKE_TAG: "Snowflake Tag",
  TERNARY_CUSTOM_LABEL: "Ternary Custom Label",
  TERNARY_CUSTOM_MEASURE: "Ternary Custom Measure",
  TERNARY_MULTI_CLOUD_FIELD: "Ternary Defined Multi-Cloud Field",
  TERNARY_LABEL_GROUPING: "Ternary Label Grouping",
};
