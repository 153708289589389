import { ProviderType, UnitType } from "../enums";

const measures = {
  maxCpuAverageUsedCores: {
    displayName: "Max CPU Average Used Cores",
    providerType: ProviderType.GCP,
    schemaName: "maxCpuAverageUsedCores",
    unit: UnitType.KILOGRAMS,
  },
  maxCpuReservedCores: {
    displayName: "Max CPU Reserved Cores",
    providerType: ProviderType.GCP,
    schemaName: "maxCpuReservedCores",
    unit: UnitType.BYTES,
  },
  maxDiskSizeBytes: {
    displayName: "Max Disk Size Bytes",
    providerType: ProviderType.GCP,
    schemaName: "maxDiskSizeBytes",
    unit: UnitType.BYTES,
  },
  maxDiskUsedBytes: {
    displayName: "Max Disk Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "maxDiskUsedBytes",
    unit: UnitType.BYTES,
  },
  maxRamAverageUsedBytes: {
    displayName: "Max RAM Average Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "maxRamAverageUsedBytes",
    unit: UnitType.BYTES,
  },
  maxRamUsedBytesWithBuffer: {
    displayName: "Max RAM Used Bytes With Buffer",
    providerType: ProviderType.GCP,
    schemaName: "maxRamUsedBytesWithBuffer",
    unit: UnitType.BYTES,
  },
  networkSentBytes: {
    displayName: "Network Sent Bytes",
    providerType: ProviderType.GCP,
    schemaName: "networkSentBytes",
    unit: UnitType.BYTES,
  },
  ramReservedBytes: {
    displayName: "RAM Reserved Bytes",
    providerType: ProviderType.GCP,
    schemaName: "ramReservedBytes",
    unit: UnitType.BYTES,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
  totalCpuReservedCores: {
    displayName: "Total CPU Reserved Cores",
    providerType: ProviderType.GCP,
    schemaName: "totalCpuReservedCores",
    unit: UnitType.STANDARD,
  },
  totalCpuUsedCoreHours: {
    displayName: "Total CPU Used Core Hours",
    providerType: ProviderType.GCP,
    schemaName: "totalCpuUsedCoreHours",
    unit: UnitType.STANDARD,
  },
  totalDiskSizeBytes: {
    displayName: "Total Disk Size Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalDiskSizeBytes",
    unit: UnitType.BYTES,
  },
  totalDiskUsedBytes: {
    displayName: "Total Disk Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalDiskUsedBytes",
    unit: UnitType.BYTES,
  },
  totalNetworkSentBytes: {
    displayName: "Total Network Sent Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalNetworkSentBytes",
    unit: UnitType.BYTES,
  },
  totalRamReservedBytes: {
    displayName: "Total RAM Reserved Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalRamReservedBytes",
    unit: UnitType.BYTES,
  },
  totalRamUsedBytes: {
    displayName: "Total RAM Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalRamUsedBytes",
    unit: UnitType.BYTES,
  },
  totalRamUsedBytesWithBuffer: {
    displayName: "Total RAM Used Bytes With Buffer",
    providerType: ProviderType.GCP,
    schemaName: "totalRamUsedBytesWithBuffer",
    unit: UnitType.BYTES,
  },
} as const;

const dimensions = {
  availability: {
    displayName: "Availability",
    providerType: ProviderType.GCP,
    schemaName: "availability",
  },
  databaseID: {
    displayName: "Database ID",
    providerType: ProviderType.GCP,
    schemaName: "databaseId",
  },
  databaseType: {
    displayName: "Database Type",
    providerType: ProviderType.GCP,
    schemaName: "databaseType",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
} as const;

export const gcpCloudSqlInstanceUsageSchema = { dimensions, measures };
