import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { useAvailableDimensionsByDataSourceV2 } from "@/hooks/useAvailableDimensionsByDataSource";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { AlertType, postAlert } from "@/utils/alerts";
import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import {
  faArrowLeftLong,
  faChartLine,
  faChevronDown,
  faChevronRight,
  faEllipsisH,
  faEllipsisV,
  faFileExport,
  faHashtag,
  faList,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faPercent,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  PREVIOUS_TIMESTAMP_KEY,
  RAW_DIFFERENCE_KEY,
} from "@ternary/api-lib/analytics/constants";
import {
  ChartType,
  CompareDurationType,
  DataSource,
  DurationType,
  MetricAggregate,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { detailedBillingSchema } from "@ternary/api-lib/analytics/schemas/detailedBilling";
import { RawData } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import CrossSectionalDataTable from "@ternary/api-lib/analytics/ui/CrossSectionalDataTable";
import DataTable from "@ternary/api-lib/analytics/ui/DataTable";
import KPIChart, {
  ComparisonFormat,
} from "@ternary/api-lib/analytics/ui/KPIChart";
import LineChart from "@ternary/api-lib/analytics/ui/LineChart";
import PieChart from "@ternary/api-lib/analytics/ui/PieChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import TimeSeriesDataTable from "@ternary/api-lib/analytics/ui/TimeSeriesDataTable";
import useReferenceIfEqual from "@ternary/api-lib/analytics/ui/hooks/useReferenceIfEqual";
import {
  Dimension,
  Filter,
  Measure,
  ReportDataConfig,
  SortRule,
} from "@ternary/api-lib/analytics/ui/types";
import { isValidDetailedBillingDate } from "@ternary/api-lib/analytics/utils";
import { getReportChartConfiguration } from "@ternary/api-lib/analytics/utils/ChartDataUtils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import {
  getCompareDateRangeFromDurationType,
  getComparisonDateRangeFromReport,
  getCubeDateRangeFromDurationType,
  getDateRangeFromLastNDays,
  getDateRangeFromLastNMonths,
  getDateRangeFromReport,
  getMeasureFromMetricAggregate,
  isValidRollingWindow,
} from "@ternary/api-lib/analytics/utils/ReportUtils";
import {
  CustomMetricDataType,
  DashboardScope,
  DashboardType,
  JobStatus,
  WidgetType,
} from "@ternary/api-lib/constants/enums";
import systemUser, {
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import { ExportSourceConfig } from "@ternary/api-lib/core/types/GcpIntegration";
import {
  ReportEntity,
  ReportScope,
  ReportType,
} from "@ternary/api-lib/core/types/Report";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import BarLoader from "@ternary/web-ui-lib/components/EmptyPlaceholder/BarLoader";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import { get, has, isEqual, keyBy, noop, uniq, uniqBy } from "lodash";
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useParams } from "react-router";
import { useWindowSize } from "react-use";
import {
  JsonParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { MAX_ROWS } from "../../../api/analytics/AnalyticsApiClient";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import NameInputModal from "../../../components/NameInputModal";
import NotConfiguredPlaceholder from "../../../components/NotConfiguredPlaceholder";
import useAvailableMeasuresByDataSource from "../../../hooks/useAvailableMeasuresByDataSource";
import { useCSVDownloader } from "../../../ui-lib/components/CSVDownloader";
import Checkbox from "../../../ui-lib/components/Checkbox";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls/DateRangeControls";
import DropdownButton from "../../../ui-lib/components/DropdownButton";
import { FavoriteButton } from "../../../ui-lib/components/FavoriteButton";
import Grid from "../../../ui-lib/components/Grid";
import SelectDropdown from "../../../ui-lib/components/SelectDropdown";
import { LabeledSwitch } from "../../../ui-lib/components/Switch";
import Tabs from "../../../ui-lib/components/Tabs";
import { isTimeGranularity } from "../../../utils/typeGuards";
import useGetDimensionPreferencesByTenantID from "../../admin/hooks/useGetDimensionPreferencesByTenantID";
import useGetMeasurePreferencesByTenantID from "../../admin/hooks/useGetMeasurePreferencesByTenantID";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import { useCaptureViewReport } from "../hooks/useCaptureViewReport";
import useCreateCustomMetric from "../hooks/useCreateCustomMetric";
import useCreateDashboard from "../hooks/useCreateDashboard";
import useCreateReport from "../hooks/useCreateReport";
import useDeleteCustomMetric from "../hooks/useDeleteCustomMetric";
import useDeleteReport from "../hooks/useDeleteReport";
import useGenerateReportPDF from "../hooks/useGenerateReportPDFByID";
import useGetBigQueryMetadata from "../hooks/useGetBigQueryMetadata";
import useGetCustomMetricsByTenantID from "../hooks/useGetCustomMetricsByTenantID";
import useGetDashboardsByTenantID from "../hooks/useGetDashboardsByTenantID";
import useGetDatadogIntegrationByTenantID from "../hooks/useGetDatadogIntegrationByTenantID";
import useGetReportByID from "../hooks/useGetReportByID";
import useGetReportCSV from "../hooks/useGetReportCSV";
import useGetReportData, {
  defaultReportDataResult,
} from "../hooks/useGetReportData";
import useGetReportsByTenantID from "../hooks/useGetReportsByTenantID";
import useUpdateCustomMetric from "../hooks/useUpdateCustomMetric";
import useUpdateDashboard from "../hooks/useUpdateDashboard";
import useUpdateReport from "../hooks/useUpdateReport";
import { Order } from "../types";
import {
  filterDataBasedOnSearchText,
  formatWidgetSpec,
  getTimeLastUpdatedCaption,
  isChartType,
  isMetricAggregate,
  padInvoiceMonthDateRange,
} from "../utils";
import CustomMetricForm from "./CustomMetricForm";
import CustomMetricListModal from "./CustomMetricListModal";
import DashboardListModal from "./DashboardListModal";
import ReportBuilderSidePanelMainTab from "./ReportBuilderSidePanelMainTab";
import ReportBuilderSidePanelUnitEconTab from "./ReportBuilderSidePanelUnitEconTab";
import ReportChart from "./ReportChart";
import ReportFormModal from "./ReportFormModal";
import ReportListModal from "./ReportListModal";
import ReportSubscriptionContainer from "./ReportSubscriptionContainer";
import ReportViewContainer from "./ReportViewContainer";

const CHART_SECTION_HEIGHT_PERCENTAGE_DIVISOR = 2.5;
const CHART_SECTION_MIN_HEIGHT = 400;
const INCH_14_LAPTOP_SCREEN_HEIGHT = 750;
const INCH_14_LAPTOP_SCREEN_WIDTH = 1512;
const SIDE_PANEL_FOOTER_HEIGHT = 40;
const SIDE_PANEL_WIDTH_CLOSED = 65;
const SIDE_PANEL_WIDTH_MAX = 600;
const SIDE_PANEL_WIDTH_OPEN = 410;
const SIDE_PANEL_WIDTH_PERCENTAGE_DIVISOR = 4;
const TOP_NAV_HEIGHT = 64;
const MAIN_CONTENT_WIDTH = "55.125rem";

const DIMENSION_HEIGHT = 25;
const MEASURE_HEIGHT = 25;
const FILTER_HEIGHT = 75;

const DEBOUNCE_SEARCH_MS = 600;
const REFETCH_INTERVAL = 15 * 1000; // 15 Seconds

const MODAL_CREATE_DASHBOARD = "CREATE_DASHBOARD";
const MODAL_CREATE_REPORT = "CREATE_REPORT";
const MODAL_DASHBOARD_LIST = "DASHBOARD_LIST";
const MODAL_DELETE_REPORT = "DELETE_REPORT";
const MODAL_METRIC_LIST = "METRIC_LIST";
const MODAL_REPORT_LIST = "LIST_REPORT";
const MODAL_SAVE_EXISTING_TO_DASHBOARD = "SAVE_EXISTING_TO_DASHBOARD";
const MODAL_SUBSCRIBE_REPORT = "MODAL_SUBSCRIBE_REPORT";
const MODAL_UPDATE_REPORT = "UPDATE_REPORT";
const MODAL_UPDATE_SYSTEM_REPORT = "UPDATE_SYSTEM_REPORT";

const hiddenChildTenantLabels = [
  "dataSource",
  "msp_rule_author",
  "msp_rule_id",
  "msp_rule_name",
  "msp_rule_operation_id",
  "msp_ruleset_id",
];

const TAB_MAIN = "main";
const TAB_UNIT_ECON = "unitecon";

export enum Action {
  COPY = "COPY",
  SAVE = "SAVE",
}
type Interaction =
  | AreaChart.Interaction
  | CustomMetricForm.Interaction
  | CustomMetricListModal.Interaction
  | StackedBarChart.Interaction
  | LineChart.Interaction
  | PieChart.Interaction
  | TimeSeriesDataTable.Interaction
  | CrossSectionalDataTable.Interaction
  | ReportBuilderSidePanelMainTab.Interaction
  | ReportBuilderSidePanelUnitEconTab.Interaction
  | ReportListModal.Interaction
  | DashboardListModal.Interaction
  | ReportViewContainer.Interaction;

interface State {
  action: Action | undefined;
  backButtonLabel: string;
  bigQueryConfig: ExportSourceConfig | null;
  chartType: ChartType;
  compareDateRange: DateRange | null;
  compareDurationType: CompareDurationType | null;
  creditTypes: string[];
  csvReport: ReportDataConfig | null;
  isCumulative: boolean;
  dataSource: DataSource;
  dateRange: DateRange | null;
  dimensions: Dimension[];
  durationType: DurationType;
  excludeNegativeNumbers: boolean;
  excludeOther: boolean;
  filters: Filter[];
  filteredKpiMeasure: Measure | null;
  formula: string;
  formulaAlias: string;
  timeGranularity: TimeGranularity;
  hasClickedReportPDF: boolean;
  hiddenMeasures: string[];
  isDropdownOpen: boolean;
  isFormulaHidden: boolean;
  isMetricHidden: boolean;
  isPanelOpen: boolean;
  isSideDrawerOpen: boolean;
  isTranspose: boolean;
  limit: number | null;
  limitOptions: string[];
  measures: Measure[];
  meterComparisonFormat: ComparisonFormat;
  metricAggregate: MetricAggregate;
  metricFilters: Filter[];
  metric: string;
  modalKey: string;
  nLookback: number | null;
  order: Order;
  previousPage: string;
  reverse: boolean;
  runQueryTriggered: boolean;
  searchText: string;
  selectedDashboardID: string | undefined;
  selectedCustomMetricID: string | undefined;
  showTimeSeriesTable: boolean;
  showUnitEconomics: boolean;
  sortRule: SortRule | undefined;
  xAxisKey: string;
}

const defaultCustomMetrics = [];
const defaultLimitOptions = ["5", "10", "15", "20", "NaN"];

const initialState: State = {
  action: undefined,
  backButtonLabel: copyText.reportsPageTitle,
  bigQueryConfig: null,
  chartType: ChartType.LINE,
  compareDateRange: null,
  compareDurationType: null,
  creditTypes: [],
  csvReport: null,
  isCumulative: false,
  dataSource: DataSource.BILLING,
  dateRange: null,
  dimensions: [],
  durationType: DurationType.LAST_THIRTY_DAYS,
  excludeNegativeNumbers: false,
  excludeOther: false,
  filters: [],
  filteredKpiMeasure: null,
  formula: "",
  formulaAlias: "",
  hasClickedReportPDF: false,
  hiddenMeasures: [],
  isDropdownOpen: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  isPanelOpen: true,
  isSideDrawerOpen: false,
  isTranspose: false,
  limit: null,
  limitOptions: defaultLimitOptions,
  measures: [],
  meterComparisonFormat: ComparisonFormat.PERCENT,
  metricAggregate: MetricAggregate.SUM,
  metricFilters: [],
  metric: "",
  modalKey: "",
  nLookback: null,
  order: {},
  previousPage: "",
  reverse: false,
  runQueryTriggered: false,
  searchText: "",
  selectedDashboardID: undefined,
  selectedCustomMetricID: undefined,
  showTimeSeriesTable: true,
  showUnitEconomics: false,
  sortRule: undefined,
  timeGranularity: TimeGranularity.DAY,
  xAxisKey: DEFAULT_X_AXIS_KEY,
};

const today = new Date();

export default function ReportBuilderContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const caseManagementStore = useCaseManagementStore();
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { reportID } = useParams();

  const { downloadCSV, csvElement } = useCSVDownloader();

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, TAB_MAIN),
    report_config: JsonParam,
  });

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const debouncedSearchText = useDebounce(state.searchText, DEBOUNCE_SEARCH_MS);

  const dateRange = getDateRangeFromState(
    state.durationType,
    state.dateRange,
    state.nLookback
  );

  const measures = useMemo(
    () =>
      state.chartType === ChartType.KPI
        ? state.measures.slice(0, 1)
        : state.measures,
    [state.chartType, state.measures]
  );

  const dimensions = useMemo(
    () => (state.chartType === ChartType.KPI ? [] : state.dimensions),
    [state.chartType, state.dimensions]
  );

  const hasMeasure = measures.length > 0;

  //
  // Queries
  //

  const {
    data: _customMetrics = defaultCustomMetrics,
    isLoading: isLoadingCustomMetrics,
    refetch: refetchCustomMetrics,
  } = useGetCustomMetricsByTenantID(authenticatedUser.tenant.id, {
    refetchInterval: REFETCH_INTERVAL,
  });

  const { data: bigQueryMetadata, isLoading: isLoadingBigQueryMetadata } =
    useGetBigQueryMetadata(
      {
        tenantID: authenticatedUser.tenant.id,
        datasetID: state.bigQueryConfig?.datasetID ?? "",
        projectID: state.bigQueryConfig?.projectID ?? "",
        tableID: state.bigQueryConfig?.tableID ?? "",
      },
      { enabled: gatekeeper.canListCustomMetrics && !!state.bigQueryConfig }
    );

  const {
    data: report,
    isLoading: isLoadingReport,
    refetch: refetchReport,
  } = useGetReportByID(reportID ?? "", authenticatedUser.tenant.fsDocID, {
    enabled: !!reportID && gatekeeper.canListReports,
  });

  const { data: reportPDF, isFetching: isLoadingReportPDF } =
    useGenerateReportPDF(report ?? ({} as ReportEntity), {
      enabled:
        !!reportID &&
        !!report &&
        state.hasClickedReportPDF &&
        gatekeeper.canListReports,
    });

  const {
    data: _reports = [],
    isFetching: isLoadingReports,
    refetch: refetchReports,
  } = useGetReportsByTenantID(authenticatedUser.tenant.id);

  const {
    data: _dashboards = [],
    isLoading: isLoadingDashboards,
    refetch: refetchDashboards,
  } = useGetDashboardsByTenantID(authenticatedUser.tenant.id);

  const { data: dimensionValuesMap = {}, isLoading: isLoadingDimensionValues } =
    useGetDimensionValuesByDataSource(
      {
        dataSource: state.dataSource,
        dateRange,
        dimensions: state.filters,
        measure: measures[0],
      },
      { enabled: state.filters.length > 0 }
    );

  const {
    data: externalDimensionValuesMap = {},
    isLoading: isLoadingExternalDimensionValues,
  } = useGetDimensionValuesByDataSource(
    {
      dataSource: DataSource.EXTERNAL_METRICS,
      dateRange,
      dimensions: state.metricFilters,
      measure: getMeasureFromMetricAggregate(
        state.metricAggregate ?? MetricAggregate.SUM
      ),
    },
    { enabled: state.metricFilters.length > 0 }
  );

  const reportDataConfig = useReferenceIfEqual(
    getReportDataConfigFromState(state)
  );

  const { data: integration, isLoading: isLoadingIntegration } =
    useGetDatadogIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  const customMetricMap = _customMetrics?.reduce((accum, metric) => {
    if (metric.status === JobStatus.FAILURE) {
      return accum;
    }

    accum[metric.name] = {
      groupings: Object.entries(metric.schema).reduce(
        (accum: string[], [key, value]) => {
          if (value === CustomMetricDataType.DIMENSION) {
            accum.push(key);
          }

          return accum;
        },
        []
      ),
      name: metric.name,
    };

    return accum;
  }, {});

  const {
    data: reportDataResult = defaultReportDataResult,
    isLoading: isLoadingReportData,
    isSuccess: hasLoadedReportData,
  } = useGetReportData(reportDataConfig, {
    enabled:
      report && !isStateDifferentThanReport(state, report)
        ? true
        : state.runQueryTriggered,
  });

  const { main: reportDataMain, mainGroupingTotals: reportDataMainTotals } =
    reportDataResult;

  const combinedExternalMetrics = {
    ...integration?.metrics,
    ...customMetricMap,
  };

  const {
    impactMode,
    isInvoiceMonthMode,
    measures: chartMeasures,
    shouldApplyGranularity,
    xAxisKey,
  } = useMemo(
    () =>
      getReportChartConfiguration({
        integration: { metrics: combinedExternalMetrics },
        report: reportDataConfig,
      }),
    [
      combinedExternalMetrics,
      state.chartType,
      state.compareDurationType,
      state.dataSource,
      state.dimensions,
      state.durationType,
      state.formula,
      state.formulaAlias,
      state.hiddenMeasures,
      state.isFormulaHidden,
      state.isMetricHidden,
      state.metric,
      state.measures,
      state.timeGranularity,
      state.xAxisKey,
    ]
  );

  const isTableTimeSeries =
    state.showTimeSeriesTable &&
    state.chartType !== ChartType.PIE &&
    state.chartType !== ChartType.TABLE &&
    (xAxisKey === DEFAULT_X_AXIS_KEY || isInvoiceMonthMode);

  const filteredTableData = useMemo(() => {
    const data = filterDataBasedOnSearchText(
      isTableTimeSeries
        ? reportDataMain.result.data
        : reportDataMainTotals.result.data,
      state.searchText
    );

    if (!reportDataConfig.compareDurationType) return data;

    // NOTE:
    // this duplicates each datum for comparison data in time series table.
    // getSortedTimestampsForTimeSeries does not work without this step
    return data.reduce<RawData[]>((accum, datum) => {
      const temp = [datum];

      if (
        reportDataConfig.compareDurationType &&
        reportDataConfig.xAxisKey === DEFAULT_X_AXIS_KEY &&
        reportDataConfig.timeGranularity
      ) {
        const previousDatum = { ...datum };

        reportDataConfig.measures.forEach((measure) => {
          previousDatum[measure.schemaName] =
            previousDatum[`${measure.schemaName}${COMPARISON_KEY}`];
        });
        previousDatum[DEFAULT_X_AXIS_KEY] =
          `${previousDatum[PREVIOUS_TIMESTAMP_KEY]} (${COMPARISON_KEY})`;
        temp.push(previousDatum);
      }

      return [...accum, ...temp];
    }, []);
  }, [
    isTableTimeSeries,
    reportDataConfig,
    reportDataMain.result.data,
    reportDataMainTotals.result.data,
    state.searchText,
  ]);

  const csvResult = useGetReportCSV({
    report: state.csvReport,
    isTranspose: state.isTranspose,
    searchText: debouncedSearchText,
  });

  const {
    data: dimensionPreferences = [],
    isLoading: isLoadingDimensionPreferences,
  } = useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const {
    data: measurePreferences = [],
    isLoading: isLoadingMeasurePreferences,
  } = useGetMeasurePreferencesByTenantID(authenticatedUser.tenant.id);

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  //
  // Mutations
  //

  const { isPending: isCreatingCustomMetric, mutate: createCustomMetric } =
    useCreateCustomMetric({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingCustomMetricMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchCustomMetrics();

        postAlert({
          message: copyText.SUCCESS_CUSTOM_METRIC_CREATED_message,
          type: AlertType.SUCCESS,
        });

        mergeState({
          action: undefined,
          isSideDrawerOpen: false,
          bigQueryConfig: null,
        });
      },
    });

  const { isPending: isDeletingCustomMetric, mutate: deleteCustomMetric } =
    useDeleteCustomMetric({
      onError: () => {
        postAlert({
          message: copyText.errorDeletingCustomMetricMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchCustomMetrics();

        mergeState({ isSideDrawerOpen: false });

        postAlert({
          message: copyText.SUCCESS_CUSTOM_METRIC_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isUpdatingCustomMetric, mutate: updateCustomMetric } =
    useUpdateCustomMetric({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingCustomMetricMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchCustomMetrics();

        mergeState({
          action: undefined,
          isSideDrawerOpen: false,
          selectedCustomMetricID: undefined,
        });

        postAlert({
          message: copyText.SUCCESS_CUSTOM_METRIC_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { mutate: updateDashboard } = useUpdateDashboard({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorUpdatingDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (dashboardID) => {
      queryClient.resetQueries({
        queryKey: ["dashboards", dashboardID],
      });

      refetchDashboards();

      mergeState({ modalKey: "" });

      mergeState({ selectedDashboardID: undefined });

      postAlert({
        message: copyText.successUpdatingDashboardMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingReport, mutate: createReport } = useCreateReport(
    {
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (newReportID) => {
        navigate(paths._reportBuilder.replace(":reportID", newReportID));

        refetchReports();

        if (selectedDashboard) {
          const newReportSpec = formatWidgetSpec(
            newReportID,
            WidgetType.REPORT,
            selectedDashboard.widgetSpecs
          );

          const updatedSpecs = [
            ...selectedDashboard.widgetSpecs,
            newReportSpec,
          ].map((spec) => ({
            ...(spec.budgetID ? { budgetID: spec.budgetID } : {}),
            ...(spec.reportID ? { reportID: spec.reportID } : {}),
            ...(spec.savingsOpportunityFilterID
              ? { savingsOpportunityFilterID: spec.savingsOpportunityFilterID }
              : {}),
            height: spec.height,
            type: spec.type,
            width: spec.width,
            xCoordinate: spec.xCoordinate,
            yCoordinate: spec.yCoordinate,
          }));

          updateDashboard({
            dashboardID: selectedDashboard.id,
            widgetSpecs: updatedSpecs,
          });
        }

        mergeState({ modalKey: "", selectedDashboardID: undefined });

        postAlert({
          message: copyText.SUCCESS_REPORT_CREATED_message,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const { isPending: isDeletingReport, mutate: deleteReport } = useDeleteReport(
    {
      onError: () => {
        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.errorDeletingReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        navigate(paths._reports);

        refetchReports();

        refetchDashboards();

        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.SUCCESS_REPORT_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const selectedDashboard = _dashboards.find(
    (dashboard) => dashboard.id === state.selectedDashboardID
  );

  const { isPending: isUpdatingReport, mutate: updateReport } = useUpdateReport(
    {
      onMutate: async (params) => {
        await queryClient.cancelQueries({ queryKey: ["reports"] });

        const tenantID = authenticatedUser.tenant.id;

        const previousReports: ReportEntity[] =
          queryClient.getQueryData(queryKeys.tenantReports(tenantID)) ?? [];

        //Optimistic update when favorting report within report builder
        if (params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantReports(tenantID),
            (currentReports: ReportEntity[] | undefined) => {
              return (currentReports ?? []).map((report) =>
                report.id === params.reportID
                  ? {
                      ...report,
                      favoritedUserIDs: params.favoritedUserIDs ?? [],
                    }
                  : report
              );
            }
          );
        }

        return { previousReports };
      },
      onError: (
        _,
        params,
        context: { previousReports: ReportEntity[] } | undefined
      ) => {
        if (context && params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantReports(authenticatedUser.tenant.id),
            context.previousReports
          );
        }

        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorUpdatingReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (_, params) => {
        queryClient.invalidateQueries({ queryKey: ["dashboards"] });

        if (params.favoritedUserIDs) return;

        refetchReport();
        refetchReports();

        if (selectedDashboard) {
          const updateReportSpec = formatWidgetSpec(
            params.reportID,
            WidgetType.REPORT,
            selectedDashboard.widgetSpecs
          );

          const updatedSpecs = [
            ...selectedDashboard.widgetSpecs,
            updateReportSpec,
          ].map((spec) => ({
            ...(spec.budgetID ? { budgetID: spec.budgetID } : {}),
            ...(spec.reportID ? { reportID: spec.reportID } : {}),
            ...(spec.savingsOpportunityFilterID
              ? { savingsOpportunityFilterID: spec.savingsOpportunityFilterID }
              : {}),
            height: spec.height,
            type: spec.type,
            width: spec.width,
            xCoordinate: spec.xCoordinate,
            yCoordinate: spec.yCoordinate,
          }));

          updateDashboard({
            dashboardID: selectedDashboard.id,
            widgetSpecs: updatedSpecs,
          });

          mergeState({ modalKey: "", selectedDashboardID: undefined });

          return;
        }

        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.SUCCESS_REPORT_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
      },
    }
  );

  const { isPending: isCreatingDashboard, mutate: createDashboard } =
    useCreateDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        refetchDashboards();

        mergeState({
          modalKey:
            reportID && state.action !== Action.COPY
              ? MODAL_SAVE_EXISTING_TO_DASHBOARD
              : MODAL_CREATE_REPORT,
          selectedDashboardID: dashboardID,
        });

        postAlert({
          message: copyText.successCreatingDashboardMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Side Effects
  //

  useEffect(() => {
    if (hasLoadedReportData) {
      mergeState({ runQueryTriggered: false });
    }
  }, [hasLoadedReportData]);

  useEffect(() => {
    if (reportID) return;

    mergeState(initialState);
  }, [reportID]);

  useEffect(() => {
    if (
      !report ||
      has(location.state, "report") ||
      searchParamState.report_config
    ) {
      return;
    }

    mergeState({ ...getStateFromReport(report), runQueryTriggered: true });
  }, [report?.id]);

  useEffect(() => {
    if (!has(location.state, "report")) return;

    const report: ReportEntity = get(location.state, "report");
    const previousPage: string = get(location.state, "from");
    const previousPageTitle: string = get(location.state, "title");

    const reportState = getStateFromReport({ ...initialState, ...report });

    mergeState({
      ...reportState,
      ...(has(location.state, "runQueryTriggered")
        ? { runQueryTriggered: true }
        : {}),
      backButtonLabel: previousPageTitle,
      previousPage,
    });
  }, [location.state === null]);

  useEffect(() => {
    if (!searchParamState.report_config) return;

    const reportConfig: ReportEntity = searchParamState.report_config;

    mergeState({
      ...getStateFromReport({
        ...initialState,
        ...reportConfig,
      }),
      runQueryTriggered: true,
    });
  }, [searchParamState.report_config === undefined]);

  useEffect(() => {
    downloadCSV({
      data: csvResult.data.rows,
      fileName: `ternary-data-${formatDate(new Date(), "MM-dd-yyyy")}`,
      headers: csvResult.data.headers,
    });
  }, [csvResult.data]);

  useEffect(() => {
    if (!reportPDF || !state.hasClickedReportPDF || isLoadingReportPDF) {
      return;
    }

    const uint8Array = new Uint8Array(reportPDF);

    const content = new Blob([uint8Array], {
      type: "application/pdf",
    });

    const encodedUri = window.URL.createObjectURL(content);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${report?.name.replace(/ /g, "-")}-${formatDate(
        new Date(),
        "MM-dd-yyyy"
      )}`
    );

    link.click();

    mergeState({ hasClickedReportPDF: false });
  }, [state.hasClickedReportPDF, reportPDF]);

  //
  // Interaction Handlers
  //

  useCaptureViewReport(
    actions.VIEW_TRE_REPORT_BUILDER,
    reportDataMain.result.data,
    {
      ...reportDataConfig,
      scope: report?.scope ?? ReportScope.SHARED,
    }
  );

  function handleChangeChartType(chartType: string): void {
    if (!isChartType(chartType)) return;

    activityTracker.captureAction(actions.SELECT_TRE_CHANGE_REPORT_CHART_TYPE);

    mergeState({ chartType: chartType as ChartType, searchText: "" });
  }

  function handleChangeGranularity(granularity: string): void {
    if (!isTimeGranularity(granularity)) return;

    mergeState({ timeGranularity: granularity, runQueryTriggered: true });
  }

  function handleChangeLimit(value: string): void {
    let limit = isNaN(Number(value)) ? null : Number(value);

    value = limit?.toString() || value;

    if (limit === null) {
      value = "NaN";
    }
    if (limit !== null && limit > 50) {
      limit = 50;
      value = "50";
    }

    let newOptions = state.limitOptions;

    if (!state.limitOptions.find((option) => option === value)) {
      newOptions = [value, ...state.limitOptions];
    }

    mergeState({
      limit,
      limitOptions: newOptions,
      runQueryTriggered: true,
      searchText: "",
    });
  }

  function handleChangeXAxisKey(key: string): void {
    mergeState({ runQueryTriggered: true, xAxisKey: key });
  }

  function handleDownloadReportPDF(): void {
    if (!reportID) return;

    mergeState({ hasClickedReportPDF: true });
  }

  function handleFavoriteReport(): void {
    if (!reportID) return;

    const favoritedUserIDs = reportsKeyedByID[reportID].favoritedUserIDs;

    if (!favoritedUserIDs.includes(authenticatedUser.id)) {
      updateReport({
        reportID: reportID,
        favoritedUserIDs: [...favoritedUserIDs, authenticatedUser.id],
      });
    } else {
      updateReport({
        reportID: reportID,
        favoritedUserIDs: favoritedUserIDs.filter(
          (id) => id !== authenticatedUser.id
        ),
      });
    }
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createCustomMetric({
          tenantID: authenticatedUser.tenant.id,
          ...(interaction.bigQueryConfig
            ? { bigQueryConfig: interaction.bigQueryConfig }
            : {}),
          ...(interaction.csv ? { csv: interaction.csv } : {}),
          schema: interaction.schema,
          name: interaction.name,
        });
        break;
      }
      case CustomMetricForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!state.selectedCustomMetricID) return;

        updateCustomMetric({
          customMetricID: state.selectedCustomMetricID,
          csv: interaction.csv,
          schema: interaction.schema,
          name: interaction.name,
          status: JobStatus.IN_PROGRESS,
        });

        break;
      }
      case CustomMetricForm.INTERACTION_VALIDATE_BUTTON_CLICKED_UPDATE: {
        mergeState({ bigQueryConfig: interaction.bigQueryConfig });

        break;
      }
      case CustomMetricForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          action: undefined,
          bigQueryConfig: null,
          isSideDrawerOpen: false,
          selectedCustomMetricID: undefined,
        });
        break;
      }
      case CustomMetricListModal.INTERACTION_CREATE_CLICKED: {
        mergeState({ isSideDrawerOpen: true });
        break;
      }
      case CustomMetricListModal.INTERACTION_DELETE_CLICKED: {
        deleteCustomMetric({ customMetricID: interaction.customMetricID });
        break;
      }
      case CustomMetricListModal.INTERACTION_EDIT_CLICKED: {
        mergeState({
          isSideDrawerOpen: true,
          selectedCustomMetricID: interaction.customMetricID,
        });
        break;
      }
      case LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          runQueryTriggered: true,
          filters: uniqBy(
            [...currentState.filters, ...interaction.filters],
            (filter) => filter.schemaName
          ),
        }));
        break;
      }
      case CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED:
      case TimeSeriesDataTable.INTERACTION_SORT_TABLE_CLICKED: {
        if (state.chartType === ChartType.TABLE) {
          mergeState({
            runQueryTriggered: true,
            sortRule: interaction.sortRule,
          });
        }
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_ADD_DIMENSION_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_DIMENSION_NAME, {
          value: interaction.dimensions.map(
            (dimension) => dimension.schemaName
          ),
        });

        setState((currentState) => {
          const newDimensions = uniqBy(
            [...currentState.dimensions, ...interaction.dimensions],
            "schemaName"
          );

          const hasResourceID = interaction.dimensions.some(
            (dimension) =>
              dimension.schemaName ===
              detailedBillingSchema.dimensions.resourceID.schemaName
          );

          if (state.dataSource === DataSource.BILLING && hasResourceID) {
            return {
              ...currentState,
              dataSource: DataSource.DETAILED_BILLING,
              dimensions: newDimensions,
              searchText: "",
            };
          }
          return {
            ...currentState,
            dimensions: newDimensions,
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_ADD_FILTER_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_FILTER_NAME, {
          value: interaction.filter.schemaName,
        });

        setState((currentState) => ({
          ...currentState,
          filters: [...currentState.filters, interaction.filter],
          isDropdownOpen: false,
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_ADD_MEASURE_CLICKED: {
        console.log("interaction", interaction);
        activityTracker.captureAction(actions.SELECT_TRE_MEASURE_NAME, {
          value: interaction.measures.map((measure) => measure.schemaName),
        });

        setState((currentState) => {
          const newMeasures = uniqBy(
            [...currentState.measures, ...interaction.measures],
            "schemaName"
          );

          if (!currentState.showUnitEconomics) {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              measures: newMeasures,
              searchText: "",
            };
          }

          const totalLength =
            currentState.measures.length +
            interaction.measures.length +
            (currentState.metric.length > 0 ? 1 : 0);

          if (totalLength > 1 && currentState.formula === "") {
            if (currentState.metric) {
              return {
                ...currentState,
                formula: "A / X",
                formulaAlias: report?.formulaAlias ?? "",
                measures: newMeasures,
                searchText: "",
              };
            } else {
              return {
                ...currentState,
                formula: "A / B",
                formulaAlias: report?.formulaAlias ?? "",
                measures: newMeasures,
                searchText: "",
              };
            }
          }

          if (totalLength === 1 && currentState.formula !== "") {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              measures: newMeasures,
              searchText: "",
            };
          }

          if (report?.formula && currentState.formula === "") {
            return {
              ...currentState,
              formula: report.formula ?? "",
              formulaAlias: report.formulaAlias ?? "",
              measures: newMeasures,
              searchText: "",
            };
          }

          return {
            ...currentState,
            measures: newMeasures,
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_BLUR_TRIGGERED: {
        setState((currentState) => {
          if (!currentState.isDropdownOpen) {
            return { ...currentState };
          }

          return { ...currentState, isDropdownOpen: false };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_FILTER_DROPDOWN_TRIGGER_CLICKED: {
        setState((currentState) => {
          if (currentState.isDropdownOpen) {
            return { ...currentState };
          }

          return { ...currentState, isDropdownOpen: true };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_DIMENSION_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          dataSource:
            state.dataSource === DataSource.DETAILED_BILLING &&
            currentState.dimensions[interaction.index].schemaName ===
              "resourceId"
              ? DataSource.BILLING
              : state.dataSource,
          dimensions: [
            ...currentState.dimensions.slice(0, interaction.index),
            ...currentState.dimensions.slice(interaction.index + 1),
          ],
          searchText: "",
          xAxisKey: DEFAULT_X_AXIS_KEY,
        }));
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          filters: [
            ...currentState.filters.slice(0, interaction.index),
            ...currentState.filters.slice(interaction.index + 1),
          ],
        }));
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_REMOVE_MEASURE_CLICKED: {
        setState((currentState) => {
          const selectedMeasure = currentState.measures[interaction.index];

          if (selectedMeasure.schemaName === currentState.sortRule?.id) {
            currentState.sortRule = undefined;
          }

          if (!selectedMeasure) {
            return { ...currentState };
          }

          if (!currentState.showUnitEconomics) {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              hiddenMeasures: currentState.hiddenMeasures.filter(
                (measure) => measure !== selectedMeasure.schemaName
              ),
              measures: [
                ...currentState.measures.slice(0, interaction.index),
                ...currentState.measures.slice(interaction.index + 1),
              ],
              searchText: "",
            };
          }

          const totalLength =
            [
              ...currentState.measures.slice(0, interaction.index),
              ...currentState.measures.slice(interaction.index + 1),
            ].length + (currentState.metric.length > 0 ? 1 : 0);

          if (totalLength > 1 && currentState.formula !== "") {
            if (currentState.metric) {
              return {
                ...currentState,
                formula: "A / X",
                formulaAlias: report?.formulaAlias ?? "",
                hiddenMeasures: currentState.hiddenMeasures.filter(
                  (measure) => measure !== selectedMeasure.schemaName
                ),
                measures: [
                  ...currentState.measures.slice(0, interaction.index),
                  ...currentState.measures.slice(interaction.index + 1),
                ],
                searchText: "",
              };
            } else {
              return {
                ...currentState,
                formula: "A / B",
                formulaAlias: report?.formulaAlias ?? "",
                hiddenMeasures: currentState.hiddenMeasures.filter(
                  (measure) => measure !== selectedMeasure.schemaName
                ),
                measures: [
                  ...currentState.measures.slice(0, interaction.index),
                  ...currentState.measures.slice(interaction.index + 1),
                ],
                searchText: "",
              };
            }
          }

          if (totalLength === 1 && currentState.formula !== "") {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              hiddenMeasures: currentState.hiddenMeasures.filter(
                (measure) => measure !== selectedMeasure.schemaName
              ),
              measures: [
                ...currentState.measures.slice(0, interaction.index),
                ...currentState.measures.slice(interaction.index + 1),
              ],
              searchText: "",
            };
          }

          if (report?.formula && currentState.formula === "") {
            return {
              ...currentState,
              formula: report.formula ?? "",
              formulaAlias: report.formulaAlias ?? "",
              hiddenMeasures: currentState.hiddenMeasures.filter(
                (measure) => measure !== selectedMeasure.schemaName
              ),
              measures: [
                ...currentState.measures.slice(0, interaction.index),
                ...currentState.measures.slice(interaction.index + 1),
              ],
              searchText: "",
            };
          }

          return {
            ...currentState,
            hiddenMeasures: currentState.hiddenMeasures.filter(
              (measure) => measure !== selectedMeasure.schemaName
            ),
            measures: [
              ...currentState.measures.slice(0, interaction.index),
              ...currentState.measures.slice(interaction.index + 1),
            ],
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_REORDER_DIMENSIONS: {
        mergeState({ dimensions: interaction.dimensions });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_REORDER_MEASURES: {
        mergeState({ measures: interaction.measures });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_SELECT_SOURCE_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_SOURCE, {
          source: interaction.value,
        });

        mergeState({
          ...initialState,
          dataSource: interaction.value,
          dimensions:
            interaction.value === DataSource.DETAILED_BILLING
              ? [detailedBillingSchema.dimensions.resourceID]
              : [],
          durationType:
            interaction.value === DataSource.CARBON_FOOTPRINT
              ? DurationType.YEAR_TO_DATE
              : DurationType.LAST_THIRTY_DAYS,
          searchText: "",
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_TOGGLE_MEASURE: {
        const isHidden = state.hiddenMeasures.some(
          (measure) => measure === interaction.measure
        );

        setState((currentState) => ({
          ...currentState,
          hiddenMeasures: isHidden
            ? currentState.hiddenMeasures.filter(
                (measure) => measure !== interaction.measure
              )
            : [...currentState.hiddenMeasures, interaction.measure],
          searchText: "",
        }));

        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_DIMENSION_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_DIMENSION_NAME, {
          value: interaction.dimension.schemaName,
        });

        setState((currentState) => ({
          ...currentState,
          dimensions: [
            ...currentState.dimensions.slice(0, interaction.index),
            interaction.dimension,
            ...currentState.dimensions.slice(interaction.index + 1),
          ],
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_FILTER_NAME, {
          value: interaction.filter.schemaName,
        });

        setState((currentState) => ({
          ...currentState,
          filters: [
            ...currentState.filters.slice(0, interaction.index),
            interaction.filter,
            ...currentState.filters.slice(interaction.index + 1),
          ],
          isDropdownOpen: false,
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED: {
        setState((currentState) => {
          const filter = currentState.filters[interaction.index];

          let values: Filter["values"] = null;

          switch (interaction.operator) {
            case Operator.EQUALS:
            case Operator.NOT_EQUALS:
              values = filter.values ?? [];
              break;
            case Operator.CONTAINS:
            case Operator.NOT_CONTAINS:
              values =
                filter.values && filter.values[0] ? [filter.values[0]] : [];
              break;
            case Operator.NOT_SET:
            case Operator.SET:
              values = null;
              break;
          }

          return {
            ...currentState,
            filters: [
              ...currentState.filters.slice(0, interaction.index),
              { ...filter, operator: interaction.operator, values },
              ...currentState.filters.slice(interaction.index + 1),
            ],
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_FILTER_VALUES_CLICKED: {
        setState((currentState) => {
          const filter = currentState.filters[interaction.index];

          return {
            ...currentState,
            filters: [
              ...currentState.filters.slice(0, interaction.index),
              { ...filter, values: interaction.values },
              ...currentState.filters.slice(interaction.index + 1),
            ],
            isDropdownOpen: false,
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelMainTab.INTERACTION_UPDATE_MEASURE_CLICKED: {
        activityTracker.captureAction(actions.SELECT_TRE_MEASURE_NAME, {
          value: interaction.measure.schemaName,
        });

        setState((currentState) => ({
          ...currentState,
          measures: [
            ...currentState.measures.slice(0, interaction.index),
            interaction.measure,
            ...currentState.measures.slice(interaction.index + 1),
          ],
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_ADD_METRIC_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          metricFilters: [
            ...currentState.metricFilters,
            interaction.metricFilter,
          ],
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_ALIAS_CHANGED: {
        mergeState({
          formulaAlias: interaction.alias,
          searchText: "",
        });
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_CLOSE_SIDE_DRAWER: {
        mergeState({
          action: undefined,
          isSideDrawerOpen: false,
          selectedCustomMetricID: undefined,
        });
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_FLIP_FORMULA_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          formula: `${currentState.formula.slice(
            -1
          )} / ${currentState.formula.slice(0, 1)}`,
          searchText: "",
        }));

        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_REMOVE_METRIC_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          metricFilters: [
            ...currentState.metricFilters.slice(0, interaction.index),
            ...currentState.metricFilters.slice(interaction.index + 1),
          ],
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_SELECT_METRIC_AGGREGATE_CLICKED: {
        if (!isMetricAggregate(interaction.value)) return;

        setState((currentState) => ({
          ...currentState,
          metricAggregate: interaction.value,
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_SELECT_METRIC_CLICKED: {
        setState((currentState) => {
          if (!currentState.showUnitEconomics) {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              metric: interaction.id,
              searchText: "",
            };
          }
          const totalLength =
            currentState.measures.length + (interaction.id ? 1 : 0);

          if (totalLength > 1 && currentState.formula === "") {
            if (interaction.id) {
              return {
                ...currentState,
                formula: "A / X",
                formulaAlias: report?.formulaAlias ?? "",
                metric: interaction.id,
                searchText: "",
              };
            } else {
              return {
                ...currentState,
                formula: "A / B",
                formulaAlias: report?.formulaAlias ?? "",
                metric: interaction.id,
                searchText: "",
              };
            }
          }

          if (totalLength === 1 && currentState.formula !== "") {
            return {
              ...currentState,
              formula: "",
              formulaAlias: "",
              metric: interaction.id,
              searchText: "",
            };
          }

          if (report?.formula && currentState.formula === "") {
            return {
              ...currentState,
              formula: report.formula ?? "",
              formulaAlias: report.formulaAlias ?? "",
              metric: interaction.id,
              searchText: "",
            };
          }

          return {
            ...currentState,
            metric: interaction.id,
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_FORMULA: {
        setState((currentState) => ({
          ...currentState,
          isFormulaHidden: !currentState.isFormulaHidden,
          searchText: "",
        }));

        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC: {
        setState((currentState) => ({
          ...currentState,
          isMetricHidden: !currentState.isMetricHidden,
          searchText: "",
        }));

        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC_TABLE: {
        setState((currentState) => ({
          ...currentState,
          modalKey: MODAL_METRIC_LIST,
        }));

        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_CLICKED: {
        setState((currentState) => ({
          ...currentState,
          metricFilters: [
            ...currentState.metricFilters.slice(0, interaction.index),
            interaction.metricFilter,
            ...currentState.metricFilters.slice(interaction.index + 1),
          ],
          searchText: "",
        }));
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED: {
        setState((currentState) => {
          const filter = currentState.metricFilters[interaction.index];

          let values: Filter["values"] = null;

          switch (interaction.operator) {
            case Operator.EQUALS:
            case Operator.NOT_EQUALS:
              values = filter.values ?? [];
              break;
            case Operator.CONTAINS:
            case Operator.NOT_CONTAINS:
              values =
                filter.values && filter.values[0] ? [filter.values[0]] : [];
              break;
            case Operator.NOT_SET:
            case Operator.SET:
              values = null;
              break;
          }

          return {
            ...currentState,
            metricFilters: [
              ...currentState.metricFilters.slice(0, interaction.index),
              { ...filter, operator: interaction.operator, values },
              ...currentState.metricFilters.slice(interaction.index + 1),
            ],
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_VALUES_CLICKED: {
        setState((currentState) => {
          const metricFilter = currentState.metricFilters[interaction.index];

          return {
            ...currentState,
            metricFilters: [
              ...currentState.metricFilters.slice(0, interaction.index),
              { ...metricFilter, values: interaction.values },
              ...currentState.metricFilters.slice(interaction.index + 1),
            ],
            searchText: "",
          };
        });
        break;
      }
      case ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FORMULA: {
        // Due to above useEffect AND conditional rendering, we are guaranteed a formula in state here.
        if (interaction.index === 0) {
          setState((currentState) => {
            const newFormula = `${
              interaction.variableName
            }${currentState.formula.slice(1)}`;

            return {
              ...currentState,
              formula: newFormula,
              searchText: "",
            };
          });
        } else {
          setState((currentState) => {
            const newFormula = `${currentState.formula.slice(0, 4)}${
              interaction.variableName
            }`;

            return {
              ...currentState,
              formula: newFormula,
              searchText: "",
            };
          });
        }

        break;
      }
      case ReportListModal.INTERACTION_ROW_CLICKED: {
        const report = reportsKeyedByID[interaction.reportID];

        navigate(
          paths._reportBuilder.replace(":reportID", interaction.reportID),
          { state: { report } }
        );
        break;
      }
      case DashboardListModal.INTERACTION_ROW_CLICKED: {
        mergeState({ selectedDashboardID: interaction.dashboardID });

        if (reportID && state.action !== Action.COPY) {
          mergeState({ modalKey: MODAL_SAVE_EXISTING_TO_DASHBOARD });
        } else {
          mergeState({ modalKey: MODAL_CREATE_REPORT });
        }
        break;
      }
      case DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED: {
        mergeState({ modalKey: MODAL_CREATE_DASHBOARD });
        break;
      }
      case ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED: {
        const { reportID, report } = interaction;

        navigate(paths._reportBuilder.replace(":reportID", reportID), {
          state: { report },
        });
        return;
      }
    }
  }

  function handleCreateReport(params: {
    description?: string;
    name: string;
    scope: ReportScope;
    tags: string[];
  }): void {
    activityTracker.captureAction(actions.CLICK_TRE_CREATE_REPORT);

    const [compareStartDate, compareEndDate] = state.compareDateRange
      ? state.compareDurationType === DurationType.CUSTOM
        ? [
            state.compareDateRange[0].toISOString(),
            state.compareDateRange[1].toISOString(),
          ]
        : state.compareDurationType === DurationType.INVOICE
          ? [
              format(state.compareDateRange[0], "MM/yyyy"),
              format(state.compareDateRange[1], "MM/yyyy"),
            ]
          : [undefined, undefined]
      : [undefined, undefined];

    const [startDate, endDate] =
      state.dateRange && state.durationType === DurationType.CUSTOM
        ? [state.dateRange[0].toISOString(), state.dateRange[1].toISOString()]
        : [undefined, undefined];

    const [invoiceMonthStart, invoiceMonthEnd] =
      state.dateRange && state.durationType === DurationType.INVOICE
        ? [
            format(state.dateRange[0], "MM/yyyy"),
            format(state.dateRange[1], "MM/yyyy"),
          ]
        : [undefined, undefined];

    createReport({
      tenantID:
        params.scope === ReportScope.GLOBAL
          ? SYSTEM_TENANT_ID
          : authenticatedUser.tenant.id,
      name: params.name,
      chartType: state.chartType,
      ...(compareEndDate ? { compareEndDate } : {}),
      ...(compareStartDate ? { compareStartDate } : {}),
      compareDurationType: state.compareDurationType ?? undefined,
      isCumulative: state.isCumulative,
      dataSource: state.dataSource,
      description: params.description,
      dimensions: dimensions.length > 0 ? dimensions : undefined,
      durationType: state.durationType,
      ...(endDate ? { endDate } : {}),
      excludedCreditTypes:
        state.creditTypes.length > 0 ? state.creditTypes : undefined,
      excludeNegativeNumbers: state.excludeNegativeNumbers,
      excludeOther: state.excludeOther,
      filters: state.filters.length > 0 ? state.filters : undefined,
      formula: state.formula.length > 0 ? state.formula : undefined,
      formulaAlias:
        state.formulaAlias.length > 0 ? state.formulaAlias : undefined,
      hiddenMeasures: state.hiddenMeasures,
      isFormulaHidden: state.isFormulaHidden,
      isMetricHidden: state.isMetricHidden,
      ...(invoiceMonthEnd ? { invoiceMonthEnd } : {}),
      ...(invoiceMonthStart ? { invoiceMonthStart } : {}),
      ...(state.limit ? { limit: state.limit } : {}),
      measures: measures,
      metric: state.metric.length > 0 ? state.metric : undefined,
      metricAggregate:
        state.metric.length > 0 ? state.metricAggregate : undefined,
      metricFilters:
        state.metricFilters.length > 0 ? state.metricFilters : undefined,
      nLookback: state.nLookback,
      scope: params.scope,
      sortRule: state.sortRule,
      ...(startDate ? { startDate } : {}),
      tags: params.tags,
      timeGranularity: shouldApplyGranularity
        ? state.timeGranularity
        : undefined,
      type: ReportType.DEFAULT,
      reverse: state.reverse,
      xAxisKey: state.xAxisKey,
    });
  }

  function handleDeleteReport(): void {
    if (!reportID) return;

    activityTracker.captureAction(actions.CLICK_TRE_DELETE_REPORT);

    deleteReport({ reportID });
  }

  function handleResetReport(): void {
    if (location.pathname !== paths._reportBuilderNew) {
      navigate(paths._reportBuilderNew);
    }

    mergeState(initialState);
  }

  function handleSaveReport(): void {
    if (!reportID) {
      mergeState({ modalKey: MODAL_CREATE_REPORT });
      return;
    }

    const [compareStartDate, compareEndDate] = state.compareDateRange
      ? state.compareDurationType === DurationType.CUSTOM
        ? [
            state.compareDateRange[0].toISOString(),
            state.compareDateRange[1].toISOString(),
          ]
        : state.compareDurationType === DurationType.INVOICE
          ? [
              format(state.compareDateRange[0], "MM/yyyy"),
              format(state.compareDateRange[1], "MM/yyyy"),
            ]
          : [null, null]
      : [null, null];

    const [startDate, endDate] =
      state.dateRange && state.durationType === DurationType.CUSTOM
        ? [state.dateRange[0].toISOString(), state.dateRange[1].toISOString()]
        : [null, null];

    const [invoiceMonthStart, invoiceMonthEnd] =
      state.dateRange && state.durationType === DurationType.INVOICE
        ? [
            format(state.dateRange[0], "MM/yyyy"),
            format(state.dateRange[1], "MM/yyyy"),
          ]
        : [null, null];

    // TODO: Introduce logic to only send changes
    updateReport({
      reportID,
      compareDurationType: state.compareDurationType,
      compareEndDate,
      compareStartDate,
      chartType: state.chartType,
      endDate,
      dimensions: dimensions,
      durationType: state.durationType,
      excludedCreditTypes: state.creditTypes,
      excludeNegativeNumbers: state.excludeNegativeNumbers,
      excludeOther: state.excludeOther,
      filters: state.filters,
      formula: state.formula.length > 0 ? state.formula : null,
      formulaAlias: state.formulaAlias.length > 0 ? state.formulaAlias : null,
      hiddenMeasures: state.hiddenMeasures,
      invoiceMonthEnd,
      invoiceMonthStart,
      isCumulative: state.isCumulative,
      isFormulaHidden: state.isFormulaHidden,
      isMetricHidden: state.isMetricHidden,
      limit: state.limit ? state.limit : null,
      measures: measures,
      metric: state.metric.length > 0 ? state.metric : null,
      metricAggregate: state.metric.length > 0 ? state.metricAggregate : null,
      metricFilters: state.metricFilters,
      nLookback: state.nLookback,
      reverse: state.reverse,
      sortRule: state.sortRule ?? null,
      startDate,
      timeGranularity:
        state.chartType === ChartType.TABLE ? null : state.timeGranularity,
      xAxisKey: state.xAxisKey,
    });
  }

  function handleClickSaveReportToDashboard(action: Action): void {
    mergeState({ action: action, modalKey: MODAL_DASHBOARD_LIST });
  }

  function handleUpdateReport(params: {
    description?: string;
    name: string;
    tags: string[];
  }): void {
    if (!reportID) return;

    activityTracker.captureAction(actions.CLICK_TRE_UPDATE_REPORT);

    updateReport({
      reportID,
      name: params.name,
      description: params.description,
      tags: params.tags,
    });
  }
  function handleChangeSearchText(text: string): void {
    mergeState({ searchText: text.toLowerCase() });
  }

  const exportCSVOptions = [
    {
      label: copyText.exportButtonLabel,
      value: copyText.exportButtonLabel,
      onClick: handleClickCSV,
    },
    {
      label: copyText.exportTransposedButtonLabel,
      value: copyText.exportTransposedButtonLabel,
      onClick: handleClickCSV,
    },
  ];

  function handleClickCSV(cvsOption: string): void {
    if (!hasMeasure) {
      return;
    }

    setState((currentState) => {
      const currentCSVReport = currentState.csvReport;

      const newCSVReport = { ...reportDataConfig };

      if (currentCSVReport) {
        // increment ID to force new csv query
        let id = isNaN(Number(currentCSVReport.id))
          ? 0
          : Number(currentCSVReport.id);
        id++;
        newCSVReport.id = String(id);
      } else {
        newCSVReport.id = "0";
      }

      return {
        ...currentState,
        isTranspose:
          cvsOption === copyText.exportTransposedButtonLabel ? true : false,
        csvReport: newCSVReport,
      };
    });
  }

  function handleCreateDashboard(name: string) {
    createDashboard({
      tenantID: authenticatedUser.tenant.id,
      name,
      widgetSpecs: [],
      scope: DashboardScope.SHARED,
      type: DashboardType.DEFAULT,
    });
  }

  const disableCreditType = !state.measures.some(
    (measure) =>
      measure.schemaName === "netCost" ||
      measure.schemaName === "absoluteCredits"
  );

  function handleClickCheckbox(event: ChangeEvent<HTMLInputElement>): void {
    if (disableCreditType) return;

    const { checked, name } = event.target;

    const currentCreditType = `absoluteCredits${
      name.charAt(0).toUpperCase() + name.slice(1)
    }`;

    activityTracker.captureAction(actions.TOGGLE_TRE_REPORT_CREDIT_TYPES);

    setState((currentState) => ({
      ...currentState,
      creditTypes: checked
        ? currentState.creditTypes.filter(
            (creditType) => creditType !== currentCreditType
          )
        : [...currentState.creditTypes, currentCreditType],
      searchText: "",
    }));
  }

  function handleFilterCompactKpi(measure: Measure): void {
    if (state.chartType === ChartType.TABLE) return;

    if (measure.schemaName === state.filteredKpiMeasure?.schemaName) {
      mergeState({ filteredKpiMeasure: null });
    } else {
      mergeState({ filteredKpiMeasure: measure });
    }
  }

  //
  // Render
  //

  if (!gatekeeper.hasConfiguredDataIntegration) {
    return <NotConfiguredPlaceholder />;
  }

  const availableDimensions = useAvailableDimensionsByDataSourceV2(
    state.dataSource
  ).filter(
    (dimension) =>
      // Only include customReason if user can apply custom pricing
      (dimension.displayName !== "customReason" ||
        gatekeeper.canApplyCustomPricing) &&
      // Hide certain dimensions for child tenants
      (authenticatedUser.tenant.parentTenantID
        ? !hiddenChildTenantLabels.includes(dimension.schemaName)
        : true)
  );

  const availableMeasures = useAvailableMeasuresByDataSource(state.dataSource);

  const availableMetricDimensions = useAvailableDimensionsByDataSourceV2(
    DataSource.EXTERNAL_METRICS
  );

  const filteredMetricDimensions = useMemo(() => {
    if (!combinedExternalMetrics) return [];

    const selectedMetric = combinedExternalMetrics[state.metric];

    if (!selectedMetric) return [];

    return availableMetricDimensions.reduce((accum: Dimension[], dimension) => {
      return selectedMetric.groupings.some(
        (grouping) => grouping === dimension.schemaName
      )
        ? [...accum, dimension]
        : accum;
    }, []);
  }, [state.metric, combinedExternalMetrics]);

  const chartTypeOptions = state.compareDurationType
    ? [
        {
          label: copyText.chartTypeOptionLine,
          value: ChartType.LINE,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionTable,
          value: ChartType.TABLE,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionKPI,
          value: ChartType.KPI,
          onClick: handleChangeChartType,
        },
      ]
    : [
        {
          label: copyText.chartTypeOptionLine,
          value: ChartType.LINE,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionArea,
          value: ChartType.AREA,
          onClick: handleChangeChartType,
        },

        {
          label: copyText.chartTypeOptionStackedArea,
          value: ChartType.STACKED_AREA,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionStackedBar,
          value: ChartType.STACKED_BAR,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionClusteredBar,
          value: ChartType.CLUSTERED_BAR,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionPie,
          value: ChartType.PIE,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionKPI,
          value: ChartType.KPI,
          onClick: handleChangeChartType,
        },
        {
          label: copyText.chartTypeOptionTable,
          value: ChartType.TABLE,
          onClick: handleChangeChartType,
        },
      ];

  const chartTypeOption = chartTypeOptions.find(
    (option) => option.value === state.chartType
  );

  const granularityOptions = [
    {
      label: copyText.granularityOptionLabelHour,
      value: TimeGranularity.HOUR,
      onClick: handleChangeGranularity,
    },
    {
      label: copyText.granularityOptionLabelDay,
      value: TimeGranularity.DAY,
      onClick: handleChangeGranularity,
    },
    {
      label: copyText.granularityOptionLabelWeek,
      value: TimeGranularity.WEEK,
      onClick: handleChangeGranularity,
    },
    {
      label: copyText.granularityOptionLabelMonth,
      value: TimeGranularity.MONTH,
      onClick: handleChangeGranularity,
    },

    {
      label: copyText.granularityOptionLabelQuarter,
      value: TimeGranularity.QUARTER,
      onClick: handleChangeGranularity,
    },
  ];

  const granularityOption = granularityOptions.find(
    (option) => option.value === state.timeGranularity
  );

  const allLimitOptions = uniq([
    ...state.limitOptions,
    ...defaultLimitOptions,
    ...(state.limit ? [String(state.limit)] : []),
  ])
    .sort((a, b) => {
      if (a === b) return 0;
      if (a === "NaN") return 1;
      if (b === "NaN") return -1;
      return Number(a) - Number(b);
    })
    .map((option) => ({
      label: getLimitLabelWithMeasure(
        measures,
        option === "NaN"
          ? copyText.limitOptionLabelShowAll
          : copyText.limitOptionLabelTop.replace("%LIMIT%", option)
      ),
      value: option,
    }));

  const limitOption = allLimitOptions.find(
    (option) =>
      (isNaN(Number(option.value)) ? null : Number(option.value)) ===
      state.limit
  );

  const defaultXAxisKeyOption = {
    label: copyText.xAxisKeyOptionDefaultTime,
    value: DEFAULT_X_AXIS_KEY,
    onClick: handleChangeXAxisKey,
  };

  const dimensionOptions = dimensions.map((dimension) => {
    return {
      label: dimension.displayName,
      value: dimension.schemaName,
      onClick: handleChangeXAxisKey,
    };
  });

  const xAxisKeyOptions = [defaultXAxisKeyOption, ...dimensionOptions];

  const xAxisKeyOption = xAxisKeyOptions.find(
    (option) => option.value === state.xAxisKey
  );

  const isTimeSeries =
    (xAxisKey === DEFAULT_X_AXIS_KEY || xAxisKey === "invoiceMonth") &&
    state.chartType !== ChartType.TABLE &&
    state.chartType !== ChartType.PIE;

  const isGlobalReport = report?.scope === ReportScope.GLOBAL;

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const reports = _reports.map((report) => {
    const createdByUser = usersKeyedByID[report.createdByID];
    const updatedByUser = usersKeyedByID[report.updatedByID ?? ""];

    return {
      ...report,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const dashboards = _dashboards.map((dashboard) => {
    const createdByUser = usersKeyedByID[dashboard.createdByID];
    const updatedByUser = usersKeyedByID[dashboard.updatedByID ?? ""];

    return {
      ...dashboard,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const reportsKeyedByID = keyBy(reports, "id");

  const existingTags = useMemo(() => {
    if (!reports) return [];

    return reports.reduce((accum: string[], report) => {
      report.tags.forEach((tag) => {
        if (!accum.includes(tag)) {
          accum.push(tag);
        }
      });
      return accum;
    }, []);
  }, [reports]);

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_CREATE_REPORT: {
        const name = reportID
          ? `${report?.name} (${copyText.reportNameCopyLabel})`
          : undefined;

        return (
          <ReportFormModal
            existingTags={existingTags}
            isCreateModal
            isGlobalReport={isGlobalReport}
            isProcessing={isCreatingReport}
            report={{ ...report, name }}
            title={`${copyText.modalTitleCreateReport} ${
              selectedDashboard
                ? copyText.modalTitleCreateAndSaveToDashboard.replace(
                    "%dashboard%",
                    selectedDashboard?.name
                  )
                : ""
            }`}
            onClose={() =>
              mergeState({ selectedDashboardID: undefined, modalKey: "" })
            }
            onSubmit={handleCreateReport}
          />
        );
      }
      case MODAL_DELETE_REPORT: {
        return (
          <ConfirmationModal
            isLoading={isDeletingReport}
            message={copyText.deleteReportConfirmationMessage}
            title={copyText.deleteReportConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteReport}
          />
        );
      }
      case MODAL_METRIC_LIST: {
        return (
          <CustomMetricListModal
            isLoading={isDeletingCustomMetric || isLoadingCustomMetrics}
            customMetrics={customMetrics ?? []}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_REPORT_LIST: {
        return (
          <ReportListModal
            isLoading={isLoadingReports}
            reports={reports ?? []}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_UPDATE_REPORT: {
        return (
          <ReportFormModal
            existingTags={existingTags}
            isGlobalReport={isGlobalReport}
            isProcessing={isUpdatingReport}
            report={report}
            title={copyText.modalTitleEditReport}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleUpdateReport}
          />
        );
      }
      case MODAL_SUBSCRIBE_REPORT: {
        if (report) {
          return (
            <ReportSubscriptionContainer
              isLoadingReport={isLoadingReport}
              report={report}
              users={users}
              refetch={refetchReport}
              onClose={() => mergeState({ modalKey: "" })}
            />
          );
        }
        return null;
      }
      case MODAL_DASHBOARD_LIST: {
        return (
          <DashboardListModal
            dashboards={dashboards}
            isLoading={isLoadingDashboards}
            title={copyText.modalTitleSaveToDashboard}
            onClose={() =>
              mergeState({ selectedDashboardID: undefined, modalKey: "" })
            }
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_SAVE_EXISTING_TO_DASHBOARD: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingReport}
            message={copyText.modalTitleConfirmSaveExistingReportToDashboard
              .replace("%report%", report?.name ?? "report")
              .replace("%dashboard%", selectedDashboard?.name ?? "dashboard")}
            title={copyText.modalTitleConfirm}
            onCancel={() =>
              mergeState({ selectedDashboardID: undefined, modalKey: "" })
            }
            onConfirm={handleSaveReport}
          />
        );
      }
      case MODAL_CREATE_DASHBOARD: {
        return (
          <NameInputModal
            isProcessing={isCreatingDashboard}
            title={copyText.modalTitleCreateDashboard}
            onClose={() =>
              mergeState({ selectedDashboardID: undefined, modalKey: "" })
            }
            onSubmit={handleCreateDashboard}
          />
        );
      }
      case MODAL_UPDATE_SYSTEM_REPORT: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingReport}
            message={copyText.modalMessageUpdateSystemReport}
            title={copyText.modalTitleEditSystemReport}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleSaveReport}
          />
        );
      }
      default:
        return null;
    }
  }

  const { canRunReport, tooltipMessage } = useMemo(() => {
    const tooltipMessage: string[] = [];
    const isRunQueryButtonDisabled = reportDataMain.result.data.length > 0;

    if (state.dataSource === DataSource.DETAILED_BILLING) {
      if (!gatekeeper.isConfiguredResourceLevelBilling) {
        return {
          canRun: true,
          tooltipMessage: [copyText.notConfiguredPlaceholderText],
        };
      }

      let isValidDate = true;
      if (dimensions.length > 3) {
        tooltipMessage.push(copyText.disableAddGroupingTooltipCaption);
      }
      if (state.dateRange) {
        const startDate = new Date(state.dateRange[0]);
        const endDate = new Date(state.dateRange[1]);
        isValidDate = isValidDetailedBillingDate(startDate, endDate);
      }

      if (!isValidDate) {
        tooltipMessage.push(copyText.disableDateControlTooltipCaption);
      }
      return {
        canRunReport:
          isRunQueryButtonDisabled ||
          dimensions.length > 3 ||
          !isValidDate ||
          !measures.length,
        tooltipMessage,
      };
    }

    return {
      canRun: isRunQueryButtonDisabled || !measures.length,
      tooltipMessage,
    };
  }, [measures, state.dateRange, dimensions, reportDataMain.result.data]);

  const isLargeDataSet = reportDataMain.result.data.length >= MAX_ROWS;

  const isLoading = (!!reportID && isLoadingReport) || isLoadingReportData;

  const hasIncompleteFilter = state.filters.some(
    (filter) => filter.values && filter.values.length === 0
  );

  const canSaveReport = report
    ? isStateDifferentThanReport(state, report) &&
      !hasIncompleteFilter &&
      (hasMeasure || state.showUnitEconomics)
    : (!hasIncompleteFilter && hasMeasure) || state.metric.length > 0;

  const canSaveAs =
    !!reportID &&
    !hasIncompleteFilter &&
    (hasMeasure || state.showUnitEconomics);

  const canSaveToDashboard =
    !hasIncompleteFilter && (hasMeasure || state.showUnitEconomics);

  const isSystemReportCopy = report && report.type === ReportType.SYSTEM_COPY;

  const saveOptions = [
    {
      label: copyText.actionMenuItemSaveAsReport,
      locked:
        !gatekeeper.canCreateReports || !canSaveReport || isSystemReportCopy,
      onClick: () => {
        if (report?.tenantID === SYSTEM_TENANT_ID) {
          mergeState({ modalKey: MODAL_UPDATE_SYSTEM_REPORT });
          return;
        }

        handleSaveReport();
      },
    },
    {
      label: copyText.actionMenuItemSaveToDashboard,
      locked: !gatekeeper.canCreateReports || !canSaveToDashboard,
      onClick: () => {
        handleClickSaveReportToDashboard(Action.SAVE);
      },
    },
  ];

  const saveAsOptions = [
    {
      label: copyText.actionMenuItemSaveAsReport,
      locked: !gatekeeper.canCreateReports || !canSaveAs,
      onClick: () => mergeState({ modalKey: MODAL_CREATE_REPORT }),
    },
    {
      label: copyText.actionMenuItemSaveToDashboard,
      locked: !gatekeeper.canCreateReports || !canSaveToDashboard,
      onClick: () => {
        handleClickSaveReportToDashboard(Action.COPY);
      },
    },
  ];

  const hasExcludedCreditTypes =
    !!report && report.excludedCreditTypes.length > 0;
  const isComparisonMode = !!report?.compareDurationType;
  const isCumulative = !!report?.isCumulative;
  const isReverse = !!report?.reverse;

  const canExportReportPDF =
    report &&
    !canSaveReport &&
    !impactMode &&
    !isComparisonMode &&
    !isCumulative &&
    !isLargeDataSet &&
    !isReverse &&
    !report.excludeNegativeNumbers &&
    reportID !== undefined &&
    report.chartType !== ChartType.KPI &&
    report.excludedCreditTypes.length === 0 &&
    reportDataMain.result.data.length > 0;

  const tooltipText = getTooltipText({
    canSaveReport,
    hasExcludedCreditTypes,
    isComparisonMode,
    isImpactMode: impactMode,
    isKPI: report?.chartType === ChartType.KPI,
    isLargeDataSet,
    isCumulative,
    isReverse,
    reportID,
  });

  const reportOptions = [
    {
      label: copyText.actionMenuItemSubscribeToReport,
      locked: !gatekeeper.canUpdateReports,
      onClick: () => mergeState({ modalKey: MODAL_SUBSCRIBE_REPORT }),
    },
    {
      label: copyText.actionMenuItemExportReportPDF,
      locked:
        !canExportReportPDF || state.hasClickedReportPDF || isLoadingReportPDF,
      tooltipText: tooltipText,
      onClick: handleDownloadReportPDF,
    },
  ];

  // If System report copy, should not be able to edit or delete.
  if (!isSystemReportCopy) {
    reportOptions.unshift(
      {
        label: copyText.newReportButtonLabel,
        locked: false,
        onClick: () => handleResetReport(),
      },
      {
        label: copyText.actionMenuItemEditReport,
        locked: !gatekeeper.canUpdateReports,
        onClick: () => mergeState({ modalKey: MODAL_UPDATE_REPORT }),
      },
      {
        label: copyText.actionMenuItemDeleteReport,
        locked: !report
          ? false
          : !gatekeeper.getCanDeleteSpecificReport(report.createdByID),
        onClick: () => mergeState({ modalKey: MODAL_DELETE_REPORT }),
      }
    );
  }

  const dateRangeFromDurationType = useMemo(
    () => getCubeDateRangeFromDurationType(state.durationType),
    [state.durationType]
  );

  const compareDateRangeFromDurationType = useMemo(
    () =>
      state.compareDateRange
        ? state.compareDateRange
        : getCompareDateRangeFromDurationType({
            dateRange: dateRangeFromDurationType,
            durationType: state.durationType,
          }),
    [state.compareDateRange, dateRange, state.durationType]
  );

  const customMetrics = _customMetrics?.map((_metric) => ({
    ..._metric,
    createdBy: usersKeyedByID[_metric.createdByID]
      ? usersKeyedByID[_metric.createdByID].email
      : "--",
  }));

  const selectedCustomMetric = customMetrics?.find(
    (metric) => metric.id === state.selectedCustomMetricID
  );

  //
  // JSX
  //

  if (caseManagementStore.isResourceSelectionMode && report) {
    return (
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        height="100%"
        width="100%"
        alignItems="center"
      >
        <Box
          key={report?.id + " " + String(0)}
          height="80vh"
          position="relative"
          width="80vw"
        >
          <ReportViewContainer
            dashboardID=""
            index={0}
            report={{ ...report, ...reportDataConfig }}
            onInteraction={handleInteraction}
          />
        </Box>
      </Flex>
    );
  }

  function renderHeaderSection() {
    const isFavorited = report
      ? report.favoritedUserIDs.includes(authenticatedUser.id)
      : false;

    return (
      <>
        <Button
          iconStart={<Icon icon={faArrowLeftLong} />}
          marginBottom={theme.space_xs}
          secondary
          size="small"
          width="fit-content"
          onClick={() => {
            navigate(state.previousPage ? state.previousPage : paths._reports);
          }}
        >
          {copyText.backButtonLabel.replace(
            "%TITLE",
            state.backButtonLabel ?? copyText.reportsPageTitle
          )}
        </Button>

        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={theme.space_md}
          padding={theme.space_xxs}
        >
          {reportID && isLoadingReport ? (
            <BarLoader height={40} width={500} />
          ) : (
            <Box flex="0 1 100%" minWidth={0}>
              <Text fontSize={theme.h3_fontSize} truncate>
                {report ? report.name : copyText.reportNamePlaceholder}
              </Text>

              {report && report.updatedAt && (
                <Text
                  color={theme.text_color_caption}
                  lineHeight={1}
                  marginTop=".25rem"
                >
                  {getTimeLastUpdatedCaption(report.updatedAt)}
                </Text>
              )}
            </Box>
          )}
          <Flex justifyContent="space-between" marginLeft={theme.space_xl}>
            <Dropdown
              disabled={
                !canSaveReport ||
                (isGlobalReport && !gatekeeper.canUpdateSystemReport)
              }
              options={saveOptions}
              placement="bottom-start"
            >
              <Button
                locked={
                  (!gatekeeper.canCreateReports && !report) ||
                  (!gatekeeper.canUpdateReports && !!report)
                }
                marginRight={theme.space_xs}
                primary={canSaveReport || canSaveToDashboard}
                secondary={!canSaveReport && !canSaveToDashboard}
                size="small"
              >
                {copyText.saveButtonLabel}
              </Button>
            </Dropdown>
            <Dropdown
              disabled={!canSaveAs}
              options={saveAsOptions}
              placement="bottom-start"
            >
              <Button
                locked={!gatekeeper.canCreateReports}
                marginRight={theme.space_xs}
                secondary={!canSaveAs}
                size="small"
              >
                {copyText.saveAsReportButtonLabel}
              </Button>
            </Dropdown>
            <Box>
              <FavoriteButton
                isLoading={isLoadingReport}
                disabled={!report}
                favorited={isFavorited}
                marginRight={theme.space_xs}
                size="small"
                onClick={handleFavoriteReport}
              />
            </Box>
            <Dropdown
              disabled={!report}
              options={reportOptions}
              placement="bottom-end"
            >
              <Button
                iconStart={<Icon icon={faEllipsisH} />}
                secondary={!report}
                size="small"
              />
            </Dropdown>
          </Flex>
        </Flex>
      </>
    );
  }

  function renderControlsBarSection() {
    const isKPIChart = state.chartType === ChartType.KPI;
    return (
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        justifyContent="space-between"
        marginBottom={theme.space_sm}
        paddingHorizontal={theme.space_xs}
        paddingVertical={theme.space_sm}
        width="100%"
      >
        <Flex>
          <DropdownButton
            button={
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="small"
              />
            }
            height={350}
            placement="bottom-start"
          >
            <Flex padding={theme.space_xs}>
              <Box marginRight={theme.space_sm} width={250}>
                <Box marginBottom={theme.space_md}>
                  <Text appearance="h4">{copyText.limitHeader}</Text>
                  <SelectDropdown
                    createDialogue={(input) =>
                      copyText.addLimitValueDialogue.replace("%VALUE%", input)
                    }
                    createOption={(inputValue) => ({
                      label: inputValue,
                      value: inputValue,
                    })}
                    disabled={isKPIChart}
                    options={allLimitOptions}
                    isCreatable
                    isNumberInput
                    onCreateOption={(value) => {
                      setState((currentState) => ({
                        ...currentState,
                        limitOptions: [...currentState.limitOptions, value],
                      }));
                    }}
                    onChange={(value) => handleChangeLimit(value)}
                  >
                    <Button
                      fullWidth
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      type="button"
                    >
                      <Text truncate={150}>
                        {isKPIChart
                          ? copyText.limitOptionLabelShowAll
                          : limitOption?.label}
                      </Text>
                    </Button>
                  </SelectDropdown>
                </Box>
                <Flex direction="column">
                  <Box marginBottom={theme.space_xs}>
                    <LabeledSwitch
                      label={copyText.showOther}
                      checked={!state.excludeOther}
                      disabled={isKPIChart}
                      onChange={(checked) =>
                        mergeState({
                          excludeOther: !checked,
                          runQueryTriggered: true,
                        })
                      }
                    />
                  </Box>
                </Flex>
              </Box>
              <Box marginRight={theme.space_sm} width={250}>
                <Box marginBottom={theme.space_md} width="100%">
                  <Text appearance="h4">{copyText.xAxisHeader}</Text>
                  <Dropdown
                    disabled={
                      state.chartType === ChartType.PIE ||
                      state.chartType === ChartType.TABLE
                    }
                    options={xAxisKeyOptions}
                    placement="bottom-end"
                    selectedOption={xAxisKeyOption}
                    truncate
                    width={250}
                  >
                    <Button
                      fullWidth
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      type="button"
                    >
                      <Text truncate={150}>{xAxisKeyOption?.label}</Text>
                    </Button>
                  </Dropdown>
                </Box>
                <Box marginBottom={theme.space_xs}>
                  <LabeledSwitch
                    checked={isTimeSeries && state.isCumulative}
                    disabled={!isTimeSeries || !!state.compareDurationType}
                    label={copyText.cumulative}
                    onChange={(cumulative) =>
                      mergeState({
                        isCumulative: cumulative,
                        runQueryTriggered: true,
                      })
                    }
                  />
                </Box>
                <Box marginBottom={theme.space_xs}>
                  <LabeledSwitch
                    checked={Boolean(state.reverse)}
                    label={copyText.reverse}
                    onChange={(reverse) =>
                      mergeState({ reverse, runQueryTriggered: true })
                    }
                  />
                </Box>
                <Box>
                  <LabeledSwitch
                    checked={Boolean(state.excludeNegativeNumbers)}
                    label={copyText.excludeNegativesLabel}
                    tooltipCaption={copyText.excludeNegativesToolTipCaption}
                    onChange={(checked) =>
                      mergeState({ excludeNegativeNumbers: checked })
                    }
                  />
                </Box>
              </Box>
              <Flex
                direction="column"
                justifyContent="space-between"
                height={200}
                width={280}
              >
                <Text appearance="h4">{copyText.creditsHeader}</Text>
                <Checkbox
                  name="committedUsageDiscount"
                  checked={
                    !state.creditTypes.includes(
                      "absoluteCreditsCommittedUsageDiscount"
                    )
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxCommitedUsageDiscountLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="committedUsageDiscountDollarBase"
                  checked={
                    !state.creditTypes.includes(
                      "absoluteCreditsCommittedUsageDiscountDollarBase"
                    )
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxCommitedUsageDiscountDollarBaseLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="discount"
                  checked={
                    !state.creditTypes.includes("absoluteCreditsDiscount")
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxDiscountLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="freeTier"
                  checked={
                    !state.creditTypes.includes("absoluteCreditsFreeTier")
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxFreeTierLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="promotion"
                  checked={
                    !state.creditTypes.includes("absoluteCreditsPromotion")
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxPromotionLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="subscriptionBenefit"
                  checked={
                    !state.creditTypes.includes(
                      "absoluteCreditsSubscriptionBenefit"
                    )
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxSubscriptionBenefitLabel}
                  onChange={handleClickCheckbox}
                />
                <Checkbox
                  name="sustainedUsageDiscount"
                  checked={
                    !state.creditTypes.includes(
                      "absoluteCreditsSustainedUsageDiscount"
                    )
                  }
                  disabled={disableCreditType}
                  label={copyText.checkboxSustainedUsageDiscountLabel}
                  onChange={handleClickCheckbox}
                />
              </Flex>
            </Flex>
          </DropdownButton>
          <Dropdown
            options={chartTypeOptions}
            placement="bottom-start"
            selectedOption={chartTypeOption}
          >
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              marginHorizontal={theme.space_xs}
              secondary
              size="small"
            >
              {chartTypeOption?.label}
            </Button>
          </Dropdown>
          <Dropdown
            disabled={
              state.chartType === ChartType.PIE ||
              state.chartType === ChartType.TABLE ||
              (xAxisKey !== DEFAULT_X_AXIS_KEY && xAxisKey !== "invoiceMonth")
            }
            options={granularityOptions}
            placement="bottom-start"
            selectedOption={granularityOption}
          >
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
            >
              {granularityOption?.label}
            </Button>
          </Dropdown>
        </Flex>
        <Flex>
          <DateRangeControls
            chartType={state.chartType}
            compareDurationType={state.compareDurationType}
            compareDateRange={state.compareDateRange}
            dateRange={state.dateRange}
            dataSource={state.dataSource}
            durationType={state.durationType}
            maxDate={today}
            hiddenOptions={
              impactMode
                ? [
                    DurationType.YESTERDAY,
                    DurationType.LAST_SEVEN_DAYS,
                    DurationType.LAST_THIRTY_DAYS,
                    DurationType.MONTH_TO_DATE,
                    DurationType.QUARTER_TO_DATE,
                  ]
                : [
                    DurationType.QUARTER_TO_DATE,
                    ...(state.isPanelOpen
                      ? [DurationType.LAST_MONTH, DurationType.YESTERDAY]
                      : []),
                  ]
            }
            isFiscalMode={authenticatedUser.settings.fiscalMode}
            n={state.nLookback}
            showRollingLookback
            onChangeCompareDateRange={(
              durationType: CompareDurationType | null,
              dateRange
            ) =>
              mergeState({
                compareDurationType: durationType,
                compareDateRange: dateRange,
                runQueryTriggered: true,
              })
            }
            onChangeDateRange={(durationType, dateRange) => {
              mergeState({
                dateRange,
                durationType,
                nLookback: null,
                runQueryTriggered: true,
                searchText: "",
              });
            }}
            onChangeRollingLookback={(durationType, nLookback) => {
              mergeState({
                dateRange: null,
                durationType,
                nLookback,
                runQueryTriggered: true,
              });
            }}
          />
          <Flex
            alignItems="center"
            justifyContent="center"
            marginLeft={theme.space_sm}
            overflowX="hidden"
            transition={"width ease 0.5s"}
            width={state.compareDurationType ? 80 : 0}
          >
            <Button
              iconEnd={<Icon icon={faPercent} />}
              secondary={
                state.meterComparisonFormat === ComparisonFormat.PERCENT
              }
              size="small"
              onClick={() =>
                mergeState({ meterComparisonFormat: ComparisonFormat.PERCENT })
              }
            />
            <Button
              iconEnd={<Icon icon={faHashtag} />}
              marginLeft={theme.space_xxs}
              secondary={state.meterComparisonFormat === ComparisonFormat.UNIT}
              size="small"
              onClick={() =>
                mergeState({ meterComparisonFormat: ComparisonFormat.UNIT })
              }
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }

  function getMostRecentUniqueObjects(data: RawData[]): RawData[] {
    const lastTimestamp = data?.[data.length - 1]?.timestamp ?? 0;
    return data.filter((datum) => datum.timestamp === lastTimestamp);
  }

  function renderCompactKpis(): ReactNode | null {
    const isComparisonMode = state.compareDateRange !== null;
    const reportData = reportDataMain.result.data;

    const shouldOpen = !isLoadingReportData
      ? state.measures.length > 0 && hasLoadedReportData
      : isLoadingReportData;

    const _measures = state.measures.filter(
      (measure) => !state.hiddenMeasures.includes(measure.schemaName)
    );

    const kpiTotals = _measures.map((measure) => {
      const data = state.isCumulative
        ? getMostRecentUniqueObjects(reportData)
        : reportData;

      const result = data.reduce((accum: RawData, datum) => {
        if (!accum[measure.schemaName]) {
          accum = { ...datum };
        } else {
          for (const key in datum) {
            if (
              typeof accum[key] === "number" &&
              typeof datum[key] === "number"
            ) {
              accum[key] = (accum[key] || 0) + datum[key];
            }
          }
        }

        return accum;
      }, {});

      //NOTE: Replace this reduce with Datalligator result when available
      return result;
    });

    let width = isComparisonMode ? 35 : Math.floor(100 / state.measures.length);

    width = width < 18 ? 25 : width;

    const height = isComparisonMode ? 100 : 80;

    if (state.chartType === ChartType.KPI || state.measures.length === 0) {
      return null;
    }

    return (
      <Box
        height={shouldOpen ? height : 0}
        marginBottom={shouldOpen ? theme.space_sm : 0}
        transition={"height ease 0.5s"}
        position="relative"
      >
        <Grid
          gridAutoColumns={`${width}%`}
          gridAutoFlow="column"
          gridColumnGap={theme.space_xs}
          gridTemplateColumns={`repeat(auto-fit, minmax(${width - 2}%, 1fr))`}
          overflowX="auto"
        >
          {isLoading
            ? state.measures.map((_, i) => (
                <Flex
                  key={i}
                  backgroundColor={theme.panel_backgroundColor}
                  borderRadius={theme.borderRadius_3}
                  height={height}
                  justifyContent="center"
                  paddingHorizontal={theme.space_xs}
                  paddingVertical={theme.space_sm}
                >
                  <EmptyPlaceholder
                    height="100%"
                    icon={faList}
                    loading
                    skeletonVariant="meter"
                    small
                    width={`${width}`}
                  />
                </Flex>
              ))
            : _measures.map((measure) => {
                const isFilteredMeasure =
                  state.filteredKpiMeasure &&
                  state.filteredKpiMeasure.schemaName.includes(
                    measure.schemaName
                  );

                const measures = chartMeasures.filter((chartMeasure) =>
                  chartMeasure.schemaName.includes(measure.schemaName)
                );

                if (isComparisonMode) {
                  measures.push({
                    displayName: RAW_DIFFERENCE_KEY,
                    providerType: measure.providerType,
                    schemaName: RAW_DIFFERENCE_KEY,
                    unit: measure.unit,
                  });
                }

                return (
                  <Box
                    border={
                      isFilteredMeasure
                        ? `solid 2px ${theme.primary_color_border}`
                        : "none"
                    }
                    backgroundColor={
                      state.filteredKpiMeasure === null
                        ? theme.panel_backgroundColor
                        : !isFilteredMeasure
                          ? theme.secondary_color_background_hover
                          : theme.panel_backgroundColor
                    }
                    backgroundColorOnHover={
                      state.chartType === ChartType.TABLE ||
                      _measures.length === 1
                        ? undefined
                        : !isFilteredMeasure
                          ? theme.secondary_color_background_hover
                          : theme.secondary_color_background
                    }
                    borderRadius={theme.borderRadius_3}
                    cursor="pointer"
                    height={height}
                    onClick={() => {
                      if (_measures.length === 1) return noop();
                      handleFilterCompactKpi(measure);
                    }}
                  >
                    <KPIChart
                      compact
                      data={kpiTotals}
                      durationType={state.durationType}
                      isFiscalMode={false}
                      isLoading={false}
                      isServerChart={false}
                      measures={measures}
                      unitDisplay={state.meterComparisonFormat}
                    />
                  </Box>
                );
              })}
        </Grid>
        {width * _measures.length > 100 && (
          <Box
            height="30px"
            position="absolute"
            right="10px"
            top="50%"
            transform="translateY(-50%)"
            width="30px"
          >
            <Icon
              icon={faChevronRight}
              size="2x"
              color={theme.secondary_color_background_hover}
            />
          </Box>
        )}
      </Box>
    );
  }

  function renderPlaceholder(placeholder): ReactNode {
    return (
      <>
        <Icon
          color={impactMode ? theme.eco_impact : theme.text_color_secondary}
          icon={faChartLine}
          size="8x"
        />
        <Text
          appearance="h3"
          color={impactMode ? theme.eco_impact : theme.text_color_secondary}
        >
          {placeholder}
        </Text>
      </>
    );
  }

  function renderChart(): ReactNode {
    const dataConfig = { ...reportDataConfig };

    const filteredKPIMeasure = state.filteredKpiMeasure ?? null;

    dataConfig.measures = filteredKPIMeasure
      ? dataConfig.measures.filter((measure) =>
          filteredKPIMeasure.schemaName.includes(measure.schemaName)
        )
      : reportDataConfig.measures;

    return (
      <ReportChart
        data={reportDataMain.result.data}
        isLoading={isLoading}
        report={reportDataConfig}
        showLegend
        showToolTip
        onInteraction={handleInteraction}
      />
    );
  }

  function renderTable(): ReactNode {
    return (
      <>
        <Flex
          alignItems="center"
          borderRadius={theme.borderRadius_2}
          justifyContent="space-between"
          marginBottom={theme.space_md}
          width="100%"
        >
          <Box width="20rem">
            <TextInput
              placeholder={copyText.textInputLabel}
              size="medium"
              value={state.searchText}
              onChange={(e) => handleChangeSearchText(e.target.value)}
            />
          </Box>
          <Flex>
            {reportDataConfig.chartType !== ChartType.KPI &&
              reportDataConfig.chartType !== ChartType.PIE &&
              reportDataConfig.chartType !== ChartType.TABLE && (
                <Button
                  onClick={() => {
                    activityTracker.captureAction(
                      actions.SELECT_TRE_CHANGE_REPORT_CHART_TABLE_COLUMN_TYPE,
                      {
                        value: state.showTimeSeriesTable
                          ? "Time Series"
                          : "Grouped",
                      }
                    );

                    mergeState({
                      showTimeSeriesTable: !state.showTimeSeriesTable,
                    });
                  }}
                  marginLeft={theme.space_lg}
                  size="small"
                >
                  {isTableTimeSeries
                    ? copyText.tableTimeSeriesTurnOff
                    : copyText.tableTimeSeriesTurnOn}
                </Button>
              )}

            <Dropdown
              options={exportCSVOptions}
              setSelectedOption={false}
              placement="bottom-start"
            >
              <Button
                iconStart={
                  csvResult.isLoading ? (
                    <LoadingSpinner
                      color={theme.feedback_neutral_outline}
                      size="xs"
                    />
                  ) : (
                    <Icon color="inherit" icon={faFileExport} />
                  )
                }
                marginLeft={theme.space_lg}
                size="small"
              >
                {copyText.exportButtonLabel}
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
        <DataTable
          key={`${report?.id}-${state.chartType}`}
          creditTypes={state.creditTypes}
          compareDateRange={
            state.compareDateRange ?? compareDateRangeFromDurationType
          }
          compareDurationType={state.compareDurationType}
          data={filteredTableData}
          dateRange={state.dateRange ?? dateRangeFromDurationType}
          dimensions={dimensions}
          footer
          isColumnResizing
          isCumulativeMode={state.isCumulative}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isGroupable={state.chartType === ChartType.TABLE}
          isLoading={isLoading}
          limit={
            typeof state.limit === "number" &&
            state.chartType !== ChartType.TABLE
              ? (state.limit + 1) * measures.length
              : state.limit
          }
          measures={chartMeasures}
          pinnedColumns={dimensions.length}
          reverse={state.reverse}
          selectedMeasures={state.measures}
          sortable
          sortRule={
            state.chartType === ChartType.TABLE ? state.sortRule : undefined
          }
          timeSeriesGranularity={
            isTableTimeSeries ? state.timeGranularity : undefined
          }
          isInvoiceMonthMode={isInvoiceMonthMode}
          onInteraction={handleInteraction}
        />
      </>
    );
  }

  function renderRunReportButton() {
    return (
      <>
        <Tooltip
          content={
            <Flex maxWidth={500} minWidth={325} direction="column">
              {tooltipMessage.map((message, index) => {
                return (
                  <Text
                    key={index}
                    align="left"
                    color={theme.tooltip_text_color}
                  >
                    {index + 1}: {message}
                  </Text>
                );
              })}
            </Flex>
          }
          hide={!canRunReport || tooltipMessage.length === 0}
          placement="top-start"
        >
          <Button
            disabled={canRunReport || isLoadingReportData}
            iconStart={
              isLoadingReportData ? <LoadingSpinner /> : <Icon icon={faPlay} />
            }
            primary
            width={130}
            onClick={() => {
              setState((currentState) => ({
                ...currentState,
                runQueryTriggered: true,
              }));
            }}
          >
            {isLoadingReportData
              ? copyText.runReportLoadingButtonLabel
              : copyText.runReportButtonLabel}
          </Button>
        </Tooltip>
      </>
    );
  }

  function renderVisualizations(): ReactNode {
    const chartSectionHeight =
      windowHeight > INCH_14_LAPTOP_SCREEN_HEIGHT
        ? windowHeight / CHART_SECTION_HEIGHT_PERCENTAGE_DIVISOR
        : CHART_SECTION_MIN_HEIGHT;

    let chartContent;
    let renderWrapper = true;
    let tableContent;

    if (!hasMeasure && state.metric.length === 0) {
      chartContent = renderPlaceholder(copyText.noMeasureSelectedPlaceholder);
    } else if (!isLoading && reportDataMain.result.data.length === 0) {
      if (hasLoadedReportData) {
        chartContent = renderPlaceholder(copyText.noDataPlaceholderMessage);
      } else if (state.runQueryTriggered) {
        chartContent = renderChart();
      } else {
        chartContent = renderRunReportButton();
      }
    } else if (state.chartType === ChartType.TABLE) {
      chartContent = renderTable();
      renderWrapper = false;
    } else {
      chartContent = renderChart();
      tableContent = renderTable();
    }

    return (
      <>
        {renderWrapper ? (
          <Flex
            alignItems="center"
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={chartSectionHeight}
            justifyContent="center"
            marginBottom={theme.space_md}
            minHeight={chartSectionHeight}
            padding={theme.space_sm}
          >
            {chartContent}
          </Flex>
        ) : (
          chartContent
        )}
        {tableContent}
      </>
    );
  }

  const sidePanelWidth = calculateSidePanelWidth(
    windowWidth,
    state.isPanelOpen
  );

  function renderSidePanel() {
    const isComparisonMode = !!state.compareDurationType;

    const tabs = [
      {
        component: (
          <ReportBuilderSidePanelMainTab
            availableDimensions={availableDimensions}
            availableMeasures={availableMeasures}
            dataSource={state.dataSource}
            dimensionPreferences={dimensionPreferences}
            dimensions={state.dimensions}
            dimensionValuesMap={dimensionValuesMap}
            filters={state.filters}
            hiddenMeasures={state.hiddenMeasures}
            impactMode={impactMode}
            isKPI={state.chartType === ChartType.KPI}
            isLoadingDimensionValues={isLoadingDimensionValues}
            isLoadingDimensionPreferences={isLoadingDimensionPreferences}
            isLoadingMeasurePreferences={isLoadingMeasurePreferences}
            isSelectDisabled={!!reportID}
            measurePreferences={measurePreferences}
            measures={state.measures}
            width={sidePanelWidth}
            onInteraction={handleInteraction}
          />
        ),
        label: copyText.sidePanelTabLabelParams,
        value: TAB_MAIN,
      },
      {
        component: (
          <ReportBuilderSidePanelUnitEconTab
            alias={state.formulaAlias}
            availableMetricDimensions={filteredMetricDimensions}
            bigQueryMetadata={bigQueryMetadata}
            customMetrics={customMetrics}
            dimensions={state.dimensions}
            externalDimensionValuesMap={externalDimensionValuesMap}
            externalLabelMap={labelMaps?.EXTERNAL_METRICS}
            externalMetrics={combinedExternalMetrics}
            formula={state.formula}
            impactMode={impactMode}
            isComparisonMode={isComparisonMode}
            isHiddenFormula={state.isFormulaHidden}
            isHiddenMetric={state.isMetricHidden}
            isLoadingBigQueryMetadata={isLoadingBigQueryMetadata}
            isLoadingCustomMetrics={isLoadingCustomMetrics || isLoadingUsers}
            isLoadingExternalDimensionValues={isLoadingExternalDimensionValues}
            isLoadingIntegrations={isLoadingIntegration}
            isProcessing={isCreatingCustomMetric || isUpdatingCustomMetric}
            isSideDrawerOpen={state.isSideDrawerOpen}
            measures={state.measures}
            metric={state.metric}
            metricAggregate={state.metricAggregate}
            metricFilters={state.metricFilters}
            selectedCustomMetric={selectedCustomMetric}
            showUnitEconomics={state.showUnitEconomics}
            width={sidePanelWidth}
            onInteraction={handleInteraction}
          />
        ),
        disabled: state.dimensions.length > 0 || isComparisonMode,
        disabledText:
          state.dimensions.length > 0
            ? copyText.unitEconomicsHeaderDimensionsDisabled
            : isComparisonMode
              ? copyText.unitEconomicsHeaderComparisonDisabled
              : "",
        label: copyText.sidePanelTabLabelUnitEcon,
        value: TAB_UNIT_ECON,
      },
    ];

    const content = (
      <Tabs
        activeValue={searchParamState.tab}
        tabs={tabs}
        onSelect={(tab: string) => {
          setSearchParamState({ tab });

          if (tab === TAB_UNIT_ECON) {
            setState((currentState) => {
              const totalLength =
                currentState.measures.length +
                (currentState.metric.length > 0 ? 1 : 0);

              if (currentState.showUnitEconomics && totalLength < 2) {
                return {
                  ...currentState,
                  formula: initialState.formula,
                  formulaAlias: initialState.formulaAlias,
                  metricAggregate: initialState.metricAggregate,
                  metricFilters: initialState.metricFilters,
                  metric: initialState.metric,
                  searchText: initialState.searchText,
                  showUnitEconomics: initialState.showUnitEconomics,
                };
              }

              if (totalLength > 1 && currentState.formula === "") {
                if (currentState.metric) {
                  return {
                    ...currentState,
                    formula: "A / X",
                    formulaAlias: report?.formulaAlias ?? "",
                    showUnitEconomics: true,
                    searchText: "",
                  };
                } else {
                  return {
                    ...currentState,
                    formula: "A / B",
                    formulaAlias: report?.formulaAlias ?? "",
                    showUnitEconomics: true,
                    searchText: "",
                  };
                }
              }

              if (totalLength === 1 && currentState.formula !== "") {
                return {
                  ...currentState,
                  formula: "",
                  formulaAlias: report?.formulaAlias ?? "",
                  showUnitEconomics: true,
                  searchText: "",
                };
              }

              if (report?.formula && currentState.formula === "") {
                return {
                  ...currentState,
                  formula: report.formula ?? "",
                  formulaAlias: report.formulaAlias ?? "",
                  showUnitEconomics: true,
                  searchText: "",
                };
              }
              return {
                ...currentState,
                showUnitEconomics: true,
                searchText: "",
              };
            });
          }
        }}
      />
    );

    const defaultSidePanelHeight =
      windowHeight - TOP_NAV_HEIGHT - SIDE_PANEL_FOOTER_HEIGHT;

    // NOTE: This is a calculation to account for the filter dropdowns going off the bottom of the screen
    const calculatedSidePanelHeight =
      defaultSidePanelHeight +
      state.measures.length * MEASURE_HEIGHT +
      state.dimensions.length * DIMENSION_HEIGHT +
      state.filters.length * FILTER_HEIGHT;

    const sidePanelHeight = state.isDropdownOpen
      ? calculatedSidePanelHeight
      : defaultSidePanelHeight;

    return (
      <Box
        position="sticky"
        top={!state.isPanelOpen ? "50%" : 0}
        style={{ zIndex: theme.zIndex_200 }}
      >
        {state.isPanelOpen ? (
          <Flex direction="column" justifyContent="space-between">
            <Box height={sidePanelHeight} overflowY="auto">
              {content}
            </Box>
            <Flex
              alignItems="center"
              backgroundColorOnHover={theme.secondary_color_background_hover}
              borderTop={`1px solid ${theme.border_color}`}
              cursor="pointer"
              height={SIDE_PANEL_FOOTER_HEIGHT}
              justifyContent={state.isPanelOpen ? undefined : "center"}
              paddingHorizontal={theme.space_xs}
              paddingVertical={theme.space_xxs}
              width="100%"
              onClick={() => mergeState({ isPanelOpen: false })}
            >
              <Flex alignItems="center">
                <Icon
                  color={theme.text_color_secondary}
                  icon={faLongArrowAltRight}
                  size="sm"
                />
                <Box
                  backgroundColor={theme.text_color_secondary}
                  height={12}
                  marginHorizontal={theme.space_xxs}
                  width={2}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            cursor="pointer"
            height="100%"
            justifyContent="center"
          >
            <Box
              backgroundColor={theme.text_color_secondary}
              cursor="pointer"
              height={12}
              marginHorizontal={theme.space_xxs}
              width={2}
            />
            <Icon
              clickable
              color={theme.text_color_secondary}
              icon={faLongArrowAltLeft}
              size="lg"
            />
          </Flex>
        )}
      </Box>
    );
  }

  return (
    <Flex height="100%">
      {renderModal()}
      {csvElement}

      <Flex
        direction="column"
        flex="1 0 auto"
        width={MAIN_CONTENT_WIDTH}
        overflowY="auto"
        maxHeight={"90vh"}
      >
        <Box
          backgroundColor={theme.background_color}
          left={0}
          paddingLeft={theme.size_tiny}
          paddingRight={theme.size_tiny}
          paddingTop={theme.size_tiny}
          position="sticky"
          top={0}
          zIndex={theme.zIndex_100}
        >
          {renderHeaderSection()}
          {renderControlsBarSection()}
        </Box>
        <Box
          paddingLeft={theme.size_tiny}
          paddingRight={theme.size_tiny}
          paddingBottom={theme.size_tiny}
        >
          {renderCompactKpis()}
          {renderVisualizations()}
        </Box>
      </Flex>
      <Box
        cursor={state.isPanelOpen ? "inherit" : "pointer"}
        backgroundColor={theme.panel_backgroundColor}
        backgroundColorOnHover={
          state.isPanelOpen ? undefined : theme.background_color_disabled
        }
        flex="0 0 auto"
        transition="width 0.2s, min-width 0.2s"
        width={sidePanelWidth}
        onClick={() =>
          state.isPanelOpen ? noop : mergeState({ isPanelOpen: true })
        }
      >
        {renderSidePanel()}
      </Box>
    </Flex>
  );
}

function calculateSidePanelWidth(windowWidth: number, isPanelOpen: boolean) {
  if (!isPanelOpen) return SIDE_PANEL_WIDTH_CLOSED;

  let sidePanelWidth = SIDE_PANEL_WIDTH_OPEN;

  if (windowWidth > INCH_14_LAPTOP_SCREEN_WIDTH) {
    const dynamicWidth = windowWidth / SIDE_PANEL_WIDTH_PERCENTAGE_DIVISOR;

    sidePanelWidth =
      dynamicWidth > SIDE_PANEL_WIDTH_MAX ? SIDE_PANEL_WIDTH_MAX : dynamicWidth;
  }

  return sidePanelWidth;
}

function getDateRangeFromState(
  durationType: DurationType,
  dateRange?: DateRange | null,
  nLookback?: number | null
): DateRange {
  if (
    durationType === DurationType.LAST_N_DAYS &&
    isValidRollingWindow(nLookback)
  ) {
    return getDateRangeFromLastNDays({ nLookback });
  }

  if (
    durationType === DurationType.LAST_N_MONTHS &&
    isValidRollingWindow(nLookback)
  ) {
    return getDateRangeFromLastNMonths({ nLookback });
  }

  if (!dateRange) {
    return getCubeDateRangeFromDurationType(durationType);
  }

  if (durationType === DurationType.INVOICE) {
    dateRange = padInvoiceMonthDateRange(dateRange);

    return [dateRange[0], dateRange[1]];
  }

  return [dateRange[0], dateRange[1]];
}

function getReportDateRange(dateRange: DateRange): string[] {
  return [dateRange[0].toISOString(), dateRange[1].toISOString()];
}

function isStateDifferentThanReport(state: State, report: ReportEntity) {
  const reportDataConfig = getReportDataConfigFromState(state);

  return !isEqual(report, { ...report, ...reportDataConfig });
}

function getStateFromReport(report: ReportEntity): State {
  let limitOptions = defaultLimitOptions;

  if (
    report.limit !== null &&
    report.limit !== undefined &&
    !limitOptions.find((option) => option === report.limit?.toString())
  ) {
    limitOptions = [report.limit.toString(), ...limitOptions];
  }

  return {
    ...initialState,
    chartType: report.chartType,
    creditTypes: report.excludedCreditTypes ?? [],
    compareDateRange: report.compareDurationType
      ? getComparisonDateRangeFromReport(report)
      : null,
    compareDurationType: report.compareDurationType,
    dataSource: report.dataSource,
    dateRange: getDateRangeFromReport(report),
    dimensions: report.dimensions,
    durationType: report.durationType,
    excludeNegativeNumbers: report.excludeNegativeNumbers,
    excludeOther: report.excludeOther,
    timeGranularity: report.timeGranularity ?? TimeGranularity.DAY,
    filters: report.filters.map((filter) => ({ ...filter, isDate: false })),
    formula: report.formula ?? "",
    formulaAlias: report.formulaAlias ?? "",
    hiddenMeasures: report.hiddenMeasures,
    isCumulative: report.isCumulative,
    isFormulaHidden: report.isFormulaHidden,
    isMetricHidden: report.isMetricHidden,
    limit: report.limit,
    measures: report.measures,
    metric: report.metric ?? "",
    metricAggregate:
      report.metric && report.metricAggregate
        ? report.metricAggregate
        : MetricAggregate.SUM,
    metricFilters: report.metricFilters,
    nLookback: report.nLookback,
    reverse: report.reverse,
    sortRule: report.sortRule ?? undefined,
    showUnitEconomics: !!report.formula || !!report.metric,
    xAxisKey: report.xAxisKey ?? DEFAULT_X_AXIS_KEY,
  };
}

function getReportDataConfigFromState(state: State): ReportDataConfig {
  let [compareStartDate, compareEndDate]: string[] | null[] = [null, null];

  if (state.compareDateRange) {
    if (state.compareDurationType === CompareDurationType.CUSTOM) {
      [compareStartDate, compareEndDate] = getReportDateRange(
        state.compareDateRange
      );
    } else if (state.compareDurationType === CompareDurationType.INVOICE) {
      [compareStartDate, compareEndDate] = [
        format(state.compareDateRange[0], "MM/yyyy"),
        format(state.compareDateRange[1], "MM/yyyy"),
      ];
    }
  }

  const [startDate, endDate] =
    state.dateRange && state.durationType === DurationType.CUSTOM
      ? getReportDateRange(state.dateRange)
      : [null, null];

  const [invoiceMonthStart, invoiceMonthEnd] =
    state.dateRange && state.durationType === DurationType.INVOICE
      ? [
          format(state.dateRange[0], "MM/yyyy"),
          format(state.dateRange[1], "MM/yyyy"),
        ]
      : [null, null];

  return {
    chartType: state.chartType,
    compareDurationType: state.compareDurationType,
    compareEndDate: compareEndDate,
    compareStartDate: compareStartDate,
    dataSource: state.dataSource,
    dimensions: state.chartType === ChartType.KPI ? [] : state.dimensions,
    durationType: state.durationType,
    endDate,
    excludedCreditTypes: state.creditTypes,
    excludeOther: state.excludeOther,
    filters: state.filters,
    formula: state.formula.length > 0 ? state.formula : null,
    formulaAlias: state.formulaAlias.length > 0 ? state.formulaAlias : null,
    hiddenMeasures: state.hiddenMeasures,
    excludeNegativeNumbers: state.excludeNegativeNumbers,
    isCumulative: state.isCumulative,
    isFormulaHidden: state.isFormulaHidden,
    isMetricHidden: state.isMetricHidden,
    invoiceMonthEnd,
    invoiceMonthStart,
    limit: state.limit,
    measures:
      state.chartType === ChartType.KPI
        ? state.measures.slice(0, 1)
        : state.measures,
    metric: state.metric.length > 0 ? state.metric : null,
    metricAggregate: state.metric.length > 0 ? state.metricAggregate : null,
    metricFilters: state.metricFilters,
    nLookback: state.nLookback,
    reverse: state.reverse,
    sortRule: state.sortRule ?? null,
    startDate,
    timeGranularity:
      state.chartType !== ChartType.TABLE ||
      (state.xAxisKey !== DEFAULT_X_AXIS_KEY && state.timeGranularity)
        ? state.timeGranularity
        : null,
    xAxisKey: state.xAxisKey,
  };
}

function getLimitLabelWithMeasure(measures: Measure[], label: string) {
  return measures.length > 1
    ? `${label} ${copyText.limitOptionLabelBy} ${measures[0].displayName}`
    : label;
}

function getTooltipText(params: {
  canSaveReport: boolean;
  hasExcludedCreditTypes: boolean;
  isComparisonMode: boolean;
  isCumulative: boolean;
  isImpactMode: boolean;
  isKPI: boolean;
  isLargeDataSet: boolean;
  isReverse: boolean;
  reportID?: string;
}): string | undefined {
  if (!params.reportID || params.canSaveReport) {
    return copyText.exportReportPDFSaveReportTooltipCaption;
  } else if (params.hasExcludedCreditTypes) {
    return copyText.exportReportPDFExcludedCreditTypesTooltipCaption;
  } else if (params.isComparisonMode) {
    return copyText.exportReportPDFComparisonCaption;
  } else if (params.isCumulative) {
    return copyText.exportReportPDFCumulativeCaption;
  } else if (params.isImpactMode) {
    return copyText.exportReportPDFImpactModeTooltipCaption;
  } else if (params.isKPI) {
    return copyText.exportReportPDFKPI;
  } else if (params.isReverse) {
    return copyText.exportReportPDFReverseCaption;
  } else if (params.isLargeDataSet) {
    return copyText.exportReportPDFLargeDatasetTooltipCaption;
  }
}
