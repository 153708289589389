import { ProviderType, UnitType } from "../enums";

const measures = {
  backupCost: {
    displayName: "Backup Cost",
    providerType: ProviderType.GCP,
    schemaName: "backupCost",
    unit: UnitType.CURRENCY,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  cpuAndRamCost: {
    displayName: "CPU and RAM Cost",
    providerType: ProviderType.GCP,
    schemaName: "cpuAndRamCost",
    unit: UnitType.CURRENCY,
  },
  cpuCost: {
    displayName: "CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "cpuCost",
    unit: UnitType.CURRENCY,
  },
  idlingCost: {
    displayName: "Idling Cost",
    providerType: ProviderType.GCP,
    schemaName: "idlingCost",
    unit: UnitType.CURRENCY,
  },
  networkCost: {
    displayName: "Network Cost",
    providerType: ProviderType.GCP,
    schemaName: "networkCost",
    unit: UnitType.CURRENCY,
  },
  ramCost: {
    displayName: "RAM Cost",
    providerType: ProviderType.GCP,
    schemaName: "ramCost",
    unit: UnitType.CURRENCY,
  },
  remainingCost: {
    displayName: "Remaining Cost",
    providerType: ProviderType.GCP,
    schemaName: "remainingCost",
    unit: UnitType.CURRENCY,
  },
  storageCost: {
    displayName: "Storage Cost",
    providerType: ProviderType.GCP,
    schemaName: "storageCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  availability: {
    displayName: "Availability",
    providerType: ProviderType.GCP,
    schemaName: "availability",
  },
  classification: {
    displayName: "Classification",
    providerType: ProviderType.GCP,
    schemaName: "classification",
  },
  databaseType: {
    displayName: "Database Type",
    providerType: ProviderType.GCP,
    schemaName: "databaseType",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.GCP,
    schemaName: "instanceType",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  replica: {
    displayName: "Replica",
    providerType: ProviderType.GCP,
    schemaName: "replica",
  },
} as const;

export const gcpCloudSqlCostSchema = { dimensions, measures };
