import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { AWSDatabaseMemoryDBDatum } from "../types";

export interface Params {
  dateRange: Date[];
  dimensions?: Dimension[];
  filters?: QueryFilter[];
  granularity?: TimeGranularity;
  measures: Measure[];
  nullString?: string;
}

export default function useGetAWSDatabaseMemoryDBInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AWSDatabaseMemoryDBDatum[], UError>
): UseQueryResult<AWSDatabaseMemoryDBDatum[], UError> {
  const client = useAnalyticsApiClient();

  const nullString = params.nullString ?? "";

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AWS_DATABASE_MEMORY_DB,
    dateRange: params.dateRange,
    dimensions: params.dimensions,
    granularity: params.granularity,
    measures: params.measures,
    preAggFilters: params.filters,
  });

  return useQuery({
    queryKey: ["awsDatabaseMemoryDB", query],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      return result.response.map(
        (datum) =>
          ({
            payerAccountId: datum.payerAccountId ?? nullString,
            usageAccountId: datum.usageAccountId ?? nullString,
            clusterArn: datum.clusterArn ?? nullString,
            clusterId: datum.clusterId ?? nullString,
            instanceType: datum.instanceType ?? nullString,
            instanceMemory: datum.instanceMemory ?? nullString,
            instanceVcpu: datum.instanceVcpu ?? nullString,
            instanceNetworkPerformance:
              datum.instanceNetworkPerformance ?? nullString,
            instanceFamily: datum.instanceFamily ?? nullString,
            region: datum.region ?? nullString,

            cost: datum.cost ?? 0,
            nodeCost: datum.nodeCost ?? 0,
            dataWriteCost: datum.dataWriteCost ?? 0,
            backupStorageCost: datum.backupStorageCost ?? 0,
            nodeUsage: datum.nodeUsage ?? 0,
            dataWriteUsage: datum.dataWriteUsage ?? 0,
            backupStorageUsage: datum.backupStorageUsage ?? 0,
            timestamp: datum.timestamp,
          }) as AWSDatabaseMemoryDBDatum
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
