import { ProviderType, UnitType } from "../enums";

const measures = {
  totalAlertEvents: {
    displayName: "Total Alert Events",
    providerType: ProviderType.TERNARY,
    schemaName: "totalAlertEvents",
    unit: UnitType.STANDARD,
  },
  totalDelta: {
    displayName: "Total Delta",
    providerType: ProviderType.TERNARY,
    schemaName: "totalDelta",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  tenantID: {
    displayName: "Tenant ID",
    providerType: ProviderType.TERNARY,
    schemaName: "tenantId",
  },
} as const;

export const mspAlertRollupSchema = {
  dimensions,
  measures,
};
