import { ProviderType } from "../enums";
import { billingCucSchema } from "./billingCUC";

// Same measures are used for both Billing & Detailed Billing
const measures = billingCucSchema.measures;

// Detailed Billing is just regular billing + resourceId
const dimensions = {
  ...billingCucSchema.dimensions,
  resourceID: {
    displayName: "Resource ID",
    providerType: ProviderType.TERNARY,
    schemaName: "resourceId",
  },
} as const;

export const detailedBillingSchema = { dimensions, measures };
