import { ProviderType, UnitType } from "../enums";

const measures = {
  cpuCoreUsageTime: {
    displayName: "CPU Core Usage Time",
    providerType: ProviderType.GCP,
    schemaName: "CPUCoreUsageTime",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  totalCpuCores: {
    displayName: "Total CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "totalCPUCores",
    unit: UnitType.STANDARD,
  },
  totalCpuCost: {
    displayName: "Total CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalCPUCost",
    unit: UnitType.CURRENCY,
  },
  totalGPUMemoryBytes: {
    displayName: "Total GPU Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalGPUMemoryBytes",
    unit: UnitType.BYTES,
  },
  totalMemoryCost: {
    displayName: "Total Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryCost",
    unit: UnitType.CURRENCY,
  },
  totalMemoryBytes: {
    displayName: "Total Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryBytes",
    unit: UnitType.BYTES,
  },
  usedGPUMemoryBytes: {
    displayName: "Used GPU Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "usedGPUMemoryBytes",
    unit: UnitType.BYTES,
  },
  usedMemoryBytes: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "usedMemoryBytes",
    unit: UnitType.BYTES,
  },
  wasteCPUs: {
    displayName: "Waste CPUs",
    providerType: ProviderType.GCP,
    schemaName: "wasteCPUs",
    unit: UnitType.STANDARD,
  },
  wasteMemoryBytes: {
    displayName: "Waste Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "wasteMemoryBytes",
    unit: UnitType.BYTES,
  },
} as const;

const dimensions = {
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.GCP,
    schemaName: "cloudId",
  },
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.GCP,
    schemaName: "clusterName",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.GCP,
    schemaName: "location",
  },
  nodeName: {
    displayName: "Node Name",
    providerType: ProviderType.GCP,
    schemaName: "nodeName",
  },
  nodepool: {
    displayName: "Nodepool",
    providerType: ProviderType.GCP,
    schemaName: "nodepool",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
} as const;

export const gcpKubernetesNodeUsageSchema = { dimensions, measures };
