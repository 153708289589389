import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { LabelMap, LabelMapsEntity } from "@ternary/api-lib/core/types";
import { uniq } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import useBuildDataQuery from "./utils/useDataQuery";

interface Params {
  dataSource: DataSource;
  dateRange: Date[];
  dimensions: Dimension[];
  parentTenantID?: string;
  preAggFilters?: QueryFilter[];
}

interface DimensionValuesMap {
  [key: string]: string[];
}

export default function useGetDimensionValuesByDataSourceV2(
  params: Params,
  options?: UseQueryOptions<DimensionValuesMap, UError>
): UseQueryResult<DimensionValuesMap, UError> {
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  const { parentTenantID, ...restParams } = params;

  const [tenantID, query] = useBuildDataQuery(restParams);

  return useQuery({
    queryKey: ["dimensionValues", params],
    queryFn: async () => {
      const result = await client.loadData(parentTenantID ?? tenantID, query);

      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const labelMap: LabelMap = labelMaps[params.dataSource] ?? {};

      const reversedLabelMap = Object.entries(labelMap).reduce(
        (accum: { [key: string]: string }, [key, value]) => {
          accum[value] = key;
          return accum;
        },
        {}
      );

      const dimensionValuesMap = result.response.reduce(
        (accum: DimensionValuesMap, datum) => {
          Object.entries(datum).forEach(([key, value]) => {
            if (typeof value !== "string") return;

            const dimension = reversedLabelMap[key]
              ? reversedLabelMap[key]
              : key;

            if (!accum[dimension]) {
              accum[dimension] = [value];
              return;
            }

            if (accum[dimension]) {
              accum[dimension].push(value);
              return;
            }
          }, {});

          return accum;
        },
        {}
      );

      return Object.entries(dimensionValuesMap).reduce(
        (accum, [key, value]) => {
          accum[key] = uniq(value);
          return accum;
        },
        {}
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
