import { isAfter, isBefore } from "date-fns";
import { groupBy, keyBy, uniq } from "lodash";
import { DatadogIntegrationEntity } from "../../core/types";
import { ReportEntity } from "../../core/types/Report";
import copyText from "../../ui-lib/copyText";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  NOT_SHOWN_KEY,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
} from "../constants";
import {
  ChartType,
  CompareDurationType,
  DataSource,
  Operator,
  ProviderType,
  TimeGranularity,
  UnitType,
} from "../enums";
import { RawData, RawValue } from "../types";
import { DimensionWithValue } from "../ui/ChartDataManager";
import {
  ChartDatum,
  Dimension,
  Filter,
  Measure,
  ReportDataConfig,
} from "../ui/types";
import { getIsInvoiceMonthMode } from "./ReportUtils";

/**
 * Transforms raw data into a format suitable for time series charts
 *
 * @param params - Configuration parameters for chart data transformation
 * @param params.chartType - The type of chart to generate data for
 * @param params.data - The raw data to transform
 * @param params.dimensions - Dimensions to use for grouping and display
 * @param params.excludedKeys - Keys to exclude from the final chart data
 * @param params.isFiscalMode - Whether to use fiscal date handling
 * @param params.maximumGroupings - Maximum number of groupings to display before collapsing
 * @param params.measures - Measures to include in the chart data
 * @param params.reverse - Whether to reverse the sort order
 * @param params.xAxisKey - The key to use for the x-axis
 * @returns Tuple containing [chartData, dataKeys, dataByXAxis, maxValue]
 */
export function getTimeSeriesChartDataFromRawData(params: {
  chartType?: ChartType;
  data: RawData[];
  dimensions: Dimension[];
  excludedKeys: string[];
  isFiscalMode?: boolean;
  maximumGroupings: number;
  measures: Measure[];
  reverse?: boolean;
  xAxisKey: string;
  delimiterMeasure?: string;
  delimiterDimension?: string;
}): [ChartDatum[], string[], { [xAxisValue: string]: ChartDatum }, number] {
  const allKeysMap: { [key: string]: number } = {};
  let highestMeasureValue = 0;
  const dataGroupedByXAxisKey = groupBy(params.data, params.xAxisKey);

  const data: ChartDatum[] = Object.keys(dataGroupedByXAxisKey).map(
    (xAxisValue) => {
      const entriesForDate = dataGroupedByXAxisKey[xAxisValue];

      const valuesForDate = entriesForDate.reduce(
        (accum: ChartDatum, entry) => {
          const dimensionKeysAndValues = params.dimensions.map((d) => ({
            key: d.schemaName,
            value: entry[d.schemaName] ?? "null",
          }));

          params.measures.forEach((measure) => {
            const key = getKeyForMeasureAndDimensions(
              dimensionKeysAndValues,
              measure,
              params.measures.length,
              params.delimiterMeasure,
              params.delimiterDimension
            );

            const valueForMeasure = entry[measure.schemaName];

            if (valueForMeasure === undefined || valueForMeasure === null) {
              return accum;
            }

            if (
              typeof valueForMeasure === "number" &&
              valueForMeasure > highestMeasureValue
            ) {
              highestMeasureValue = valueForMeasure;
            }

            if (allKeysMap[key] && typeof valueForMeasure === "number") {
              allKeysMap[key] += valueForMeasure;
            } else if (typeof valueForMeasure === "number") {
              allKeysMap[key] = valueForMeasure;
            }

            // early exit
            if (params.excludedKeys.includes(key)) {
              accum[key] = null;
              return accum;
            }

            // not exited
            if (accum[key]) {
              accum[key] = Number(accum[key]) + Number(valueForMeasure);
            } else if (typeof valueForMeasure === "number") {
              accum[key] = valueForMeasure;
            }
          });
          return accum;
        },
        {}
      );

      return { [params.xAxisKey]: xAxisValue, ...valuesForDate };
    }
  );

  let sortedData: ChartDatum[] = [];

  if (params.xAxisKey === DEFAULT_X_AXIS_KEY) {
    if (params.isFiscalMode) {
      sortedData = data;
    } else {
      sortedData = data.sort((a, b) => {
        if (
          typeof a.timestamp !== "string" ||
          typeof b.timestamp !== "string"
        ) {
          return 0;
        }
        if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
          return -1;
        }
        if (isAfter(new Date(a.timestamp), new Date(b.timestamp))) {
          return 1;
        }
        return 0;
      });
    }
  } else if (params.xAxisKey === "invoiceMonth") {
    sortedData = data.sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    });
  } else {
    sortedData = data.sort((a, b) => {
      const aTotal = Object.values(a).reduce((accum: number, val) => {
        if (typeof val === "number") {
          accum += val;
        }
        return accum;
      }, 0);
      const bTotal = Object.values(b).reduce((accum: number, val) => {
        if (typeof val === "number") {
          accum += val;
        }
        return accum;
      }, 0);

      if (aTotal > bTotal) {
        return -1;
      }
      if (bTotal < aTotal) {
        return 1;
      }
      return 0;
    });
  }

  // sorted smallest to largest to respect recharts putting first at the bottom when stacking
  let allKeysReverseSorted = Object.keys(allKeysMap).sort((a, b) => {
    const aValue = allKeysMap[a];
    const bValue = allKeysMap[b];

    if (
      params.chartType === ChartType.STACKED_AREA ||
      params.chartType === ChartType.STACKED_BAR
    ) {
      if (a.includes(copyText.dimensionOtherNotShown)) {
        return -1;
      }

      if (b.includes(copyText.dimensionOtherNotShown)) {
        return 1;
      }
    }

    if (aValue < bValue) {
      return -1;
    }
    if (bValue < aValue) {
      return 1;
    }
    return 0;
  });

  if (params.reverse) {
    allKeysReverseSorted = allKeysReverseSorted.reverse();
  }

  if (
    params.maximumGroupings < Infinity &&
    allKeysReverseSorted.length > params.maximumGroupings
  ) {
    const removedCategories = allKeysReverseSorted.splice(
      0,
      allKeysReverseSorted.length - params.maximumGroupings
    );

    const removedCategoriesDict = removedCategories.reduce(
      (accum: { [category: string]: boolean }, category) => ({
        ...accum,
        [category]: true,
      }),
      {}
    );

    if (removedCategories.length > 0) {
      allKeysReverseSorted.unshift(NOT_SHOWN_KEY);
      sortedData = sortedData.map((datum) => {
        let totalForDate = 0;
        Object.keys(datum).forEach((key) => {
          if (removedCategoriesDict[key]) {
            if (!params.excludedKeys.includes(NOT_SHOWN_KEY)) {
              totalForDate += Number(datum[key]);
            }
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete datum[key];
          }
        });

        if (totalForDate !== 0) {
          datum[NOT_SHOWN_KEY] = totalForDate;
        }

        return datum;
      });
    }
  }

  const dataKeyedByXAxisKey = keyBy(sortedData, params.xAxisKey);
  return [
    sortedData,
    allKeysReverseSorted,
    dataKeyedByXAxisKey,
    highestMeasureValue,
  ];
}

/**
 * Gets the key for a measure and its dimensions
 *
 * @param dimensions - Array of dimension key-value pairs
 * @param measure - The measure to get the key for
 * @param measureCount - Total number of measures
 * @returns Formatted key string for the measure and dimensions
 */
export function getKeyForMeasureAndDimensions(
  dimensions: { key: string; value: RawValue }[],
  measure: Measure,
  measureCount: number,
  delimiterMeasure = ":",
  delimiterDimension = "/"
): string {
  if (dimensions.length === 0) return measure.schemaName;

  // In single dimension cases, we hide the name of the dimension for easier user reading
  if (dimensions.length === 1) {
    if (dimensions[0] && typeof dimensions[0].value === "string") {
      if (measureCount > 1) {
        return `${measure.schemaName}${delimiterMeasure} ${dimensions[0].value}`;
        // If there is ALSO only one measure, we go ahead and hide that too, for user reading
      } else {
        return dimensions[0].value;
      }
    }
    return "";
  }

  return `${measure.schemaName}${delimiterMeasure} ${dimensions
    .map((d) => `${d.key}${delimiterMeasure}${d.value}`)
    .join(` ${delimiterDimension} `)}`;
}

/**
 * Gets ticks for a chart based on the data
 *
 * @param data - The chart data
 * @param xAxisKey - The key to use for the x-axis
 * @returns Array of tick values or undefined if there are too few data points
 */
export function getTicks(
  data: ChartDatum[],
  xAxisKey: string
): (string | number)[] | undefined {
  const intervalLength = Math.floor(data.length / 4);

  if (intervalLength < 1) return undefined;

  const ticks: (string | number)[] = [];

  for (let i = 0; i < data.length; i += intervalLength) {
    const currentValue = data[i][xAxisKey];

    if (currentValue !== null) {
      ticks.push(currentValue);
    }
  }

  return ticks;
}

/**
 * Gets the uniform unit type for a set of measures
 *
 * @param measures - Array of measures to analyze
 * @returns The uniform unit type if all measures have the same unit, undefined otherwise
 */
export function getUniformUnitType(measures: Measure[]) {
  const measureUnitTypes = uniq(measures.map((measure) => measure.unit));
  const unitType =
    measureUnitTypes.length > 1 ? undefined : measureUnitTypes[0];
  return unitType;
}

/**
 * Builds filters from dimensions with values
 *
 * @param dimensionsWithValues - Array of dimensions with their selected values
 * @returns Array of query filters
 */
export function buildFiltersFromDimensionsWithValues(
  dimensionsWithValues: DimensionWithValue[]
): Filter[] {
  return dimensionsWithValues.map(({ dimension, value }) =>
    value === "null"
      ? {
          displayName: dimension.displayName,
          operator: Operator.NOT_SET,
          providerType: dimension.providerType,
          schemaName: dimension.schemaName,
          values: null,
        }
      : {
          displayName: dimension.displayName,
          operator: Operator.EQUALS,
          providerType: dimension.providerType,
          schemaName: dimension.schemaName,
          values: [value],
        }
  );
}

/**
 * Gets KPI measure from a report
 *
 * @param report - The report entity to extract measures from
 * @param integration - Optional Datadog integration for metric information
 * @returns Array of filtered measures for KPI display
 */
export function getKPIMeasureFromReport(
  report: ReportEntity,
  integration?: DatadogIntegrationEntity
): Measure[] {
  // Get all measures with units applied
  const measures = getMeasuresWithUnits(report);

  // Filter out hidden measures
  const visibleMeasures = measures.filter(
    (measure) => !report.hiddenMeasures.includes(measure.schemaName)
  );

  // Add metric measure if applicable
  const metricMeasure = getMetricMeasure(report, integration);

  // Add formula measure if applicable
  const formulaMeasure = getFormulaMeasure(report);

  // Combine all measures
  const filteredMeasures = [
    ...visibleMeasures,
    ...(metricMeasure ? [metricMeasure] : []),
    ...(formulaMeasure ? [formulaMeasure] : []),
  ];

  // Return in priority order: formula > metric > all measures
  const formulaAlias = report.formulaAlias ?? "Formula";

  const priorityFormulaMeasure = filteredMeasures.find(
    (measure) => measure.displayName === formulaAlias
  );

  if (priorityFormulaMeasure) {
    return [priorityFormulaMeasure];
  }

  const reportMetricName = getReportMetricName(report, integration);

  const priorityMetricMeasure = filteredMeasures.find(
    (measure) => reportMetricName && measure.displayName === reportMetricName
  );

  if (priorityMetricMeasure) {
    return [priorityMetricMeasure];
  }

  return filteredMeasures;
}

// Helper functions to encapsulate logic
function getMetricMeasure(
  report: ReportEntity,
  integration?: DatadogIntegrationEntity
): Measure | null {
  const selectedMetricName =
    integration?.metrics && report.metric
      ? integration.metrics[report.metric].name
      : undefined;

  const showUnitEconomics = !!report.formula || !!report.metric;

  if (
    (report.metric ?? "").length > 0 &&
    selectedMetricName &&
    !report.isMetricHidden &&
    showUnitEconomics &&
    report.dimensions.length === 0
  ) {
    return {
      displayName: selectedMetricName,
      providerType: ProviderType.TERNARY,
      schemaName: selectedMetricName,
      unit: UnitType.STANDARD,
    };
  }

  return null;
}

function getFormulaMeasure(report: ReportEntity): Measure | null {
  if (!report.isFormulaHidden && report.formula) {
    const name =
      report.formulaAlias && report.formulaAlias.length > 0
        ? report.formulaAlias
        : "Formula";

    return {
      displayName: name,
      providerType: ProviderType.TERNARY,
      schemaName: name,
      unit: UnitType.STANDARD,
    };
  }

  return null;
}

function getReportMetricName(
  report: ReportEntity,
  integration?: DatadogIntegrationEntity
): string | null {
  if (!integration || !report.metric) return null;

  const metric = integration.metrics[report.metric];

  if (!metric) return null;

  return metric.name;
}

/**
 * Gets measures with units from a report
 *
 * @param report - The report entity to extract measures from
 * @returns Array of measures with their appropriate units
 */
function getMeasuresWithUnits(report: ReportEntity): Measure[] {
  return report.measures.reduce((accum: Measure[], measure) => {
    const currentMeasure = measure;

    if (report.compareDurationType) {
      const previousMeasure = {
        displayName: `${currentMeasure.displayName}${COMPARISON_KEY}`,
        providerType: currentMeasure.providerType,
        schemaName: `${currentMeasure.schemaName}${COMPARISON_KEY}`,
        unit: currentMeasure.unit,
      };

      const deltas = [
        ...(report.measures.length === 1
          ? [
              {
                displayName: RAW_DIFFERENCE_KEY,
                providerType: measure.providerType,
                schemaName: RAW_DIFFERENCE_KEY,
                unit: currentMeasure.unit,
              },
            ]
          : []),
        ...(report.measures.length === 1 && report.chartType === ChartType.TABLE
          ? [
              {
                displayName: PERCENT_DIFFERENCE_KEY,
                providerType: measure.providerType,
                schemaName: PERCENT_DIFFERENCE_KEY,
                unit: UnitType.STANDARD,
              },
            ]
          : []),
      ];

      return [...accum, currentMeasure, previousMeasure, ...deltas];
    }

    return [...accum, currentMeasure];
  }, []);
}

const DEFAULT_FORMULA_ALIAS = copyText.unitEconomicsFormulaPlaceHolder;

/**
 * Gets KPI measures for a report
 * Prioritizes formula measures, then metric measures, then falls back to existing measures
 *
 * @param report - The report configuration
 * @param existingMeasures - Existing measures to filter from
 * @param integration - Optional Datadog integration for metric information
 * @returns Array of measures filtered for KPI display
 */
function getKPIMeasures(
  report: ReportDataConfig,
  existingMeasures: Measure[],
  integration: Pick<DatadogIntegrationEntity, "metrics"> | null
) {
  const formulaAlias = report.formulaAlias ?? DEFAULT_FORMULA_ALIAS;

  const formulaMeasure = existingMeasures.find(
    (measure) => measure.schemaName === formulaAlias
  );

  if (formulaMeasure) {
    return [formulaMeasure];
  }

  const reportMetricName =
    report.metric && (integration?.metrics ?? {})[report.metric]?.name;

  const metricMeasure = existingMeasures.find(
    (measure) => reportMetricName && measure.schemaName === reportMetricName
  );

  if (metricMeasure) {
    return [metricMeasure];
  }

  return existingMeasures;
}

export function addComparisonToReportMeasures({
  chartType,
  compareDurationType,
  measures,
}: {
  chartType: ChartType;
  compareDurationType: CompareDurationType | null;
  measures: Measure[];
}) {
  return measures.reduce((accum: Measure[], measure) => {
    const currentMeasure = {
      displayName: measure.displayName,
      providerType: measure.providerType,
      schemaName: measure.schemaName,
      unit: measure.unit,
    };

    if (!compareDurationType) {
      return [...accum, currentMeasure];
    }

    const previousMeasure = {
      displayName: `${measure.displayName}${COMPARISON_KEY}`,
      providerType: measure.providerType,
      schemaName: `${measure.schemaName}${COMPARISON_KEY}`,
      unit: measure.unit,
    };

    const deltaMeasures: Measure[] = [];

    if (measures.length === 1) {
      deltaMeasures.push({
        displayName: RAW_DIFFERENCE_KEY,
        providerType: measure.providerType,
        schemaName: RAW_DIFFERENCE_KEY,
        unit: measure.unit,
      });
    }

    if (measures.length === 1 && chartType === ChartType.TABLE) {
      deltaMeasures.push({
        displayName: PERCENT_DIFFERENCE_KEY,
        providerType: measure.providerType,
        schemaName: PERCENT_DIFFERENCE_KEY,
        unit: UnitType.STANDARD,
      });
    }

    return [...accum, currentMeasure, previousMeasure, ...deltaMeasures];
  }, []);
}

type GetReportChartConfigurationParams = {
  integration: Pick<DatadogIntegrationEntity, "metrics"> | null;
  report: ReportDataConfig;
};

/**
 * Gets a complete chart configuration from a report configuration
 * Processes report settings, dimensions, measures, and chart mode settings
 *
 * @param params - Parameters for chart configuration
 * @param params.integration - Optional Datadog integration for metric information
 * @param params.report - The report configuration to process
 * @returns Standardized chart configuration object
 */
export function getReportChartConfiguration(
  params: GetReportChartConfigurationParams
) {
  //
  // COMPARISON MODE
  //

  const comparisonMode = !!params.report.compareDurationType;

  //
  // DIMENSIONS
  //

  const dimensions = params.report.dimensions;

  //
  // IMPACT MODE
  //

  const impactMode = params.report.dataSource === DataSource.CARBON_FOOTPRINT;

  //
  // IS INVOICE MONTH MODE
  //

  const isInvoiceMonthMode = Boolean(
    getIsInvoiceMonthMode(params.report) &&
      params.report.xAxisKey === DEFAULT_X_AXIS_KEY
  );

  //
  // MEASURES
  //

  const showUnitEconomics = !!params.report.formula || !!params.report.metric;

  const selectedMetric =
    params.integration?.metrics && params.report.metric
      ? params.integration.metrics[params.report.metric]
      : undefined;

  const selectedMetricName = selectedMetric ? selectedMetric.name : undefined;

  const measuresWithCompare = addComparisonToReportMeasures(params.report);

  let measures: Measure[] = [
    ...measuresWithCompare.filter(
      (measure) =>
        !params.report.hiddenMeasures.some(
          (hiddenMeasure) => hiddenMeasure === measure.schemaName
        )
    ),
  ];

  if (
    (params.report.metric ?? "").length > 0 &&
    selectedMetricName &&
    !params.report.isMetricHidden &&
    showUnitEconomics &&
    params.report.dimensions.length === 0
  ) {
    measures.push({
      displayName: selectedMetricName,
      providerType: ProviderType.TERNARY,
      schemaName: selectedMetricName,
      unit: UnitType.STANDARD,
    });
  }

  if (!params.report.isFormulaHidden && params.report.formula) {
    measures.push({
      displayName:
        params.report.formulaAlias && params.report.formulaAlias.length > 0
          ? params.report.formulaAlias
          : copyText.unitEconomicsFormulaPlaceHolder,
      providerType: ProviderType.TERNARY,
      schemaName:
        params.report.formulaAlias && params.report.formulaAlias.length > 0
          ? params.report.formulaAlias
          : copyText.unitEconomicsFormulaPlaceHolder,
      unit: UnitType.STANDARD,
    });
  }

  if (params.report.chartType === ChartType.KPI) {
    measures = getKPIMeasures(params.report, measures, params.integration);
  }

  //
  // SHOULD APPLY GRANULARITY
  //

  const shouldApplyGranularity =
    params.report.chartType !== ChartType.PIE &&
    params.report.chartType !== ChartType.TABLE &&
    (params.report.xAxisKey === DEFAULT_X_AXIS_KEY || !params.report.xAxisKey);

  //
  // TIME GRANULARITY
  //

  const timeGranularity = params.report.timeGranularity ?? TimeGranularity.DAY;

  //
  // X AXIS KEY
  //

  const xAxisKey = isInvoiceMonthMode
    ? "invoiceMonth"
    : (params.report.xAxisKey ?? DEFAULT_X_AXIS_KEY);

  return {
    comparisonMode,
    dimensions,
    impactMode,
    isInvoiceMonthMode,
    measures,
    shouldApplyGranularity,
    timeGranularity,
    xAxisKey,
  };
}
