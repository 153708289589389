import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  backupStorageCost: {
    displayName: "Backup Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageCost",
    unit: UnitType.CURRENCY,
  },
  backupStorageUsage: {
    displayName: "Backup Storage Usage",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageUsage",
    unit: UnitType.BINARY_BYTES,
  },
  nodeCost: {
    displayName: "Node Cost",
    providerType: ProviderType.AWS,
    schemaName: "nodeCost",
    unit: UnitType.CURRENCY,
  },
  nodeUsage: {
    displayName: "Node Usage",
    providerType: ProviderType.AWS,
    schemaName: "nodeUsage",
    unit: UnitType.BINARY_BYTES,
  },
} as const;

const dimensions = {
  cacheEngine: {
    displayName: "Cache Engine",
    providerType: ProviderType.AWS,
    schemaName: "cacheEngine",
  },
  clusterArn: {
    displayName: "Cluster ARN",
    providerType: ProviderType.AWS,
    schemaName: "clusterArn",
  },
  clusterID: {
    displayName: "Cluster ID",
    providerType: ProviderType.AWS,
    schemaName: "clusterId",
  },
  instanceFamily: {
    displayName: "Instance Family",
    providerType: ProviderType.AWS,
    schemaName: "instanceFamily",
  },
  instanceMemory: {
    displayName: "Instance Memory",
    providerType: ProviderType.AWS,
    schemaName: "instanceMemory",
  },
  instanceNetworkPerformance: {
    displayName: "Instance Network Performance",
    providerType: ProviderType.AWS,
    schemaName: "instanceNetworkPerformance",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceVcpu: {
    displayName: "Instance VCPU",
    providerType: ProviderType.AWS,
    schemaName: "instanceVcpu",
  },
  payerAccountId: {
    displayName: "Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "payerAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  usageAccountId: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "usageAccountId",
  },
} as const;

export const awsDatabaseElastiCacheSchema = { dimensions, measures };
