import { ProviderType, UnitType } from "../enums";

const measures = {
  absoluteCredits: {
    displayName: "Absolute Credits",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCredits",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsCommittedUsageDiscount: {
    displayName: "Absolute Credits Committed Usage Discount",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsCommittedUsageDiscount",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsCommittedUsageDiscountDollarBase: {
    displayName: "Absolute Credits Committed Usage Discount Dollar Base",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsCommittedUsageDiscountDollarBase",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsDiscount: {
    displayName: "Absolute Credits Discount",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsDiscount",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsFreeTier: {
    displayName: "Absolute Credits Free Tier",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsFreeTier",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsPromotion: {
    displayName: "Absolute Credits Promotion",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsPromotion",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsResellerMargin: {
    displayName: "Absolute Credits Reseller Margin",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsResellerMargin",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsSubscriptionBenefit: {
    displayName: "Absolute Credits Subscription Benefit",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsSubscriptionBenefit",
    unit: UnitType.CURRENCY,
  },
  absoluteCreditsSustainedUsageDiscount: {
    displayName: "Absolute Credits Sustained Usage Discount",
    providerType: ProviderType.TERNARY,
    schemaName: "absoluteCreditsSustainedUsageDiscount",
    unit: UnitType.CURRENCY,
  },
  amortizedCost: {
    displayName: "Amortized Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "amortizedCost",
    unit: UnitType.CURRENCY,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  credits: {
    displayName: "Credits",
    providerType: ProviderType.TERNARY,
    schemaName: "credits",
    unit: UnitType.CURRENCY,
  },
  customNetCost: {
    displayName: "Custom Net Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "customNetCost",
    unit: UnitType.CURRENCY,
  },
  indirectCost: {
    displayName: "Indirect Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "indirectCost",
    unit: UnitType.CURRENCY,
  },
  netAmortizedCost: {
    displayName: "Net Amortized Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "netAmortizedCost",
    unit: UnitType.CURRENCY,
  },
  netCost: {
    displayName: "Net Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "netCost",
    unit: UnitType.CURRENCY,
  },
  usageAmount: {
    displayName: "Usage Amount",
    providerType: ProviderType.TERNARY,
    schemaName: "usageAmount",
    unit: UnitType.STANDARD,
  },
  usageAmountInPricingUnits: {
    displayName: "Usage Amount in Pricing Units",
    providerType: ProviderType.TERNARY,
    schemaName: "usageAmountInPricingUnits",
    unit: UnitType.STANDARD,
  },
} as const;

// If customer has AWS integration we combine these with regular
// billing measures at run time.
export const awsBillingMeasures = {
  awsNetUnblendedCost: {
    displayName: "AWS Net Unblended Cost",
    providerType: ProviderType.AWS,
    schemaName: "awsNetUnblendedCost",
    unit: UnitType.CURRENCY,
  },
  awsReservationAmortizedUpfrontCostForUsage: {
    displayName: "AWS Reservation Amortized Upfront Cost For Usage",
    providerType: ProviderType.AWS,
    schemaName: "awsReservationAmortizedUpfrontCostForUsage",
    unit: UnitType.CURRENCY,
  },
  awsReservationAmortizedUpfrontFeeForBillingPeriod: {
    displayName: "AWS Reservation Amortized Upfront Fee For Billing Period",
    providerType: ProviderType.AWS,
    schemaName: "awsReservationAmortizedUpfrontFeeForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  awsReservationEffectiveCost: {
    displayName: "AWS Reservation Effective Cost",
    providerType: ProviderType.AWS,
    schemaName: "awsReservationEffectiveCost",
    unit: UnitType.CURRENCY,
  },
  awsReservationRecurringFeeForUsage: {
    displayName: "AWS Reservation Recurring Fee For Usage",
    providerType: ProviderType.AWS,
    schemaName: "awsReservationRecurringFeeForUsage",
    unit: UnitType.CURRENCY,
  },
  awsSavingsPlanAmortizedUpfrontCommitmentForBillingPeriod: {
    displayName:
      "AWS Savings Plan Amortized Upfront Commitment For Billing Period",
    providerType: ProviderType.AWS,
    schemaName: "awsSavingsPlanAmortizedUpfrontCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  awsSavingsPlanEffectiveCost: {
    displayName: "AWS Savings Plan Effective Cost",
    providerType: ProviderType.AWS,
    schemaName: "awsSavingsPlanEffectiveCost",
    unit: UnitType.CURRENCY,
  },
  awsSavingsPlanRecurringCommitmentForBillingPeriod: {
    displayName: "AWS Savings Plan Recurring Commitment For Billing Period",
    providerType: ProviderType.AWS,
    schemaName: "awsSavingsPlanRecurringCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  awsUsageBlendedCost: {
    displayName: "AWS Usage Blended Cost",
    providerType: ProviderType.AWS,
    schemaName: "awsUsageBlendedCost",
    unit: UnitType.CURRENCY,
  },
};

export const dimensions = {
  ancestryIDs: {
    displayName: "Ancestry IDs",
    providerType: ProviderType.TERNARY,
    schemaName: "ancestryIds",
  },
  ancestryNames: {
    displayName: "Ancestry Names",
    providerType: ProviderType.TERNARY,
    schemaName: "ancestryNames",
  },
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.TERNARY,
    schemaName: "billingAccountId",
  },
  billingAccountName: {
    displayName: "Billing Account Name",
    providerType: ProviderType.TERNARY,
    schemaName: "billingAccountName",
  },
  category: {
    displayName: "Category",
    providerType: ProviderType.TERNARY,
    schemaName: "category",
  },
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.TERNARY,
    schemaName: "cloudId",
  },
  cloudName: {
    displayName: "Cloud Name",
    providerType: ProviderType.TERNARY,
    schemaName: "cloudName",
  },
  commitmentPaymentModel: {
    displayName: "Commitment Payment Model",
    providerType: ProviderType.TERNARY,
    schemaName: "commitmentPaymentModel",
  },
  customReason: {
    displayName: "Custom Reason",
    providerType: ProviderType.TERNARY,
    schemaName: "customReason",
  },
  databaseEngine: {
    displayName: "Database Engine",
    providerType: ProviderType.TERNARY,
    schemaName: "databaseEngine",
  },
  dataSource: {
    displayName: "Data Source",
    providerType: ProviderType.TERNARY,
    schemaName: "dataSource",
  },
  invoiceMonth: {
    displayName: "Invoice Month",
    providerType: ProviderType.TERNARY,
    schemaName: "invoiceMonth",
  },
  lineItemType: {
    displayName: "Line Item Type",
    providerType: ProviderType.TERNARY,
    schemaName: "lineItemType",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.TERNARY,
    schemaName: "location",
  },
  operatingSystem: {
    displayName: "Operating System",
    providerType: ProviderType.TERNARY,
    schemaName: "operatingSystem",
  },
  parentID: {
    displayName: "Parent ID",
    providerType: ProviderType.TERNARY,
    schemaName: "parentId",
  },
  parentName: {
    displayName: "Parent Name",
    providerType: ProviderType.TERNARY,
    schemaName: "parentName",
  },
  pricingModel: {
    displayName: "Pricing Model",
    providerType: ProviderType.TERNARY,
    schemaName: "pricingModel",
  },
  pricingUnit: {
    displayName: "Pricing Unit",
    providerType: ProviderType.TERNARY,
    schemaName: "pricingUnit",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.TERNARY,
    schemaName: "projectId",
  },
  projectName: {
    displayName: "Project Name",
    providerType: ProviderType.TERNARY,
    schemaName: "projectName",
  },
  projectNumber: {
    displayName: "Project Number",
    providerType: ProviderType.TERNARY,
    schemaName: "projectNumber",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.TERNARY,
    schemaName: "region",
  },
  serviceDescription: {
    displayName: "Service Description",
    providerType: ProviderType.TERNARY,
    schemaName: "serviceDescription",
  },
  serviceID: {
    displayName: "Service ID",
    providerType: ProviderType.TERNARY,
    schemaName: "serviceId",
  },
  skuDescription: {
    displayName: "SKU Description",
    providerType: ProviderType.TERNARY,
    schemaName: "skuDescription",
  },
  skuID: {
    displayName: "SKU ID",
    providerType: ProviderType.TERNARY,
    schemaName: "skuId",
  },
  tenancy: {
    displayName: "Tenancy",
    providerType: ProviderType.TERNARY,
    schemaName: "tenancy",
  },
  usageUnit: {
    displayName: "Usage Unit",
    providerType: ProviderType.TERNARY,
    schemaName: "usageUnit",
  },
  vendor: {
    displayName: "Vendor",
    providerType: ProviderType.TERNARY,
    schemaName: "vendor",
  },
  zone: {
    displayName: "Zone",
    providerType: ProviderType.TERNARY,
    schemaName: "zone",
  },
} as const;

export const billingCucSchema = { dimensions, measures };
