import { ProviderType, UnitType } from "../enums";

const measures = {
  cud1YearCost: {
    displayName: "CUD 1 Year Cost",
    providerType: ProviderType.GCP,
    schemaName: "cud1YearCost",
    unit: UnitType.CURRENCY,
  },
  cud1YearUsageAmount: {
    displayName: "CUD 1 Year Usage Amount",
    providerType: ProviderType.GCP,
    schemaName: "cud1YearUsageAmount",
    unit: UnitType.CURRENCY,
  },
  cud3YearCost: {
    displayName: "CUD 3 Year Cost",
    providerType: ProviderType.GCP,
    schemaName: "cud3YearCost",
    unit: UnitType.CURRENCY,
  },
  cud3YearUsageAmount: {
    displayName: "CUD 3 Year Usage Amount",
    providerType: ProviderType.GCP,
    schemaName: "cud3YearUsageAmount",
    unit: UnitType.CURRENCY,
  },
  cudsCreditAmount: {
    displayName: "CUDs Credit Amount",
    providerType: ProviderType.GCP,
    schemaName: "cudsCreditAmount",
    unit: UnitType.CURRENCY,
  },
  resourceCost: {
    displayName: "Resource Cost",
    providerType: ProviderType.GCP,
    schemaName: "resourceCost",
    unit: UnitType.CURRENCY,
  },
  resourceUsageAmount: {
    displayName: "Resource Usage Amount",
    providerType: ProviderType.GCP,
    schemaName: "resourceUsageAmount",
    unit: UnitType.CURRENCY,
  },
  sudsCreditAmount: {
    displayName: "SUDS Credit Amount",
    providerType: ProviderType.GCP,
    schemaName: "sudsCreditAmount",
    unit: UnitType.CURRENCY,
  },
  totalResourceCreditsAmount: {
    displayName: "Total Resource Credits Amount",
    providerType: ProviderType.GCP,
    schemaName: "totalResourceCreditsAmount",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.GCP,
    schemaName: "cloudId",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.GCP,
    schemaName: "family",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  resourceUsageUnit: {
    displayName: "Resource Usage Unit",
    providerType: ProviderType.GCP,
    schemaName: "resourceUsageUnit",
  },
};

export const gcpCommittedUseSchema = { dimensions, measures };
