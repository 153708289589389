import { ProviderType, UnitType } from "../enums";

const dimensions = {
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountId",
  },
  commitmentEndTimestamp: {
    displayName: "Commitment End Timestamp",
    providerType: ProviderType.GCP,
    schemaName: "commitmentEndTimestamp",
  },
  commitmentId: {
    displayName: "Commitment ID",
    providerType: ProviderType.GCP,
    schemaName: "commitmentId",
  },
  commitmentPlan: {
    displayName: "Commitment Plan",
    providerType: ProviderType.GCP,
    schemaName: "commitmentPlan",
  },
  commitmentStartTimestamp: {
    displayName: "Commitment Start Timestamp",
    providerType: ProviderType.GCP,
    schemaName: "commitmentStartTimestamp",
  },
  commitmentStatus: {
    displayName: "Commitment Status",
    providerType: ProviderType.GCP,
    schemaName: "commitmentStatus",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.GCP,
    schemaName: "family",
  },
  plan: {
    displayName: "Plan",
    providerType: ProviderType.GCP,
    schemaName: "plan",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  service: {
    displayName: "Service",
    providerType: ProviderType.GCP,
    schemaName: "service",
  },
};

const measures = {
  hourlyCommittedAmount: {
    displayName: "Hourly Committed Amount",
    providerType: ProviderType.GCP,
    schemaName: "hourlyCommittedAmount",
    unit: UnitType.STANDARD,
  },
  resourceAmountRAM: {
    displayName: "Resource Amount RAM",
    providerType: ProviderType.GCP,
    schemaName: "resourceAmountRAM",
    unit: UnitType.STANDARD,
  },
  resourceAmountVCPU: {
    displayName: "Resource Amount VCPU",
    providerType: ProviderType.GCP,
    schemaName: "resourceAmountVCPU",
    unit: UnitType.STANDARD,
  },
};

export const gcpComputeCudInventorySchema = { dimensions, measures };
