import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { awsOpenSearchVisibilitySchema } from "@ternary/api-lib/analytics/schemas/awsOpenSearchVisibility";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSOpenSearchInstanceGroup } from "../types";

export interface Params {
  dateRange: Date[];
}

export type AWSOpenSearchInstanceGroupEntity = {
  blockStorageCost: number;
  dataTransferAwsInbound: number;
  dataTransferAwsOutbound: number;
  dataTransferCost: number;
  dataTransferInterRegionInbound: number;
  dataTransferInterRegionOutbound: number;
  domainId: string;
  instanceCost: number;
  region: string;
  usageAccountId: string;
};

export default function useGetAWSOpenSearchInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AWSOpenSearchInstanceGroupEntity[], UError>
): UseQueryResult<AWSOpenSearchInstanceGroupEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["awsOpenSearceGroupInstances", params],
    queryFn: async () => {
      const measures = [
        awsOpenSearchVisibilitySchema.measures.blockStorageCost,
        awsOpenSearchVisibilitySchema.measures.dataTransferAwsInbound,
        awsOpenSearchVisibilitySchema.measures.dataTransferAwsOutbound,
        awsOpenSearchVisibilitySchema.measures.dataTransferCost,
        awsOpenSearchVisibilitySchema.measures.dataTransferInterRegionInbound,
        awsOpenSearchVisibilitySchema.measures.dataTransferInterRegionOutbound,
        awsOpenSearchVisibilitySchema.measures.instanceCost,
      ];

      const dimensions = [
        awsOpenSearchVisibilitySchema.dimensions.domainID,
        awsOpenSearchVisibilitySchema.dimensions.region,
        awsOpenSearchVisibilitySchema.dimensions.usageAccountID,
      ];

      const query = new DataQuery({
        dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
        dateRange: params.dateRange,
        dimensions,
        measures,
        preAggFilters: [
          {
            schemaName:
              awsOpenSearchVisibilitySchema.dimensions.domainID.schemaName,
            operator: Operator.SET,
          },
        ],
      });
      const result = await client.loadData<AWSOpenSearchInstanceGroup>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        blockStorageCost: summary.blockStorageCost ?? 0,
        dataTransferAwsInbound: summary.dataTransferAwsInbound ?? 0,
        dataTransferAwsOutbound: summary.dataTransferAwsOutbound ?? 0,
        dataTransferCost: summary.dataTransferCost ?? 0,
        dataTransferInterRegionInbound:
          summary.dataTransferInterRegionInbound ?? 0,
        dataTransferInterRegionOutbound:
          summary.dataTransferInterRegionOutbound ?? 0,
        domainId: summary.domainId ?? "",
        instanceCost: summary.instanceCost ?? 0,
        region: summary.region ?? "",
        usageAccountId: summary.usageAccountId ?? "",
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasAWSIntegration,
    ...options,
  });
}
