import { ProviderType, UnitType } from "../enums";

const measures = {
  backupStorageCost: {
    displayName: "Backup Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageCost",
    unit: UnitType.CURRENCY,
  },
  backupStorageUsage: {
    displayName: "Backup Storage Usage",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageUsage",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  dataScanCost: {
    displayName: "Data Scan Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataScanCost",
    unit: UnitType.CURRENCY,
  },
  dataScanUsage: {
    displayName: "Data Scan Usage",
    providerType: ProviderType.AWS,
    schemaName: "dataScanUsage",
    unit: UnitType.STANDARD,
  },
  nodeCost: {
    displayName: "Node Cost",
    providerType: ProviderType.AWS,
    schemaName: "nodeCost",
    unit: UnitType.CURRENCY,
  },
  nodeUsage: {
    displayName: "Node Usage",
    providerType: ProviderType.AWS,
    schemaName: "nodeUsage",
    unit: UnitType.STANDARD,
  },
  serverlessComputeCost: {
    displayName: "Serverless Compute Cost",
    providerType: ProviderType.AWS,
    schemaName: "serverlessComputeCost",
    unit: UnitType.CURRENCY,
  },
  serverlessComputeUsage: {
    displayName: "Serverless Compute Usage",
    providerType: ProviderType.AWS,
    schemaName: "serverlessComputeUsage",
    unit: UnitType.STANDARD,
  },
  storageCost: {
    displayName: "Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "storageCost",
    unit: UnitType.CURRENCY,
  },
  storageUsage: {
    displayName: "Storage Usage",
    providerType: ProviderType.AWS,
    schemaName: "storageUsage",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  payerAccountID: {
    displayName: "Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "payerAccountId",
  },
  clusterArn: {
    displayName: "Cluster ARN",
    providerType: ProviderType.AWS,
    schemaName: "clusterArn",
  },
  clusterID: {
    displayName: "Cluster ID",
    providerType: ProviderType.AWS,
    schemaName: "clusterId",
  },
  instanceMemory: {
    displayName: "Instance Memory",
    providerType: ProviderType.AWS,
    schemaName: "instanceMemory",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceVcpu: {
    displayName: "Instance vCPU",
    providerType: ProviderType.AWS,
    schemaName: "instanceVcpu",
  },
  productFamily: {
    displayName: "Product Family",
    providerType: ProviderType.AWS,
    schemaName: "productFamily",
  },
  io: {
    displayName: "IO",
    providerType: ProviderType.AWS,
    schemaName: "io",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  storage: {
    displayName: "Storage",
    providerType: ProviderType.AWS,
    schemaName: "storage",
  },
  usageAccountID: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "usageAccountId",
  },
} as const;

export const awsRedshiftVisibilitySchema = { dimensions, measures };
