import { ProviderType, UnitType } from "../enums";

const measures = {
  averagePerDatabaseDailyDatabaseBytes: {
    displayName: "Average Per Database Daily Database Bytes",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "averagePerDatabaseDailyDatabaseBytes",
    unit: UnitType.BYTES,
  },
  averagePerDatabaseDailyFailsafeBytes: {
    displayName: "Average Per Database Daily Failsafe Bytes",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "averagePerDatabaseDailyFailsafeBytes",
    unit: UnitType.BYTES,
  },
  totalDistinctDatabases: {
    displayName: "Total Distinct Databases",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "totalDistinctDatabases",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  accountName: {
    displayName: "Account Name",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "accountName",
  },
  comment: {
    displayName: "Comment",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "comment",
  },
  databaseID: {
    displayName: "Database ID",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "databaseID",
  },
  databaseName: {
    displayName: "Database Name",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "databaseName",
  },
  databaseOwner: {
    displayName: "Database Owner",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "databaseOwner",
  },
  deletionTime: {
    displayName: "Deletion Time",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "deletionTime",
  },
  lastAlteredTime: {
    displayName: "Last Altered Time",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "lastAlteredTime",
  },
  retentionDays: {
    displayName: "Retention Days",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "retentionDays",
  },
};

export const snowflakeDatabaseUsageSchema = {
  dimensions,
  measures,
};
