import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { awsRedshiftVisibilitySchema } from "@ternary/api-lib/analytics/schemas/awsRedshiftVisibility";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSRedshiftResourcesEntity } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
  isCSV?: boolean;
}

export default function useGetAWSRedshiftResources(
  params: Params,
  options?: UseQueryOptions<AWSRedshiftResourcesEntity[], UError>
): UseQueryResult<AWSRedshiftResourcesEntity[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    awsRedshiftVisibilitySchema.dimensions.clusterArn,
    awsRedshiftVisibilitySchema.dimensions.clusterID,
    awsRedshiftVisibilitySchema.dimensions.productFamily,
    awsRedshiftVisibilitySchema.dimensions.instanceMemory,
    awsRedshiftVisibilitySchema.dimensions.instanceType,
    awsRedshiftVisibilitySchema.dimensions.instanceVcpu,
    awsRedshiftVisibilitySchema.dimensions.io,
    awsRedshiftVisibilitySchema.dimensions.payerAccountID,
    awsRedshiftVisibilitySchema.dimensions.region,
    awsRedshiftVisibilitySchema.dimensions.storage,
    awsRedshiftVisibilitySchema.dimensions.usageAccountID,
  ];

  const measures = [
    awsRedshiftVisibilitySchema.measures.cost,
    awsRedshiftVisibilitySchema.measures.dataScanCost,
    awsRedshiftVisibilitySchema.measures.nodeCost,
    awsRedshiftVisibilitySchema.measures.storageCost,
    awsRedshiftVisibilitySchema.measures.serverlessComputeCost,
    awsRedshiftVisibilitySchema.measures.backupStorageCost,
    awsRedshiftVisibilitySchema.measures.nodeUsage,
    awsRedshiftVisibilitySchema.measures.dataScanUsage,
    awsRedshiftVisibilitySchema.measures.storageUsage,
    awsRedshiftVisibilitySchema.measures.serverlessComputeUsage,
    awsRedshiftVisibilitySchema.measures.backupStorageUsage,
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: params.queryFilters,
    dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
    measures,
    dimensions,
  });

  return useQuery({
    queryKey: [
      params.isCSV ? "AWSRedshiftResources" : "AWSRedshiftResourcesCSV",
      {
        dateRange: params.dateRange,
        preAggFilters: params.queryFilters,
      },
    ],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const resources = result.response.map((datum) => {
        return {
          // DIMENSIONS
          clusterArn: datum.clusterArn ?? "",
          clusterId: datum.clusterId ?? "",
          productFamily: datum.productFamily ?? "",
          instanceMemory: datum.instanceMemory ?? "",
          instanceType: datum.instanceType ?? "",
          instanceVcpu: datum.instanceVcpu ?? "",
          io: datum.io ?? "",
          payerAccountId: datum.payerAccountId ?? "",
          region: datum.region ?? "",
          storage: datum.storage ?? "",
          usageAccountId: datum.usageAccountId ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          dataScanCost: datum.dataScanCost ?? 0,
          nodeCost: datum.nodeCost ?? 0,
          storageCost: datum.storageCost ?? 0,
          serverlessComputeCost: datum.serverlessComputeCost ?? 0,
          backupStorageCost: datum.backupStorageCost ?? 0,
          nodeUsage: datum.nodeUsage ?? 0,
          dataScanUsage: datum.dataScanUsage ?? 0,
          storageUsage: datum.storageUsage ?? 0,
          serverlessComputeUsage: datum.serverlessComputeUsage ?? 0,
          backupStorageUsage: datum.backupStorageUsage ?? 0,
        } as AWSRedshiftResourcesEntity;
      });

      return resources;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
