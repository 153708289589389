import { ProviderType, UnitType } from "../enums";

const measures = {
  avgJobDuration: {
    displayName: "Average Job Duration",
    providerType: ProviderType.GCP,
    schemaName: "avgJobDuration",
    unit: UnitType.STANDARD,
  },
  onDemandCost: {
    displayName: "On Demand Cost",
    providerType: ProviderType.GCP,
    schemaName: "onDemandCost",
    unit: UnitType.CURRENCY,
  },
  jobCount: {
    displayName: "Job Count",
    providerType: ProviderType.GCP,
    schemaName: "jobCount",
    unit: UnitType.STANDARD,
  },
  maxJobSlots: {
    displayName: "Max Job Slots",
    providerType: ProviderType.GCP,
    schemaName: "maxJobSlots",
    unit: UnitType.STANDARD,
  },
  reservationCount: {
    displayName: "Reservation Count",
    providerType: ProviderType.GCP,
    schemaName: "reservationCount",
    unit: UnitType.STANDARD,
  },
  totalBytesBilled: {
    displayName: "Total Bytes Billed",
    providerType: ProviderType.GCP,
    schemaName: "totalBytesBilled",
    unit: UnitType.BYTES,
  },
  totalSlotS: {
    displayName: "Total Slot Seconds",
    providerType: ProviderType.GCP,
    schemaName: "totalSlotS",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  datasetID: {
    displayName: "Dataset ID",
    providerType: ProviderType.GCP,
    schemaName: "datasetId",
  },
  datasetProjectID: {
    displayName: "Dataset Project ID",
    providerType: ProviderType.GCP,
    schemaName: "datasetProjectId",
  },
  jobType: {
    displayName: "Job Type",
    providerType: ProviderType.GCP,
    schemaName: "jobType",
  },
  priority: {
    displayName: "Priority",
    providerType: ProviderType.GCP,
    schemaName: "priority",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  reservationID: {
    displayName: "Reservation ID",
    providerType: ProviderType.GCP,
    schemaName: "reservationId",
  },
  statementType: {
    displayName: "Statement Type",
    providerType: ProviderType.GCP,
    schemaName: "statementType",
  },
  tableID: {
    displayName: "Table ID",
    providerType: ProviderType.GCP,
    schemaName: "tableId",
  },
  timestamp: {
    displayName: "Timestamp",
    providerType: ProviderType.GCP,
    schemaName: "timestamp",
  },
  userEmail: {
    displayName: "User Email",
    providerType: ProviderType.GCP,
    schemaName: "userEmail",
  },
} as const;

export const gcpBigQueryUsageSchema = { dimensions, measures };
