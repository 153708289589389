import { ProviderType, UnitType } from "../enums";

const measures = {
  bytesDownloaded: {
    displayName: "Bytes Downloaded",
    providerType: ProviderType.AWS,
    schemaName: "bytesDownloaded",
    unit: UnitType.BYTES,
  },
  bytesUploaded: {
    displayName: "Bytes Uploaded",
    providerType: ProviderType.AWS,
    schemaName: "bytesUploaded",
    unit: UnitType.BYTES,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  credits: {
    displayName: "Credits",
    providerType: ProviderType.AWS,
    schemaName: "credits",
    unit: UnitType.STANDARD,
  },
  networkCost: {
    displayName: "Network Cost",
    providerType: ProviderType.AWS,
    schemaName: "networkCost",
    unit: UnitType.CURRENCY,
  },
  numberOfObjects: {
    displayName: "Number of Objects",
    providerType: ProviderType.AWS,
    schemaName: "numberOfObjects",
    unit: UnitType.STANDARD,
  },
  operationsCost: {
    displayName: "Operations Cost",
    providerType: ProviderType.AWS,
    schemaName: "operationsCost",
    unit: UnitType.CURRENCY,
  },
  requestCount: {
    displayName: "Request Count",
    providerType: ProviderType.AWS,
    schemaName: "requestCount",
    unit: UnitType.STANDARD,
  },
  storageCost: {
    displayName: "Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "storageCost",
    unit: UnitType.CURRENCY,
  },
  storageUsedBytes: {
    displayName: "Storage Used Bytes",
    providerType: ProviderType.AWS,
    schemaName: "storageUsedBytes",
    unit: UnitType.BYTES,
  },
};

const dimensions = {
  accountID: {
    displayName: "Account ID",
    providerType: ProviderType.AWS,
    schemaName: "accountID",
  },
  bucketName: {
    displayName: "Bucket Name",
    providerType: ProviderType.AWS,
    schemaName: "bucketName",
  },
  lineItemUsageAccountID: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountID",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  storageClass: {
    displayName: "Storage Class",
    providerType: ProviderType.AWS,
    schemaName: "storageClass",
  },
};

export const awsStorageVisibilitySchema = { measures, dimensions };
