import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpBigQueryReservationUsageSchema } from "@ternary/api-lib/analytics/schemas/gcpBigQueryReservationUsage";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { GcpBigQueryEditionType } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GcpBigQueryEditionEntity } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
}

export type BigQueryEdition = {
  adminProjectId: string;
  edition: GcpBigQueryEditionType;
  jobCount: number;
  potentialSlotS: number;
  region: string;
  reservationName: string;
  usedSlotS: number;
  utilization: number;
};

export default function useGetBigQueryDatasetGroups(
  params: Params,
  options?: UseQueryOptions<BigQueryEdition[], UError>
): UseQueryResult<BigQueryEdition[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    gcpBigQueryReservationUsageSchema.dimensions.adminProjectId,
    gcpBigQueryReservationUsageSchema.dimensions.edition,
    gcpBigQueryReservationUsageSchema.dimensions.region,
    gcpBigQueryReservationUsageSchema.dimensions.reservationName,
  ];

  const measures = [
    gcpBigQueryReservationUsageSchema.measures.jobCount,
    gcpBigQueryReservationUsageSchema.measures.potentialSlotS,
    gcpBigQueryReservationUsageSchema.measures.usedSlotS,
    gcpBigQueryReservationUsageSchema.measures.utilization,
  ];

  const [tenantID, costQuery] = useBuildDataQuery({
    dataSource: DataSource.BIGQUERY_RESERVATION_USAGE,
    dateRange: params.dateRange,
    dimensions,
    measures,
    preAggFilters: params.queryFilters,
  });

  return useQuery({
    queryKey: ["bigQueryEditions", params],
    queryFn: async () => {
      const result = await client.loadData<GcpBigQueryEditionEntity>(
        tenantID,
        costQuery
      );

      const resources = result.response.map((datum): BigQueryEdition => {
        return {
          // DIMENSIONS
          adminProjectId: datum.adminProjectId,
          edition: datum.edition,
          region: datum.region,
          reservationName: datum.reservationName,

          // MEASURES
          jobCount: datum.jobCount,
          potentialSlotS: datum.potentialSlotS,
          usedSlotS: datum.usedSlotS,
          utilization: datum.utilization,
        };
      });

      return resources;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
