import { ProviderType, UnitType } from "../enums";

const measures = {
  gpuRequests: {
    displayName: "GPU Requests",
    providerType: ProviderType.GCP,
    schemaName: "gpuRequests",
    unit: UnitType.STANDARD,
  },
  limitCPUCores: {
    displayName: "Limit CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "limitCPUCores",
    unit: UnitType.STANDARD,
  },
  limitMemoryBytes: {
    displayName: "Limit Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "limitMemoryBytes",
    unit: UnitType.BYTES,
  },
  requestCpuCores: {
    displayName: "Request CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "requestCPUCores",
    unit: UnitType.STANDARD,
  },
  requestCpuCost: {
    displayName: "Request CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "requestCPUCost",
    unit: UnitType.CURRENCY,
  },
  requestMemoryBytes: {
    displayName: "Request Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "requestMemoryBytes",
    unit: UnitType.BYTES,
  },
  requestMemoryCost: {
    displayName: "Request Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "requestMemoryCost",
    unit: UnitType.CURRENCY,
  },
  totalGpuMemoryBytes: {
    displayName: "Total GPU Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalGpuMemoryBytes",
    unit: UnitType.BYTES,
  },
  totalRequestCost: {
    displayName: "Total Request Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalRequestCost",
    unit: UnitType.CURRENCY,
  },
  totalWasteCost: {
    displayName: "Total Waste Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalWasteCost",
    unit: UnitType.CURRENCY,
  },
  usedCpuCores: {
    displayName: "Used CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "usedCPUCores",
    unit: UnitType.STANDARD,
  },
  usedCPUCost: {
    displayName: "Used CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "usedCPUCost",
    unit: UnitType.CURRENCY,
  },
  usedGpuMemoryBytes: {
    displayName: "Used GPU Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "usedGpuMemoryBytes",
    unit: UnitType.BYTES,
  },
  usedMemoryBytes: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "usedMemoryBytes",
    unit: UnitType.BYTES,
  },
  usedMemoryCost: {
    displayName: "Used Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "usedMemoryCost",
    unit: UnitType.CURRENCY,
  },
  wasteCPUs: {
    displayName: "Waste CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "wasteCPUs",
    unit: UnitType.STANDARD,
  },
  wasteMemoryBytes: {
    displayName: "Waste Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "wasteMemoryBytes",
    unit: UnitType.BYTES,
  },
  wasteCPUCost: {
    displayName: "Waste CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "wasteCPUCost",
    unit: UnitType.CURRENCY,
  },
  wasteMemoryCost: {
    displayName: "Waste Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "wasteMemoryCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.GCP,
    schemaName: "cloudId",
  },
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.GCP,
    schemaName: "clusterName",
  },
  containerName: {
    displayName: "Container Name",
    providerType: ProviderType.GCP,
    schemaName: "containerName",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.GCP,
    schemaName: "location",
  },
  namespace: {
    displayName: "Namespace",
    providerType: ProviderType.GCP,
    schemaName: "namespace",
  },
  nodeName: {
    displayName: "Node Name",
    providerType: ProviderType.GCP,
    schemaName: "nodeName",
  },
  nodepool: {
    displayName: "Nodepool",
    providerType: ProviderType.GCP,
    schemaName: "nodepool",
  },
  podName: {
    displayName: "Pod Name",
    providerType: ProviderType.GCP,
    schemaName: "podName",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  workload: {
    displayName: "Workload",
    providerType: ProviderType.GCP,
    schemaName: "workload",
  },
} as const;

export const gcpKubernetesContainerUsageSchema = {
  dimensions,
  measures,
};
