import { DateRange } from "@/utils/dates";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
} from "@ternary/api-lib/analytics/constants";
import {
  ChartType,
  DurationType,
  MetricAggregate,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { RawData, RawValue } from "@ternary/api-lib/analytics/types";
import { TOTAL_KEY } from "@ternary/api-lib/analytics/ui/SimpleChartTooltip";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { getUniformUnitType } from "@ternary/api-lib/analytics/utils/ChartDataUtils";
import {
  convertDimensionValue,
  formatMeasureValueWithUnit,
  formatTimestamp,
} from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import { getGroupingFromKeyName } from "@ternary/api-lib/analytics/utils/ChartGroupingUtils";
import { getFormatForGranularity } from "@ternary/api-lib/analytics/utils/DateUtils";
import { HourOfDay, WidgetType } from "@ternary/api-lib/constants/enums";
import { DashboardEntity } from "@ternary/api-lib/core/types";
import { ReportScope } from "@ternary/api-lib/core/types/Report";
import { add, formatDistance, isBefore, sub } from "date-fns";
import { groupBy, isEmpty } from "lodash";
import copyText from "./copyText";

export function getDurationTypeFromDashboard(
  dashboard: DashboardEntity,
  isEcoMode: boolean,
  globalDate: {
    date: Date[];
    durationType: DurationType;
  } | null
): DurationType {
  if (globalDate) return globalDate.durationType;

  return dashboard.durationType
    ? dashboard.durationType
    : isEcoMode
      ? DurationType.LAST_NINETY_DAYS
      : DurationType.LAST_THIRTY_DAYS;
}

export function getScopeText(scope: string) {
  switch (scope) {
    case ReportScope.GLOBAL:
      return copyText.tableScopeGlobal;
    case ReportScope.PRIVATE:
      return copyText.tableScopePrivate;
    case ReportScope.SHARED:
      return copyText.tableScopeShared;
    default:
      return copyText.tableScopeShared;
  }
}

export function getTimeDurationCaption(
  durationType: DurationType,
  isFiscalMode: boolean
): string {
  switch (durationType) {
    case DurationType.LAST_N_DAYS:
    case DurationType.LAST_N_MONTHS:
    case DurationType.CUSTOM: {
      return copyText.durationTypeCustomCaption;
    }
    case DurationType.INVOICE: {
      return copyText.durationTypeInvoiceCaption;
    }
    case DurationType.LAST_MONTH: {
      return copyText.durationTypeLastMonthCaption;
    }
    case DurationType.LAST_NINETY_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastNinetyDaysCaption
        : copyText.durationTypeLastNinetyDaysCaption;
    }
    case DurationType.LAST_SEVEN_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastSevenDaysCaption
        : copyText.durationTypeLastSevenDaysCaption;
    }
    case DurationType.LAST_THIRTY_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastThirtyDaysCaption
        : copyText.durationTypeLastThirtyDaysCaption;
    }
    case DurationType.MONTH_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalMonthToDateDaysCaption
        : copyText.durationTypeMonthToDateDaysCaption;
    }
    case DurationType.QUARTER_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalQuarterToDateCaption
        : copyText.durationTypeQuarterToDateCaption;
    }
    case DurationType.TODAY: {
      return copyText.durationTypeTodayCaption;
    }
    case DurationType.YEAR_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalYearToDateCaption
        : copyText.durationTypeYearToDateCaption;
    }
    case DurationType.YESTERDAY: {
      return copyText.durationTypeYesterdayCaption;
    }
    default: {
      const _exhaustiveCheck: never = durationType;
      return _exhaustiveCheck;
    }
  }
}

export function getTimeLastUpdatedCaption(updatedAt: string): string {
  const distance = formatDistance(new Date(updatedAt), new Date());

  return copyText.reportTimeLastUpdatedCaption.replace("%distance%", distance);
}

export function getTooltipUnitFormatter(params: {
  dataSource: string;
  dimensions: Dimension[];
  measures: Measure[];
}) {
  return (value: unknown, groupingKey?: string) => {
    if (typeof value !== "number") return "0";

    let unit: string | undefined;

    if (groupingKey === TOTAL_KEY) {
      const sharedMeasureUnit = getUniformUnitType(params.measures);
      if (sharedMeasureUnit) {
        unit = sharedMeasureUnit;
      }
    } else {
      const { measureSchemaName } = getGroupingFromKeyName(
        groupingKey ?? DEFAULT_X_AXIS_KEY,
        params.measures,
        params.dimensions
      );

      const measure = params.measures.find(
        (measure) => measure.schemaName === measureSchemaName
      );

      if (measure) {
        unit = measure.unit;
      }
    }

    return formatMeasureValueWithUnit({
      unit,
      value,
    });
  };
}

export function padInvoiceMonthDateRange(dateRange: DateRange): DateRange {
  return [
    sub(dateRange[0], { days: 3 }),
    add(dateRange[1], { days: 2, months: 1 }),
  ];
}

//
// CSV Generation Functions
//

export function filterDataBasedOnSearchText(
  data: RawData[],
  searchText: string
) {
  return data.filter((datum) => {
    const found = Object.values(datum).find((value) => {
      if (
        (typeof value === "string" &&
          value.toLowerCase().includes(searchText)) ||
        String(value).toLowerCase().includes(searchText)
      ) {
        return true;
      }
    });

    return found !== undefined;
  });
}

export function getSortedTimestampsForTimeSeries(
  filteredData: RawData[],
  options?: {
    isFiscal?: boolean;
    isInvoiceMonthMode?: boolean;
  }
): string[] {
  const dataGroupedByTimestamp = options?.isInvoiceMonthMode
    ? groupBy(filteredData, "invoiceMonth")
    : groupBy(filteredData, "timestamp");

  if (options?.isFiscal) {
    return Object.keys(dataGroupedByTimestamp);
  }

  const timestamps = Object.keys(dataGroupedByTimestamp).filter(
    (key) => !isNaN(Date.parse(key)) || key.includes(COMPARISON_KEY)
  );

  if (options?.isInvoiceMonthMode) {
    return timestamps;
  }

  return timestamps.sort((a, b) => {
    if (isBefore(new Date(a), new Date(b))) return -1;
    else return 1;
  });
}

export function removeInvalidAccessorCharacters(accessor: string): string {
  const KNOWN_CHARACTERS_WITH_ISSUES = [".", "[", "]"];
  let safeAccessor = accessor;

  KNOWN_CHARACTERS_WITH_ISSUES.forEach((char) => {
    if (accessor.includes(char)) {
      safeAccessor = safeAccessor.replace(new RegExp(`\\${char}`, "g"), "--");
    }
  });

  return safeAccessor;
}

export function getFlatData(params: {
  dimensions: Dimension[];
  filteredData: RawData[];
  hasFiscalDates?: boolean;
  isCumulativeMode?: boolean;
  isComparisonMode?: boolean;
  isInvoiceMonthMode?: boolean;
  measures: Measure[];
  timeSeriesGranularity?: TimeGranularity;
}) {
  if (params.timeSeriesGranularity) {
    const rows: RawData[] = [];

    const DELIMITER = "-%%DELIM%%-";
    const dimensionKeys = params.dimensions.map(
      (dimension) => dimension.schemaName
    );

    const dataGroupedByDimensionsAndSingleMeasure = params.filteredData.reduce(
      (accum: { [key: string]: RawData[] }, datum) => {
        const dimensionsForKey = dimensionKeys
          .map((key) => datum[key] ?? "null")
          .join(DELIMITER);

        params.measures.forEach((measure) => {
          const key = dimensionsForKey + DELIMITER + measure.schemaName;

          if (accum[key]) {
            accum[key].push(datum);
          } else {
            accum[key] = [datum];
          }
        });

        return accum;
      },
      {}
    );

    Object.keys(dataGroupedByDimensionsAndSingleMeasure).forEach(
      (compositeKey) => {
        const arr = compositeKey.split(DELIMITER);
        const dimensionValues = arr.slice(0, -1);
        const measureKey = arr[arr.length - 1];

        const row: { [key: string]: RawValue } = {};

        row.selected_measure = removeInvalidAccessorCharacters(measureKey);

        dimensionKeys.forEach((key, i) => {
          row[removeInvalidAccessorCharacters(key)] = dimensionValues[i];
        });

        const datedEntities =
          dataGroupedByDimensionsAndSingleMeasure[compositeKey];

        datedEntities.forEach((datum) => {
          const dateString = params.isInvoiceMonthMode
            ? datum.invoiceMonth
            : datum.timestamp;

          if (typeof dateString === "string") {
            const dateKey =
              params.hasFiscalDates || params.isInvoiceMonthMode
                ? dateString
                : dateString.includes(COMPARISON_KEY)
                  ? `${formatTimestamp(
                      dateString.replace(` (${COMPARISON_KEY})`, ""),
                      getFormatForGranularity(params.timeSeriesGranularity)
                    )} (${COMPARISON_KEY})`
                  : formatTimestamp(
                      dateString,
                      getFormatForGranularity(params.timeSeriesGranularity)
                    );

            row[dateKey] = datum[measureKey];
          }
        });

        if (params.isCumulativeMode) {
          row.totals = datedEntities[datedEntities.length - 1][measureKey];
        } else {
          row.totals = datedEntities.reduce((accum: number, entity) => {
            const measureValue = entity[measureKey];
            if (typeof measureValue === "number" && measureValue !== null) {
              return accum + measureValue;
            } else return accum;
          }, 0);
        }

        rows.push(row);
      }
    );

    // Only run this once data is available otherwise a blank CSV will download before full CSV
    if (params.filteredData.length > 0) {
      const previousTotalsRow: RawData = {};
      const totalsKeyedByDate: { [date: string]: number } = {};
      const totalsRow: RawData = {};

      const firstDimension =
        params.dimensions[0]?.schemaName || "selected_measure";

      rows.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const currentValue = totalsRow[key];

          if (
            typeof value !== "number" ||
            (typeof currentValue !== "number" &&
              typeof currentValue !== "undefined")
          )
            return null;

          if (currentValue !== undefined) {
            totalsRow[key] = (currentValue ?? 0) + (value ?? 0);
          } else {
            totalsRow[key] = value;
          }

          totalsKeyedByDate[key] = totalsRow[key] as number;
        });
      });

      if (params.measures.length === 1 && params.isComparisonMode) {
        const dateStrings = Object.keys(totalsKeyedByDate);

        Object.entries(totalsRow).forEach(([key, value]) => {
          const currentTimestampIndex = dateStrings.indexOf(key);

          if (key.includes(COMPARISON_KEY)) return null;

          const currentTotal = totalsKeyedByDate[key];

          const previousTotal =
            totalsKeyedByDate[dateStrings[currentTimestampIndex + 1]];

          if (
            typeof previousTotal !== "number" &&
            typeof previousTotal !== "undefined"
          ) {
            return null;
          }

          if (previousTotal !== undefined) {
            previousTotalsRow[key] = currentTotal - previousTotal;
          } else {
            previousTotalsRow[key] = value;
          }
        });

        previousTotalsRow[firstDimension] = copyText.csvTotalsDeltasHeader;
      }

      totalsRow[firstDimension] = copyText.csvTotalsHeader;

      rows.push(
        totalsRow,
        ...(isEmpty(previousTotalsRow) ? [] : [previousTotalsRow])
      );
    }

    return rows;
  } else {
    return params.filteredData.map((datum) => {
      const newDatum = { ...datum };

      params.dimensions.forEach((dimension) => {
        newDatum[removeInvalidAccessorCharacters(dimension.schemaName)] =
          convertDimensionValue(
            datum[dimension.schemaName],
            getFormatForGranularity(params.timeSeriesGranularity)
          );
      });
      return newDatum;
    });
  }
}

export function getFlatTransposeCSVData(params: {
  dimensions: Dimension[];
  filteredData: RawData[];
  hasFiscalDates?: boolean;
  isComparisonMode?: boolean;
  isInvoiceMonthMode?: boolean;
  measures: Measure[];
  timeSeriesGranularity?: TimeGranularity;
}) {
  if (params.timeSeriesGranularity) {
    const dimensionKeys = params.dimensions.map(
      (dimension) => dimension.schemaName
    );

    const measureKeys = params.measures.map((measure) => measure.schemaName);

    const timestampKeys = getSortedTimestampsForTimeSeries(
      params.filteredData,
      {
        isFiscal: params.hasFiscalDates,
        isInvoiceMonthMode: params.isInvoiceMonthMode,
      }
    );

    const rows = params.filteredData.reduce((accum: RawData[], datum) => {
      const dimensionsForWithKey = {};
      const timestamp = String(datum.timestamp);

      dimensionKeys.map(
        (key) => (dimensionsForWithKey[key] = datum[key] ?? "null")
      );

      const dateString = params.isInvoiceMonthMode
        ? datum.invoiceMonth
        : params.isComparisonMode
          ? timestamp.replace(` (${COMPARISON_KEY})`, "")
          : timestamp;

      if (typeof dateString === "string") {
        const dateKey =
          params.hasFiscalDates || params.isInvoiceMonthMode
            ? dateString
            : formatTimestamp(
                dateString,
                getFormatForGranularity(params.timeSeriesGranularity)
              );
        const measureData: { [key: string]: number } = { totals: 0 };
        measureKeys.map((key) => {
          const value = datum[key];
          if (typeof value === "number" && value !== null) {
            measureData[key] = value;
            measureData.totals += value;
          }
        });

        const timeGranularityLabel = params.timeSeriesGranularity
          ? params.timeSeriesGranularity.charAt(0).toUpperCase() +
            params.timeSeriesGranularity.slice(1).toLowerCase()
          : TimeGranularity.DAY;

        const dateIndex = timestampKeys.indexOf(timestamp);

        const label = params.isComparisonMode
          ? timestamp.includes(COMPARISON_KEY)
            ? ` (${COMPARISON_KEY} - ${timeGranularityLabel} ${
                (dateIndex % 2 ? dateIndex - 1 : dateIndex + 2) / 2 + 1
              })`
            : ` (Current - ${timeGranularityLabel} ${
                (dateIndex % 2 ? dateIndex - 1 : dateIndex + 2) / 2
              })`
          : "";

        accum.push({
          selected_date: dateKey + label,
          ...measureData,
          ...dimensionsForWithKey,
        });
      }

      return accum;
    }, []);

    // Only run this once data is available otherwise a blank CSV will download before full CSV
    if (params.filteredData.length > 0) {
      const totalsRow: RawData = {};

      const firstDimension = "selected_date";

      rows.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const currentValue = totalsRow[key];

          if (
            typeof value !== "number" ||
            (typeof currentValue !== "number" &&
              typeof currentValue !== "undefined")
          )
            return null;

          if (currentValue !== undefined) {
            totalsRow[key] = (currentValue ?? 0) + (value ?? 0);
          } else {
            totalsRow[key] = value;
          }
        });
      });

      totalsRow[firstDimension] = copyText.csvTotalsHeader;

      rows.push(totalsRow);
    }
    return rows;
  } else {
    return params.filteredData.map((datum) => {
      const newDatum = { ...datum };

      params.dimensions.forEach((dimension) => {
        newDatum[removeInvalidAccessorCharacters(dimension.schemaName)] =
          convertDimensionValue(
            datum[dimension.schemaName],
            getFormatForGranularity(params.timeSeriesGranularity)
          );
      });
      return newDatum;
    });
  }
}

export function limitTimeSeriesCSVRows(params: {
  rows: RawData[];
  dimensions: Dimension[];
  limit: number;
  measures: Measure[];
}) {
  const dimensionSet = params.dimensions.reduce(
    (set, dimension) => ({ ...set, [dimension.schemaName]: true }),
    {} as { [dimension: string]: boolean }
  );

  const rowTotals = params.rows.map((row) => {
    const dateKeys = Object.keys(row).filter(
      (key) => key !== "selected_measure" && !dimensionSet[key]
    );

    let total = 0;

    for (const dateKey of dateKeys) {
      const dateValue = row[dateKey];

      if (typeof dateValue !== "number") continue;

      total += dateValue;
    }

    return total;
  });

  type TopRowsByMeasure = {
    [measureName: string]: RowWithTotal[];
  };

  type RowWithTotal = {
    row: RawData;
    total: number;
  };

  const topRowsByMeasure = params.measures
    .map(({ schemaName }) => schemaName)
    .reduce(
      (accum, measureSchemaName) => ({ ...accum, [measureSchemaName]: [] }),
      {} as TopRowsByMeasure
    );

  for (let index = 0; index < params.rows.length; index++) {
    const row = params.rows[index];
    const total = rowTotals[index];

    const measureName = row.selected_measure;
    if (typeof measureName !== "string") continue;

    const topRows = topRowsByMeasure[measureName];
    if (!topRows) continue;

    topRows.push({ row, total });
    topRows.sort((a, b) => b.total - a.total);
    if (topRows.length > params.limit) topRows.pop();
  }

  const sortedTimeSeriesCSVRowsWithLimit = params.measures.reduce(
    (accum, measure) => {
      const thisMeasuresTopRows = topRowsByMeasure[measure.schemaName]?.map(
        (rowWithTotal) => rowWithTotal.row
      );

      if (!thisMeasuresTopRows) return accum;

      return [...accum, ...thisMeasuresTopRows];
    },
    [] as RawData[]
  );

  return sortedTimeSeriesCSVRowsWithLimit;
}

//
// Type Guards
//

export function isChartType(input: string): input is ChartType {
  return Object.keys(ChartType).some((key) => ChartType[key] === input);
}

export function isDurationType(input: string): input is DurationType {
  return Object.keys(DurationType).some((key) => DurationType[key] === input);
}

export function isMetricAggregate(input: string): input is MetricAggregate {
  return Object.keys(MetricAggregate).some(
    (key) => MetricAggregate[key] === input
  );
}

//
// Grid Layout
//

export function formatWidgetSpec(
  resourceID: string,
  type: WidgetType,
  widgetSpecs: { width: number; xCoordinate: number; yCoordinate: number }[],
  height?: number
) {
  const newWidgetSpec = {
    budgetID: "",
    reportID: "",
    savingsOpportunityFilterID: "",
    textWidgetID: "",
    height: height ?? 2,
    type,
    width: 2,
    xCoordinate: 0,
    yCoordinate: 0,
  };

  switch (type) {
    case WidgetType.BUDGET_CURRENT_MONTH:
    case WidgetType.BUDGET_DAILY_TRENDS:
      newWidgetSpec.budgetID = resourceID;
      break;
    case WidgetType.REPORT:
      newWidgetSpec.reportID = resourceID;
      break;
    case WidgetType.SAVINGS_OPPORTUNITY_FILTER:
      newWidgetSpec.savingsOpportunityFilterID = resourceID;
      break;
    case WidgetType.TEXT:
      newWidgetSpec.textWidgetID = resourceID;
  }

  if (widgetSpecs.length > 0) {
    const sortedWidgetSpec = [...widgetSpecs].sort((a, b) => {
      return a.yCoordinate === b.yCoordinate
        ? a.xCoordinate - b.xCoordinate
        : a.yCoordinate - b.yCoordinate;
    });

    const lastWidgetSpec = sortedWidgetSpec.slice(-1)[0];

    if (lastWidgetSpec.width >= 3) {
      // place report in following row
      newWidgetSpec.yCoordinate = lastWidgetSpec.yCoordinate + 1;
    } else {
      //place report right next to the previous one same row
      newWidgetSpec.xCoordinate = lastWidgetSpec.xCoordinate === 2 ? 0 : 2;
      newWidgetSpec.yCoordinate = lastWidgetSpec.yCoordinate;
    }
  }

  return newWidgetSpec;
}

export function getDateTimeInput(hourOfDay: HourOfDay): Date {
  //Create Date obj with utc hour to get related local hour
  const utcHour = convertToHourNumber(hourOfDay);

  const timeInput = new Date();
  timeInput.setUTCHours(utcHour);
  timeInput.setMinutes(0);

  return timeInput;
}

const AMHours = {
  TWELVE: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
};

const PMHours = {
  TWELVE: 12,
  ONE: 13,
  TWO: 14,
  THREE: 15,
  FOUR: 16,
  FIVE: 17,
  SIX: 18,
  SEVEN: 19,
  EIGHT: 20,
  NINE: 21,
  TEN: 22,
  ELEVEN: 23,
};

export function convertToHourNumber(hour: HourOfDay): number {
  const hourStr = hour.split("_")[0];
  const meridian = hour.split("_")[1];

  return meridian === "AM" ? AMHours[hourStr] : PMHours[hourStr];
}

export function convertToHourOfDay(hour: number): HourOfDay {
  let hourStr: string;

  //00:00 - 23:00
  if (hour > 11) {
    const _hour =
      Object.keys(PMHours).find((_hour) => PMHours[_hour] === hour) ?? "NINE";
    hourStr = _hour.concat("_", "PM");
  } else {
    const _hour =
      Object.keys(AMHours).find((_hour) => AMHours[_hour] === hour) ?? "NINE";
    hourStr = _hour.concat("_", "AM");
  }

  return hourStr as HourOfDay;
}
