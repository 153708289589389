import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { awsComputeInstancesSchema } from "@ternary/api-lib/analytics/schemas/awsComputeInstances";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSComputeInstanceGroup,
  AWSComputeInstanceGroupStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetAWSComputeInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AWSComputeInstanceGroup[], UError>
): UseQueryResult<AWSComputeInstanceGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsComputeInstanceGroups", params],
    queryFn: async () => {
      const dimensions = [
        awsComputeInstancesSchema.dimensions.family,
        awsComputeInstancesSchema.dimensions.familyType,
        awsComputeInstancesSchema.dimensions.lineItemUsageAccountId,
        awsComputeInstancesSchema.dimensions.operatingSystem,
        awsComputeInstancesSchema.dimensions.region,
      ];

      const measures = [
        awsComputeInstancesSchema.measures.cpuUtilization,
        awsComputeInstancesSchema.measures.diskReadBytes,
        awsComputeInstancesSchema.measures.diskWriteBytes,
        awsComputeInstancesSchema.measures.instanceIDCount,
        awsComputeInstancesSchema.measures.instanceUnits,
        awsComputeInstancesSchema.measures.networkInBytes,
        awsComputeInstancesSchema.measures.networkOutBytes,
        awsComputeInstancesSchema.measures.productMemoryBytes,
        awsComputeInstancesSchema.measures.productVCPU,
        awsComputeInstancesSchema.measures.totalCost,
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_COMPUTE_INSTANCES,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const instanceGroups = result.map((datum): AWSComputeInstanceGroup => {
        const [error, validData] = validate(
          datum,
          AWSComputeInstanceGroupStruct
        );

        if (error) {
          throw new UError("INVALID_AWS_COMPUTE_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          family: validData.family ?? "",
          familyType: validData.familyType ?? "",
          lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
          operatingSystem: validData.operatingSystem ?? "",
          region: validData.region ?? "",

          // MEASURES
          cpuUtilization: validData.cpuUtilization ?? 0,
          diskReadBytes: validData.diskReadBytes ?? 0,
          diskWriteBytes: validData.diskWriteBytes ?? 0,
          instanceIDCount: validData.instanceIDCount ?? 0,
          instanceUnits: validData.instanceUnits ?? 0,
          networkInBytes: validData.networkInBytes ?? 0,
          networkOutBytes: validData.networkOutBytes ?? 0,
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          productVCPU: validData.productVCPU ?? 0,
          totalCost: validData.totalCost ?? 0,
        };
      });

      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
