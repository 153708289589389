import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import Modal from "@/ui-lib/components/Modal";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChartArea, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  DurationType,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { awsStorageVisibilitySchema } from "@ternary/api-lib/analytics/schemas/awsStorageVisibility";
import { RawData } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils/ReportUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import copyText from "../../copyText";
import useGetAWSStorageBuckets from "../hooks/useGetAWSStorageS3Buckets";
import { S3BucketGroup, S3BucketGroupFilters } from "../types";
import AWSStorageS3GroupTable from "./AWSStorageS3GroupTable";
import AWSStorageS3GroupTableControls from "./AWSStorageS3GroupTableControls";
import AWSStorageS3SubTable from "./AWSStorageS3SubTable";

const S3CostChartOption = {
  BUCKETS: "BUCKETS",
  LINKED_ACCOUNTS: "LINKED_ACCOUNTS",
  STORAGE_CLASS: "STORAGE_CLASS",
  REGION: "REGION",
} as const;

type S3CostChartOption =
  (typeof S3CostChartOption)[keyof typeof S3CostChartOption];

const S3UsageChartOption = {
  STORAGE: "STORAGE",
  DATA_TRANSFER: "DATA_TRANSFER",
  OPERATIONS: "OPERATIONS",
} as const;

type S3UsageChartOption =
  (typeof S3UsageChartOption)[keyof typeof S3UsageChartOption];

type Interaction =
  | AWSStorageS3GroupTable.Interaction
  | AWSStorageS3GroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  bucketGroupFilters: z.infer<typeof bucketGroupFiltersStruct>;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  selectedCostChartOption: S3CostChartOption;
  selectedGroupID: string | null;
  selectedUsageChartOption: S3UsageChartOption;
};

const costChartOptionEnum = createEnumParam(Object.values(S3CostChartOption));
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(Object.values(S3UsageChartOption));

const bucketGroupFiltersDefault = {
  accountID: null,
  region: null,
};

const bucketGroupFiltersStruct = z.object({
  accountID: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const queryParamConfigMap = {
  bucket_group_filters: createStructParam(bucketGroupFiltersStruct),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    S3CostChartOption.LINKED_ACCOUNTS
  ),
  selected_group_id: StringParam,
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    S3UsageChartOption.STORAGE
  ),
};

export default function AWSStorageS3VisibilityContainer() {
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_STORAGE_VISIBILITY,
      dateRange,
      dimensions: getCostDimensionsFromOptions(
        queryParamState.selectedCostChartOption
      ),
      granularity: queryParamState.dateRangeGranularity,
      measures: [
        awsStorageVisibilitySchema.measures.cost,
        awsStorageVisibilitySchema.measures.storageCost,
      ],
    });

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_STORAGE_VISIBILITY,
      dateRange,
      dimensions: getUsageDimensionsFromOption(
        queryParamState.selectedUsageChartOption
      ),
      granularity: queryParamState.dateRangeGranularity,
      measures: [
        awsStorageVisibilitySchema.measures.bytesDownloaded,
        awsStorageVisibilitySchema.measures.bytesUploaded,
        awsStorageVisibilitySchema.measures.requestCount,
        awsStorageVisibilitySchema.measures.numberOfObjects,
        awsStorageVisibilitySchema.measures.storageUsedBytes,
      ],
    });

  const { data: bucketGroups, isFetching: isLoadingBucketGroups } =
    useGetAWSStorageBuckets({
      dateRange,
    });

  //
  // MODIFIED QUERY DATA
  //

  const filteredBucketGroups = useMemo(() => {
    return getFilteredBucketGroups({
      allBucketGroups: bucketGroups ?? [],
      bucketGroupFilters: queryParamState.bucketGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [queryParamState.bucketGroupFilters, bucketGroups, debouncedSearchText]);

  const csvData = useMemo(
    () => getCSVData(filteredBucketGroups),
    [filteredBucketGroups]
  );

  const selectedGroupBuckets = useMemo(() => {
    if (queryParamState.selectedGroupID === null || !bucketGroups) {
      return [];
    }

    const selectedGroup = bucketGroups.find(
      (group) => group.groupID === queryParamState.selectedGroupID
    );

    return selectedGroup?.buckets ?? [];
  }, [bucketGroups, queryParamState.selectedGroupID]);

  const isEmptyUsageChartData = checkEmptyData(usageChartData);

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.bucketGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ bucket_group_filters: nextFilters });
        break;
      }
      case AWSStorageS3GroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.bucketGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ bucket_group_filters: null });
        } else {
          setQueryParams({ bucket_group_filters: nextFilters });
        }
        break;
      }
      case AWSStorageS3GroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  return (
    <Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          direction="column"
          height={500}
          padding={theme.space_md}
        >
          <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.s3OptimizationsChartTitleCost}
            </Text>

            {/* COST DROPDOWN */}
            <Dropdown
              options={costChartOptions.map((option) => ({
                ...option,
                onClick: () =>
                  setQueryParams({ selected_cost_chart_option: option.value }),
              }))}
              placement="bottom-end"
              selectedOption={
                costChartOptions.find(
                  (option) =>
                    option.value === queryParamState.selectedCostChartOption
                ) ?? costChartOptions[0]
              }
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
                width={140}
              >
                {getOptionLabel(queryParamState.selectedCostChartOption)}
              </Button>
            </Dropdown>
          </Flex>

          {/* COST CHART */}
          <Box flex="1 0 0">
            <StackedBarChart
              data={costChartData}
              dimensions={getCostDimensionsFromOptions(
                queryParamState.selectedCostChartOption
              )}
              disableDrilldown
              isLoading={isLoadingCostChartData}
              measures={[awsStorageVisibilitySchema.measures.cost]}
              showTooltip
              showLegend
              timeSeriesGranularity={queryParamState.dateRangeGranularity}
              xAxisKey="timestamp"
            />
          </Box>
        </Flex>

        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          direction="column"
          height={500}
          padding={theme.space_md}
        >
          <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.s3OptimizationsChartTitleUsage}
            </Text>

            {/* USAGE DROPDOWN */}
            <Dropdown
              options={usageChartOptions.map((option) => ({
                ...option,
                onClick: () =>
                  setQueryParams({ selected_usage_chart_option: option.value }),
              }))}
              placement="bottom-end"
              selectedOption={
                usageChartOptions.find(
                  (option) =>
                    option.value === queryParamState.selectedUsageChartOption
                ) ?? usageChartOptions[0]
              }
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
                width={140}
              >
                {getOptionLabel(queryParamState.selectedUsageChartOption)}
              </Button>
            </Dropdown>
          </Flex>

          {/* USAGE CHART */}
          <Box flex="1 0 0">
            {queryParamState.selectedUsageChartOption ===
              S3UsageChartOption.DATA_TRANSFER ||
            (queryParamState.selectedUsageChartOption ===
              S3UsageChartOption.OPERATIONS &&
              isEmptyUsageChartData) ? (
              <EmptyPlaceholder
                height="25rem"
                icon={faChartArea}
                loading={false}
                skeletonVariant="cartesian"
                text={copyText.noDataPlaceholderMessage}
              />
            ) : (
              <AreaChart
                data={usageChartData}
                dimensions={getUsageDimensionsFromOption(
                  queryParamState.selectedUsageChartOption
                )}
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            )}
          </Box>
        </Flex>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSStorageS3GroupTableControls
          bucketGroupFilters={queryParamState.bucketGroupFilters}
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <AWSStorageS3GroupTable
        bucketGroups={filteredBucketGroups}
        isLoadingBucketGroups={isLoadingBucketGroups}
        onInteraction={handleInteraction}
      />

      {queryParamState.selectedGroupID !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() => setQueryParams({ selected_group_id: null })}
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Text fontSize={theme.h4_fontSize}>{"Buckets"}</Text>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <AWSStorageS3SubTable
              buckets={selectedGroupBuckets}
              date={dateRange}
              onClose={() => setQueryParams({ selected_group_id: null })}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

const costChartOptions = [
  S3CostChartOption.BUCKETS,
  S3CostChartOption.LINKED_ACCOUNTS,
  S3CostChartOption.STORAGE_CLASS,
  S3CostChartOption.REGION,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  S3UsageChartOption.STORAGE,
  S3UsageChartOption.DATA_TRANSFER,
  S3UsageChartOption.OPERATIONS,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getOptionLabel(option: S3CostChartOption | S3UsageChartOption) {
  return copyText[`s3ChartOptionLabel_${option}`];
}

function getUsageMeasuresFromOption(option: S3UsageChartOption): Measure[] {
  switch (option) {
    case S3UsageChartOption.DATA_TRANSFER:
      return [
        awsStorageVisibilitySchema.measures.bytesDownloaded,
        awsStorageVisibilitySchema.measures.bytesUploaded,
      ];
    case S3UsageChartOption.OPERATIONS:
      return [awsStorageVisibilitySchema.measures.requestCount];
    case S3UsageChartOption.STORAGE:
      return [awsStorageVisibilitySchema.measures.storageUsedBytes];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageDimensionsFromOption(option: S3UsageChartOption): Dimension[] {
  switch (option) {
    case S3UsageChartOption.DATA_TRANSFER:
      return [awsStorageVisibilitySchema.dimensions.region];
    case S3UsageChartOption.OPERATIONS:
      return [awsStorageVisibilitySchema.dimensions.bucketName];
    case S3UsageChartOption.STORAGE:
      return [awsStorageVisibilitySchema.dimensions.storageClass];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostDimensionsFromOptions(option: S3CostChartOption): Dimension[] {
  switch (option) {
    case S3CostChartOption.BUCKETS:
      return [awsStorageVisibilitySchema.dimensions.bucketName];
    case S3CostChartOption.LINKED_ACCOUNTS:
      return [awsStorageVisibilitySchema.dimensions.lineItemUsageAccountID];
    case S3CostChartOption.REGION:
      return [awsStorageVisibilitySchema.dimensions.region];
    case S3CostChartOption.STORAGE_CLASS:
      return [awsStorageVisibilitySchema.dimensions.storageClass];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function checkEmptyData(usageData: RawData[]): boolean {
  let check = 0;
  usageData.forEach((usage) => {
    if (
      usage.bytesUploaded &&
      typeof usage.bytesUploaded === "number" &&
      usage.bytesDownloaded &&
      typeof usage.bytesDownloaded === "number" &&
      usage.requestCount &&
      typeof usage.requestCount === "number"
    ) {
      check += usage.bytesUploaded;
      check += usage.bytesDownloaded;
      check += usage.requestCount;
    }
  });

  if (check > 0) {
    return false;
  }
  return true;
}

const csvAccessors = [
  "accountID",
  "bucketCount",
  "networkCost",
  "operationsCost",
  "region",
  "storageCost",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: S3BucketGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    accountID: group.accountID,
    bucketCount: group.buckets.length,
    networkCost: group.networkCost,
    operationsCost: group.operationsCost,
    region: group.region,
    storageCost: group.storageCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `s3TableBucketGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredBucketGroupsParams = {
  allBucketGroups: S3BucketGroup[];
  bucketGroupFilters: S3BucketGroupFilters;
  searchText: string | null;
};

function getFilteredBucketGroups(
  params: GetFilteredBucketGroupsParams
): S3BucketGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allBucketGroups.filter((bucketGroup) => {
    if (!bucketGroupPassesFilters(bucketGroup, params.bucketGroupFilters)) {
      return false;
    }

    if (!bucketGroupHasSearchText(bucketGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    bucketGroupFilters:
      queryParams.bucket_group_filters ?? bucketGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedGroupID: queryParams.selected_group_id ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

function nullOrIsSame(filter: string | null, b: string) {
  if (filter === null) return true;
  return filter.toLowerCase().trim() === b.toLowerCase().trim();
}

function bucketGroupPassesFilters(
  bucketGroup: S3BucketGroup,
  filters: S3BucketGroupFilters
) {
  if (!nullOrIsSame(filters.accountID, bucketGroup.accountID)) {
    return false;
  }
  if (!nullOrIsSame(filters.region, bucketGroup.region)) {
    return false;
  }

  return true;
}

function bucketGroupHasSearchText(
  bucketGroup: S3BucketGroup,
  searchText: string
) {
  if (searchText === "") return true;

  if (bucketGroup.accountID.toLowerCase().trim().includes(searchText)) {
    return true;
  }
  if (bucketGroup.region.toLowerCase().trim().includes(searchText)) {
    return true;
  }

  return false;
}
