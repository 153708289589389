import { ProviderType, UnitType } from "../enums";

const measures = {
  oneYearDiscount: {
    displayName: "One Year Discount",
    providerType: ProviderType.GCP,
    schemaName: "oneYearDiscount",
    unit: UnitType.PERCENTAGE,
  },
  threeYearDiscount: {
    displayName: "Three Year Discount",
    providerType: ProviderType.GCP,
    schemaName: "threeYearDiscount",
    unit: UnitType.PERCENTAGE,
  },
};
const dimensions = {
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountID",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.GCP,
    schemaName: "family",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
} as const;

export const gcpCustomPricingSchema = { dimensions, measures };
