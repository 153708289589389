import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  totalBackupCost: {
    displayName: "Total Backup Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalBackupCost",
    unit: UnitType.CURRENCY,
  },
  totalComputeCost: {
    displayName: "Total Compute Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalComputeCost",
    unit: UnitType.CURRENCY,
  },
  totalDataBoostComputeCost: {
    displayName: "Total Data Boost Compute Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalDataBoostComputeCost",
    unit: UnitType.CURRENCY,
  },
  totalEgressCost: {
    displayName: "Total Egress Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalEgressCost",
    unit: UnitType.CURRENCY,
  },
  totalStorageCost: {
    displayName: "Total Storage Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalStorageCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  instanceConfig: {
    displayName: "Instance Config",
    providerType: ProviderType.GCP,
    schemaName: "instanceConfig",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.GCP,
    schemaName: "instanceId",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
} as const;

export const gcpCloudSpannerSchema = { dimensions, measures };
