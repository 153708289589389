import { ResourceSubscriptionEntity } from ".";
import {
  ChartType,
  CompareDurationType,
  DataSource,
  DurationType,
  MetricAggregate,
  TimeGranularity,
} from "../../analytics/enums";
import {
  Dimension,
  Filter,
  FilterLegacy,
  Measure,
  SortRule,
} from "../../analytics/ui/types";

export const ReportScope = {
  GLOBAL: "GLOBAL",
  PRIVATE: "PRIVATE",
  SHARED: "SHARED",
} as const;

export type ReportScope = (typeof ReportScope)[keyof typeof ReportScope];

export const ReportType = {
  DEFAULT: "DEFAULT",
  SNAPSHOT: "SNAPSHOT",
  SYSTEM_COPY: "SYSTEM_COPY",
  SYSTEM: "SYSTEM",
} as const;

export type ReportType = (typeof ReportType)[keyof typeof ReportType];

export type ReportEntity = {
  id: string;
  tenantID: string;
  sourceReportID?: string | null; // This field is purposefully optional since only System Admins should ever know about it.
  createdAt: string;
  createdByID: string;
  updatedAt: string | null;
  updatedByID: string | null;
  chartType: ChartType;
  compareDurationType: CompareDurationType | null;
  compareEndDate: string | null;
  compareStartDate: string | null;
  dataSource: DataSource;
  description: string | null;
  dimensions: Dimension[];
  dimensionsLegacy?: string[];
  durationType: DurationType;
  endDate: string | null;
  excludedCreditTypes: string[];
  excludeNegativeNumbers: boolean;
  excludeOther: boolean;
  favoritedUserIDs: string[];
  filters: Filter[];
  filtersLegacy?: FilterLegacy[];
  formula: string | null;
  formulaAlias: string | null;
  hiddenMeasures: string[];
  invoiceMonthEnd: string | null;
  invoiceMonthStart: string | null;
  isCumulative: boolean;
  isDistributed?: boolean | null; // This field is purposefully optional since only System Admins should ever know about it.
  isFormulaHidden: boolean;
  isMetricHidden: boolean;
  limit: number | null;
  measures: Measure[];
  measuresLegacy?: string[];
  metric: string | null;
  metricAggregate: MetricAggregate | null;
  metricFilters: Filter[];
  name: string;
  nLookback: number | null;
  reverse: boolean;
  scope: ReportScope;
  sortRule: SortRule | null;
  startDate: string | null;
  tags: string[];
  timeGranularity: TimeGranularity | null;
  type: ReportType;
  xAxisKey: string | null;
  subscriptions?: ResourceSubscriptionEntity[];
};
