import { ProviderType, UnitType } from "../enums";

const measures = {
  billedCost: {
    displayName: "Billed Cost",
    providerType: ProviderType.GCP,
    schemaName: "billedCost",
    unit: UnitType.CURRENCY,
  },
  listCost: {
    displayName: "List Cost",
    providerType: ProviderType.GCP,
    schemaName: "listCost",
    unit: UnitType.CURRENCY,
  },
  userCost: {
    displayName: "User Cost",
    providerType: ProviderType.GCP,
    schemaName: "userCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountId",
  },
  invoiceIssuer: {
    displayName: "Invoice Issuer",
    providerType: ProviderType.GCP,
    schemaName: "invoiceIssuer",
  },
  invoiceMonth: {
    displayName: "Invoice Month",
    providerType: ProviderType.GCP,
    schemaName: "invoiceMonth",
  },
  provider: {
    displayName: "Provider", // TODO: Remove this as a follow up
    providerType: ProviderType.GCP,
    schemaName: "provider",
  },
  providerName: {
    displayName: "Provider Name",
    providerType: ProviderType.GCP,
    schemaName: "providerName",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  serviceCategory: {
    displayName: "Service Category",
    providerType: ProviderType.GCP,
    schemaName: "serviceCategory",
  },
  serviceName: {
    displayName: "Service Name",
    providerType: ProviderType.GCP,
    schemaName: "serviceName",
  },
  subAccountId: {
    displayName: "Sub Account ID",
    providerType: ProviderType.GCP,
    schemaName: "subAccountId",
  },
  tenantID: {
    displayName: "Tenant ID",
    providerType: ProviderType.GCP,
    schemaName: "tenantId",
  },
} as const;

export const mspRollupSchema = { dimensions, measures };
