import Chip from "@/ui-lib/components/Chip";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { CudInventoryFilters } from "../types";

export type CudInventoryCSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number | null>[];
};

type Props = {
  csvData: CudInventoryCSVData;
  filters: CudInventoryFilters;
  debouncedSearchText: string;
  searchText: string;
  onInteraction: (interaction: CudInventoryTableControls.Interaction) => void;
};

function CudInventoryTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.filters.service !== null ? (
          <Chip
            label={copyText.cudInventoryTableHeader_service}
            text={props.filters.service}
            onClick={() =>
              props.onInteraction({
                type: CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "service",
              })
            }
          />
        ) : null}

        {props.filters.instanceFamily !== null ? (
          <Chip
            label={copyText.cudInventoryTableHeader_instanceFamily}
            text={props.filters.instanceFamily}
            onClick={() =>
              props.onInteraction({
                type: CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "instanceFamily",
              })
            }
          />
        ) : null}

        {props.filters.region !== null ? (
          <Chip
            label={copyText.cudInventoryTableHeader_region}
            text={props.filters.region}
            onClick={() =>
              props.onInteraction({
                type: CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "region",
              })
            }
          />
        ) : null}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: CudInventoryTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>
      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`cud-inventory-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

CudInventoryTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `CudInventoryTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof CudInventoryFilters;
}
interface InteractionSearchTextUpdated {
  type: typeof CudInventoryTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CudInventoryTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default CudInventoryTableControls;
