import { CubeQueryBuilderProps, buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { gcpCombinedCudUtilizationSchema } from "@ternary/api-lib/analytics/schemas/gcpCombinedCudUtilization";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

type CudUtilizationEntity = {
  amountSaved: number;
  name: string;
  totalCost: number;
};

type UseQueriesOptions = [
  [CudUtilizationEntity, UError],
  [CudUtilizationEntity, UError],
];

export interface Params {
  dataSource: DataSource;
  dateRange?: Date[];
  isFiscalMode?: boolean;
  queryFilters?: QueryFilter[];
}

export default function useGetCudMeterInventoryData(
  params: Params,
  options?: UseQueryOptions<CudUtilizationEntity, UError>
): UseQueryResult<CudUtilizationEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dateRange: "This month",
              durationType: DurationType.MONTH_TO_DATE,
              measures: [
                gcpCombinedCudUtilizationSchema.measures.amountSaved,
                gcpCombinedCudUtilizationSchema.measures.totalCost,
              ],
            })
          )) as CudUtilizationEntity[];

          const summary = result[0];

          const amountSaved = summary.amountSaved ?? 0;
          const totalCost = summary.totalCost ?? 0;

          return {
            totalCost,
            amountSaved,
            name: "Current MTD",
          };
        },
        queryKey: ["gcpCombinedCUDUtilizationData", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const queryParams: CubeQueryBuilderProps = {
            ...params,
            dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
            dateRange: [
              dateHelper.firstOfLastMonthMinusThreeDays(),
              dateHelper.lastDayLastMonthPlusThreeDays(),
            ],
            measures: [
              gcpCombinedCudUtilizationSchema.measures.amountSaved,
              gcpCombinedCudUtilizationSchema.measures.totalCost,
            ],
          };

          const fiscalQueryParams: CubeQueryBuilderProps = {
            ...params,
            dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
            dateRange: "Last month",
            durationType: DurationType.LAST_THIRTY_DAYS,
            measures: [
              gcpCombinedCudUtilizationSchema.measures.amountSaved,
              gcpCombinedCudUtilizationSchema.measures.totalCost,
            ],
          };

          const result = (await client.load(
            buildCubeQuery(
              params.isFiscalMode ? fiscalQueryParams : queryParams
            )
          )) as CudUtilizationEntity[];

          const summary = result[0];

          const amountSaved = summary.amountSaved ?? 0;
          const totalCost = summary.totalCost ?? 0;

          return {
            totalCost,
            amountSaved,
            name: "Last Month Full",
          };
        },
        queryKey: ["gcpCombinedCUDUtilizationData", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
