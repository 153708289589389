import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { awsCommitmentChartSchema } from "@ternary/api-lib/analytics/schemas/awsCommitmentChart";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { validate } from "../../../../api/analytics/utils/Cubestruct";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSCommitmentUsageDatum, AWSCommitmentUsageStruct } from "../types";

export interface Params {
  dateRange: Date[];
  granularity?: TimeGranularity;
  queryFilters?: QueryFilter[];
}

export default function useGetAWSCommitmentUsage(
  params: Params,
  options?: UseQueryOptions<AWSCommitmentUsageDatum[], UError>
): UseQueryResult<AWSCommitmentUsageDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsCommitmentUsage", params],
    queryFn: async () => {
      const costUsageData = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_COMMITMENT_CHART,
          dateRange: params.dateRange,
          dimensions: [],
          granularity: params.granularity,
          measures: [
            awsCommitmentChartSchema.measures.dailyCommitmentCost,
            awsCommitmentChartSchema.measures.onDemandCost,
            awsCommitmentChartSchema.measures.onDemandNormalizedUsageHours,
            awsCommitmentChartSchema.measures.onDemandUsageHours,
            awsCommitmentChartSchema.measures.riCoveragePercentage,
            awsCommitmentChartSchema.measures.riCoveredNormalizedUsageHours,
            awsCommitmentChartSchema.measures.riCoveredUsageCost,
            awsCommitmentChartSchema.measures.riCoveredUsageHours,
            awsCommitmentChartSchema.measures.riRealizedSavings,
            awsCommitmentChartSchema.measures.riUnusedCommitmentCost,
            awsCommitmentChartSchema.measures.riUnusedHours,
            awsCommitmentChartSchema.measures.spCoveragePercentage,
            awsCommitmentChartSchema.measures.spCoveredNormalizedUsageHours,
            awsCommitmentChartSchema.measures.spCoveredUsageCost,
            awsCommitmentChartSchema.measures.spRealizedSavings,
          ],
          queryFilters: params.queryFilters,
        })
      );

      return costUsageData.map((datum) => {
        const [error, validData] = validate(datum, AWSCommitmentUsageStruct);

        if (error) {
          throw new UError("INVALID_AWS_COMMITMENT_USAGE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          dailyCommitmentCost: validData.dailyCommitmentCost ?? 0,
          onDemandCost: validData.onDemandCost ?? 0,
          onDemandNormalizedUsageHours:
            validData.onDemandNormalizedUsageHours ?? 0,
          onDemandUsageHours: validData.onDemandUsageHours ?? 0,
          realizedSavings:
            (validData.riRealizedSavings ?? 0) +
            (validData.spRealizedSavings ?? 0),
          riCoveragePercentage: validData.riCoveragePercentage ?? 0,
          riCoveredNormalizedUsageHours:
            validData.riCoveredNormalizedUsageHours ?? 0,
          riCoveredUsageCost: validData.riCoveredUsageCost ?? 0,
          riCoveredUsageHours: validData.riCoveredUsageHours ?? 0,
          riRealizedSavings: validData.riRealizedSavings ?? 0,
          riUnusedCommitmentCost: validData.riUnusedCommitmentCost ?? 0,
          riUnusedHours: validData.riUnusedHours ?? 0,
          spCoveragePercentage: validData.spCoveragePercentage ?? 0,
          spCoveredUsageCost: validData.spCoveredUsageCost ?? 0,
          spCoveredNormalizedUsageHours:
            validData.spCoveredNormalizedUsageHours ?? 0,
          spRealizedSavings: validData.spRealizedSavings ?? 0,
          timestamp: validData.timestamp ?? "null",
        };
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
