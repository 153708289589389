import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  nonSavingsPlanCoveredCost: {
    displayName: "Non Savings Plan Covered Cost",
    providerType: ProviderType.AWS,
    schemaName: "nonSavingsPlanCoveredCost",
    unit: UnitType.CURRENCY,
  },
  savingsPlanCoveredCost: {
    displayName: "Savings Plan Covered Cost",
    providerType: ProviderType.AWS,
    schemaName: "savingsPlanCoveredCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  accountID: {
    displayName: "Account ID",
    providerType: ProviderType.AWS,
    schemaName: "accountId",
  },
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.AWS,
    schemaName: "clusterName",
  },
  costKind: {
    displayName: "Cost Kind",
    providerType: ProviderType.AWS,
    schemaName: "costKind",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AWS,
    schemaName: "instanceId",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceTypeFamily: {
    displayName: "Instance Type Family",
    providerType: ProviderType.AWS,
    schemaName: "instanceTypeFamily",
  },
  lineItemDescription: {
    displayName: "Line Item Description",
    providerType: ProviderType.AWS,
    schemaName: "lineItemDescription",
  },
  memoryPerInstance: {
    displayName: "Memory per Instance",
    providerType: ProviderType.AWS,
    schemaName: "memoryPerInstance",
  },
  nodeName: {
    displayName: "Node Name",
    providerType: ProviderType.AWS,
    schemaName: "nodeName",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  timestamp: {
    displayName: "Timestamp",
    providerType: ProviderType.AWS,
    schemaName: "timestamp",
  },
  vcpuPerInstance: {
    displayName: "vCPU per Instance",
    providerType: ProviderType.AWS,
    schemaName: "vcpuPerInstance",
  },
} as const;

export const awsKubernetesNodeCostSchema = { dimensions, measures };
