import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { AWSKubernetesSpendSummaryEntity } from "../aws-kubernetes/types";
import { KubernetesSpendSummaryStruct } from "./schema";

export interface Params {
  dataSource:
    | typeof DataSource.AWS_KUBERNETES_NODE_COST
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.AZURE_KUBERNETES_NODE_USAGE;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [AWSKubernetesSpendSummaryEntity, UError],
  [AWSKubernetesSpendSummaryEntity, UError],
  [AWSKubernetesSpendSummaryEntity, UError],
];

export default function useGetKubernetesSpendSummaries(
  params: Params,
  options?: UseQueryOptions<AWSKubernetesSpendSummaryEntity, UError>
): UseQueryResult<AWSKubernetesSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();
  const gatekeeper = useGatekeeper();

  let enabled = gatekeeper.hasGCPIntegration;
  if (params?.dataSource === DataSource.AZURE_KUBERNETES_NODE_USAGE) {
    enabled = gatekeeper.hasAzureIntegration;
  } else if (params?.dataSource === DataSource.AWS_KUBERNETES_NODE_COST) {
    enabled = gatekeeper.hasAWSIntegration;
  }

  const measures = [billingCucSchema.measures.cost];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
              measures,
            })
          );

          const [error, data] = validate(
            result[0],
            KubernetesSpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_KUBERNETES_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["AWSKubernetesSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(
            result[0],
            KubernetesSpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_KUBERNETES_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["AWSKubernetesSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(
            result[0],
            KubernetesSpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_KUBERNETES_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["AWSKubernetesSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
