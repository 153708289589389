import { format } from "date-fns-tz";
import { ResourceType } from "../constants/enums";
import { DataSource, Operator } from "./enums";
import { billingCucSchema } from "./schemas/billingCUC";
import { QueryFilter, RawData } from "./types";

export type DateRange = Date[];

// TODO: Move this to web-ui
export function getLabelMappedData(
  result: RawData[],
  reversedLabelMap: { [x: string]: string }
): RawData[] {
  return result.map((datum) =>
    Object.entries(datum).reduce((accum: RawData, [key, value]) => {
      const dimension = reversedLabelMap[key] ? reversedLabelMap[key] : key;
      return { ...accum, [dimension]: value };
    }, {})
  );
}

// TODO: Move this to web-ui
export function isValidDetailedBillingDate(date1: Date, date2?: Date): boolean {
  if (!date2) {
    return false;
  }

  const stateDate = new Date(date1);
  const endDate = new Date(date2);

  const differnce = stateDate.getTime() - endDate.getTime();
  const differnceInDays = Math.floor(differnce / (1000 * 3600 * 24));

  if (differnceInDays < 0) {
    return differnceInDays >= -31;
  } else if (differnceInDays > 0) {
    return differnceInDays < 31;
  }
  return false;
}

export function getInvoiceMonthFilters(dateRange: DateRange): QueryFilter[] {
  const invoiceMonthRange = getInvoiceMonthRange(dateRange);

  return [
    {
      schemaName: billingCucSchema.dimensions.invoiceMonth.schemaName,
      operator: Operator.GTE,
      values: [invoiceMonthRange[0]],
    },
    {
      schemaName: billingCucSchema.dimensions.invoiceMonth.schemaName,
      operator: Operator.LTE,
      values: [invoiceMonthRange[1]],
    },
  ];
}

export function getInvoiceMonthRange(dateRange: DateRange): string[] {
  return [format(dateRange[0], "yyyyMM"), format(dateRange[1], "yyyyMM")];
}

export function getSchemaFromDataSource(dataSource: DataSource): string {
  switch (dataSource) {
    case DataSource.ALERT_EVENTS:
      return "AlertEvents";
    case DataSource.AWS_COMMITMENT_ALLOCATION:
      return "AWSCommitmentAllocation";
    case DataSource.AWS_COMMITMENT_CHART:
      return "AWSCommitmentChart";
    case DataSource.AWS_COMMITMENT_TABLE:
      return "AWSCommitmentTable";
    case DataSource.AWS_COMPUTE_INSTANCES:
      return "AWSComputeInstances";
    case DataSource.AWS_COMPUTE_UTILIZATION:
      return "EC2InstanceUtilization";
    case DataSource.AWS_COMPUTE_VISIBILITY:
      return "AWSComputeVisibility";
    case DataSource.AWS_DATABASE_ELASTICACHE:
      return "AWSElastiCacheVisibility";
    case DataSource.AWS_DATABASE_INSTANCES:
      return "AWSRDSInstances";
    case DataSource.AWS_DATABASE_MEMORY_DB:
      return "AWSMemoryDBVisibility";
    case DataSource.AWS_DATABASE_VISIBILITY:
      return "AWSRDSVisibility";
    case DataSource.AWS_EBS_VISIBILITY:
      return "AWSEBSVisibility";
    case DataSource.AWS_EBS_VOLUMES:
      return "AWSEBSVolumes";
    case DataSource.AWS_KUBERNETES_INSTANCES:
      return "EKSNodeUsageMeterHelper";
    case DataSource.AWS_KUBERNETES_NODE_COST:
      return "EKSNodeCost";
    case DataSource.AWS_KUBERNETES_NODE_USAGE:
      return "EKSNodeUsage";
    case DataSource.AWS_OPEN_SEARCH_VISIBILITY:
      return "AWSOpenSearchVisibility";
    case DataSource.AWS_REDSHIFT_VISIBILITY:
      return "AWSRedshiftVisibility";
    case DataSource.AWS_STORAGE_VISIBILITY:
      return "AWSStorageVisibility";
    case DataSource.AZURE_COMMITMENT_CHART:
      return "AzureCommitmentChart";
    case DataSource.AZURE_COMMITMENT_TABLE:
      return "AzureCommitmentTable";
    case DataSource.AZURE_COMPUTE_VISIBILITY:
      return "AzureComputeVisibility";
    case DataSource.AZURE_KUBERNETES_NODE_USAGE:
      return "AzureAKSNodeUsage";
    case DataSource.AZURE_SQL_VISIBILITY:
      return "AzureSQLVisibility";
    case DataSource.AZURE_STORAGE_VISIBILITY:
      return "AzureStorageVisibility";
    case DataSource.BIGQUERY_CAPACITY_COMMITTED:
      return "BigQueryCapacityCommitted";
    case DataSource.BIGQUERY_COST:
      return "GCPBigQueryCost";
    case DataSource.BIGQUERY_RESERVATION_USAGE:
      return "GCPBigQueryReservationUsage";
    case DataSource.BIGQUERY_STORAGE:
      return "GCPBigQueryStorage";
    case DataSource.BIGQUERY_TIMELINE:
      return "BigQueryTimeline";
    case DataSource.BIGQUERY_USAGE:
      return "BigQueryUsage";
    case DataSource.BILLING:
      return "Billing";
    case DataSource.CARBON_FOOTPRINT:
      return "CarbonFootprint";
    case DataSource.CLOUD_RUN_SERVICES:
      return "GCPCloudRunServices";
    case DataSource.CLOUD_RUN:
      return "GCPCloudRun";
    case DataSource.CLOUD_SPANNER_USAGE:
      return "GCPCloudSpannerUsage";
    case DataSource.CLOUD_SPANNER:
      return "GCPCloudSpanner";
    case DataSource.CLOUD_SQL_COST:
      return "CloudSQLCost";
    case DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY:
      return "CloudSQLInstanceUsageDaily";
    case DataSource.CLOUD_SQL_INSTANCE_USAGE:
      return "CloudSQLInstanceUsage";
    case DataSource.COMMITTED_USE:
      return "GCPCommittedUse";
    case DataSource.DETAILED_BILLING:
      return "DetailedBilling";
    case DataSource.EXTERNAL_METRICS:
      return "ExternalMetrics";
    case DataSource.FOCUS_BILLING:
      return "FOCUS_BILLING";
    case DataSource.GCP_BIGQUERY_COMMITMENT_INVENTORY:
      return "GCPBigQueryCommitmentInventory";
    case DataSource.GCP_COMBINED_CUD_UTILIZATION:
      return "GCPCombinedCUDUtilization";
    case DataSource.GCP_COMPUTE_CUD_INVENTORY:
      return "GCPComputeCUDInventory";
    case DataSource.GCP_COMPUTE_CUD_SPEND_INVENTORY:
      return "GCPSpendBasedCUDInventory";
    case DataSource.GCP_COMPUTE_CUD_UTILIZATION:
      return "GCPComputeCUDUtilization";
    case DataSource.GCP_COMPUTE_INSTANCES:
      return "GCPComputeInstancesV2";
    case DataSource.GCP_COMPUTE_VISIBILITY:
      return "GCPComputeVisibilityV2";
    case DataSource.GCP_CUD_COVERABLE_COST:
      return "GCPCUDCoverableCost";
    case DataSource.GCP_CUSTOM_PRICING:
      return "GCPCustomPricing";
    case DataSource.KUBERNETES_CLUSTER_USAGE:
      return "KubernetesClusterUsage";
    case DataSource.KUBERNETES_CONTAINER_USAGE:
      return "KubernetesContainerUsage";
    case DataSource.KUBERNETES_NODE_USAGE:
      return "KubernetesNodeUsage";
    case DataSource.MSP_ALERT_ROLLUP:
      return "MSPAlertRollup";
    case DataSource.MSP_ROLLUP:
      return "MSPRollup";
    case DataSource.PREDICTION_BOUNDS:
      return "PredictionBounds";
    case DataSource.SNOWFLAKE_DATABASE_USAGE_METER_HELPER:
      return "SnowflakeDatabaseUsageMeterHelper";
    case DataSource.SNOWFLAKE_DATABASE_USAGE:
      return "SnowflakeDatabaseUsage";
    case DataSource.SNOWFLAKE_WAREHOUSE_USAGE:
      return "SnowflakeWarehouseUsage";
    case DataSource.STORAGE_COST:
      return "StorageCost";
    case DataSource.STORAGE_USAGE:
      return "StorageUsage";
  }
}

export function getFormattedResourceType(resourceType: ResourceType | null) {
  switch (resourceType) {
    case ResourceType.BUDGET:
      return "Budget";
    case ResourceType.COST_ALERT:
      return "Cost Alert";
    case ResourceType.DASHBOARD:
      return "Dashboard";
    case ResourceType.RAMP_PLAN:
      return "Ramp Plan";
    case ResourceType.RECOMMENDATION:
      return "Recommendation";
    case ResourceType.REPORT:
      return "Report";
    default:
      return resourceType;
  }
}
