import useBuildDataQuery from "@/api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { azureSqlVisibilitySchema } from "@ternary/api-lib/analytics/schemas/azureSqlVisibility";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureDatabaseInstanceEntity } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
  isCSV?: boolean;
}

export default function useGetAzureDatabaseInstances(
  params: Params,
  options?: UseQueryOptions<AzureDatabaseInstanceEntity[], UError>
): UseQueryResult<AzureDatabaseInstanceEntity[], UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_SQL_VISIBILITY,
    preAggFilters: params.queryFilters,
    dimensions: [
      azureSqlVisibilitySchema.dimensions.backupRetentionPeriodDays,
      azureSqlVisibilitySchema.dimensions.backupStorageRedundancy,
      azureSqlVisibilitySchema.dimensions.billingMethod,
      azureSqlVisibilitySchema.dimensions.computeTier,
      azureSqlVisibilitySchema.dimensions.databaseEngine,
      azureSqlVisibilitySchema.dimensions.highAvailabilityEnabled,
      azureSqlVisibilitySchema.dimensions.instanceName,
      azureSqlVisibilitySchema.dimensions.location,
      azureSqlVisibilitySchema.dimensions.projectID,
      azureSqlVisibilitySchema.dimensions.resourceID,
      azureSqlVisibilitySchema.dimensions.skuName,
      azureSqlVisibilitySchema.dimensions.skuTier,
    ],
    measures: [
      azureSqlVisibilitySchema.measures.absoluteCredits,
      azureSqlVisibilitySchema.measures.connections,
      azureSqlVisibilitySchema.measures.cores,
      azureSqlVisibilitySchema.measures.coreUsage,
      azureSqlVisibilitySchema.measures.cost,
      azureSqlVisibilitySchema.measures.cpuPercentAvg,
      azureSqlVisibilitySchema.measures.cpuPercentMax,
      azureSqlVisibilitySchema.measures.cpuPercentMin,
      azureSqlVisibilitySchema.measures.memoryBytesUsage,
      azureSqlVisibilitySchema.measures.memoryGB,
      azureSqlVisibilitySchema.measures.memoryPercentAvg,
      azureSqlVisibilitySchema.measures.memoryPercentMax,
      azureSqlVisibilitySchema.measures.memoryPercentMin,
      azureSqlVisibilitySchema.measures.resourceCount,
      azureSqlVisibilitySchema.measures.storageCapacityGB,
      azureSqlVisibilitySchema.measures.storagePercent,
      azureSqlVisibilitySchema.measures.usageAmount,
    ],
  });

  return useQuery({
    queryKey: [
      params.isCSV ? "azureDatabaseInstances" : "azureDatabaseInstancesCSV",
      {
        dateRange: params.dateRange,
        preAggFilters: params.queryFilters,
      },
    ],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const instanceGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          backupRetentionPeriodDays: datum.backupRetentionPeriodDays ?? "",
          backupStorageRedundancy: datum.backupStorageRedundancy ?? "",
          billingMethod: datum.billingMethod ?? "",
          computeTier: datum.computeTier ?? "",
          databaseEngine: datum.databaseEngine ?? "",
          highAvailabilityEnabled: datum.highAvailabilityEnabled,
          instanceName: datum.instanceName ?? "",
          location: datum.location ?? "",
          projectId: datum.projectId ?? "",
          resourceId: datum.resourceId ?? "",
          skuName: datum.skuName ?? "",
          skuTier: datum.skuTier ?? "",

          // MEASURES
          absoluteCredits: datum.absoluteCredits ?? 0,
          connections: datum.connections ?? 0,
          cores: datum.cores ?? 0,
          coreUsage: datum.coreUsage ?? 0,
          cost: datum.cost ?? 0,
          cpuPercentAvg: datum.cpuPercentAvg ?? 0,
          cpuPercentMax: datum.cpuPercentMax ?? 0,
          cpuPercentMin: datum.cpuPercentMin ?? 0,
          memoryBytesUsage: datum.memoryBytesUsage ?? 0,
          memoryGB: datum.memoryGB ?? 0,
          memoryPercentAvg: datum.memoryPercentAvg ?? 0,
          memoryPercentMax: datum.memoryPercentMax ?? 0,
          memoryPercentMin: datum.memoryPercentMin ?? 0,
          resourceCount: datum.resourceCount ?? 0,
          storageCapacityGB: datum.storageCapacityGB ?? 0,
          storagePercent: datum.storagePercent ?? 0,
          usageAmount: datum.usageAmount ?? 0,
        } as AzureDatabaseInstanceEntity;
      });
      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
