import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Measure } from "@ternary/api-lib/analytics/ui/types";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { StorageSpendSummaryStruct } from "./schema";

type StorageSpendSummaryEntity = {
  totalCost: number;
};

export interface Params {
  dataSource:
    | typeof DataSource.AWS_EBS_VISIBILITY
    | typeof DataSource.AWS_STORAGE_VISIBILITY
    | typeof DataSource.AZURE_STORAGE_VISIBILITY
    | typeof DataSource.BILLING;
  measures?: Measure[];
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [StorageSpendSummaryEntity, UError],
  [StorageSpendSummaryEntity, UError],
  [StorageSpendSummaryEntity, UError],
];

export default function useGetStorageSpendSummaries(
  params: Params,
  options?: UseQueryOptions<StorageSpendSummaryEntity, UError>
): UseQueryResult<StorageSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();
  const gatekeeper = useGatekeeper();

  const enabled =
    params.dataSource === DataSource.BILLING
      ? gatekeeper.hasGCPIntegration
      : gatekeeper.hasAWSIntegration;

  const measures = params.measures
    ? params.measures
    : [billingCucSchema.measures.cost];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: "This month",
              durationType: DurationType.MONTH_TO_DATE,
              measures,
            })
          );

          const [error, data] = validate(result[0], StorageSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_STORAGE_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data[measures[0].schemaName] ?? 0,
          };
        },
        queryKey: ["storageSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(result[0], StorageSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_STORAGE__COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data[measures[0].schemaName] ?? 0,
          };
        },
        queryKey: ["storageSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // LAST MONTH TO DATE
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(result[0], StorageSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_STORAGE_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data[measures[0].schemaName] ?? 0,
          };
        },
        queryKey: ["storageSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
