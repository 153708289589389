import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import { awsRedshiftVisibilitySchema } from "@ternary/api-lib/analytics/schemas/awsRedshiftVisibility";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSRedshiftResourcesEntity } from "../types";

type Props = {
  resources: AWSRedshiftResourcesEntity[];
  isLoadingResources: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AWSRedshiftResourcesEntity>();

export function AWSWarehouseSubTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const columns = useMemo(
    () => [
      ...(
        [
          [awsRedshiftVisibilitySchema.dimensions.clusterArn.schemaName, 120],
          [
            awsRedshiftVisibilitySchema.dimensions.payerAccountID.schemaName,
            120,
          ],
          [awsRedshiftVisibilitySchema.dimensions.clusterID.schemaName, 120],
          [
            awsRedshiftVisibilitySchema.dimensions.usageAccountID.schemaName,
            120,
          ],
          [awsRedshiftVisibilitySchema.dimensions.region.schemaName, 120],
          [
            awsRedshiftVisibilitySchema.dimensions.productFamily.schemaName,
            120,
          ],
          [
            awsRedshiftVisibilitySchema.dimensions.instanceMemory.schemaName,
            120,
          ],
          [awsRedshiftVisibilitySchema.dimensions.instanceType.schemaName, 120],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_cost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("nodeUsage", {
        header: copyText.awsWarehouseResourceTableHeader_nodeUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("nodeCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_nodeCost,
        meta: { align: "center" },
        size: 120,
      }),
      ...(
        [
          [awsRedshiftVisibilitySchema.dimensions.instanceVcpu.schemaName, 120],
          [awsRedshiftVisibilitySchema.dimensions.io.schemaName, 120],
          [awsRedshiftVisibilitySchema.dimensions.storage.schemaName, 120],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              {getValue() || copyText.azureKubernetesTableNull}
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("storageCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_storageCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("storageUsage", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.awsWarehouseResourceTableHeader_storageUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("dataScanCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_dataScanCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("dataScanUsage", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_dataScanUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("serverlessComputeCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_serverlessComputeCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("serverlessComputeUsage", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_serverlessComputeUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("backupStorageCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_backupStorageCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("backupStorageUsage", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_backupStorageUsage,
        meta: { align: "center" },
        size: 120,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.resources}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingResources}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
