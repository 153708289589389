import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { CaseType, ResourceType } from "@ternary/api-lib/constants/enums";
import { ReportType } from "@ternary/api-lib/core/types/Report";
import { ReportSnapshotParams } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";

export type CreateInsightsCaseParams = {
  tenantID: string;
  description: string;
  name: string;
  resourceType: ResourceType;
  type: CaseType;
  assigneeIDs?: string[];
  followerIDs?: string[];
  reportSnapshot: ReportSnapshotParams;
};

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateInsightsCaseParams
>;
type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  CreateInsightsCaseParams
>;

export default function useCreateInsightsCase(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: async (params) => {
      const { reportSnapshot, ...caseParams } = params;

      const [startDate, endDate] = reportSnapshot.dateRange
        ? [
            reportSnapshot.dateRange[0].toISOString(),
            reportSnapshot.dateRange[1].toISOString(),
          ]
        : [undefined, undefined];

      const reportSnapshotID = await client.createReport({
        tenantID: caseParams.tenantID,
        chartType: reportSnapshot.chartType,
        isCumulative: false,
        dataSource: reportSnapshot.dataSource,
        ...(endDate ? { endDate } : {}),
        dimensions: reportSnapshot.dimensions,
        durationType: reportSnapshot.durationType,
        excludeNegativeNumbers: false,
        excludeOther: false,
        filters: reportSnapshot.filters,
        isFormulaHidden: false,
        isMetricHidden: false,
        measures: reportSnapshot.measures,
        name: reportSnapshot.name,
        nLookback: null,
        reverse: false,
        ...(startDate ? { startDate } : {}),
        timeGranularity: reportSnapshot.granularity,
        type: ReportType.SNAPSHOT,
        xAxisKey: reportSnapshot.xAxisKey,
      });

      return client.createCase({
        ...caseParams,
        resourceID: reportSnapshotID,
      });
    },
    ...options,
  });
}
