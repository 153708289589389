import { ProviderType, UnitType } from "../enums";

const measures = {
  locationBasedOffset: {
    displayName: "Location Based Offset",
    providerType: ProviderType.GCP,
    schemaName: "locationBasedOffset",
    unit: UnitType.KILOGRAMS,
  },
  scope1: {
    displayName: "Scope 1",
    providerType: ProviderType.GCP,
    schemaName: "scope1",
    unit: UnitType.KILOGRAMS,
  },
  scope2Location: {
    displayName: "Scope 2 Location",
    providerType: ProviderType.GCP,
    schemaName: "scope2Location",
    unit: UnitType.KILOGRAMS,
  },
  scope3: {
    displayName: "Scope 3",
    providerType: ProviderType.GCP,
    schemaName: "scope3",
    unit: UnitType.KILOGRAMS,
  },
} as const;

const dimensions = {
  carbonModel: {
    displayName: "Carbon Model",
    providerType: ProviderType.GCP,
    schemaName: "carbonModel",
  },
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountId",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.GCP,
    schemaName: "location",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  serviceID: {
    displayName: "Service ID",
    providerType: ProviderType.GCP,
    schemaName: "serviceId",
  },
  serviceDescription: {
    displayName: "Service Description",
    providerType: ProviderType.GCP,
    schemaName: "serviceDescription",
  },
} as const;

export const carbonFootprintSchema = { dimensions, measures };
