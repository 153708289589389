import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  productVcpuChart: {
    displayName: "Product vCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPUChart",
    unit: UnitType.STANDARD,
  },
  usedVcpuChart: {
    displayName: "Used vCPU",
    providerType: ProviderType.AWS,
    schemaName: "usedVCPUChart",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  lineItemUsageAccountID: {
    displayName: "Line Item Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
};

export const awsRdsVisibilitySchema = { measures, dimensions };
