import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { createSortingUtils } from "@/utils/sort";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import externalLinks from "../../../../constants/externalLinks";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import copyText from "../../copyText";
import {
  GCPComputeInstanceGroup,
  GCPComputeInstanceGroupFilters,
} from "../types";

type TableData = {
  family: string;
  familyType: string;
  instanceIDCount: number;
  productMemoryBytes: number;
  productVCPU: number;
  projectId: string;
  provisionedDiskBytes: number;
  region: string;
  totalCost: number;
};

type Props = {
  instanceGroups: GCPComputeInstanceGroup[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: GCPComputeInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>();

function GCPComputeInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const hiddenColumns = gatekeeper.isConfiguredResourceLevelBilling
    ? []
    : ["instanceCount", "provisionedDiskBytes"];

  const columnVisibility = hiddenColumns.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {} as Record<string, boolean>
  );

  const tooltipTextFragments =
    copyText.gcpComputeTableInstanceGroupConfigureBillingTooltip.split(
      "%link%"
    );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceIDCount: instanceGroup.instanceIDCount,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        projectId: instanceGroup.projectId,
        provisionedDiskBytes: instanceGroup.provisionedDiskBytes,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip
            content={
              <Box maxWidth={250}>
                {gatekeeper.isConfiguredResourceLevelBilling ? (
                  copyText.gcpComputeTableInstanceGroupViewInstancesTooltip
                ) : (
                  <div>
                    {tooltipTextFragments[0]}
                    <a
                      href={externalLinks.readmeGCPOnboardingDocumentation}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {
                        copyText.gcpComputeTableInstanceGroupConfigureBillingTooltipLink
                      }
                    </a>
                    {tooltipTextFragments[1]}
                  </div>
                )}
              </Box>
            }
          >
            <LinkWithSearchParams
              disabled={!gatekeeper.isConfiguredResourceLevelBilling}
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                disabled={!gatekeeper.isConfiguredResourceLevelBilling}
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("family", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "family",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.gcpComputeTableNull}
          </Text>
        ),
        header: copyText.gcpComputeTableInstanceGroupHeader_family,
        meta: { align: "right" },
        size: 60,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("projectId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "projectId",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.gcpComputeTableNull}
          </Text>
        ),
        header: copyText.gcpComputeTableInstanceGroupHeader_projectId,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.gcpComputeTableNull}
          </Text>
        ),
        header: copyText.gcpComputeTableInstanceGroupHeader_region,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("familyType", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "familyType",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.gcpComputeTableNull}
          </Text>
        ),
        header: copyText.gcpComputeTableInstanceGroupHeader_familyType,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceIDCount", {
        header: copyText.gcpComputeTableInstanceGroupHeader_instanceIDCount,
        meta: { align: "right" },
        size: 75,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.gcpComputeTableInstanceGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productVCPU", {
        header: copyText.gcpComputeTableInstanceGroupHeader_productVCPU,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.gcpComputeTableInstanceGroupHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("provisionedDiskBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header:
          copyText.gcpComputeTableInstanceGroupHeader_provisionedDiskBytes,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
    ],
    [props.instanceGroups]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{
        sorting: [sortRule],
        columnVisibility,
      }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      projectId: datum.projectId,
      region: datum.region,
    });
  } catch {
    return "";
  }
}

GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof GCPComputeInstanceGroupFilters;
  filterValue: GCPComputeInstanceGroupFilters[keyof GCPComputeInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPComputeInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default GCPComputeInstanceGroupTable;
