import { useFeatureText } from "@/context/FeatureTextProvider";
import Form, { FormField } from "@/ui-lib/components/Form";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { DatalligatorOperator } from "@ternary/api-lib/analytics/api/types";
import { getDatalligatorOperator } from "@ternary/api-lib/analytics/api/utils";
import { Operator } from "@ternary/api-lib/analytics/enums";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { MspBillingRuleType } from "@ternary/api-lib/constants/enums";
import { RuleMeta, TenantEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { toZonedTime } from "date-fns-tz";
import { isEqual, keyBy } from "lodash";
import React, { useState } from "react";
import { DimensionValuesMap } from "../../../api/analytics/useGetDimensionValues";
import { operatorOptions } from "../../../constants";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { Input } from "../../../types";
import Checkbox from "../../../ui-lib/components/Checkbox";
import { DatePicker } from "../../../ui-lib/components/DateRangeControls/DatePicker";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import NumberInput from "../../../ui-lib/components/NumberInput";
import Select from "../../../ui-lib/components/Select";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import SelectDropdown from "../../../ui-lib/components/SelectDropdown";
import Switch from "../../../ui-lib/components/Switch";
import TextInput from "../../../ui-lib/components/TextInput";
import _copyText from "../copyText";
import { TenantBillingAccountMap } from "../hooks/useGetMspBillingAccountsByTenantIDs";
import { Rule, RuleSet } from "../types";

interface Props {
  availableDimensions: Dimension[];
  billingAccounts: TenantBillingAccountMap;
  childTenants: TenantEntity[];
  dimensionValues: DimensionValuesMap;
  selectedRuleSet?: Omit<RuleSet, "createdByEmail">;
  isLoading: boolean;
  isLoadingBillingAccounts: boolean;
  isLoadingDimensionValues: boolean;
  isProcessing: boolean;
  onInteraction: (interaction: MspBillingRuleSetForm.Interaction) => void;
}

type InputMeta = Omit<RuleMeta, "dimensions"> & {
  dimensions?: { name: string; value: string }[];
};

type RuleInput = {
  id: string | undefined;
  isOpen: boolean;
  name: string;
  type: MspBillingRuleType;
  meta: InputMeta;
};

interface State {
  applyToAllTenantsInput: Input<boolean>;
  endTimeInput: Input<string>;
  nameInput: Input<string>;
  rulesInput: Input<RuleInput[]>;
  startTimeInput: Input<string>;
  tenantIDsInput: Input<string[]>;
}

const _initialState: State = {
  applyToAllTenantsInput: { value: false, hasChanged: false, isValid: true },
  endTimeInput: { value: "", hasChanged: false, isValid: true },
  nameInput: { value: "", hasChanged: false, isValid: false },
  rulesInput: { value: [], hasChanged: false, isValid: false },
  startTimeInput: { value: "", hasChanged: false, isValid: false },
  tenantIDsInput: { value: [], hasChanged: false, isValid: false },
};

const emptyRule: RuleInput = {
  id: undefined,
  isOpen: true,
  name: "",
  type: MspBillingRuleType.RERATE,
  meta: { adjustToList: false, filters: undefined, metrics: [], value: 0 },
};

const availableMeasures = [
  { label: _copyText.ruleSetFormCostLabel, value: "cost" },
  { label: _copyText.ruleSetFormCreditsLabel, value: "credit_total" },
];

export function MspBillingRuleSetForm(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const { copyText } = useFeatureText(_copyText);

  const initialState = props.selectedRuleSet
    ? {
        applyToAllTenantsInput: {
          value: props.selectedRuleSet.applyToAllTenants,
          hasChanged: false,
          isValid: true,
        },
        endTimeInput: {
          value: props.selectedRuleSet.endTime ?? "",
          hasChanged: false,
          isValid: true,
        },
        nameInput: {
          value: props.selectedRuleSet.name,
          hasChanged: false,
          isValid: true,
        },
        rulesInput: {
          value: props.selectedRuleSet
            ? props.selectedRuleSet.rules
                .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                .map((rule) => ({
                  isOpen: false,
                  id: rule.id,
                  name: rule.name,
                  type: rule.type,
                  meta: {
                    ...rule.meta,
                    ...(rule.meta.dimensions
                      ? {
                          dimensions: Object.entries(rule.meta.dimensions).map(
                            ([key, value]) => ({ name: key, value })
                          ),
                        }
                      : { dimensions: undefined }),
                  },
                }))
            : [],
          hasChanged: false,
          isValid: true,
        },
        startTimeInput: {
          value: props.selectedRuleSet.startTime,
          hasChanged: false,
          isValid: true,
        },
        tenantIDsInput: {
          value: props.selectedRuleSet.tenantIDs,
          hasChanged: false,
          isValid: true,
        },
      }
    : _initialState;

  const [state, setState] = useState<State>(initialState);

  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleAddDimension(index: number) {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const currentDimensions = currentRule.meta.dimensions ?? [];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          dimensions: [...currentDimensions, { name: "", value: "" }],
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged: true,
          isValid: true,
        },
      };
    });
  }

  function handleAddFilter(index: number): void {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const currentFilters = currentRule.meta.filters ?? [];

      const updatedFilters = [
        ...currentFilters,
        {
          schema_field_name: "",
          operator: getDatalligatorOperator(Operator.EQUALS),
          values: [],
        },
      ];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          filters: updatedFilters,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, index),
            updatedRule,
            ...currentState.rulesInput.value.slice(index + 1),
          ],
          hasChanged: true,
          isValid: true,
        },
      };
    });
  }

  function handleAddRule(): void {
    setState((currentState) => {
      const currentRules = currentState.rulesInput.value;

      const newRule = {
        ...emptyRule,
        type: MspBillingRuleType.RERATE,
      };

      return {
        ...currentState,
        rulesInput: {
          value: [...currentRules, newRule],
          hasChanged: true,
          isValid: false,
        },
      };
    });
  }

  function handleAddTenant(tenantID: string): void {
    setState((currentState) => {
      const updatedValue = [...currentState.tenantIDsInput.value, tenantID];

      const hasChanged = !isEqual(
        props.selectedRuleSet?.tenantIDs,
        updatedValue
      );

      return {
        ...currentState,
        tenantIDsInput: {
          value: [...currentState.tenantIDsInput.value, tenantID],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleCancel(): void {
    props.onInteraction({
      type: MspBillingRuleSetForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
  }

  function handleChangeEndTime(date: Date | null): void {
    const hasChanged = !isEqual(
      date?.toISOString(),
      props.selectedRuleSet?.endTime
    );

    mergeState({
      endTimeInput: {
        value: date?.toISOString?.() ?? "",
        isValid: true,
        hasChanged,
      },
    });
  }

  function handleChangePercentage(value: number, index: number): void {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.value, value)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          metrics: currentRule.meta.metrics ?? [],
          value,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleClickAdjustToListCheckbox(
    checked: boolean,
    index: number
  ): void {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.adjustToList, checked)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          adjustToList: checked,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleChangeRecurringChargeValue(value: number, index: number) {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.value, value)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          value,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleChangeRuleName(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const value = event.target.value;

    const isValid = value.length > 0;

    const hasChanged = props.selectedRuleSet?.rules[index]
      ? !isEqual(props.selectedRuleSet.rules[index].name, value)
      : true;

    setState((currentState) => {
      const updatedValue = {
        ...currentState.rulesInput.value[index],
        name: value,
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, index),
            updatedValue,
            ...currentState.rulesInput.value.slice(index + 1),
          ],
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleChangeRuleSetName(value: string): void {
    const isValid = value.length > 0;

    const hasChanged = !isEqual(value, props.selectedRuleSet?.name);

    mergeState({
      nameInput: { value, hasChanged, isValid },
    });
  }

  function handleChangeRuleType(type: MspBillingRuleType, index: number) {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule ? !isEqual(existingRule.type, type) : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const filter =
        type === MspBillingRuleType.BACKOUT_LINE_ITEM ||
        type === MspBillingRuleType.MOVE_BILLING_ACCOUNT
          ? {
              schema_field_name: "",
              operator: getDatalligatorOperator(Operator.EQUALS),
              values: [],
            }
          : undefined;

      let meta: InputMeta = filter ? { filters: [filter] } : {};

      if (type === MspBillingRuleType.RECURRING_CHARGE) {
        meta = {
          ...meta,
          dimensions: [],
          isPercentage: false,
          value: 0,
        };
      }

      if (
        type === MspBillingRuleType.RERATE ||
        type === MspBillingRuleType.APPLY_MARGIN
      ) {
        meta = emptyRule.meta;
      }

      const newRule = {
        ...emptyRule,
        name: currentRule.name,
        type,
        meta,
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        newRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleChangeStartTime(date: Date | null): void {
    if (date === null) return;

    let newEndDate: Date | undefined = undefined;

    // Do not allow end time to be sooner than or equal to start time.
    if (date.getTime() >= new Date(state.endTimeInput.value ?? "").getTime()) {
      newEndDate = new Date(date);
      newEndDate.setDate(date.getDate() + 1);
    }

    const startTimeHasChanged = !isEqual(
      date.toISOString(),
      props.selectedRuleSet?.startTime
    );

    const endTimeHasChanged = newEndDate
      ? !isEqual(newEndDate.toISOString(), props.selectedRuleSet?.endTime)
      : true;

    mergeState({
      startTimeInput: {
        hasChanged: startTimeHasChanged,
        isValid: true,
        value: date.toISOString(),
      },
      ...(newEndDate
        ? {
            endTimeInput: {
              hasChanged: endTimeHasChanged,
              isValid: true,
              value: newEndDate.toISOString(),
            },
          }
        : {}),
    });
  }

  function handleClickDownArrow(ruleIndex: number): void {
    setState((currentState) => {
      const rulesArray = currentState.rulesInput.value;

      if (ruleIndex === rulesArray.length - 1) {
        return { ...currentState };
      }

      const currentRule = currentState.rulesInput.value[ruleIndex];

      rulesArray[ruleIndex] = rulesArray[ruleIndex + 1];
      rulesArray[ruleIndex + 1] = currentRule;

      const hasChanged = !isEqual(props.selectedRuleSet?.rules, rulesArray);

      return {
        ...currentState,
        rulesInput: {
          value: rulesArray,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleClickRecurringChargePercentCheckbox(
    checked: boolean,
    index: number
  ) {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.isPercentage, checked)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          isPercentage: checked,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleClickUpArrow(ruleIndex: number): void {
    if (ruleIndex === 0) return;

    setState((currentState) => {
      const rules = currentState.rulesInput.value;

      const currentRule = currentState.rulesInput.value[ruleIndex];

      rules[ruleIndex] = rules[ruleIndex - 1];

      rules[ruleIndex - 1] = currentRule;

      const hasChanged = !isEqual(props.selectedRuleSet?.rules, rules);

      return {
        ...currentState,
        rulesInput: {
          value: rules,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleClickAllTenantsCheckbox(value: boolean) {
    mergeState({
      applyToAllTenantsInput: {
        value,
        hasChanged: !isEqual(value, props.selectedRuleSet?.applyToAllTenants),
        isValid: true,
      },
      ...(value === true
        ? {
            tenantIDsInput: {
              value: [],
              hasChanged: !isEqual([], props.selectedRuleSet?.tenantIDs),
              isValid: true,
            },
          }
        : {}),
    });
  }

  function handleDeleteRule(ruleIndex: number): void {
    setState((currentState) => {
      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged: true,
          isValid: true,
        },
      };
    });
  }

  function handleDeleteFilter(filterIndex: number, ruleIndex: number): void {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];

      const currentFilters = currentRule.meta.filters ?? [];

      const updatedFilters = [
        ...currentFilters.slice(0, filterIndex),
        ...currentFilters.slice(filterIndex + 1),
      ];

      const hasChanged = !isEqual(
        props.selectedRuleSet?.rules[ruleIndex].meta.filters,
        updatedFilters
      );

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          filters: updatedFilters,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            updatedRule,
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleDeleteTenant(index: number): void {
    setState((currentState) => {
      return {
        ...currentState,
        tenantIDsInput: {
          value: [
            ...currentState.tenantIDsInput.value.slice(0, index),
            ...currentState.tenantIDsInput.value.slice(index + 1),
          ],
          hasChanged: true,
          isValid: true,
        },
      };
    });
  }

  function handleRemoveDimension(
    ruleIndex: number,
    dimensionIndex: number
  ): void {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];

      const currentDimensions = currentRule.meta.dimensions ?? [];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          dimensions: [
            ...currentDimensions.slice(0, dimensionIndex),
            ...currentDimensions.slice(dimensionIndex + 1),
          ],
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, ruleIndex),
        updatedRule,
        ...currentState.rulesInput.value.slice(ruleIndex + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleSelectMeasure(values: string[], index: number): void {
    const existingRule = props.selectedRuleSet?.rules[index];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.metrics, values)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[index];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          metrics: values,
          value: 0,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, index),
        updatedRule,
        ...currentState.rulesInput.value.slice(index + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleSelectPayerAccount(value: string, ruleIndex: number): void {
    const existingRule = props.selectedRuleSet?.rules[ruleIndex];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.billingAccountID, value)
      : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];

      const updatedRule = {
        ...currentRule,
        meta: {
          billingAccountID: value,
          tenantID: currentRule.meta.tenantID,
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, ruleIndex),
        updatedRule,
        ...currentState.rulesInput.value.slice(ruleIndex + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleSelectTargetTenantID(value: string, ruleIndex: number): void {
    props.onInteraction({
      type: MspBillingRuleSetForm.INTERACTION_ADD_TARGET_TENANT_ID,
      tenantID: value,
    });

    const existingRule = props.selectedRuleSet?.rules[ruleIndex];

    const hasChanged = existingRule
      ? !isEqual(existingRule.meta.tenantID, value)
      : true;

    setState((currentState) => {
      const currentValue = {
        ...currentState.rulesInput.value[ruleIndex],
        meta: {
          billingAccountID: "",
          tenantID: value,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            currentValue,
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleToggleRule(ruleIndex: number) {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];

      const updatedRule = {
        ...currentRule,
        isOpen: !currentRule.isOpen,
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, ruleIndex),
        updatedRule,
        ...currentState.rulesInput.value.slice(ruleIndex + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          ...currentState.rulesInput,
          value: inputs,
        },
      };
    });
  }

  function handleUpdateDimensionName(
    name: string,
    ruleIndex: number,
    dimensionIndex: number
  ) {
    props.onInteraction({
      type: MspBillingRuleSetForm.INTERACTION_DIMENSION_NAME_SELECTED,
      name: name,
    });

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];

      const currentDimensions = currentRule.meta.dimensions ?? [];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          dimensions: [
            ...currentDimensions.slice(0, dimensionIndex),
            { name, value: "" },
            ...currentDimensions.slice(dimensionIndex + 1),
          ],
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, ruleIndex),
        updatedRule,
        ...currentState.rulesInput.value.slice(ruleIndex + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleUpdateDimensionValue(
    value: string,
    ruleIndex: number,
    dimensionIndex: number
  ) {
    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];
      const currentDimensions = currentRule.meta.dimensions ?? [];

      const currentDimension = currentDimensions[dimensionIndex];

      const updatedDimension = {
        ...currentDimension,
        value,
      };

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          dimensions: [
            ...currentDimensions.slice(0, dimensionIndex),
            updatedDimension,
            ...currentDimensions.slice(dimensionIndex + 1),
          ],
        },
      };

      const inputs = [
        ...currentState.rulesInput.value.slice(0, ruleIndex),
        updatedRule,
        ...currentState.rulesInput.value.slice(ruleIndex + 1),
      ];

      return {
        ...currentState,
        rulesInput: {
          value: inputs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleUpdateFilterName(
    value: string,
    ruleIndex: number,
    filterIndex: number
  ) {
    props.onInteraction({
      type: MspBillingRuleSetForm.INTERACTION_ADD_FILTER_CLICKED,
      filter: value,
    });

    const existingRule = props.selectedRuleSet?.rules[ruleIndex];
    const existingFilter = (existingRule?.meta.filters ?? [])[filterIndex];

    const hasChanged =
      existingRule && existingFilter
        ? !isEqual(existingFilter.schema_field_name, value)
        : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];
      const currentFilters = currentRule.meta.filters ?? [];

      const updatedFilter = {
        ...currentFilters[filterIndex],
        schema_field_name: value,
        values: [],
      };

      const updatedFilters = [
        ...currentFilters.slice(0, filterIndex),
        updatedFilter,
        ...currentFilters.slice(filterIndex + 1),
      ];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          filters: updatedFilters,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            updatedRule,
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleUpdateFilterOperator(
    value: Operator,
    ruleIndex: number,
    filterIndex: number
  ) {
    const existingRule = props.selectedRuleSet?.rules[ruleIndex];
    const existingFilter = (existingRule?.meta.filters ?? [])[filterIndex];

    const hasChanged =
      existingRule && existingFilter
        ? !isEqual(existingFilter.operator, value)
        : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];
      const currentFilters = currentRule.meta.filters ?? [];

      const updatedFilter = {
        ...currentFilters[filterIndex],
        operator: getDatalligatorOperator(value),
        values:
          value === Operator.SET || value === Operator.NOT_SET
            ? []
            : currentFilters[filterIndex].values,
      };

      const updatedFilters = [
        ...currentFilters.slice(0, filterIndex),
        updatedFilter,
        ...currentFilters.slice(filterIndex + 1),
      ];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          filters: updatedFilters,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            updatedRule,
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleUpdateFilterValues(
    value: string | string[],
    ruleIndex: number,
    filterIndex: number
  ) {
    const existingRule = props.selectedRuleSet?.rules[ruleIndex];
    const existingFilter = (existingRule?.meta.filters ?? [])[filterIndex];

    const values = Array.isArray(value) ? value : [value];

    const hasChanged =
      existingRule && existingFilter
        ? !isEqual(existingFilter.values, values)
        : true;

    setState((currentState) => {
      const currentRule = currentState.rulesInput.value[ruleIndex];
      const currentFilters = currentRule.meta.filters ?? [];

      const updatedFilter = {
        ...currentFilters[filterIndex],
        values: values,
      };

      const updatedFilters = [
        ...currentFilters.slice(0, filterIndex),
        updatedFilter,
        ...currentFilters.slice(filterIndex + 1),
      ];

      const updatedRule = {
        ...currentRule,
        meta: {
          ...currentRule.meta,
          filters: updatedFilters,
        },
      };

      return {
        ...currentState,
        rulesInput: {
          value: [
            ...currentState.rulesInput.value.slice(0, ruleIndex),
            updatedRule,
            ...currentState.rulesInput.value.slice(ruleIndex + 1),
          ],
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    const startTime = new Date(state.startTimeInput.value).toDateString();
    const endTime =
      state.endTimeInput.value.length > 0
        ? new Date(state.endTimeInput.value).toDateString()
        : undefined;

    const utcStartDate = new Date(startTime);
    const utcEndDate = endTime ? new Date(endTime) : undefined;

    utcStartDate.setUTCHours(0);

    if (utcEndDate) {
      utcEndDate.setUTCHours(0);
    }

    if (props.selectedRuleSet) {
      props.onInteraction({
        type: MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(state.applyToAllTenantsInput.hasChanged
          ? { applyToAllTenants: state.applyToAllTenantsInput.value }
          : {}),
        ...(state.endTimeInput.hasChanged
          ? { endTime: utcEndDate ? utcEndDate.toISOString() : "" }
          : {}),
        ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
        ...(state.rulesInput.hasChanged
          ? {
              rules: state.rulesInput.value.map((rule, index) => ({
                id: rule.id,
                name: rule.name,
                priority: index + 1,
                type: rule.type,
                meta: {
                  ...rule.meta,
                  ...(rule.meta.dimensions
                    ? {
                        dimensions: rule.meta.dimensions.reduce(
                          (accum, dimension) => ({
                            ...accum,
                            [dimension.name]: dimension.value,
                          }),
                          {}
                        ),
                      }
                    : {}),
                },
              })),
            }
          : {}),
        ...(state.startTimeInput.hasChanged
          ? { startTime: utcStartDate.toISOString() }
          : {}),
        ...(state.tenantIDsInput.hasChanged
          ? { tenantIDs: state.tenantIDsInput.value }
          : {}),
      });
    } else {
      props.onInteraction({
        type: MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        applyToAllTenants: state.applyToAllTenantsInput.value,
        endTime: utcEndDate ? utcEndDate.toISOString() : "",
        name: state.nameInput.value,
        rules: state.rulesInput.value.map((rule, index) => ({
          id: rule.id,
          name: rule.name,
          priority: index + 1,
          type: rule.type,
          meta: {
            ...rule.meta,
            ...(rule.meta.dimensions
              ? {
                  dimensions: rule.meta.dimensions.reduce(
                    (accum, dimension) => ({
                      ...accum,
                      [dimension.name]: dimension.value,
                    }),
                    {}
                  ),
                }
              : {}),
          },
        })),
        startTime: utcStartDate.toISOString(),
        tenantIDs: state.tenantIDsInput.value,
      });
    }
  }

  //
  // Render
  //

  const typeOptions = [
    {
      label: copyText.ruleSetTypeLabel_RERATE,
      value: MspBillingRuleType.RERATE,
    },
    {
      label: copyText.ruleSetTypeLabel_ADJUST_TO_LIST,
      value: MspBillingRuleType.ADJUST_TO_LIST,
    },
    {
      label: copyText.ruleSetTypeLabel_MARGIN,
      value: MspBillingRuleType.APPLY_MARGIN,
    },
    {
      label: copyText.ruleSetTypeLabel_BACKOUT_LINE_ITEM,
      value: MspBillingRuleType.BACKOUT_LINE_ITEM,
    },
    {
      label: copyText.ruleSetTypeLabel_MOVE_BILLING_ACCOUNT,
      value: MspBillingRuleType.MOVE_BILLING_ACCOUNT,
    },
    {
      label: copyText.ruleSetTypeLabel_RECURRING_CHARGE,
      value: MspBillingRuleType.RECURRING_CHARGE,
    },
  ];

  const tenantsKeyedByID = keyBy(props.childTenants, "id");

  const tenantOptions = props.childTenants.map((tenant) => ({
    label: tenant.name,
    value: tenant.id,
  }));

  const selectedTenantOptions = tenantOptions.filter((option) =>
    state.tenantIDsInput.value.includes(option.value)
  );

  const dimensionOptions = props.availableDimensions.map((dimension) => ({
    label: dimension.displayName,
    value: dimension.schemaName,
  }));

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const isValid = Object.values(state).every((input) => input.isValid);

  const tenantsApplicationValid =
    state.applyToAllTenantsInput.value === true ||
    state.tenantIDsInput.value.length > 0;

  const canSubmit =
    (props.selectedRuleSet ? hasChanged && isValid : isValid) &&
    tenantsApplicationValid;

  function renderTypeInputs(rule: RuleInput, ruleIndex: number) {
    switch (rule.type) {
      case MspBillingRuleType.ADJUST_TO_LIST: {
        return <></>;
      }
      case MspBillingRuleType.BACKOUT_LINE_ITEM: {
        return <></>;
      }
      case MspBillingRuleType.MOVE_BILLING_ACCOUNT: {
        const tenantID = rule.meta.tenantID as string;

        const tenant = tenantsKeyedByID[tenantID]
          ? tenantsKeyedByID[tenantID]
          : undefined;

        const billingAccountOptions =
          tenant && props.billingAccounts[tenant.fsDocID]
            ? props.billingAccounts[tenant.fsDocID].map((billingAccountID) => ({
                label: billingAccountID,
                value: billingAccountID,
              }))
            : [];

        return (
          <FormField label={copyText.ruleSetFormDestinationLabel} required>
            <Flex alignItems="center">
              <Box width="275px">
                <Select
                  value={tenantOptions.find(
                    (tenant) => tenant.value === rule.meta.tenantID
                  )}
                  placeholder={copyText.ruleSetFormSelectTenantPlaceholder}
                  options={tenantOptions}
                  onChange={(option) =>
                    option &&
                    handleSelectTargetTenantID(option.value, ruleIndex)
                  }
                />
              </Box>
              <Box marginLeft={theme.space_sm} width="275px">
                <Select
                  isLoading={props.isLoadingBillingAccounts}
                  options={billingAccountOptions}
                  placeholder={
                    copyText.ruleSetFormSelectBillingAccountPlaceholder
                  }
                  value={billingAccountOptions.find(
                    (account) => account.value === rule.meta.billingAccountID
                  )}
                  onChange={(option) =>
                    option && handleSelectPayerAccount(option.value, ruleIndex)
                  }
                />
              </Box>
            </Flex>
          </FormField>
        );
      }
      case MspBillingRuleType.RECURRING_CHARGE: {
        const unitOptions = [
          { label: copyText.ruleSetFormCurrencyLabel, value: "currency" },
          { label: copyText.ruleSetFormPercentageLabel, value: "percent" },
        ];

        const selectedMetricOption = availableMeasures.find((measure) =>
          rule.meta.metrics ? measure.value === rule.meta.metrics[0] : undefined
        );

        return (
          <Box>
            <Flex alignItems="center">
              <Box marginRight={theme.space_sm} width="300px">
                <FormField label={copyText.ruleSetFormMetricLabel} required>
                  <Select
                    options={availableMeasures}
                    value={selectedMetricOption}
                    onChange={(option) =>
                      option && handleSelectMeasure([option.value], ruleIndex)
                    }
                  />
                </FormField>
              </Box>
              <Box marginRight={theme.space_sm} width="300px">
                <FormField label={copyText.ruleSetFormValueLabel} required>
                  <NumberInput
                    value={rule.meta.value as number}
                    onChange={(number) =>
                      handleChangeRecurringChargeValue(number, ruleIndex)
                    }
                  />
                </FormField>
              </Box>
              <Box width="250px">
                <FormField label={copyText.ruleSetFormUnitLabel} required>
                  <Select
                    options={unitOptions}
                    value={
                      rule.meta.isPercentage === true
                        ? {
                            label: copyText.ruleSetFormPercentageLabel,
                            value: "percent",
                          }
                        : {
                            label: copyText.ruleSetFormCurrencyLabel,
                            value: "currency",
                          }
                    }
                    onChange={(option) => {
                      if (!option) return;

                      handleClickRecurringChargePercentCheckbox(
                        option.value === "percent" ? true : false,
                        ruleIndex
                      );
                    }}
                  />
                </FormField>
              </Box>
              <Tooltip
                content={copyText.ruleSetFormRecurringChargeTooltip}
                width="250px"
              >
                <Box marginLeft={theme.space_sm}>
                  <Icon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            </Flex>
            <Box marginBottom={theme.space_sm}>
              <Flex>
                <Text>{copyText.ruleSetFormDimensionsLabel}</Text>
                <Tooltip
                  content={copyText.ruleSetFormDimensionsTooltipLabel}
                  placement="right"
                >
                  <Box marginLeft={theme.space_sm}>
                    <Icon icon={faInfoCircle} />
                  </Box>
                </Tooltip>
              </Flex>
              {(rule.meta.dimensions ?? []).map((dimension, dimensionIndex) => {
                const selectedDimension = props.availableDimensions.find(
                  (availableDimension) =>
                    availableDimension.schemaName === dimension.name
                );

                const value = selectedDimension
                  ? {
                      label: selectedDimension.displayName,
                      value: selectedDimension.schemaName,
                    }
                  : undefined;

                return (
                  <Flex
                    key={dimensionIndex}
                    alignItems="center"
                    marginBottom={theme.space_sm}
                  >
                    <Box marginRight={theme.space_xs} width={275}>
                      <Select
                        options={dimensionOptions}
                        placeholder={copyText.searchInputPlaceholder}
                        searchable
                        value={value}
                        onChange={(option) =>
                          option &&
                          handleUpdateDimensionName(
                            option.value,
                            ruleIndex,
                            dimensionIndex
                          )
                        }
                      />
                    </Box>
                    <Box width={275}>
                      <Select
                        isCreatable
                        isLoading={props.isLoadingDimensionValues}
                        options={
                          props.dimensionValues[dimension.name]
                            ? props.dimensionValues[dimension.name]
                                .sort()
                                .map((value) => ({
                                  label: value,
                                  value,
                                }))
                            : []
                        }
                        placeholder={copyText.searchInputPlaceholder}
                        searchable
                        value={
                          dimension.value.length > 0
                            ? { label: dimension.value, value: dimension.value }
                            : undefined
                        }
                        onChange={(option) =>
                          option &&
                          handleUpdateDimensionValue(
                            option.value,
                            ruleIndex,
                            dimensionIndex
                          )
                        }
                      />
                    </Box>
                    <Button
                      iconStart={<Icon icon={faTimes} />}
                      size="small"
                      type="button"
                      onClick={() =>
                        handleRemoveDimension(ruleIndex, dimensionIndex)
                      }
                    />
                  </Flex>
                );
              })}
            </Box>
            <Button
              iconStart={<Icon icon={faPlus} />}
              marginBottom={theme.space_sm}
              secondary
              size="small"
              type="button"
              onClick={() => handleAddDimension(ruleIndex)}
            >
              {copyText.ruleSetFormAddDimension}
            </Button>
          </Box>
        );
      }
      case MspBillingRuleType.RERATE: {
        return (
          <Box>
            <Flex alignItems="center" marginBottom={theme.space_sm}>
              <Text marginRight={theme.space_xs}>
                {copyText.ruleSetFormAdjustToListLabel}
              </Text>
              <FormField marginBottom={0}>
                <Checkbox
                  checked={!!rule.meta.adjustToList}
                  onChange={(event) =>
                    handleClickAdjustToListCheckbox(
                      event.currentTarget.checked,
                      ruleIndex
                    )
                  }
                />
              </FormField>
              <Tooltip content={copyText.ruleSetFormAdjustToListTooltip}>
                <Box marginLeft={theme.space_sm}>
                  <Icon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            </Flex>
            <FormField label={copyText.ruleSetFormRerateLabel} required>
              <Flex alignItems="center">
                <Box width="300px">
                  <SelectCheckbox
                    options={availableMeasures}
                    selectedValues={rule.meta.metrics ? rule.meta.metrics : []}
                    onChange={(values: string[]) =>
                      handleSelectMeasure(values, ruleIndex)
                    }
                  />
                </Box>
                <Flex
                  alignItems="center"
                  marginLeft={theme.space_sm}
                  width="220px"
                >
                  <NumberInput
                    value={rule.meta.value ?? 0}
                    onChange={(number) =>
                      handleChangePercentage(number, ruleIndex)
                    }
                  />
                </Flex>
                <Text bold marginLeft={theme.space_sm}>
                  %
                </Text>
                <Tooltip content={copyText.ruleSetFormRerateTooltip}>
                  <Box marginLeft={theme.space_sm}>
                    <Icon icon={faInfoCircle} />
                  </Box>
                </Tooltip>
              </Flex>
            </FormField>
          </Box>
        );
      }
      case MspBillingRuleType.APPLY_MARGIN: {
        return (
          <Box>
            <Flex alignItems="center" marginBottom={theme.space_sm}>
              <Text marginRight={theme.space_xs}>
                {copyText.ruleSetFormAdjustToListLabel}
              </Text>
              <FormField marginBottom={0}>
                <Checkbox
                  checked={!!rule.meta.adjustToList}
                  onChange={(event) =>
                    handleClickAdjustToListCheckbox(
                      event.currentTarget.checked,
                      ruleIndex
                    )
                  }
                />
              </FormField>
              <Tooltip content={copyText.ruleSetFormAdjustToListMarginTooltip}>
                <Box marginLeft={theme.space_sm}>
                  <Icon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            </Flex>
            <FormField label={copyText.ruleSetFormMarginLabel} required>
              <Flex alignItems="center">
                <Box width="300px">
                  <SelectCheckbox
                    options={availableMeasures}
                    selectedValues={rule.meta.metrics ? rule.meta.metrics : []}
                    onChange={(values: string[]) =>
                      handleSelectMeasure(values, ruleIndex)
                    }
                  />
                </Box>
                <Flex
                  alignItems="center"
                  marginLeft={theme.space_sm}
                  width="220px"
                >
                  <NumberInput
                    value={rule.meta.value ?? 0}
                    onChange={(number) =>
                      handleChangePercentage(number, ruleIndex)
                    }
                  />
                </Flex>
                <Text bold marginLeft={theme.space_sm}>
                  %
                </Text>
                <Tooltip content={copyText.ruleSetFormMarginTooltip}>
                  <Box marginLeft={theme.space_sm}>
                    <Icon icon={faInfoCircle} />
                  </Box>
                </Tooltip>
              </Flex>
            </FormField>
          </Box>
        );
      }
    }
  }

  return (
    <Form>
      {/* Rule Set Name Input */}
      <FormField
        input={TextInput}
        label={copyText.nameInputLabel}
        required
        value={state.nameInput.value}
        onChange={(event) => handleChangeRuleSetName(event.target.value)}
      />

      {/* Date Input */}
      <Flex>
        <Box marginRight={theme.space_md} width="48%">
          <FormField label={copyText.ruleSetFormStartDateLabel} required>
            <DatePicker
              dateFormat={"MM/dd/yyyy"}
              selected={
                state.startTimeInput.value
                  ? toZonedTime(new Date(state.startTimeInput.value), "UTC")
                  : null
              }
              onChange={handleChangeStartTime}
            />
          </FormField>
        </Box>
        <Box width="48%">
          <FormField label={copyText.ruleSetFormEndDateLabel}>
            <DatePicker
              dateFormat={"MM/dd/yyyy"}
              selected={
                state.endTimeInput.value.length
                  ? toZonedTime(new Date(state.endTimeInput.value), "UTC")
                  : null
              }
              onChange={handleChangeEndTime}
            />
          </FormField>
        </Box>
      </Flex>

      {/* Rules */}
      <Text fontWeight={"bold"}>{copyText.ruleSetFormRulesLabel}</Text>
      <Box>
        {state.rulesInput.value.map((rule, ruleIndex) => {
          const isFiltersRequired =
            rule.type === MspBillingRuleType.BACKOUT_LINE_ITEM ||
            rule.type === MspBillingRuleType.MOVE_BILLING_ACCOUNT;

          const selectedTypeOption = typeOptions.find(
            (option) => option.value === rule.type
          );

          return (
            <Box key={ruleIndex} marginBottom={theme.space_xs}>
              <Flex
                alignItems="center"
                backgroundColor={theme.background_color}
                borderRadius={theme.borderRadius_2}
                padding={theme.space_xs}
                width="100%"
              >
                <Flex
                  justifyContent="center"
                  marginRight={theme.space_xs}
                  width={10}
                >
                  <Text>{ruleIndex + 1}</Text>
                </Flex>
                <Flex justifyContent="space-between" width="100%">
                  <Flex alignItems="center">
                    <Flex>
                      <Box width={350}>
                        <Text truncate>{rule.name}</Text>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Button
                      iconStart={
                        <Icon icon={rule.isOpen ? faEye : faEyeSlash} />
                      }
                      marginLeft={theme.space_xs}
                      size="tiny"
                      type="button"
                      onClick={() => handleToggleRule(ruleIndex)}
                    />
                    <Button
                      iconStart={<Icon icon={faArrowUp} />}
                      marginLeft={theme.space_xs}
                      type="button"
                      size="tiny"
                      onClick={() => handleClickUpArrow(ruleIndex)}
                    />
                    <Button
                      iconStart={<Icon icon={faArrowDown} />}
                      marginLeft={theme.space_xs}
                      size="tiny"
                      type="button"
                      onClick={() => handleClickDownArrow(ruleIndex)}
                    />
                    <Button
                      iconStart={<Icon icon={faTimes} />}
                      marginLeft={theme.space_xs}
                      size="tiny"
                      type="button"
                      onClick={() => handleDeleteRule(ruleIndex)}
                    />
                  </Flex>
                </Flex>
              </Flex>
              {rule.isOpen && (
                <Box
                  paddingHorizontal={theme.space_md}
                  marginTop={theme.space_sm}
                  width="100%"
                >
                  {/* Rule Name */}
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box width={350}>
                      <FormField
                        input={TextInput}
                        label={copyText.nameInputLabel}
                        required
                        value={rule.name}
                        onChange={(event) =>
                          handleChangeRuleName(event, ruleIndex)
                        }
                      />
                    </Box>
                    <Box width={200}>
                      <FormField label="Type">
                        <Select
                          disabled={!!props.selectedRuleSet && !!rule.id}
                          options={typeOptions}
                          placeholder={
                            copyText.ruleSetFormSelectTypePlaceholder
                          }
                          value={selectedTypeOption}
                          onChange={(option) =>
                            option &&
                            handleChangeRuleType(option.value, ruleIndex)
                          }
                        />
                      </FormField>
                    </Box>
                  </Flex>

                  {/* Rule Type Inputs */}
                  {renderTypeInputs(rule, ruleIndex)}

                  {/* Filters */}
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={theme.space_xs}
                  >
                    <Text appearance="h4">
                      {copyText.ruleSetFormFiltersLabel}
                    </Text>
                    <Button
                      disabled={
                        rule.type === MspBillingRuleType.RECURRING_CHARGE &&
                        !rule.meta.isPercentage
                      }
                      iconStart={<Icon icon={faPlus} />}
                      secondary
                      size="small"
                      type="button"
                      onClick={() => handleAddFilter(ruleIndex)}
                    >
                      {copyText.ruleSetFormAddFilterButtonLabel}
                    </Button>
                  </Flex>
                  <FormField
                    label={
                      isFiltersRequired
                        ? copyText.ruleSetFormRequiredLabel
                        : undefined
                    }
                    required={isFiltersRequired}
                  >
                    <Box>
                      {(rule.meta.filters ?? []).map((filter, filterIndex) => {
                        const dimension = props.availableDimensions.find(
                          (dimension) =>
                            dimension.schemaName === filter.schema_field_name
                        );

                        const value = dimension
                          ? {
                              label: dimension.displayName,
                              value: dimension.schemaName,
                            }
                          : undefined;

                        return (
                          <Flex
                            key={filterIndex}
                            alignItems="center"
                            marginBottom={theme.space_xs}
                          >
                            <Box width={200}>
                              <Select
                                options={dimensionOptions}
                                placeholder={copyText.searchInputPlaceholder}
                                searchable
                                value={value}
                                onChange={(option) =>
                                  option &&
                                  handleUpdateFilterName(
                                    option.value,
                                    ruleIndex,
                                    filterIndex
                                  )
                                }
                              />
                            </Box>
                            <Box marginLeft={theme.space_sm} width={200}>
                              <Select
                                searchable
                                options={operatorOptions}
                                value={operatorOptions.find(
                                  (option) =>
                                    getDatalligatorOperator(option.value) ===
                                    filter.operator
                                )}
                                onChange={(option) =>
                                  option &&
                                  handleUpdateFilterOperator(
                                    option.value,
                                    ruleIndex,
                                    filterIndex
                                  )
                                }
                              />
                            </Box>
                            {!(
                              [
                                getDatalligatorOperator(Operator.NOT_SET),
                                getDatalligatorOperator(Operator.SET),
                              ] as DatalligatorOperator[]
                            ).includes(filter.operator) && (
                              <Box marginLeft={theme.space_sm} width={200}>
                                <SelectCheckbox
                                  isCreatable
                                  isLoading={props.isLoadingDimensionValues}
                                  options={
                                    props.dimensionValues[
                                      filter.schema_field_name
                                    ]
                                      ? props.dimensionValues[
                                          filter.schema_field_name
                                        ]
                                          .sort()
                                          .map((value) => ({
                                            label: value,
                                            value,
                                          }))
                                      : []
                                  }
                                  selectedValues={filter.values ?? []}
                                  onChange={(values: string | string[]) =>
                                    handleUpdateFilterValues(
                                      values,
                                      ruleIndex,
                                      filterIndex
                                    )
                                  }
                                />
                              </Box>
                            )}
                            <Button
                              iconStart={<Icon icon={faTimes} />}
                              size="small"
                              type="button"
                              onClick={() =>
                                handleDeleteFilter(filterIndex, ruleIndex)
                              }
                            />
                          </Flex>
                        );
                      })}
                    </Box>
                  </FormField>
                </Box>
              )}
            </Box>
          );
        })}

        <Button
          iconStart={<Icon icon={faPlus} />}
          marginVertical={theme.space_md}
          secondary
          type="button"
          size="small"
          onClick={handleAddRule}
        >
          {copyText.ruleSetFormAddRuleLabel}
        </Button>
      </Box>

      {/* Tenants */}
      <Flex direction="column" marginBottom={theme.space_sm}>
        <Text fontWeight={"bold"} marginBottom={theme.space_xs}>
          {copyText.ruleSetFormTenantsLabel}
        </Text>
        <Flex alignItems="center">
          <Text marginRight={theme.space_sm}>
            {copyText.ruleSetFormApplyToAllTenants}
          </Text>
          <FormField marginBottom={0}>
            <Switch
              checked={state.applyToAllTenantsInput.value}
              onChange={handleClickAllTenantsCheckbox}
            />
          </FormField>
        </Flex>
      </Flex>
      <Box>
        {selectedTenantOptions.map((option, index) => (
          <Flex
            key={option.value}
            alignItems="center"
            marginBottom={theme.space_xxs}
            justifyContent="space-between"
            paddingHorizontal={theme.space_sm}
          >
            <Text marginRight={theme.space_sm}>{option.label}</Text>
            <Button
              iconStart={<Icon icon={faTimes} />}
              size="tiny"
              type="button"
              onClick={() => handleDeleteTenant(index)}
            />
          </Flex>
        ))}
        <SelectDropdown
          hideSelectedOptions
          options={tenantOptions}
          placement="top-start"
          selectedValues={state.tenantIDsInput.value}
          onChange={(option) => handleAddTenant(option)}
        >
          <Button
            disabled={state.applyToAllTenantsInput.value === true}
            iconStart={<Icon icon={faPlus} />}
            marginTop={theme.space_md}
            secondary
            size="small"
            type="button"
          >
            {copyText.ruleSetFormAddTenant}
          </Button>
        </SelectDropdown>
      </Box>

      <Flex justifyContent="flex-end" marginTop={theme.space_sm}>
        <Button secondary type="reset" width={100} onClick={handleCancel}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isLoading || props.isProcessing}
          locked={
            !gatekeeper.canCreateMspBillingRuleSet ||
            !gatekeeper.canUpdateMspBillingRuleSet
          }
          marginLeft={theme.space_sm}
          primary
          width={100}
          type="button"
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

MspBillingRuleSetForm.INTERACTION_ADD_FILTER_CLICKED =
  "RuleSetForm.INTERACTION_ADD_FILTER_CLICKED" as const;

MspBillingRuleSetForm.INTERACTION_ADD_TARGET_TENANT_ID =
  "RuleSetForm.INTERACTION_ADD_TARGET_TENANT_ID" as const;

MspBillingRuleSetForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "RuleSetForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

MspBillingRuleSetForm.INTERACTION_DIMENSION_NAME_SELECTED =
  "RuleSetForm.INTERACTION_DIMENSION_NAME_SELECTED" as const;

MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "RuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "RuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

type InteractionAddFilterClicked = {
  type: typeof MspBillingRuleSetForm.INTERACTION_ADD_FILTER_CLICKED;
  filter: string;
};

type InteractionAddTargetTenantIDClicked = {
  type: typeof MspBillingRuleSetForm.INTERACTION_ADD_TARGET_TENANT_ID;
  tenantID: string;
};

type InteractionCancelButtonClicked = {
  type: typeof MspBillingRuleSetForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionDimensionNameSelected = {
  type: typeof MspBillingRuleSetForm.INTERACTION_DIMENSION_NAME_SELECTED;
  name: string;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  applyToAllTenants: boolean;
  endTime: string;
  name: string;
  rules: Rule[];
  startTime: string;
  tenantIDs: string[];
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof MspBillingRuleSetForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  applyToAllTenants?: boolean;
  endTime?: string;
  name?: string;
  rules?: Rule[];
  startTime?: string;
  tenantIDs?: string[];
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspBillingRuleSetForm {
  export type Interaction =
    | InteractionAddFilterClicked
    | InteractionAddTargetTenantIDClicked
    | InteractionCancelButtonClicked
    | InteractionDimensionNameSelected
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default MspBillingRuleSetForm;
