import { useTheme } from "@emotion/react";
import { Operator, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { lineStyleProps } from "@ternary/api-lib/analytics/ui/styles";
import { FilterLegacy } from "@ternary/api-lib/analytics/ui/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { add, startOfHour, startOfMonth, sub } from "date-fns";
import React, { useMemo } from "react";
import {
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import useGetSparklineData from "../hooks/useGetSparklineData";
import { CostAlertDimension } from "../types";

type Props = {
  dimensionGrouping: CostAlertDimension[];
  granularity?: TimeGranularity;
  occurredAt: string;
  ruleFilters?: FilterLegacy[];
};

function getSparklineDateRange(
  occurredAt: string,
  granularity?: TimeGranularity
): [Date, Date] {
  const newDate = new Date(new Date(occurredAt).toISOString());

  let startDate;
  let endDate;

  switch (granularity) {
    case TimeGranularity.DAY: {
      startDate = sub(newDate, { days: 20 });
      endDate = add(newDate, { days: 2 });
      break;
    }
    case TimeGranularity.HOUR: {
      startDate = sub(newDate, { hours: 12 });
      endDate = add(newDate, { hours: 12 });
      break;
    }
    case TimeGranularity.MINUTE: {
      startDate = sub(newDate, { days: 1 });
      endDate = add(newDate, { days: 1 });
      break;
    }
    case TimeGranularity.MONTH: {
      startDate = startOfMonth(newDate);
      endDate = newDate;
      break;
    }
    default: {
      startDate = sub(newDate, { days: 20 });
      endDate = add(newDate, { days: 10 });
    }
  }

  return [startDate, endDate];
}

export default function CostAlertSparkLineChart(props: Props) {
  const dateRange: [Date, Date] = useMemo(() => {
    return getSparklineDateRange(props.occurredAt, props.granularity);
  }, [props.occurredAt, props.granularity]);

  const filters = [
    ...(props.ruleFilters ?? []),
    ...props.dimensionGrouping.map((dg) =>
      dg.value
        ? {
            name: dg.key,
            operator: Operator.EQUALS,
            values: [dg.value],
          }
        : {
            name: dg.key,
            operator: Operator.NOT_SET,
          }
    ),
  ].map((filter) => ({
    schemaName: filter.name,
    operator: filter.operator,
    values: filter.values,
  }));

  const { data: newData = [], isLoading } = useGetSparklineData({
    dateRange,
    filters,
    granularity: props.granularity ?? TimeGranularity.HOUR,
  });

  const theme = useTheme();

  if (isLoading) {
    return (
      <Flex alignItems="center" height={50} justifyContent="center" width={90}>
        <LoadingSpinner />
      </Flex>
    );
  }

  const dotDatum = newData.find(
    (d) => d.timestamp === startOfHour(new Date(props.occurredAt)).toISOString()
  );

  const dotValue = dotDatum?.cost;

  return (
    <Box width={90} height={50}>
      <ResponsiveContainer width="100%">
        <LineChart
          data={newData}
          height={30}
          margin={{ bottom: 3, left: 3, right: 3, top: 3 }}
          width={50}
        >
          <XAxis dataKey="timestamp" hide />
          <YAxis hide />
          <Line
            {...lineStyleProps}
            dataKey="cost"
            dot={false}
            stroke={theme.primary_color_background}
            strokeWidth={1.5}
            type="monotone"
          />
          <ReferenceDot
            fill="red"
            r={3}
            stroke="none"
            x={props.occurredAt}
            y={dotValue}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
