import { ProviderType, UnitType } from "../enums";

const measures = {
  computeCreditsUsed: {
    displayName: "Compute Credits Used",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "computeCreditsUsed",
    unit: UnitType.STANDARD,
  },
  cloudServicesCreditsUsed: {
    displayName: "Cloud Services Credits Used",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "cloudServicesCreditsUsed",
    unit: UnitType.STANDARD,
  },
  queryCount: {
    displayName: "Query Count",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "queryCount",
    unit: UnitType.STANDARD,
  },
  queryElapsedTime: {
    displayName: "Query Elapsed Time",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "queryElapsedTime",
    unit: UnitType.STANDARD,
  },
  totalCreditsUsed: {
    displayName: "Total Credits Used",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "totalCreditsUsed",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  accountName: {
    displayName: "Account Name",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "accountName",
  },
  tags: {
    displayName: "Tags",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "tags",
  },
  warehouseName: {
    displayName: "Warehouse Name",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "warehouseName",
  },
  warehouseID: {
    displayName: "Warehouse ID",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "warehouseID",
  },
  warehouseSize: {
    displayName: "Warehouse Size",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "warehouseSize",
  },
  warehouseType: {
    displayName: "Warehouse Type",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "warehouseType",
  },
} as const;

export const snowflakeWarehouseUsageSchema = { dimensions, measures };
