import { ProviderType, UnitType } from "../enums";

const measures = {
  negativeDelta: {
    displayName: "Negative Delta",
    providerType: ProviderType.TERNARY,
    schemaName: "negativeDelta",
    unit: UnitType.CURRENCY,
  },
  positiveDelta: {
    displayName: "Positive Delta",
    providerType: ProviderType.TERNARY,
    schemaName: "positiveDelta",
    unit: UnitType.CURRENCY,
  },
  totalAlertEvents: {
    displayName: "Total Alert Events",
    providerType: ProviderType.TERNARY,
    schemaName: "totalAlertEvents",
    unit: UnitType.STANDARD,
  },
  totalDelta: {
    displayName: "Total Delta",
    providerType: ProviderType.TERNARY,
    schemaName: "totalDelta",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  alertEventID: {
    displayName: "Alert Event ID",
    providerType: ProviderType.TERNARY,
    schemaName: "alertEventID",
  },
  alertRuleID: {
    displayName: "Alert Rule ID",
    providerType: ProviderType.TERNARY,
    schemaName: "alertRuleID",
  },
};

export const alertEventsSchema = { dimensions, measures };
