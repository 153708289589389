import useGetDimensionPreferencesByTenantID from "@/features/admin/hooks/useGetDimensionPreferencesByTenantID";
import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  DataSource,
  DurationType,
  Operator,
  ProviderType,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { detailedBillingSchema } from "@ternary/api-lib/analytics/schemas/detailedBilling";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils/ReportUtils";
import {
  AlertRuleConditionType,
  AlertRuleStatus,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleEntity } from "@ternary/api-lib/core/types";
import { CostAlertEntity } from "@ternary/api-lib/core/types/CostAlert";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { endOfDay, isAfter, isBefore, startOfDay, sub } from "date-fns";
import { groupBy, keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useGetAnomalyDetail from "../../../api/analytics/hooks/useGetAnomalyDetail";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import useGetRawData from "../../../api/analytics/useGetRawData";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import ErrorBoundary from "../../../components/ErrorBoundary";
import SideDrawerLegacy from "../../../components/SideDrawerLegacy";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { useAvailableDimensionsByDataSourceV2 } from "../../../hooks/useAvailableDimensionsByDataSource";
import useAvailableGlobalDate from "../../../hooks/useAvailableGlobalDate";
import useAvailableMeasuresByDataSource from "../../../hooks/useAvailableMeasuresByDataSource";
import { DateHelper } from "../../../lib/dates";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import { ReportStateConfig } from "../../../types";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls/DateRangeControls";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import {
  LEGACY_BIG_QUERY_RULE_ID,
  LEGACY_BILLING_RULE_ID,
  legacyBigQueryAlertRule,
  legacyBillingAlertRule,
} from "../defaultAlertRules";
import queryKeys from "../hooks/queryKeys";
import useCreateAlertRule from "../hooks/useCreateAlertRule";
import useGetAlertRulesByTenantID from "../hooks/useGetAlertRulesByTenantID";
import useGetBoundsData from "../hooks/useGetBoundsData";
import useGetCostAlertsByTenantID from "../hooks/useGetCostAlertsByTenantID";
import useUpdateAlertRule from "../hooks/useUpdateAlertRule";
import useUpdateCostAlert from "../hooks/useUpdateCostAlert";
import { getAnomalyDateRange, isCostAlert, populateCostAlert } from "../utils";
import AlertDetailModal from "./AlertDetailModal";
import AlertRuleForm, { Action, AlertRule } from "./AlertRuleForm";
import AlertRuleGroup from "./AlertRuleGroup";
import { AnomalyChartData } from "./AnomalyChart";
import CostAlertStatusButton from "./CostAlertStatusButton";
import CostAlertTable, { CostAlertRule } from "./CostAlertTable";
import CostAlertTableControls from "./CostAlertTableControls";

const defaultCostAlerts = [];
const defaultAlertRules = [];

type Interaction =
  | AlertDetailModal.Interaction
  | AlertRuleForm.Interaction
  | AlertRuleGroup.Interaction
  | CostAlertStatusButton.Interaction
  | CostAlertTable.Interaction
  | CostAlertTableControls.Interaction;

interface State {
  dateRange: Date[];
  duration: DurationType;
  modalKey?: string;
  searchText: string;
}

const initialState: State = {
  dateRange: [],
  duration: DurationType.LAST_THIRTY_DAYS,
  modalKey: undefined,
  searchText: "",
};

const defaultAlertCostTableData = [];

function _CostAlertContainer() {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const navigate = useNavigateWithSearchParams();
  const globalDate = useAvailableGlobalDate();
  const activityTracker = useActivityTracker();
  const queryClient = useQueryClient();
  const caseManagementStore = useCaseManagementStore();

  //
  // State
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    actionPanelKey: StringParam,
    alertID: StringParam,
    alertRuleID: StringParam,
  });

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const {
    data: alertRules = defaultAlertRules,
    isLoading: isLoadingAlertRules,
    refetch: refetchRules,
  } = useGetAlertRulesByTenantID(authenticatedUser.tenant.fsDocID, {
    select: (dt) => [...dt, legacyBillingAlertRule, legacyBigQueryAlertRule],
  });

  const {
    data: _costAlerts = defaultCostAlerts,
    isLoading: isLoadingCostAlerts,
  } = useGetCostAlertsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const availableDimensions = useAvailableDimensionsByDataSourceV2(
    DataSource.BILLING
  );

  const availableMeasures = useAvailableMeasuresByDataSource(
    DataSource.BILLING
  );

  const dimensionsKeyedBySchemaName = keyBy(availableDimensions, "schemaName");
  const measuresKeyedBySchemaName = keyBy(availableMeasures, "schemaName");

  const {
    data: dimensionValuesMap = {},
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValuesByDataSource({
    dataSource: DataSource.BILLING,
    dateRange: [
      sub(startOfDay(new Date()), { years: 1 }),
      startOfDay(new Date()),
    ],
    dimensions: [
      billingCucSchema.dimensions.billingAccountID,
      billingCucSchema.dimensions.projectID,
      billingCucSchema.dimensions.projectName,
      billingCucSchema.dimensions.serviceDescription,
      billingCucSchema.dimensions.skuDescription,
    ],
    measure: billingCucSchema.measures.cost,
  });

  //
  // Mutations
  //

  const { isPending: isCreatingRule, mutate: createRule } = useCreateAlertRule({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE
      );
    },
    onSuccess: () => {
      refetchRules();

      setSearchParamState({
        alertRuleID: null,
        actionPanelKey: undefined,
      });

      postAlert({
        message: copyText.successCreatingRuleMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isUpdatingRule, mutate: updateRule } = useUpdateAlertRule({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE
      );
    },
    onSuccess: () => {
      refetchRules();
      setSearchParamState({
        alertRuleID: null,
        actionPanelKey: null,
      });

      postAlert({
        message: copyText.successUpdatingRuleMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { mutate: updateCostAlert } = useUpdateCostAlert({
    onError: (...errorParams) => {
      postAlert({
        message: copyText.errorUpdatingCostAlertMessage,
        type: AlertType.ERROR,
      });

      const context = errorParams[2] as {
        previousCostAlerts: CostAlertEntity[];
      };

      queryClient.setQueryData<CostAlertEntity[]>(
        queryKeys.costAlerts,
        context.previousCostAlerts
      );
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({ queryKey: queryKeys.costAlerts });

      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_UPDATE_COST_ALERT
      );
      // Snapshot the previous value
      const previousCostAlerts = queryClient.getQueryData<CostAlertEntity[]>(
        queryKeys.costAlerts
      );

      const updatedCostAlerts = (previousCostAlerts ?? []).map((alert) =>
        alert.id === params.costAlertID
          ? {
              ...alert,
              status: params.status,
            }
          : { ...alert }
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CostAlertEntity[]>(
        queryKeys.costAlerts,
        updatedCostAlerts
      );

      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_UPDATE_COST_ALERT
      );

      // Return a context object with the snapshotted value
      return { previousCostAlerts };
    },
    onSuccess: () => {
      postAlert({
        message: copyText.successUpdatingCostAlertMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  let dateRange =
    state.dateRange[0] && state.dateRange[1]
      ? state.dateRange
      : getCubeDateRangeFromDurationType(state.duration);

  dateRange = globalDate.date ?? dateRange;

  const selectedCostAlert = _costAlerts.find(
    (alert) => alert.id === searchParamState.alertID
  );

  const filteredCostAlerts = useMemo(
    () => filterAlertsByDateRange(_costAlerts, dateRange),
    [_costAlerts, dateRange]
  );

  const alertRulesKeyedByID = useMemo(
    () => keyBy(alertRules, "id"),
    [alertRules]
  );

  const recentCostAlerts = useMemo(() => {
    const costAlerts = getRecentCostAlerts(filteredCostAlerts);

    return costAlerts.map((costAlert) => {
      const alertRuleID = costAlert?.alertRuleID;
      let alertRule: CostAlertRule = alertRulesKeyedByID[alertRuleID ?? ""];

      if (alertRuleID === LEGACY_BIG_QUERY_RULE_ID) {
        alertRule = legacyBigQueryAlertRule;
      } else if (alertRuleID === LEGACY_BILLING_RULE_ID) {
        alertRule = legacyBillingAlertRule;
      }

      return {
        ...costAlert,
        alertRule: {
          id: alertRule?.id,
          condition: alertRule?.condition,
          dataSource: alertRule?.dataSource,
          dimensions: alertRule?.dimensions,
          timeGranularity: alertRule?.timeGranularity,
          name: alertRule?.name,
        },
      };
    });
  }, [filteredCostAlerts, alertRulesKeyedByID]);

  const costAlertsGroupedByAlertRuleID = useMemo(
    () => groupBy(filteredCostAlerts, "alertRuleID"),
    [filteredCostAlerts]
  );

  const sortedAlertRules = useMemo(() => {
    const filteredAlertRules = filterAlertRulesBySearchText(
      alertRules,
      state.searchText
    );

    return [...filteredAlertRules].sort((a, b) => {
      const aAlertCount = (costAlertsGroupedByAlertRuleID[a.id] ?? []).length;
      const bAlertCount = (costAlertsGroupedByAlertRuleID[b.id] ?? []).length;

      // sort empty alerts last
      if (aAlertCount !== bAlertCount) {
        if (bAlertCount === 0) return -1;
        if (aAlertCount === 0) return 1;
      }

      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
  }, [alertRules, state.searchText, costAlertsGroupedByAlertRuleID]);

  const alertRule = alertRulesKeyedByID[selectedCostAlert?.alertRuleID ?? ""];

  const updatingAlertRule: AlertRuleEntity | undefined =
    alertRulesKeyedByID[searchParamState.alertRuleID ?? ""];

  const populatedCostAlert = selectedCostAlert
    ? populateCostAlert(selectedCostAlert, alertRule)
    : undefined;

  const selectedRuleFilters = populatedCostAlert?.sourceAlertRule.filters ?? [];

  const selectedAlertFilters = useMemo(() => {
    if (!selectedCostAlert || !isCostAlert(selectedCostAlert)) {
      return [];
    }

    // If value is null operator should be NOT_SET
    return selectedCostAlert.dimensions.map((dimension) => {
      return {
        name: dimension.key,
        operator: dimension.value ? Operator.EQUALS : Operator.NOT_SET,
        values: dimension.value ? [dimension.value] : null,
      };
    });
  }, [selectedCostAlert]);

  const selectedAnomalyDateRange = useMemo(() => {
    if (!populatedCostAlert) return [];

    if (globalDate.date) {
      return globalDate.date;
    }

    return getAnomalyDateRange(
      populatedCostAlert.eventTime,
      populatedCostAlert.sourceAlertRule.timeGranularity
    );
  }, [selectedCostAlert, globalDate]);

  const detailMeasure = populatedCostAlert?.sourceAlertRule.measure
    ? {
        displayName: populatedCostAlert?.sourceAlertRule.measure,
        providerType: ProviderType.TERNARY,
        schemaName: populatedCostAlert?.sourceAlertRule.measure,
        unit: UnitType.CURRENCY,
      }
    : billingCucSchema.measures.cost;

  const queryFilters = [...selectedRuleFilters, ...selectedAlertFilters].map(
    (filter) => ({
      operator: filter.operator,
      schemaName: filter.name,
      values: filter.values,
    })
  );

  const { data: _alertCostData = [], isLoading: isLoadingAlertCostData } =
    useGetAnomalyDetail(
      {
        dateRange: selectedAnomalyDateRange,
        dataSource:
          populatedCostAlert?.sourceAlertRule.dataSource ?? DataSource.BILLING,
        measures: [detailMeasure],
        queryFilters,
        granularity: populatedCostAlert?.sourceAlertRule.timeGranularity,
      },
      {
        enabled: !!selectedCostAlert && selectedAnomalyDateRange.length === 2,
      }
    );

  const { data: boundsData = [], isLoading: isLoadingBoundsData } =
    useGetBoundsData({
      dateRange: selectedAnomalyDateRange,
      costAlertID: selectedCostAlert?.id,
    });

  const alertCostData: AnomalyChartData[] = _alertCostData.map((datum) => {
    if (!populatedCostAlert) {
      return datum;
    }

    let errorRange: [number, number] | undefined = undefined;
    if (datum.time === populatedCostAlert?.eventTime) {
      errorRange = [
        datum.amount - populatedCostAlert.expectedValue.lowerBound,
        -(datum.amount - populatedCostAlert.expectedValue.upperBound),
      ];
    }

    return {
      ...datum,
      error: errorRange,
    };
  });

  const anomalyTableDateRange = populatedCostAlert
    ? [
        startOfDay(new Date(populatedCostAlert.eventTime)),
        endOfDay(new Date(populatedCostAlert.eventTime)),
      ]
    : [];

  const {
    data: alertCostTableData = defaultAlertCostTableData,
    isLoading: isLoadingAlertCostTableData,
  } = useGetRawData(
    {
      dateRange: anomalyTableDateRange,
      dataSource: DataSource.DETAILED_BILLING,
      dimensions:
        populatedCostAlert &&
        populatedCostAlert.sourceAlertRule.dimensions?.length > 0
          ? populatedCostAlert.sourceAlertRule.dimensions.map(
              (dimension) => dimensionsKeyedBySchemaName[dimension]
            )
          : [
              detailedBillingSchema.dimensions.resourceID,
              detailedBillingSchema.dimensions.category,
              detailedBillingSchema.dimensions.skuDescription,
            ],
      limit: 5,
      order: {
        cost: "desc",
      },
      measures: [
        detailedBillingSchema.measures.cost,
        detailedBillingSchema.measures.usageAmount,
      ],
      queryFilters,
    },
    {
      enabled:
        !!populatedCostAlert &&
        populatedCostAlert.sourceAlertRule.id !== LEGACY_BIG_QUERY_RULE_ID &&
        anomalyTableDateRange.length === 2,
    }
  );

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlertRuleGroup.INTERACTION_EDIT_BUTTON_CLICKED: {
        setSearchParamState({
          alertRuleID: interaction.ruleID,
          actionPanelKey: Action.UPDATE,
        });
        break;
      }
      case CostAlertStatusButton.INTERACTION_UPDATE_STATUS_CLICKED: {
        updateCostAlert({
          costAlertID: interaction.costAlertID,
          status: interaction.status,
        });
        break;
      }
      case CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_ALERT_EVENT_TO_DISPLAY
        );
        setSearchParamState({
          alertID: interaction.costAlertID,
        });
        break;
      }
      case AlertDetailModal.INTERACTION_INVESTIGATE_CLICKED: {
        if (!populatedCostAlert) {
          return;
        }

        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_INVESTIGATE,
          { value: searchParamState.alertID }
        );

        const dimensions = populatedCostAlert.sourceAlertRule.dimensions.map(
          (dimension) => ({
            displayName: dimension,
            providerType: ProviderType.TERNARY,
            schemaName: dimension,
          })
        );

        const filters = [...selectedRuleFilters, ...selectedAlertFilters].map(
          (filter) => {
            return {
              displayName: filter.name,
              operator: filter.operator,
              providerType: ProviderType.TERNARY,
              schemaName: filter.name,
              values: filter.values,
            };
          }
        );

        const measure =
          measuresKeyedBySchemaName[populatedCostAlert.sourceAlertRule.measure];

        const reportState: ReportStateConfig = {
          dataSource: populatedCostAlert.sourceAlertRule.dataSource,
          dimensions: dimensions,
          durationType: DurationType.CUSTOM,
          endDate: selectedAnomalyDateRange[1].toISOString(),
          filters: filters,
          measures: [measure],
          startDate: selectedAnomalyDateRange[0].toISOString(),
        };

        navigate(paths._reportBuilderNew, {
          state: {
            from: paths._alertTracking,
            report: reportState,
            runQueryTriggered: true,
            title: copyText.anomalyDetectionPageTitle,
          },
        });
        break;
      }
      case AlertDetailModal.INTERACTION_CREATE_CASE_CLICKED: {
        const alertRuleName =
          alertRulesKeyedByID[selectedCostAlert?.alertRuleID ?? ""]?.name ?? "";
        const eventTime = selectedCostAlert?.eventTime;

        const resourceName = `${alertRuleName} - ${eventTime}`;
        caseManagementStore.set({
          isResourceSelectionMode: false,
          isSideDrawerOpen: true,
          reportSnapshot: null,
          selectedResourceID: interaction.costAlertID,
          selectedResourceName: resourceName,
          selectedResourceType: ResourceType.COST_ALERT,
        });
        return;
      }
      case AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setSearchParamState({
          actionPanelKey: null,
          alertRuleID: null,
        });
        return;
      }
      case AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE
        );

        createRule({
          tenantID: authenticatedUser.tenant.fsDocID,
          condition: interaction.condition,
          dataSource: DataSource.BILLING,
          dimensions: interaction.selectedLabels,
          excludeTax: interaction.excludeTax,
          filters: interaction.filters,
          measure: "cost",
          name: interaction.name,
          status: AlertRuleStatus.ACTIVE,
          subscribers: interaction.subscribers,
          timeGranularity: interaction.timeGranularity,
        });
        return;
      }
      case AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE
        );

        if (!searchParamState.alertRuleID) return;

        updateRule({
          alertRuleID: searchParamState.alertRuleID,
          ...(interaction.condition
            ? { condition: { ...interaction.condition } }
            : {}),
          ...(interaction.selectedLabels
            ? { dimensions: interaction.selectedLabels }
            : {}),
          ...(interaction.excludeTax
            ? { excludeTax: interaction.excludeTax }
            : {}),
          ...(interaction.filters ? { filters: interaction.filters } : {}),
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.subscribers
            ? { subscribers: interaction.subscribers }
            : {}),
          ...(interaction.timeGranularity
            ? { timeGranularity: interaction.timeGranularity }
            : {}),
        });
        return;
      }
    }
  }

  //
  // Render
  //

  function renderAlertRuleForm(): JSX.Element | null {
    switch (searchParamState.actionPanelKey) {
      case Action.CREATE: {
        return (
          <AlertRuleForm
            action={Action.CREATE}
            availableDimensions={availableDimensions}
            dimensionPreferences={dimensionPreferences}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingRule || isCreatingRule}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.UPDATE: {
        if (!updatingAlertRule || !isEditableAlertRule(updatingAlertRule)) {
          return null;
        }

        return (
          <AlertRuleForm
            action={Action.UPDATE}
            alertRule={updatingAlertRule}
            availableDimensions={availableDimensions}
            dimensionPreferences={dimensionPreferences}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingRule || isCreatingRule}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderCostAlertModal() {
    if (!populatedCostAlert) return null;

    return (
      <AlertDetailModal
        alert={populatedCostAlert}
        availableDimensions={availableDimensions}
        boundsData={boundsData}
        isOpen={true}
        isLoadingChartData={isLoadingAlertCostData || isLoadingBoundsData}
        chartData={alertCostData}
        isLoadingTableData={isLoadingAlertCostTableData}
        tableData={alertCostTableData}
        labelMaps={labelMaps}
        onClose={() => {
          setSearchParamState({
            alertID: undefined,
          });
        }}
        onInteraction={handleInteraction}
      />
    );
  }

  //
  // JSX
  //

  return (
    <Flex direction="column">
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        padding={theme.space_md}
        marginBottom={theme.space_sm}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={state.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            mergeState({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    dateRange: newDateRange,
                  }
                : {
                    dateRange: [],
                  }),
            });
          }}
        />
      </Flex>

      <Box>
        <Box
          marginBottom={theme.space_sm}
          marginHorizontal={theme.space_sm}
          marginTop={theme.space_md}
        >
          <Text appearance="h5" color={theme.text_color_secondary}>
            {copyText.costAlertTableHeaderRecent}
          </Text>
        </Box>

        <CostAlertTable
          alertRulesKeyedByID={alertRulesKeyedByID}
          costAlerts={recentCostAlerts}
          isLoading={isLoadingCostAlerts || isLoadingAlertRules}
          labelMaps={labelMaps}
          onInteraction={handleInteraction}
          showSourceRule
        />
      </Box>
      <Box position="relative">
        <Box
          background={theme.background_color}
          paddingBottom={theme.space_xl}
          paddingTop={theme.space_xs}
          position="sticky"
          top={0}
          zIndex={theme.zIndex_800}
        >
          <Flex
            alignItems="center"
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_1}
            justifyContent="flex-end"
            padding={theme.space_md}
          >
            <Box width={250}>
              <TextInput
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="large"
                value={state.searchText}
                onChange={(event) =>
                  mergeState({ searchText: event.target.value })
                }
              />
            </Box>

            <Button
              marginLeft={theme.space_sm}
              iconStart={<Icon icon={faPlus} />}
              primary
              size="small"
              onClick={() => {
                setSearchParamState({
                  actionPanelKey: Action.CREATE,
                });
              }}
            >
              Create Alert Rule
            </Button>
          </Flex>
        </Box>

        {sortedAlertRules.map((alertRule) => (
          <AlertRuleGroup
            key={alertRule.id}
            alertRule={alertRule}
            costAlerts={costAlertsGroupedByAlertRuleID[alertRule.id] ?? []}
            labelMaps={labelMaps}
            onInteraction={handleInteraction}
          />
        ))}
      </Box>

      {renderCostAlertModal()}

      <SideDrawerLegacy
        isOpen={
          !!searchParamState.actionPanelKey || !!searchParamState.alertRuleID
        }
        title={
          searchParamState.actionPanelKey === Action.UPDATE
            ? copyText.sideDrawerTitleEdit
            : copyText.sideDrawerTitleCreate
        }
        onClose={() => {
          setSearchParamState({
            actionPanelKey: undefined,
            alertRuleID: undefined,
          });
          setSearchParamState({ alertRuleID: undefined });
        }}
        renderContent={() =>
          searchParamState.actionPanelKey ? renderAlertRuleForm() : null
        }
      />
    </Flex>
  );
}

export default function CostAlertContainer() {
  return (
    <ErrorBoundary boundaryName="AlertTrackingPage">
      <_CostAlertContainer />
    </ErrorBoundary>
  );
}

function isEditableAlertRule(
  alertRule: AlertRuleEntity | AlertRule
): alertRule is AlertRule {
  return (
    alertRule.condition.type === AlertRuleConditionType.ANOMALY_DETECTION ||
    alertRule.condition.type === AlertRuleConditionType.THRESHOLDING
  );
}

function getRecentCostAlerts(alerts: CostAlertEntity[]) {
  const remainingAlerts = [...alerts];

  const mostRecent = remainingAlerts.splice(0, 5);

  while (remainingAlerts.length > 0) {
    const toAdd = remainingAlerts.pop();

    if (!toAdd) break;

    mostRecent.push(toAdd);

    mostRecent.sort((a, b) =>
      isBefore(new Date(a.eventTime), new Date(b.eventTime))
        ? 1
        : isAfter(new Date(a.eventTime), new Date(b.eventTime))
          ? -1
          : 0
    );

    mostRecent.pop();
  }

  return mostRecent;
}

function filterAlertsByDateRange(alerts: CostAlertEntity[], dateRange: Date[]) {
  return alerts.filter((alert) => {
    const eventTime = new Date(alert.eventTime);

    return (
      isAfter(eventTime, dateRange[0]) && isBefore(eventTime, dateRange[1])
    );
  });
}

function filterAlertRulesBySearchText(
  alertRules: AlertRuleEntity[],
  searchText: string
) {
  if (searchText.trim() === "") return alertRules;

  return alertRules.filter((rule) =>
    rule.name.toLowerCase().includes(searchText.trim().toLowerCase())
  );
}
