import { useQueryClient } from "@tanstack/react-query";
import { defaultMeasuresMap } from "@ternary/api-lib/analytics/constants";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import {
  awsBillingMeasures,
  billingCucSchema,
} from "@ternary/api-lib/analytics/schemas/billingCUC";
import { Measure } from "@ternary/api-lib/analytics/ui/types";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import queryKeys from "../features/admin/hooks/queryKeys";
import { isAWSIntegration } from "../utils/typeGuards";
import { useFocusMeasures } from "./useFocusMeasures";
import useGatekeeper from "./useGatekeeper";

export default function useAvailableMeasuresByDataSource(
  dataSource: DataSource
): Measure[] {
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();

  const focusMeasures = useFocusMeasures();

  // Load focus measures from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    return focusMeasures;
  }

  let defaultMeasures: Measure[] = defaultMeasuresMap[dataSource]
    ? defaultMeasuresMap[dataSource]
    : [];

  if (dataSource === DataSource.BILLING && !gatekeeper.isMspParentTenant) {
    defaultMeasures = defaultMeasures.filter(
      (measure) =>
        measure.schemaName !==
        billingCucSchema.measures.absoluteCreditsResellerMargin.schemaName
    );
  }

  const integrations = queryClient.getQueryData<DataIntegrationEntity[]>(
    queryKeys.dataIntegrations
  );

  if (
    dataSource === DataSource.BILLING &&
    integrations &&
    hasAWSCloudWithCURAccess(integrations)
  ) {
    return [...defaultMeasures, ...Object.values(awsBillingMeasures)];
  }

  return defaultMeasures;
}

function hasAWSCloudWithCURAccess(integrations: DataIntegrationEntity[]) {
  const awsCloudsWithCUR = integrations.filter((integration) => {
    if (!isAWSIntegration(integration)) return false;

    if (
      !integration.validations.find(
        (validation) =>
          validation.name === CloudCapability.BILL_DATA_AWS &&
          validation.success
      )
    ) {
      return false;
    }

    return true;
  });

  return awsCloudsWithCUR.length > 0;
}
