import { ProviderType, UnitType } from "../enums";

const measures = {
  activeLogicalB: {
    displayName: "Active Logical Bytes",
    providerType: ProviderType.GCP,
    schemaName: "activeLogicalB",
    unit: UnitType.BYTES,
  },
  activePhysicalB: {
    displayName: "Active Physical Bytes",
    providerType: ProviderType.GCP,
    schemaName: "activePhysicalB",
    unit: UnitType.BYTES,
  },
  longTermLogicalB: {
    displayName: "Long Term Logical Bytes",
    providerType: ProviderType.GCP,
    schemaName: "longTermLogicalB",
    unit: UnitType.BYTES,
  },
  longTermPhysicalB: {
    displayName: "Long Term Physical Bytes",
    providerType: ProviderType.GCP,
    schemaName: "longTermPhysicalB",
    unit: UnitType.BYTES,
  },
} as const;

const dimensions = {
  datasetName: {
    displayName: "Dataset Name",
    providerType: ProviderType.GCP,
    schemaName: "datasetName",
  },
  datasetProjectId: {
    displayName: "Dataset Project ID",
    providerType: ProviderType.GCP,
    schemaName: "datasetProjectId",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.GCP,
    schemaName: "location",
  },
  storageBillingModel: {
    displayName: "Storage Billing Model",
    providerType: ProviderType.GCP,
    schemaName: "storageBillingModel",
  },
} as const;

export const gcpBigQueryStorageSchema = { dimensions, measures };
