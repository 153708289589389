import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { gcpCombinedCudUtilizationSchema } from "@ternary/api-lib/analytics/schemas/gcpCombinedCudUtilization";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  CudOnDemandChartDatum,
  CudOnDemandCostEntity,
  GCPBillingEntity,
} from "../types";

interface Params {
  dateRange: Date[];
  granularity: TimeGranularity;
}

export default function useGetCudOnDemandCostData(
  params: Params,
  options?: UseQueryOptions<CudOnDemandChartDatum[], UError>
): UseQueryResult<CudOnDemandChartDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudOnDemandCostData", params],
    queryFn: async () => {
      const billingResult = (await client.load(
        buildCubeQuery({
          dateRange: params.dateRange,
          dataSource: DataSource.BILLING,
          dimensions: [
            billingCucSchema.dimensions.billingAccountID,
            billingCucSchema.dimensions.cloudName,
            billingCucSchema.dimensions.serviceDescription,
          ],
          queryFilters: [
            {
              and: [
                {
                  ...billingCucSchema.dimensions.vendor,
                  operator: Operator.EQUALS,
                  values: ["Google"],
                },
              ],
            },
          ],
          granularity: params.granularity,
          measures: [billingCucSchema.measures.cost],
        })
      )) as GCPBillingEntity[];

      const cudResult = (await client.load(
        buildCubeQuery({
          dateRange: params.dateRange,
          dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
          dimensions: [
            gcpCombinedCudUtilizationSchema.dimensions.service,
            gcpCombinedCudUtilizationSchema.dimensions.billingAccountID,
          ],
          granularity: params.granularity,
          measures: [
            gcpCombinedCudUtilizationSchema.measures.potentialCreditedAmount,
          ],
        })
      )) as CudOnDemandCostEntity[];

      return [{ billingData: billingResult, cudInventoryData: cudResult }];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
