import { ProviderType, UnitType } from "../enums";

const measures = {
  cpuUtilization: {
    displayName: "CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "cpuUtilization",
    unit: UnitType.STANDARD,
  },
  databaseConnections: {
    displayName: "Database Connections",
    providerType: ProviderType.AWS,
    schemaName: "databaseConnections",
    unit: UnitType.STANDARD,
  },
  instanceIDCount: {
    displayName: "Instance ID Count",
    providerType: ProviderType.AWS,
    schemaName: "instanceIDCount",
    unit: UnitType.STANDARD,
  },
  instanceUnits: {
    displayName: "Instance Units",
    providerType: ProviderType.AWS,
    schemaName: "instanceUnits",
    unit: UnitType.STANDARD,
  },
  networkBytes: {
    displayName: "Network Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkBytes",
    unit: UnitType.STANDARD,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytes",
    unit: UnitType.STANDARD,
  },
  productVCPU: {
    displayName: "Product VCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  ramUtilization: {
    displayName: "RAM Utilization",
    providerType: ProviderType.AWS,
    schemaName: "ramUtilization",
    unit: UnitType.STANDARD,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.AWS,
    schemaName: "totalCost",
    unit: UnitType.STANDARD,
  },
  usedDiskBytes: {
    displayName: "Used Disk Bytes",
    providerType: ProviderType.AWS,
    schemaName: "usedDiskBytes",
    unit: UnitType.STANDARD,
  },
  usedMemoryBytes: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "usedMemoryBytes",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  databaseType: {
    displayName: "Database Type",
    providerType: ProviderType.AWS,
    schemaName: "databaseType",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.AWS,
    schemaName: "family",
  },
  familyType: {
    displayName: "Family Type",
    providerType: ProviderType.AWS,
    schemaName: "familyType",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AWS,
    schemaName: "instanceId",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  lineItemUsageAccountId: {
    displayName: "Line Item Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
} as const;

export const awsDatabaseInstancesSchema = { measures, dimensions };
