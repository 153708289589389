import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AZURE,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  estimatedCpuCoreHoursAvailable: {
    displayName: "Estimated CPU Core Hours Available",
    providerType: ProviderType.AZURE,
    schemaName: "estimatedCpuCoreHoursAvailable",
    unit: UnitType.STANDARD,
  },
  estimatedRssByteHoursAvailable: {
    displayName: "Estimated RSS Byte Hours Available",
    providerType: ProviderType.AZURE,
    schemaName: "estimatedRssByteHoursAvailable",
    unit: UnitType.BINARY_BYTES,
  },
  instanceHours: {
    displayName: "Instance Hours",
    providerType: ProviderType.AZURE,
    schemaName: "instanceHours",
    unit: UnitType.STANDARD,
  },
  upperBoundCpuCoreHoursUsed: {
    displayName: "Upper Bound CPU Core Hours Used",
    providerType: ProviderType.AZURE,
    schemaName: "upperBoundCpuCoreHoursUsed",
    unit: UnitType.STANDARD,
  },
  upperBoundRssByteHoursUsed: {
    displayName: "Upper Bound RSS Byte Hours Used",
    providerType: ProviderType.AZURE,
    schemaName: "upperBoundRssByteHoursUsed",
    unit: UnitType.BINARY_BYTES,
  },
} as const;

const dimensions = {
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.AZURE,
    schemaName: "cloudId",
  },
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.AZURE,
    schemaName: "clusterName",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AZURE,
    schemaName: "instanceType",
  },
  nodepool: {
    displayName: "Nodepool",
    providerType: ProviderType.AZURE,
    schemaName: "nodepool",
  },
  resourceGroupName: {
    displayName: "Resource Group Name",
    providerType: ProviderType.AZURE,
    schemaName: "resourceGroupName",
  },
  subscriptionName: {
    displayName: "Subscription Name",
    providerType: ProviderType.AZURE,
    schemaName: "subscriptionName",
  },
} as const;

export const azureAksNodeUsageSchema = { measures, dimensions };
