import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { awsEbsVolumesSchema } from "@ternary/api-lib/analytics/schemas/awsEbsVolumes";
import { groupBy } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { EBStore, EBStoreGroup, EBStoreStruct } from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetAWSStorageEBS(
  params: Params,
  options?: UseQueryOptions<EBStoreGroup[], UError>
): UseQueryResult<EBStoreGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["ebsTableData", params],
    queryFn: async () => {
      const dimensions = [
        awsEbsVolumesSchema.dimensions.billPayerAccountID,
        awsEbsVolumesSchema.dimensions.family,
        awsEbsVolumesSchema.dimensions.lineItemUsageAccountID,
        awsEbsVolumesSchema.dimensions.region,
        awsEbsVolumesSchema.dimensions.type,
        awsEbsVolumesSchema.dimensions.volumeID,
      ];

      const measures = [
        awsEbsVolumesSchema.measures.maxIopsVolumeBytes,
        awsEbsVolumesSchema.measures.maxThroughputVolumeBytes,
        awsEbsVolumesSchema.measures.maxVolumeSizeBytes,
        awsEbsVolumesSchema.measures.operationsCost,
        awsEbsVolumesSchema.measures.provisionedThroughputCost,
        awsEbsVolumesSchema.measures.snapshotCost,
        awsEbsVolumesSchema.measures.snapshotUsageBytes,
        awsEbsVolumesSchema.measures.storageCost,
        awsEbsVolumesSchema.measures.storageUsageBytes,
        awsEbsVolumesSchema.measures.totalCost,
        awsEbsVolumesSchema.measures.totalReadTime,
        awsEbsVolumesSchema.measures.totalWriteTime,
        awsEbsVolumesSchema.measures.volumeReadOps,
        awsEbsVolumesSchema.measures.volumeWriteOps,
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_EBS_VOLUMES,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const volumes = result.map((datum): EBStore => {
        const [error, validData] = validate(datum, EBStoreStruct);

        if (error) {
          throw new UError("INVALID_CLOUD_EBS_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          billPayerAccountId: validData.billPayerAccountId ?? "",
          family: validData.family ?? "",
          lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
          region: validData.region ?? "",
          type: validData.type ?? "",
          volumeId: validData.volumeId ?? "",

          // MEASURES
          maxIopsVolumeBytes: validData.maxIopsVolumeBytes ?? 0,
          maxThroughputVolumeBytes: validData.maxThroughputVolumeBytes ?? 0,
          maxVolumeSizeBytes: validData.maxVolumeSizeBytes ?? 0,
          operationsCost: validData.operationsCost ?? 0,
          snapshotCost: validData.snapshotCost ?? 0,
          snapshotUsageBytes: validData.snapshotUsageBytes ?? 0,
          storageCost: validData.storageCost ?? 0,
          storageUsageBytes: validData.storageUsageBytes ?? 0,
          totalCost: validData.totalCost ?? 0,
          totalReadTime: validData.totalReadTime ?? 0,
          totalWriteTime: validData.totalWriteTime ?? 0,
          volumeReadOps: validData.volumeReadOps ?? 0,
          volumeWriteOps: validData.volumeWriteOps ?? 0,
        };
      });

      return getGroups(volumes);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}

const groupedDimensions = ["billPayerAccountId", "region"] as const;

function getGroupID(datum: EBStore) {
  return groupedDimensions
    .map((dimension) => `${dimension}(${datum[dimension]})`)
    .join("-");
}

function getGroups(allVolumes: EBStore[]): EBStoreGroup[] {
  const volumesGroupedByGroupID = groupBy(allVolumes, getGroupID);

  const groupIDs = Object.keys(volumesGroupedByGroupID);

  const volumesGroups: EBStoreGroup[] = [];

  for (const groupID of groupIDs) {
    const volumes = volumesGroupedByGroupID[groupID];

    const groupVolumes = volumes.filter(
      (volumeGroup) =>
        volumeGroup.type.length !== 0 && volumeGroup.family.length !== 0
    );

    const groupSnapshots = volumes.filter(
      (volumeGroup) => !volumeGroup.type && !volumeGroup.family
    );

    const volumeGroup: EBStoreGroup = {
      // DIMENSIONS
      billPayerAccountId:
        volumes.length > 0 ? volumes[0].billPayerAccountId : "",
      region: volumes.length > 0 ? volumes[0].region : "",

      // MEASURES
      storageCost: 0,
      operationsCost: 0,
      snapshotCost: 0,
      snapshotUsageBytes: 0,
      storageUsageBytes: 0,
      totalCost: 0,

      // GROUP FIELDS
      groupID,
      snapshots: groupSnapshots,
      volumes: groupVolumes,
    };

    for (const volume of groupVolumes) {
      volumeGroup.storageCost += volume.storageCost;
      volumeGroup.operationsCost += volume.operationsCost;
      volumeGroup.storageUsageBytes += volume.storageUsageBytes;
      volumeGroup.totalCost += volume.totalCost;
    }

    for (const snapshot of groupSnapshots) {
      volumeGroup.snapshotCost += snapshot.snapshotCost;
      volumeGroup.snapshotUsageBytes += snapshot.snapshotUsageBytes;
      volumeGroup.totalCost += snapshot.totalCost;
    }

    volumesGroups.push(volumeGroup);
  }

  return volumesGroups;
}
