import useBuildDataQuery from "@/api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { azureSqlVisibilitySchema } from "@ternary/api-lib/analytics/schemas/azureSqlVisibility";
import UError from "unilib-error";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureDatabaseUsageDatum } from "../types";

export default function useGetAzureDatabaseUsageSummary(
  options?: UseQueryOptions<AzureDatabaseUsageDatum, UError>
): UseQueryResult<AzureDatabaseUsageDatum, UError> {
  const client = useAnalyticsApiClient();
  const now = new DateHelper();

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AZURE_SQL_VISIBILITY,
    dateRange: [now.firstOfMonth(), now.date],
    measures: [
      azureSqlVisibilitySchema.measures.memoryGB,
      azureSqlVisibilitySchema.measures.memoryBytesUsage,
    ],
  });

  return useQuery({
    queryKey: ["AzureDatabaseUsageSummary"],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const data = result.response.map((datum) => {
        return {
          memoryBytesUsage: datum.memoryBytesUsage ?? 0,
          memoryGB: datum.memoryGB ?? 0,
        } as AzureDatabaseUsageDatum;
      });

      return data[0];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
