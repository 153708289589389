import { UnitType } from "../enums";

import { ProviderType } from "../enums";

const measures = {
  cpuAllocationTime: {
    displayName: "CPU Allocation Time",
    providerType: ProviderType.GCP,
    schemaName: "cpuAllocationTime",
    unit: UnitType.STANDARD,
  },
  memoryAllocationTime: {
    displayName: "Memory Allocation Time",
    providerType: ProviderType.GCP,
    schemaName: "memoryAllocationTime",
    unit: UnitType.STANDARD,
  },
  peakInstanceCount: {
    displayName: "Peak Instance Count",
    providerType: ProviderType.GCP,
    schemaName: "peakInstanceCount",
    unit: UnitType.STANDARD,
  },
  requestCount: {
    displayName: "Request Count",
    providerType: ProviderType.GCP,
    schemaName: "requestCount",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  revisionName: {
    displayName: "Revision Name",
    providerType: ProviderType.GCP,
    schemaName: "revisionName",
  },
  serviceName: {
    displayName: "Service Name",
    providerType: ProviderType.GCP,
    schemaName: "serviceName",
  },
} as const;

export const gcpCloudRunServicesSchema = {
  dimensions,
  measures,
};
