import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { awsOpenSearchVisibilitySchema } from "@ternary/api-lib/analytics/schemas/awsOpenSearchVisibility";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";

type InstanceGroup = {
  blockStorageCost: number;
  dataTransferAwsInbound: number;
  dataTransferAwsOutbound: number;
  dataTransferCost: number;
  dataTransferInterRegionInbound: number;
  dataTransferInterRegionOutbound: number;
  domainId: string;
  instanceCost: number;
  region: string;
  usageAccountId: string;
};

type Props = {
  instanceGroups: InstanceGroup[];
  isLoading: boolean;
  onInteraction: (
    interaction: AWSOpenSearchInstanceGroupTable.Interaction
  ) => void;
};

const columnHelper = createColumnHelper<InstanceGroup>();

function AWSOpenSearchInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip
            content={copyText.awsOpenSearchInstanceGroupTableButtonTooltip}
          >
            <Button
              iconStart={<Icon icon={faTableList} />}
              primary
              size="tiny"
              onClick={() =>
                props.onInteraction({
                  type: AWSOpenSearchInstanceGroupTable.INTERACTION_INSTANCE_CLICKED,
                  instanceGroupID: row.original.domainId,
                })
              }
            />
          </Tooltip>
        ),
        size: 50,
      }),
      columnHelper.accessor("domainId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey:
                  awsOpenSearchVisibilitySchema.dimensions.domainID.schemaName,
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_domainId,
        sortDescFirst: true,
        size: 200,
      }),
      columnHelper.accessor("usageAccountId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey:
                  awsOpenSearchVisibilitySchema.dimensions.usageAccountID
                    .schemaName,
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsOpenSearchInstanceGroupTableHeader_usageAccountId}
          </Tooltip>
        ),
        meta: { align: "left" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey:
                  awsOpenSearchVisibilitySchema.dimensions.region.schemaName,
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_region,
        meta: { align: "left" },
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor("instanceCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_instanceCost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("blockStorageCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_blockStorageCost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("dataTransferCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_dataTransferCost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("dataTransferAwsInbound", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_awsInbound,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("dataTransferAwsOutbound", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsOpenSearchInstanceGroupTableHeader_awsOutbound,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("dataTransferInterRegionInbound", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header:
          copyText.awsOpenSearchInstanceGroupTableHeader_interRegionInbound,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("dataTransferInterRegionOutbound", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header:
          copyText.awsOpenSearchInstanceGroupTableHeader_interRegionOutbound,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        data={props.instanceGroups}
        isLoading={props.isLoading}
        showPagination
        sortable
      />
    </Box>
  );
}

AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

AWSOpenSearchInstanceGroupTable.INTERACTION_INSTANCE_CLICKED =
  `AWSOpenSearchInstanceGroupTable.INTERACTION_INSTANCE_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterValue: string;
  filterKey: string;
}

interface InteractionInstanceClicked {
  type: typeof AWSOpenSearchInstanceGroupTable.INTERACTION_INSTANCE_CLICKED;
  instanceGroupID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSOpenSearchInstanceGroupTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionInstanceClicked;
}
export default AWSOpenSearchInstanceGroupTable;
