import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  maxThroughputVolumeBytes: {
    displayName: "Max Throughput Volume Bytes",
    providerType: ProviderType.AWS,
    schemaName: "maxThroughputVolumeBytes",
    unit: UnitType.BYTES,
  },
  snapshotUsageBytes: {
    displayName: "Snapshot Usage Bytes",
    providerType: ProviderType.AWS,
    schemaName: "snapshotUsageBytes",
    unit: UnitType.BYTES,
  },
  storageUsageBytes: {
    displayName: "Storage Usage Bytes",
    providerType: ProviderType.AWS,
    schemaName: "storageUsageBytes",
    unit: UnitType.BYTES,
  },
  volumeReadBytes: {
    displayName: "Volume Read Bytes",
    providerType: ProviderType.AWS,
    schemaName: "volumeReadBytes",
    unit: UnitType.BYTES,
  },
  volumeWriteBytes: {
    displayName: "Volume Write Bytes",
    providerType: ProviderType.AWS,
    schemaName: "volumeWriteBytes",
    unit: UnitType.BYTES,
  },
  volumeReadOps: {
    displayName: "Volume Read Ops",
    providerType: ProviderType.AWS,
    schemaName: "volumeReadOps",
    unit: UnitType.STANDARD,
  },
  volumeWriteOps: {
    displayName: "Volume Write Ops",
    providerType: ProviderType.AWS,
    schemaName: "volumeWriteOps",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  billPayerAccountID: {
    displayName: "Bill Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "billPayerAccountId",
  },
  lineItemUsageAccountID: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  storageMedia: {
    displayName: "Storage Media",
    providerType: ProviderType.AWS,
    schemaName: "storageMedia",
  },
};

export const awsEbsVisibilitySchema = { measures, dimensions };
