import { useQuery } from "@tanstack/react-query";
import { AnalyticsSchema } from "@ternary/api-lib/analytics/api/AnalyticsApiClient";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../constants";
import { useAnalyticsApiClient } from "../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";

type Options = UseQueryOptions<AnalyticsSchema[], UError>;
type Result = UseQueryResult<AnalyticsSchema[], UError>;

export function useGetAnalyticsApiMetadata(
  tenantID: string,
  options?: Options
): Result {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["analyticsMetadata", tenantID],
    queryFn: () => client.getMetadata(tenantID),
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
