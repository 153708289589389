import { ProviderType, UnitType } from "../enums";

const measures = {
  jobCount: {
    displayName: "Job Count",
    providerType: ProviderType.GCP,
    schemaName: "jobCount",
    unit: UnitType.STANDARD,
  },
  potentialSlotS: {
    displayName: "Potential Slot Seconds",
    providerType: ProviderType.GCP,
    schemaName: "potentialSlotS",
    unit: UnitType.STANDARD,
  },
  usedSlotS: {
    displayName: "Used Slot Seconds",
    providerType: ProviderType.GCP,
    schemaName: "usedSlotS",
    unit: UnitType.STANDARD,
  },
  utilization: {
    displayName: "Utilization",
    providerType: ProviderType.GCP,
    schemaName: "utilization",
    unit: UnitType.PERCENTAGE,
  },
};

const dimensions = {
  adminProjectId: {
    displayName: "Admin Project ID",
    providerType: ProviderType.GCP,
    schemaName: "adminProjectId",
  },
  datasetID: {
    displayName: "Dataset ID",
    providerType: ProviderType.GCP,
    schemaName: "datasetId",
  },
  edition: {
    displayName: "Edition",
    providerType: ProviderType.GCP,
    schemaName: "edition",
  },
  jobProjectID: {
    displayName: "Job Project ID",
    providerType: ProviderType.GCP,
    schemaName: "jobProjectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  reservationName: {
    displayName: "Reservation Name",
    providerType: ProviderType.GCP,
    schemaName: "reservationName",
  },
} as const;

export const gcpBigQueryReservationUsageSchema = {
  dimensions,
  measures,
};
