import { ProviderType, UnitType } from "../enums";

const measures = {
  avgCpuUtilization: {
    displayName: "Average CPU Utilization",
    providerType: ProviderType.GCP,
    schemaName: "avgCpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  backupUsedB: {
    displayName: "Backup Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "backupUsedB",
    unit: UnitType.BYTES,
  },
  maxCpuUtilization: {
    displayName: "Maximum CPU Utilization",
    providerType: ProviderType.GCP,
    schemaName: "maxCpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  processingUnits: {
    displayName: "Processing Units",
    providerType: ProviderType.GCP,
    schemaName: "processingUnits",
    unit: UnitType.STANDARD,
  },
  storageLimitB: {
    displayName: "Storage Limit Bytes",
    providerType: ProviderType.GCP,
    schemaName: "storageLimitB",
    unit: UnitType.BYTES,
  },
  storageUsedB: {
    displayName: "Storage Used Bytes",
    providerType: ProviderType.GCP,
    schemaName: "storageUsedB",
    unit: UnitType.BYTES,
  },
  totalBackupGibHour: {
    displayName: "Total Backup GiB Hours",
    providerType: ProviderType.GCP,
    schemaName: "totalBackupGibHour",
    unit: UnitType.STANDARD,
  },
  totalComputeHours: {
    displayName: "Total Compute Hours",
    providerType: ProviderType.GCP,
    schemaName: "totalComputeHours",
    unit: UnitType.STANDARD,
  },
  totalDataBoostComputeCost: {
    displayName: "Total Data Boost Compute Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalDataBoostComputeCost",
    unit: UnitType.CURRENCY,
  },
  totalEgressGib: {
    displayName: "Total Egress GiB",
    providerType: ProviderType.GCP,
    schemaName: "totalEgressGib",
    unit: UnitType.BINARY_BYTES,
  },
  totalStorageGibHour: {
    displayName: "Total Storage GiB Hours",
    providerType: ProviderType.GCP,
    schemaName: "totalStorageGibHour",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  instanceConfig: {
    displayName: "Instance Config",
    providerType: ProviderType.GCP,
    schemaName: "instanceConfig",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.GCP,
    schemaName: "instanceId",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
} as const;

export const gcpCloudSpannerUsageSchema = { dimensions, measures };
