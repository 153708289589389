import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpBigQueryCommitmentInventorySchema } from "@ternary/api-lib/analytics/schemas/gcpBigQueryCommitmentInventory";
import { gcpComputeCudInventorySchema } from "@ternary/api-lib/analytics/schemas/gcpComputeCudInventory";
import { BinaryFilter, UnaryFilter } from "@ternary/api-lib/analytics/types";
import { GcpCommitmentServiceType } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  CudBigQueryInventoryCommitmentDatum,
  CudInventoryCommitmentDatum,
  CudResourceInventoryCommitmentDatum,
  CudSpendInventoryCommitmentDatum,
} from "../types";

export interface Params {
  queryFilters?: (UnaryFilter | BinaryFilter)[];
}

export default function useGetCudInventoryCommitmentData(
  params: Params,
  options?: UseQueryOptions<CudInventoryCommitmentDatum[], UError>
): UseQueryResult<CudInventoryCommitmentDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudInventoryCommitmentData", params],
    queryFn: async () => {
      const now = new DateHelper();

      const spendCommitmentFilters = params.queryFilters?.filter((filter) => {
        return (
          filter.schemaName !==
          gcpComputeCudInventorySchema.dimensions.family.schemaName
        );
      });

      const resourceCommitmentFilters = params.queryFilters?.filter(
        (filter) => {
          return (
            filter.schemaName !==
            gcpComputeCudInventorySchema.dimensions.service.schemaName
          );
        }
      );

      const includeBigQuery = params.queryFilters?.find((filter) => {
        return (
          filter.schemaName ===
            gcpComputeCudInventorySchema.dimensions.service.schemaName &&
          (filter.values?.includes(
            GcpCommitmentServiceType.BIGQUERY_ENTERPRISE_PLUS
          ) ||
            filter.values?.includes(
              GcpCommitmentServiceType.BIGQUERY_ENTERPRISE
            ))
        );
      });

      const spendResult = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_COMPUTE_CUD_SPEND_INVENTORY,
          dimensions: [
            gcpComputeCudInventorySchema.dimensions.billingAccountID,
            gcpComputeCudInventorySchema.dimensions.plan,
            gcpComputeCudInventorySchema.dimensions.service,
          ],
          measures: [
            gcpComputeCudInventorySchema.measures.hourlyCommittedAmount,
          ],
          queryFilters: spendCommitmentFilters,
        })
      )) as CudSpendInventoryCommitmentDatum[];

      const spendData: CudInventoryCommitmentDatum[] = spendResult.map(
        (datum) => {
          return {
            commitmentEndTimestamp: null,
            commitmentId: null,
            commitmentLength: datum.plan,
            family: null,
            hourlyCommittedAmount: datum.hourlyCommittedAmount,
            owner: datum.billingAccountId,
            region: datum.region,
            reservedMemoryGiB: null,
            reservedVCPU: null,
            reservedSlots: null,
            service: datum.service,
            status: null,
          };
        }
      );

      const resourceResult = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(37), now.date],
          dataSource: DataSource.GCP_COMPUTE_CUD_INVENTORY,
          dimensions: [
            gcpComputeCudInventorySchema.dimensions.commitmentEndTimestamp,
            gcpComputeCudInventorySchema.dimensions.commitmentId,
            gcpComputeCudInventorySchema.dimensions.commitmentPlan,
            gcpComputeCudInventorySchema.dimensions.commitmentStatus,
            gcpComputeCudInventorySchema.dimensions.family,
            gcpComputeCudInventorySchema.dimensions.projectID,
            gcpComputeCudInventorySchema.dimensions.region,
          ],
          measures: [
            gcpComputeCudInventorySchema.measures.resourceAmountRAM,
            gcpComputeCudInventorySchema.measures.resourceAmountVCPU,
          ],
          queryFilters: resourceCommitmentFilters,
        })
      )) as CudResourceInventoryCommitmentDatum[];

      const resourceData: CudInventoryCommitmentDatum[] = resourceResult.map(
        (datum) => {
          return {
            commitmentEndTimestamp: datum.commitmentEndTimestamp,
            commitmentId: datum.commitmentId,
            commitmentLength: datum.commitmentPlan,
            family: datum.family,
            hourlyCommittedAmount: null,
            owner: datum.projectId,
            region: datum.region,
            reservedMemoryGiB: datum.resourceAmountRAM,
            reservedVCPU: datum.resourceAmountVCPU,
            reservedSlots: null,
            service: GcpCommitmentServiceType.COMPUTE_ENGINE, // All resource-based CUDs are compute engine. Dimension is not in big query.
            status: datum.commitmentStatus,
          };
        }
      );

      if (includeBigQuery) {
        const bigqueryCommitmentResult = (await client.load(
          buildCubeQuery({
            dateRange: [now.nMonthsAgo(36), now.date],
            dataSource: DataSource.GCP_BIGQUERY_COMMITMENT_INVENTORY,
            dimensions: [
              gcpBigQueryCommitmentInventorySchema.dimensions.commitmentLength,
              gcpBigQueryCommitmentInventorySchema.dimensions.commitmentEndTime,
              gcpBigQueryCommitmentInventorySchema.dimensions
                .capacityCommitmentId,
              gcpBigQueryCommitmentInventorySchema.dimensions.state,
              gcpBigQueryCommitmentInventorySchema.dimensions.adminProjectId,
              gcpBigQueryCommitmentInventorySchema.dimensions.region,
              gcpBigQueryCommitmentInventorySchema.dimensions.edition,
            ],
            measures: [gcpBigQueryCommitmentInventorySchema.measures.slotCount],
          })
        )) as CudBigQueryInventoryCommitmentDatum[];

        const bigqueryCommitmentData: CudInventoryCommitmentDatum[] =
          bigqueryCommitmentResult.map((datum) => {
            return {
              commitmentEndTimestamp: datum.commitmentEndTime,
              commitmentId: datum.capacityCommitmentId,
              commitmentLength: datum.commitmentLength,
              family: null,
              hourlyCommittedAmount: null,
              owner: datum.adminProjectId,
              region: datum.region,
              reservedMemoryGiB: null,
              reservedVCPU: null,
              reservedSlots: datum.slotCount,
              service: datum.edition.includes("PLUS")
                ? GcpCommitmentServiceType.BIGQUERY_ENTERPRISE_PLUS
                : GcpCommitmentServiceType.BIGQUERY_ENTERPRISE,
              status: datum.state,
            };
          });
        return [...spendData, ...resourceData, ...bigqueryCommitmentData];
      }

      return [...spendData, ...resourceData];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
