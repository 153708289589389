import { ProviderType } from "../enums";

import { UnitType } from "../enums";

const measures = {
  slotCount: {
    displayName: "Slot Count",
    providerType: ProviderType.GCP,
    schemaName: "slotCount",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  adminProjectId: {
    displayName: "Admin Project ID",
    providerType: ProviderType.GCP,
    schemaName: "adminProjectId",
  },
  commitmentLength: {
    displayName: "Commitment Length",
    providerType: ProviderType.GCP,
    schemaName: "commitmentLength",
  },
  commitmentEndTime: {
    displayName: "Commitment End Time",
    providerType: ProviderType.GCP,
    schemaName: "commitmentEndTime",
  },
  capacityCommitmentId: {
    displayName: "Capacity Commitment ID",
    providerType: ProviderType.GCP,
    schemaName: "capacityCommitmentId",
  },
  edition: {
    displayName: "Edition",
    providerType: ProviderType.GCP,
    schemaName: "edition",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  state: {
    displayName: "State",
    providerType: ProviderType.GCP,
    schemaName: "state",
  },
};

export const gcpBigQueryCommitmentInventorySchema = {
  dimensions,
  measures,
};
