import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { gcpCommittedUseSchema } from "@ternary/api-lib/analytics/schemas/gcpCommittedUse";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

type RegionDatum = {
  region: string;
  resourceCost: number;
};

type Region = {
  cost: number;
  name: string;
};

interface Params {
  cloudID: string;
}

export default function useGetCUDRegions(
  params: Params,
  options?: UseQueryOptions<Region[], UError>
): UseQueryResult<Region[], UError> {
  const client = useAnalyticsApiClient();

  const dateHelper = new DateHelper();
  const startDate = dateHelper.firstOfLastMonth();
  const endDate = dateHelper.lastDayLastMonth();

  return useQuery({
    queryKey: ["regions", params],
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.COMMITTED_USE,
          dateRange: [startDate, endDate],
          dimensions: [gcpCommittedUseSchema.dimensions.region],
          queryFilters: [
            {
              schemaName: gcpCommittedUseSchema.dimensions.cloudID.schemaName,
              operator: Operator.EQUALS,
              values: [params.cloudID],
            },
            {
              schemaName: gcpCommittedUseSchema.dimensions.family.schemaName,
              operator: Operator.NOT_EQUALS,
              values: ["Not Commitment Eligible"],
            },
          ],
          measures: [gcpCommittedUseSchema.measures.resourceCost],
        })
      )) as RegionDatum[];

      return result.map((datum) => {
        return {
          cost: datum.resourceCost ?? 0,
          name: datum.region,
        };
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
