import { ProviderType, UnitType } from "../enums";

const measures = {
  dailyCommitmentCost: {
    displayName: "Daily Commitment Cost",
    providerType: ProviderType.AWS,
    schemaName: "dailyCommitmentCost",
    unit: UnitType.CURRENCY,
  },
  onDemandCost: {
    displayName: "On-Demand Cost",
    providerType: ProviderType.AWS,
    schemaName: "onDemandCost",
    unit: UnitType.CURRENCY,
  },
  onDemandNormalizedUsageHours: {
    displayName: "On-Demand Normalized Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "onDemandNormalizedUsageHours",
    unit: UnitType.STANDARD,
  },
  onDemandUsageHours: {
    displayName: "On-Demand Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "onDemandUsageHours",
    unit: UnitType.STANDARD,
  },
  riCoveragePercentage: {
    displayName: "RI Coverage Percentage",
    providerType: ProviderType.AWS,
    schemaName: "riCoveragePercentage",
    unit: UnitType.PERCENTAGE,
  },
  riCoveredNormalizedUsageHours: {
    displayName: "RI Covered Normalized Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "riCoveredNormalizedUsageHours",
    unit: UnitType.STANDARD,
  },
  riCoveredUsageCost: {
    displayName: "RI Covered Usage Cost",
    providerType: ProviderType.AWS,
    schemaName: "riCoveredUsageCost",
    unit: UnitType.CURRENCY,
  },
  riCoveredUsageHours: {
    displayName: "RI Covered Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "riCoveredUsageHours",
    unit: UnitType.STANDARD,
  },
  riRealizedSavings: {
    displayName: "RI Realized Savings",
    providerType: ProviderType.AWS,
    schemaName: "riRealizedSavings",
    unit: UnitType.CURRENCY,
  },
  riUnusedCommitmentCost: {
    displayName: "RI Unused Commitment Cost",
    providerType: ProviderType.AWS,
    schemaName: "riUnusedCommitmentCost",
    unit: UnitType.CURRENCY,
  },
  riUnusedHours: {
    displayName: "RI Unused Hours",
    providerType: ProviderType.AWS,
    schemaName: "riUnusedHours",
    unit: UnitType.STANDARD,
  },
  spCoveredUsageCost: {
    displayName: "SP Covered Usage Cost",
    providerType: ProviderType.AWS,
    schemaName: "spCoveredUsageCost",
    unit: UnitType.CURRENCY,
  },
  spCoveragePercentage: {
    displayName: "SP Coverage Percentage",
    providerType: ProviderType.AWS,
    schemaName: "spCoveragePercentage",
    unit: UnitType.PERCENTAGE,
  },
  spCoveredNormalizedUsageHours: {
    displayName: "SP Covered Normalized Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "spCoveredNormalizedUsageHours",
    unit: UnitType.STANDARD,
  },
  spRealizedSavings: {
    displayName: "SP Realized Savings",
    providerType: ProviderType.AWS,
    schemaName: "spRealizedSavings",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.AWS,
    schemaName: "cloudId",
  },
  linkedAccountID: {
    displayName: "Linked Account ID",
    providerType: ProviderType.AWS,
    schemaName: "linkedAccountID",
  },
  reservedInstanceARN: {
    displayName: "Reserved Instance ARN",
    providerType: ProviderType.AWS,
    schemaName: "reservedInstanceARN",
  },
  savingsPlanARN: {
    displayName: "Savings Plan ARN",
    providerType: ProviderType.AWS,
    schemaName: "savingsPlanARN",
  },
} as const;

export const awsCommitmentChartSchema = { dimensions, measures };
