import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { uniqBy } from "lodash";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export type SubaccountDatum = {
  cloudId: string;
  cloudName: string;
  projectId: string;
  projectName: string;
};

export type MspSubaccountEntity = {
  [key: string]: {
    cloudName: string;
    projectId: string;
    projectName: string;
  }[];
};

type Params = {
  dateRange: Date[];
  parentTenantID?: string;
  preAggFilters?: QueryFilter[];
};

export default function useGetMspSubaccounts(
  params: Params,
  options?: UseQueryOptions<MspSubaccountEntity[], UError>
): UseQueryResult<MspSubaccountEntity[], UError> {
  const client = useAnalyticsApiClient();

  const { parentTenantID, ...restParams } = params;

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.BILLING,
    dimensions: [
      billingCucSchema.dimensions.cloudName,
      billingCucSchema.dimensions.cloudID,
      billingCucSchema.dimensions.projectID,
      billingCucSchema.dimensions.projectName,
    ],
    ...restParams,
  });

  return useQuery({
    queryKey: ["mspSubaccount", params],
    queryFn: async () => {
      const result = await client.loadData<SubaccountDatum>(
        parentTenantID ?? tenantID,
        query
      );
      return result.response.reduce((accum: MspSubaccountEntity[], datum) => {
        if (!datum.cloudId) return accum;
        if (accum[datum.cloudId]) {
          accum[datum.cloudId] = uniqBy(
            [
              ...accum[datum.cloudId],
              {
                cloudName: datum.cloudName,
                projectId: datum.projectId,
                projectName: datum.projectName,
              },
            ],
            "projectId"
          );
        } else {
          accum[datum.cloudId] = [
            {
              cloudName: datum.cloudName,
              projectId: datum.projectId,
              projectName: datum.projectName,
            },
          ];
        }
        return accum;
      }, []);
    },
    ...options,
  });
}
