import { ProviderType, UnitType } from "../enums";

const measures = {
  averageNumServices: {
    displayName: "Average Number of Services",
    providerType: ProviderType.GCP,
    schemaName: "averageNumServices",
    unit: UnitType.STANDARD,
  },
  averageMemory: {
    displayName: "Average Memory",
    providerType: ProviderType.GCP,
    schemaName: "averageMemory",
    unit: UnitType.STANDARD,
  },
  averageVCPU: {
    displayName: "Average vCPU",
    providerType: ProviderType.GCP,
    schemaName: "averageVCPU",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  totalMemoryCost: {
    displayName: "Total Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryCost",
    unit: UnitType.CURRENCY,
  },
  totalMemoryDays: {
    displayName: "Total Memory Days",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryDays",
    unit: UnitType.STANDARD,
  },
  totalVcpuCost: {
    displayName: "Total vCPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalVCPUCost",
    unit: UnitType.CURRENCY,
  },
  totalVcpuDays: {
    displayName: "Total vCPU Days",
    providerType: ProviderType.GCP,
    schemaName: "totalVCPUDays",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  lowCarbon: {
    displayName: "Low Carbon",
    providerType: ProviderType.GCP,
    schemaName: "lowCarbon",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  regionalPriceTier: {
    displayName: "Regional Price Tier",
    providerType: ProviderType.GCP,
    schemaName: "regionalPriceTier",
  },
} as const;

export const gcpCloudRunSchema = {
  dimensions,
  measures,
};
