import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { awsDatabaseElastiCacheSchema } from "@ternary/api-lib/analytics/schemas/awsDatabaseElasticache";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import Chip from "../../../../ui-lib/components/Chip";
import copyText from "../../copyText";
import { AWSDatabaseElastiCacheInstanceGroupFilters } from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  instanceGroupFilters: AWSDatabaseElastiCacheInstanceGroupFilters;
  searchText: string;
  onInteraction: (
    interaction: AWSDatabaseElastiCacheGroupTableControls.Interaction
  ) => void;
};

function AWSDatabaseElastiCacheGroupTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {(
          [
            awsDatabaseElastiCacheSchema.dimensions.clusterID.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.payerAccountId.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.usageAccountId.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.instanceType.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.cacheEngine.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.instanceMemory.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.instanceVcpu.schemaName,
            awsDatabaseElastiCacheSchema.dimensions.region.schemaName,
          ] as const
        ).map((instanceGroupFilterKey) => {
          let filterValue = props.instanceGroupFilters[instanceGroupFilterKey];

          if (filterValue === null) return;

          if (filterValue === "") {
            filterValue = "null";
          }

          const copyTextKey: keyof typeof copyText = `awsDatabaseElastiCacheTableHeader_${instanceGroupFilterKey}`;

          return (
            <Chip
              key={instanceGroupFilterKey}
              label={copyText[copyTextKey]}
              text={filterValue}
              onClick={() =>
                props.onInteraction({
                  type: AWSDatabaseElastiCacheGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                  filterKey: instanceGroupFilterKey,
                })
              }
            />
          );
        })}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AWSDatabaseElastiCacheGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`aws-elasticache-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

AWSDatabaseElastiCacheGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AWSDatabaseElastiCacheGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AWSDatabaseElastiCacheGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AWSDatabaseElastiCacheGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof AWSDatabaseElastiCacheGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseElastiCacheInstanceGroupFilters;
}

interface InteractionSearchTextUpdated {
  type: typeof AWSDatabaseElastiCacheGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseElastiCacheGroupTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AWSDatabaseElastiCacheGroupTableControls;
