import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { DateHelper } from "../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type Vendor = { vendor: string };

export default function useGetBillingVendors(
  options?: UseQueryOptions<Vendor[], UError>
): UseQueryResult<Vendor[], UError> {
  const client = useAnalyticsApiClient();

  const datehelper = new DateHelper();

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.BILLING,
    dateRange: [datehelper.nDaysAgo(365), datehelper.date],
    dimensions: [billingCucSchema.dimensions.vendor],
  });

  return useQuery({
    queryKey: ["vendors", tenantID],
    queryFn: async () => {
      const result = await client.loadData<Vendor>(tenantID, query);

      return result.response.filter((entry) => !!entry.vendor);
    },
    ...options,
  });
}
