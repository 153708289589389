import {
  CloudProviderType,
  GcpCommitmentDurationType,
  GcpCommitmentRecommendationTierType,
  GcpCommitmentScope,
  GcpCommitmentServiceType,
  GcpTargetCommitmentType,
  RecommendationCategory,
  ServiceType,
} from "@ternary/api-lib/constants/enums";

export type CudInventoryCommitmentDatum = {
  commitmentEndTimestamp: string | null;
  commitmentId: string | null;
  commitmentLength: GcpCommitmentDurationType;
  family: string | null;
  hourlyCommittedAmount: number | null;
  owner: string | null;
  region: string | null;
  reservedMemoryGiB: number | null;
  reservedVCPU: number | null;
  reservedSlots: number | null;
  service: GcpCommitmentServiceType;
  status: GcpCommitmentStatusType | null;
};

export type CudResourceInventoryCommitmentDatum = {
  commitmentEndTimestamp: string;
  commitmentId: string;
  commitmentPlan: GcpCommitmentDurationType;
  commitmentStatus: GcpCommitmentStatusType;
  family: string;
  projectId: string;
  resourceAmountRAM: number;
  resourceAmountVCPU: number;
  region: string;
};

export type CudSpendInventoryCommitmentDatum = {
  billingAccountId: string;
  hourlyCommittedAmount: number;
  plan: GcpCommitmentDurationType;
  region: string;
  service: GcpCommitmentServiceType;
};

export type CudBigQueryInventoryCommitmentDatum = {
  commitmentLength: GcpCommitmentDurationType;
  commitmentEndTime: string;
  capacityCommitmentId: string;
  commitmentPlan: string;
  state: GcpCommitmentStatusType;
  adminProjectId: string;
  region: string;
  edition: string;
  slotCount: number;
};

export type CudInventoryInstanceDatum = {
  commitmentEndTimestamp: string | null;
  family: string | null;
  region: string | null;
  service: GcpCommitmentServiceType;
  status: GcpCommitmentStatusType | null;
};

export type CudInventoryEntity = {
  amountSaved: number;
  instanceFamily: string | null;
  hourlyCommittedAmount: number | null;
  region: string | null;
  reservedMemoryGiB: number | null;
  reservedSlots: number | null;
  reservedVCPU: number | null;
  service: GcpCommitmentServiceType | null;
  utilizationPercent: number;
};

export type CudInventoryDatum = {
  amountSaved: number;
  hourlyCommittedAmount: number | null;
  instanceFamily: string | null;
  region: string | null;
  reservedMemoryGiB: number | null;
  reservedSlots: number | null;
  reservedVCPU: number | null;
  service: GcpCommitmentServiceType | null;
  utilizationPercent: number;
};

export type CudRecommendationTableDatum = CudRecommendation & {
  contraryInsightRecommendation?: InsightRecommendation;
};
export interface CudUtilizationPercentEntity {
  utilizationPercent: number | null;
}

export interface CudCpuUsageEntity {
  resourceUsageAmountCPU: number | null;
  timestamp: string;
}

export interface CUDMemoryUsageEntity {
  resourceUsageAmountRAM: number | null;
  timestamp: string;
}

export interface CUDInventoryEntity {
  commitmentId: string;
  commitmentEndTimestamp: string;
  commitmentPlan: GcpCommitmentDurationType;
  commitmentStartTimestamp: string;
  commitmentStatus: GcpCommitmentStatusType;
  projectId: string;
  resourceAmountVCPU: number;
  resourceAmountRAM: number;
}
export interface CUDInventoryTotalsEntity {
  cpu: number;
  memory: number;
}

export type CudRecommendationFilters = {
  billingAccountID: string | null;
  projectID: string | null;
  region: string | null;
  serviceType: string | null;
  targetCommitmentInstanceFamily: string | null;
};

export interface CudUtilizationEntity {
  cpuCoverage: number | null;
  cpuUtilization: number | null;
  family: string;
  nearestExpirationDate: string | null;
  ramCoverage: number | null;
  ramUtilization: number | null;
  region: string;
  reservedCPUs: number;
  reservedRAM: number;
  savings: number;
  timestamp: string;
  totalCommits: number;
}

export type CudRecommendation = {
  id: string;
  billingAccountID: string;
  commitmentUtilization: number | null;
  coverageSetting: number;
  estimatedCurrency: string;
  estimatedMonthlySavings: number;
  lastRefreshTime: string;
  monthlySpend: number;
  scope: GcpCommitmentScope;
  serviceType: GcpCommitmentServiceType;
  projectID: string;
  region: string;
  targetCommitmentInstanceFamily: string | null;
  targetCommitmentType: GcpTargetCommitmentType;
  targetCommitmentValue: number;
  term: GcpCommitmentDurationType;
  tier: GcpCommitmentRecommendationTierType | null;
};

export type InsightRecommendation = {
  id: string;
  cloudID: string;
  applicable: boolean;
  category: RecommendationCategory;
  cloudProviderType: CloudProviderType;
  createdAt: string;
  details: { key: string; value: string }[];
  estimateCurrency: string;
  estimateValue: number;
  kind: string;
  labels: { key: string; value: string }[];
  number: string;
  projectID: string;
  resource: string;
  serviceType: ServiceType;
  snoozeUntil: string | null;
  state: string;
  typeID: string;
};

export type CustomPricingEntity = {
  oneYearDiscount: number | null;
  threeYearDiscount: number | null;
};

export type CudOnDemandCostEntity = {
  billingAccountId: string;
  potentialCreditedAmount: number;
  service: string;
  timestamp: string;
};

export type GCPBillingEntity = {
  billingAccountId: string;
  cost: number;
  minDailyCost: number;
  serviceDescription: string;
  timestamp: string;
};

export type CudOnDemandChartDatum = {
  billingData: GCPBillingEntity[];
  cudInventoryData: CudOnDemandCostEntity[];
};

// Chart Types

export type ChartDatum = {
  cud1YearInventory: number;
  cud3YearInventory: number;
  resourceUsageAmountRAM?: number | null;
  resourceUsageAmountVCPU?: number | null;
  timestamp: string;
};

export type CUDInventoryDatum = {
  commitmentEndTimestamp: string;
  commitmentPlan: GcpCommitmentDurationType;
  commitmentStartTimestamp: string;
  commitmentStatus: GcpCommitmentStatusType;
  resourceAmountRAM: number;
  resourceAmountVCPU: number;
};

export type UsageDatum = {
  resourceUsageAmountRAM?: number | null;
  resourceUsageAmountCPU?: number | null;
  timestamp: string;
};

// Enums

export const GcpCommitmentStatusType = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  NOT_YET_ACTIVE: "NOT_YET_ACTIVE",
} as const;

export type GcpCommitmentStatusType =
  (typeof GcpCommitmentStatusType)[keyof typeof GcpCommitmentStatusType];

export type CudInventoryFilters = {
  instanceFamily: string | null;
  region: string | null;
  service: string | null;
};
