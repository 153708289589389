import useBuildDataQuery from "@/api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { azureSqlVisibilitySchema } from "@ternary/api-lib/analytics/schemas/azureSqlVisibility";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureDatabaseInstanceGroupEntity } from "../types";

export interface Params {
  dateRange: Date[];
}

export interface Params {
  dateRange: Date[];
}

export default function useGetAzureDatabaseInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AzureDatabaseInstanceGroupEntity[], UError>
): UseQueryResult<AzureDatabaseInstanceGroupEntity[], UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_SQL_VISIBILITY,

    dimensions: [
      azureSqlVisibilitySchema.dimensions.projectID,
      azureSqlVisibilitySchema.dimensions.location,
      azureSqlVisibilitySchema.dimensions.databaseEngine,
      azureSqlVisibilitySchema.dimensions.backupRetentionPeriodDays,
    ],
    measures: [
      azureSqlVisibilitySchema.measures.cost,
      azureSqlVisibilitySchema.measures.absoluteCredits,
      azureSqlVisibilitySchema.measures.usageAmount,
      azureSqlVisibilitySchema.measures.cpuPercentMin,
      azureSqlVisibilitySchema.measures.cpuPercentMax,
      azureSqlVisibilitySchema.measures.cpuPercentAvg,
      azureSqlVisibilitySchema.measures.memoryPercentMin,
      azureSqlVisibilitySchema.measures.memoryPercentMax,
      azureSqlVisibilitySchema.measures.memoryPercentAvg,
      azureSqlVisibilitySchema.measures.cores,
      azureSqlVisibilitySchema.measures.coreUsage,
      azureSqlVisibilitySchema.measures.memoryBytesUsage,
      azureSqlVisibilitySchema.measures.memoryGB,
      azureSqlVisibilitySchema.measures.storageCapacityGB,
      azureSqlVisibilitySchema.measures.connections,
      azureSqlVisibilitySchema.measures.storagePercent,
      azureSqlVisibilitySchema.measures.resourceCount,
    ],
  });

  return useQuery({
    queryKey: ["azureDatabaseInstanceGroups", params],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const instanceGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          projectId: datum.projectId ?? "",
          backupRetentionPeriodDays: datum.backupRetentionPeriodDays ?? 0,
          databaseEngine: datum.databaseEngine ?? "",
          location: datum.location ?? "",

          // MEASURES
          absoluteCredits: datum.absoluteCredits ?? 0,
          connections: datum.connections ?? 0,
          cores: datum.cores ?? 0,
          coreUsage: datum.coreUsage ?? 0,
          cost: datum.cost ?? 0,
          cpuPercentAvg: datum.cpuPercentAvg ?? 0,
          cpuPercentMax: datum.cpuPercentMax ?? 0,
          cpuPercentMin: datum.cpuPercentMin ?? 0,
          memoryBytesUsage: datum.memoryBytesUsage ?? 0,
          memoryGB: datum.memoryGB ?? 0,
          memoryPercentAvg: datum.memoryPercentAvg ?? 0,
          memoryPercentMax: datum.memoryPercentMax ?? 0,
          memoryPercentMin: datum.memoryPercentMin ?? 0,
          resourceCount: datum.resourceCount ?? 0,
          storageCapacityGB: datum.storageCapacityGB ?? 0,
          storagePercent: datum.storagePercent ?? 0,
          usageAmount: datum.usageAmount ?? 0,
        } as AzureDatabaseInstanceGroupEntity;
      });
      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
