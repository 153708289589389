import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import {
  DataSource,
  DurationType,
  Operator,
} from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { getFiscalLastMTDDateRange } from "@ternary/api-lib/analytics/utils/FiscalDateUtils";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import useGatekeeper from "../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import { getLastInvoiceMonth } from "../../utils/dates";
import { FiscalPeriodMap } from "../core/types";
import { SpendSummaryEntity } from "./types";
import { CubeQueryBuilderProps, buildCubeQuery } from "./utils";

const costMeasure = billingCucSchema.measures.cost;

const creditsMeasure = billingCucSchema.measures.credits;

type SpendSummary = {
  cost: number | null;
  credits: number | null;
};

export interface Params {
  dataSource: DataSource;
  dimensions?: Dimension[];
  excludeCredits?: boolean;
  fiscalPeriodMap?: FiscalPeriodMap | null;
  isFiscalMode?: boolean;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [SpendSummaryEntity, UError],
  [SpendSummaryEntity, UError],
  [SpendSummaryEntity, UError],
];

export default function useGetBillingSpendSummaries(
  params: Params,
  options?: UseQueryOptions<SpendSummaryEntity, UError>
): UseQueryResult<SpendSummaryEntity, UError>[] {
  const dateHelper = new DateHelper();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const enabled = gatekeeper.hasConfiguredDataIntegration;

  const measures = params.excludeCredits
    ? [costMeasure]
    : [costMeasure, creditsMeasure];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
              measures,
              dimensions: params.dimensions,
            })
          )) as SpendSummary[];

          const summary = result[0];

          const credits = summary.credits ?? 0;
          const grossCost = summary.cost ?? 0;

          return {
            credits,
            grossCost,
            name: "Current MTD",
            netCost: credits + grossCost,
          };
        },
        queryKey: ["spendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const queryParams: CubeQueryBuilderProps = {
            ...params,
            dateRange: [
              dateHelper.firstOfLastMonthMinusThreeDays(),
              dateHelper.lastDayLastMonthPlusThreeDays(),
            ],
            measures,
            queryFilters: [
              ...(params.dataSource === DataSource.BILLING
                ? [
                    {
                      schemaName:
                        billingCucSchema.dimensions.invoiceMonth.schemaName,
                      operator: Operator.EQUALS,
                      values: [getLastInvoiceMonth()],
                    },
                  ]
                : []),
              ...(params.queryFilters ? params.queryFilters : []),
            ],
          };
          const fiscalQueryParams: CubeQueryBuilderProps = {
            ...params,
            dateRange: "Last month",
            durationType: DurationType.LAST_THIRTY_DAYS,
            measures,
          };

          const result = (await client.load(
            buildCubeQuery(
              params.isFiscalMode ? fiscalQueryParams : queryParams
            )
          )) as SpendSummary[];

          const summary = result[0];

          const credits = summary.credits ?? 0;
          const grossCost = summary.cost ?? 0;

          return {
            credits,
            grossCost,
            name: "Last Month Full",
            netCost: credits + grossCost,
          };
        },
        queryKey: ["spendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last MTD
      {
        queryFn: async () => {
          const fiscalLastMTDDateRange = getFiscalLastMTDDateRange(
            params.fiscalPeriodMap ?? {}
          );

          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dateRange:
                params.isFiscalMode && fiscalLastMTDDateRange
                  ? fiscalLastMTDDateRange
                  : [
                      startOfMonth(dateHelper.sameDayLastMonth()),
                      dateHelper.sameDayLastMonth(),
                    ],
              measures,
            })
          )) as SpendSummary[];

          const summary = result[0];

          const credits = summary.credits ?? 0;
          const grossCost = summary.cost ?? 0;

          return {
            credits,
            grossCost,
            name: "Last MTD",
            netCost: credits + grossCost,
          };
        },
        queryKey: ["spendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
