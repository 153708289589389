import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { azureAksNodeUsageSchema } from "@ternary/api-lib/analytics/schemas/azureAksNodeUsage";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureAKSClustersEntity } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
  isCSV?: boolean;
}

export default function useGetAzureAKSClusters(
  params: Params,
  options?: UseQueryOptions<AzureAKSClustersEntity[], UError>
): UseQueryResult<AzureAKSClustersEntity[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    azureAksNodeUsageSchema.dimensions.cloudID,
    azureAksNodeUsageSchema.dimensions.clusterName,
    azureAksNodeUsageSchema.dimensions.instanceType,
    azureAksNodeUsageSchema.dimensions.nodepool,
    azureAksNodeUsageSchema.dimensions.resourceGroupName,
    azureAksNodeUsageSchema.dimensions.subscriptionName,
  ];

  const measures = [
    azureAksNodeUsageSchema.measures.instanceHours,
    azureAksNodeUsageSchema.measures.cost,
    azureAksNodeUsageSchema.measures.upperBoundCpuCoreHoursUsed,
    azureAksNodeUsageSchema.measures.estimatedCpuCoreHoursAvailable,
    azureAksNodeUsageSchema.measures.upperBoundRssByteHoursUsed,
    azureAksNodeUsageSchema.measures.estimatedRssByteHoursAvailable,
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: params.queryFilters,
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    measures,
    dimensions,
  });

  return useQuery({
    queryKey: [
      params.isCSV ? "AzureAKSClusters" : "AzureAKSClustersCSV",
      {
        dateRange: params.dateRange,
        preAggFilters: params.queryFilters,
      },
    ],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const clusterGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          cloudId: datum.cloudId ?? "",
          clusterName: datum.clusterName ?? "",
          instanceType: datum.instanceType ?? "",
          nodepool: datum.nodepool ?? "",
          resourceGroupName: datum.resourceGroupName ?? "",
          subscriptionName: datum.subscriptionName ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          estimatedCpuCoreHoursAvailable:
            datum.estimatedCpuCoreHoursAvailable ?? 0,
          estimatedRssByteHoursAvailable:
            datum.estimatedRssByteHoursAvailable ?? 0,
          instanceHours: datum.instanceHours ?? 0,
          upperBoundCpuCoreHoursUsed: datum.upperBoundCpuCoreHoursUsed ?? 0,
          upperBoundRssByteHoursUsed: datum.upperBoundRssByteHoursUsed ?? 0,
        } as AzureAKSClustersEntity;
      });

      return clusterGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
