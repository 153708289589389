import { ProviderType, UnitType } from "../enums";

const measures = {
  cpuUtilization: {
    displayName: "CPU Utilization",
    providerType: ProviderType.GCP,
    schemaName: "cpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  diskUtilization: {
    displayName: "Disk Utilization",
    providerType: ProviderType.GCP,
    schemaName: "diskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  instanceIDCount: {
    displayName: "Instance ID Count",
    providerType: ProviderType.GCP,
    schemaName: "instanceIDCount",
    unit: UnitType.STANDARD,
  },
  productDiskBytes: {
    displayName: "Product Disk Bytes",
    providerType: ProviderType.GCP,
    schemaName: "productDiskBytes",
    unit: UnitType.STANDARD,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "productMemoryBytes",
    unit: UnitType.STANDARD,
  },
  productVCPU: {
    displayName: "Product vCPU",
    providerType: ProviderType.GCP,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  provisionedDiskBytes: {
    displayName: "Provisioned Disk Bytes",
    providerType: ProviderType.GCP,
    schemaName: "provisionedDiskBytes",
    unit: UnitType.STANDARD,
  },
  ramUtilization: {
    displayName: "RAM Utilization",
    providerType: ProviderType.GCP,
    schemaName: "ramUtilization",
    unit: UnitType.PERCENTAGE,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalCost",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  family: {
    displayName: "Family",
    providerType: ProviderType.GCP,
    schemaName: "family",
  },
  familyType: {
    displayName: "Family Type",
    providerType: ProviderType.GCP,
    schemaName: "familyType",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.GCP,
    schemaName: "instanceId",
  },
  instanceName: {
    displayName: "Instance Name",
    providerType: ProviderType.GCP,
    schemaName: "instanceName",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.GCP,
    schemaName: "instanceType",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
} as const;

export const gcpComputeInstancesSchema = {
  dimensions,
  measures,
};
