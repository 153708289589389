import { ProviderType, UnitType } from "../enums";

const measures = {
  absoluteCredits: {
    displayName: "Absolute Credits",
    providerType: ProviderType.AZURE,
    schemaName: "absoluteCredits",
    unit: UnitType.CURRENCY,
  },
  connections: {
    displayName: "Connections",
    providerType: ProviderType.AZURE,
    schemaName: "connections",
    unit: UnitType.STANDARD,
  },
  cores: {
    displayName: "Cores",
    providerType: ProviderType.AZURE,
    schemaName: "cores",
    unit: UnitType.CORES,
  },
  coreUsage: {
    displayName: "Core Usage",
    providerType: ProviderType.AZURE,
    schemaName: "coreUsage",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AZURE,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  cpuPercentMin: {
    displayName: "CPU Percent Min",
    providerType: ProviderType.AZURE,
    schemaName: "cpuPercentMin",
    unit: UnitType.PERCENTAGE,
  },
  cpuPercentMax: {
    displayName: "CPU Percent Max",
    providerType: ProviderType.AZURE,
    schemaName: "cpuPercentMax",
    unit: UnitType.PERCENTAGE,
  },
  cpuPercentAvg: {
    displayName: "CPU Percent Avg",
    providerType: ProviderType.AZURE,
    schemaName: "cpuPercentAvg",
    unit: UnitType.PERCENTAGE,
  },
  memoryBytesUsage: {
    displayName: "Memory Bytes Usage",
    providerType: ProviderType.AZURE,
    schemaName: "memoryBytesUsage",
    unit: UnitType.BINARY_BYTES,
  },
  memoryPercentMin: {
    displayName: "Memory Percent Min",
    providerType: ProviderType.AZURE,
    schemaName: "memoryPercentMin",
    unit: UnitType.PERCENTAGE,
  },
  memoryPercentMax: {
    displayName: "Memory Percent Max",
    providerType: ProviderType.AZURE,
    schemaName: "memoryPercentMax",
    unit: UnitType.PERCENTAGE,
  },
  memoryPercentAvg: {
    displayName: "Memory Percent Avg",
    providerType: ProviderType.AZURE,
    schemaName: "memoryPercentAvg",
    unit: UnitType.PERCENTAGE,
  },
  memoryGB: {
    displayName: "Memory GB",
    providerType: ProviderType.AZURE,
    schemaName: "memoryGB",
    unit: UnitType.BINARY_BYTES,
  },
  resourceCount: {
    displayName: "Resource Count",
    providerType: ProviderType.AZURE,
    schemaName: "resourceCount",
    unit: UnitType.STANDARD,
  },
  storageCapacityGB: {
    displayName: "Storage Capacity GB",
    providerType: ProviderType.AZURE,
    schemaName: "storageCapacityGB",
    unit: UnitType.BINARY_BYTES,
  },
  storagePercent: {
    displayName: "Storage Percent",
    providerType: ProviderType.AZURE,
    schemaName: "storagePercent",
    unit: UnitType.PERCENTAGE,
  },
  usageAmount: {
    displayName: "Usage Amount",
    providerType: ProviderType.AZURE,
    schemaName: "usageAmount",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  backupStorageRedundancy: {
    displayName: "Backup Storage Redundancy",
    providerType: ProviderType.AZURE,
    schemaName: "backupStorageRedundancy",
  },
  backupRetentionPeriodDays: {
    displayName: "Backup Retention Period Days",
    providerType: ProviderType.AZURE,
    schemaName: "backupRetentionPeriodDays",
  },
  billingMethod: {
    displayName: "Billing Method",
    providerType: ProviderType.AZURE,
    schemaName: "billingMethod",
  },
  computeTier: {
    displayName: "Compute Tier",
    providerType: ProviderType.AZURE,
    schemaName: "computeTier",
  },
  databaseEngine: {
    displayName: "Database Engine",
    providerType: ProviderType.AZURE,
    schemaName: "databaseEngine",
  },
  highAvailabilityEnabled: {
    displayName: "High Availability Enabled",
    providerType: ProviderType.AZURE,
    schemaName: "highAvailabilityEnabled",
  },
  instanceName: {
    displayName: "Instance Name",
    providerType: ProviderType.AZURE,
    schemaName: "instanceName",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.AZURE,
    schemaName: "location",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.AZURE,
    schemaName: "projectId",
  },
  resourceID: {
    displayName: "Resource ID",
    providerType: ProviderType.AZURE,
    schemaName: "resourceId",
  },
  skuName: {
    displayName: "SKU Name",
    providerType: ProviderType.AZURE,
    schemaName: "skuName",
  },
  skuTier: {
    displayName: "SKU Tier",
    providerType: ProviderType.AZURE,
    schemaName: "skuTier",
  },
} as const;

export const azureSqlVisibilitySchema = { dimensions, measures };
