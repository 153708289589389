import { ProviderType, UnitType } from "../enums";

const measures = {
  instanceCountWithCPUMetrics: {
    displayName: "Instance Count with CPU Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithCPUMetrics",
    unit: UnitType.STANDARD,
  },
  instanceCountWithDiskMetrics: {
    displayName: "Instance Count with Disk Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithDiskMetrics",
    unit: UnitType.STANDARD,
  },
  instanceCountWithGpuMetrics: {
    displayName: "Instance Count with GPU Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithGpuMetrics",
    unit: UnitType.STANDARD,
  },
  instanceCountWithMemoryMetrics: {
    displayName: "Instance Count with Memory Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithMemoryMetrics",
    unit: UnitType.STANDARD,
  },
  instanceCountWithNetworkInMetrics: {
    displayName: "Instance Count with Network In Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithNetworkInMetrics",
    unit: UnitType.STANDARD,
  },
  instanceCountWithNetworkOutMetrics: {
    displayName: "Instance Count with Network Out Metrics",
    providerType: ProviderType.AWS,
    schemaName: "instanceCountWithNetworkOutMetrics",
    unit: UnitType.STANDARD,
  },
  maxOfAverageCPUUtilization: {
    displayName: "Max of Average CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "maxOfAverageCPUUtilization",
    unit: UnitType.PERCENTAGE,
  },
  maxOfAverageDiskUtilization: {
    displayName: "Max of Average Disk Utilization",
    providerType: ProviderType.AWS,
    schemaName: "maxOfAverageDiskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  maxOfAverageGPUUtilization: {
    displayName: "Max of Average GPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "maxOfAverageGPUUtilization",
    unit: UnitType.PERCENTAGE,
  },
  maxOfAverageMemoryUtilization: {
    displayName: "Max of Average Memory Utilization",
    providerType: ProviderType.AWS,
    schemaName: "maxOfAverageMemoryUtilization",
    unit: UnitType.PERCENTAGE,
  },
  minOfAverageCPUUtilization: {
    displayName: "Min of Average CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "minOfAverageCPUUtilization",
    unit: UnitType.PERCENTAGE,
  },
  minOfAverageDiskUtilization: {
    displayName: "Min of Average Disk Utilization",
    providerType: ProviderType.AWS,
    schemaName: "minOfAverageDiskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  minOfAverageGPUUtilization: {
    displayName: "Min of Average GPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "minOfAverageGPUUtilization",
    unit: UnitType.PERCENTAGE,
  },
  minOfAverageMemoryUtilization: {
    displayName: "Min of Average Memory Utilization",
    providerType: ProviderType.AWS,
    schemaName: "minOfAverageMemoryUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p50OfMaxCpuUtilization: {
    displayName: "P50 of Max CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p50OfMaxCpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p50OfMaxDiskUtilization: {
    displayName: "P50 of Max Disk Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p50OfMaxDiskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p50OfMaxGpuUtilization: {
    displayName: "P50 of Max GPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p50OfMaxGpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p50OfMaxMemoryUtilization: {
    displayName: "P50 of Max Memory Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p50OfMaxMemoryUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p95OfMaxCpuUtilization: {
    displayName: "P95 of Max CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p95OfMaxCpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p95OfMaxDiskUtilization: {
    displayName: "P95 of Max Disk Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p95OfMaxDiskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p95OfMaxGpuUtilization: {
    displayName: "P95 of Max GPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p95OfMaxGpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p95OfMaxMemoryUtilization: {
    displayName: "P95 of Max Memory Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p95OfMaxMemoryUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p99OfMaxCpuUtilization: {
    displayName: "P99 of Max CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p99OfMaxCpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p99OfMaxDiskUtilization: {
    displayName: "P99 of Max Disk Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p99OfMaxDiskUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p99OfMaxGpuUtilization: {
    displayName: "P99 of Max GPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p99OfMaxGpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  p99OfMaxMemoryUtilization: {
    displayName: "P99 of Max Memory Utilization",
    providerType: ProviderType.AWS,
    schemaName: "p99OfMaxMemoryUtilization",
    unit: UnitType.PERCENTAGE,
  },
  sumUsageCost: {
    displayName: "Sum Usage Cost",
    providerType: ProviderType.AWS,
    schemaName: "sumUsageCost",
    unit: UnitType.CURRENCY,
  },
  sumUsageHours: {
    displayName: "Sum Usage Hours",
    providerType: ProviderType.AWS,
    schemaName: "sumUsageHours",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  gpuPerInstance: {
    displayName: "GPU Per Instance",
    providerType: ProviderType.AWS,
    schemaName: "gpuPerInstance",
  },
  memoryPerInstance: {
    displayName: "Memory Per Instance",
    providerType: ProviderType.AWS,
    schemaName: "memoryPerInstance",
  },
  productInstanceFamily: {
    displayName: "Product Instance Family",
    providerType: ProviderType.AWS,
    schemaName: "productInstanceFamily",
  },
  productInstanceType: {
    displayName: "Product Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "productInstanceType",
  },
  productInstanceTypeFamily: {
    displayName: "Product Instance Type Family",
    providerType: ProviderType.AWS,
    schemaName: "productInstanceTypeFamily",
  },
  productRegion: {
    displayName: "Product Region",
    providerType: ProviderType.AWS,
    schemaName: "productRegion",
  },
  usageAccountId: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "usageAccountId",
  },
  vcpuPerInstance: {
    displayName: "VCPU Per Instance",
    providerType: ProviderType.AWS,
    schemaName: "vcpuPerInstance",
  },
};

export const ec2InstanceUtilizationSchema = { dimensions, measures };
