import { useQueryClient } from "@tanstack/react-query";
import { defaultDimensionsMap } from "@ternary/api-lib/analytics/constants";
import { DataSource, ProviderType } from "@ternary/api-lib/analytics/enums";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";

type DataSourceKeys =
  | typeof DataSource.BILLING
  | typeof DataSource.DETAILED_BILLING
  | typeof DataSource.KUBERNETES_CONTAINER_USAGE
  | typeof DataSource.KUBERNETES_NODE_USAGE;

export type AvailableDimensionsMap = {
  [key in DataSourceKeys]?: Measure[];
};

/**
 * Custom hook that derives all available dimensions by DataSource by combining default
 * dimensions with custom dimensions.
 */

export default function useAvailableDimensions(): AvailableDimensionsMap {
  const queryClient = useQueryClient();

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const availableDimensionsMap = [
    DataSource.BILLING,
    DataSource.DETAILED_BILLING,
    DataSource.KUBERNETES_CONTAINER_USAGE,
    DataSource.KUBERNETES_NODE_USAGE,
  ].reduce((accum: AvailableDimensionsMap, dataSource: DataSource) => {
    const defaultDimensions = defaultDimensionsMap[dataSource] as Dimension[];

    if (!labelMaps) return { ...accum, [dataSource]: defaultDimensions };

    const labelsKeyedByDimension = labelMaps[dataSource];

    const customDimensions = Object.keys(labelsKeyedByDimension).map(
      (name) => ({
        displayName: name,
        providerType: ProviderType.TERNARY,
        schemaName: name,
      })
    );

    return {
      ...accum,
      [dataSource]: defaultDimensions
        .concat(customDimensions)
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
    };
  }, {});

  return availableDimensionsMap;
}
