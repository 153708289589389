import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpComputeInstancesSchema } from "@ternary/api-lib/analytics/schemas/gcpComputeInstances";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { getPaginatedResults } from "../../../../api/analytics/utils";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GCPComputeInstance, GCPComputeInstanceStruct } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
}

export default function useGetGCPComputeInstances(
  params: Params,
  options?: UseQueryOptions<GCPComputeInstance[], UError>
): UseQueryResult<GCPComputeInstance[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["gcpComputeInstances", params],
    queryFn: async () => {
      const dimensions = [
        gcpComputeInstancesSchema.dimensions.family,
        gcpComputeInstancesSchema.dimensions.familyType,
        gcpComputeInstancesSchema.dimensions.instanceID,
        gcpComputeInstancesSchema.dimensions.instanceName,
        gcpComputeInstancesSchema.dimensions.instanceType,
        gcpComputeInstancesSchema.dimensions.projectID,
        gcpComputeInstancesSchema.dimensions.region,
      ];

      const measures = [
        gcpComputeInstancesSchema.measures.cpuUtilization,
        gcpComputeInstancesSchema.measures.diskUtilization,
        gcpComputeInstancesSchema.measures.productMemoryBytes,
        gcpComputeInstancesSchema.measures.productVCPU,
        gcpComputeInstancesSchema.measures.provisionedDiskBytes,
        gcpComputeInstancesSchema.measures.ramUtilization,
        gcpComputeInstancesSchema.measures.totalCost,
      ];

      const queryParams = {
        dataSource: DataSource.GCP_COMPUTE_INSTANCES,
        dateRange: params.dateRange,
        dimensions,
        measures,
        queryFilters: params.queryFilters,
      };

      const result = await getPaginatedResults({
        client,
        numberOfQueries: 2,
        offset: 0,
        previousResults: [],
        queryParams,
      });

      const instances = result.map((datum): GCPComputeInstance => {
        const [error, validData] = validate(datum, GCPComputeInstanceStruct);

        if (error) {
          throw new UError("INVALID_GCP_COMPUTE_INSTANCE_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          family: validData.family ?? "",
          familyType: validData.familyType ?? "",
          instanceId: validData.instanceId ?? "",
          instanceName: validData.instanceName ?? "",
          instanceType: validData.instanceType ?? "",
          projectId: validData.projectId ?? "",
          region: validData.region ?? "",

          // MEASURES
          cpuUtilization: validData.cpuUtilization ?? 0,
          diskUtilization: validData.diskUtilization ?? 0,
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          productVCPU: validData.productVCPU ?? 0,
          provisionedDiskBytes: validData.provisionedDiskBytes ?? 0,
          ramUtilization: validData.ramUtilization ?? 0,
          totalCost: validData.totalCost ?? 0,
        };
      });

      return instances;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
