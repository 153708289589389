import { ProviderType } from "../enums";

import { UnitType } from "../enums";

const measures = {
  maxValues: {
    displayName: "Max Values",
    providerType: ProviderType.TERNARY,
    schemaName: "maxValues",
    unit: UnitType.STANDARD,
  },
  meanValues: {
    displayName: "Mean Values",
    providerType: ProviderType.TERNARY,
    schemaName: "meanValues",
    unit: UnitType.STANDARD,
  },
  minValues: {
    displayName: "Min Values",
    providerType: ProviderType.TERNARY,
    schemaName: "minValues",
    unit: UnitType.STANDARD,
  },
  sumValues: {
    displayName: "Sum Values",
    providerType: ProviderType.TERNARY,
    schemaName: "sumValues",
    unit: UnitType.STANDARD,
  },
};

export const externalMetricsSchema = { measures };
