import { useTheme } from "@emotion/react";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import useGetAnomalyDetail from "../../../api/analytics/hooks/useGetAnomalyDetail";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../hooks/useAvailableGlobalDate";
import useAvailableMeasuresByDataSource from "../../../hooks/useAvailableMeasuresByDataSource";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import AlertDetailTable from "../../alert-tracking/components/AlertDetailTable";
import AnomalyChart from "../../alert-tracking/components/AnomalyChart";
import {
  LEGACY_BIG_QUERY_RULE_ID,
  LEGACY_BILLING_RULE_ID,
} from "../../alert-tracking/defaultAlertRules";
import useGetAlertRuleByID from "../../alert-tracking/hooks/useGetAlertRuleByID";
import useGetCostAlertByID from "../../alert-tracking/hooks/useGetCostAlertByID";
import {
  getAnomalyDateRange,
  getStringifiedCostAlertEventType,
  isCostAlert,
  populateCostAlert,
} from "../../alert-tracking/utils";

interface Props {
  costAlertID: string;
}

export function CostAlertContentContainer(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const globalDate = useAvailableGlobalDate();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  const { data: costAlert, isLoading: isLoadingCostAlert } =
    useGetCostAlertByID(props.costAlertID);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const isDynamicAlertRule =
    costAlert &&
    costAlert.alertRuleID !== LEGACY_BILLING_RULE_ID &&
    costAlert.alertRuleID !== LEGACY_BIG_QUERY_RULE_ID;

  const { data: alertRule, isLoading: isLoadingAlertRule } =
    useGetAlertRuleByID(costAlert?.alertRuleID ?? "", {
      enabled: !!costAlert?.alertRuleID && isDynamicAlertRule,
      includeEvents: true,
    });

  const populatedCostAlert = costAlert
    ? populateCostAlert(costAlert, alertRule)
    : undefined;

  const ruleFilters = populatedCostAlert?.sourceAlertRule.filters ?? [];

  const alertFilters =
    costAlert && isCostAlert(costAlert)
      ? costAlert.dimensions.map((dimension) => {
          // If value is null operator should be NOT_SET
          return {
            name: dimension.key,
            operator: dimension.value ? Operator.EQUALS : Operator.NOT_SET,
            values: dimension.value ? [dimension.value] : null,
          };
        })
      : [];

  let anomalyDateRange: Date[] = [];

  if (globalDate.date) {
    anomalyDateRange = globalDate.date;
  }

  if (populatedCostAlert) {
    anomalyDateRange = getAnomalyDateRange(
      populatedCostAlert.eventTime,
      populatedCostAlert.sourceAlertRule.timeGranularity
    );
  }

  const availableMeasures = useAvailableMeasuresByDataSource(
    populatedCostAlert?.sourceAlertRule.dataSource ?? DataSource.BILLING
  );

  const measure =
    availableMeasures.find(
      (measure) =>
        measure.schemaName === populatedCostAlert?.sourceAlertRule.measure
    ) ?? billingCucSchema.measures.cost;

  const queryFilters = [...ruleFilters, ...alertFilters].map((filter) => ({
    schemaName: filter.name,
    operator: filter.operator,
    values: filter.values,
  }));

  const { data: alertCostData = [], isLoading: isLoadingAlertCostData } =
    useGetAnomalyDetail(
      {
        dateRange: anomalyDateRange,
        dataSource:
          populatedCostAlert?.sourceAlertRule.dataSource ?? DataSource.BILLING,
        granularity:
          populatedCostAlert?.sourceAlertRule.timeGranularity ??
          TimeGranularity.DAY,
        measures: [measure],
        queryFilters,
      },
      {
        enabled: !!costAlert && anomalyDateRange.length === 2,
      }
    );

  //
  // Interaction Handlers
  //

  function handleClickTitle() {
    if (!costAlert) return;

    navigate(paths._alertTracking, {
      searchParams: { alertID: costAlert.id },
    });
  }

  //
  // Render
  //

  const isLoading =
    isLoadingCostAlert || isLoadingAlertRule || isLoadingAlertCostData;

  if (!populatedCostAlert || isLoading) {
    return (
      <EmptyPlaceholder
        icon={faChartBar}
        loading={isLoading}
        skeletonVariant="cartesian"
      />
    );
  }

  return (
    <Flex direction="column" height="100%" minHeight={500} width="100%">
      <Text
        appearance="link"
        fontSize={theme.h4_fontSize}
        onClick={handleClickTitle}
      >
        {getStringifiedCostAlertEventType(populatedCostAlert.eventType)}
      </Text>
      <AnomalyChart
        data={alertCostData}
        eventTime={populatedCostAlert.eventTime}
        granularity={populatedCostAlert.sourceAlertRule.timeGranularity}
        lowerBound={
          populatedCostAlert.expectedValue
            ? populatedCostAlert.expectedValue.lowerBound
            : null
        }
        upperBound={
          populatedCostAlert.expectedValue
            ? populatedCostAlert.expectedValue.upperBound
            : null
        }
        value={populatedCostAlert.eventValue}
      />

      <AlertDetailTable alert={populatedCostAlert} labelMaps={labelMaps} />
    </Flex>
  );
}

export default CostAlertContentContainer;
