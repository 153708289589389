import { ProviderType, UnitType } from "../enums";

const measures = {
  backupStorageCost: {
    displayName: "Backup Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageCost",
    unit: UnitType.CURRENCY,
  },
  backupStorageUsage: {
    displayName: "Backup Storage Usage",
    providerType: ProviderType.AWS,
    schemaName: "backupStorageUsage",
    unit: UnitType.STANDARD,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  dataWriteCost: {
    displayName: "Data Write Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataWriteCost",
    unit: UnitType.CURRENCY,
  },
  dataWriteUsage: {
    displayName: "Data Write Usage",
    providerType: ProviderType.AWS,
    schemaName: "dataWriteUsage",
    unit: UnitType.STANDARD,
  },
  nodeCost: {
    displayName: "Node Cost",
    providerType: ProviderType.AWS,
    schemaName: "nodeCost",
    unit: UnitType.CURRENCY,
  },
  nodeUsage: {
    displayName: "Node Usage",
    providerType: ProviderType.AWS,
    schemaName: "nodeUsage",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  clusterArn: {
    displayName: "Cluster ARN",
    providerType: ProviderType.AWS,
    schemaName: "clusterArn",
  },
  clusterId: {
    displayName: "Cluster ID",
    providerType: ProviderType.AWS,
    schemaName: "clusterId",
  },
  dataWriteCost: {
    displayName: "Data Write Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataWriteCost",
  },
  instanceFamily: {
    displayName: "Instance Family",
    providerType: ProviderType.AWS,
    schemaName: "instanceFamily",
  },
  instanceMemory: {
    displayName: "Instance Memory",
    providerType: ProviderType.AWS,
    schemaName: "instanceMemory",
  },
  instanceNetworkPerformance: {
    displayName: "Instance Network Performance",
    providerType: ProviderType.AWS,
    schemaName: "instanceNetworkPerformance",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceVcpu: {
    displayName: "Instance VCPU",
    providerType: ProviderType.AWS,
    schemaName: "instanceVcpu",
  },
  nodeCost: {
    displayName: "Node Cost",
    providerType: ProviderType.AWS,
    schemaName: "nodeCost",
  },
  payerAccountId: {
    displayName: "Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "payerAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  usageAccountId: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "usageAccountId",
  },
} as const;

export const awsDatabaseMemoryDbSchema = { dimensions, measures };
