import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  cpuCoreUsageTime: {
    displayName: "CPU Core Usage Time",
    providerType: ProviderType.GCP,
    schemaName: "CPUCoreUsageTime",
    unit: UnitType.STANDARD,
  },
  totalCpuCores: {
    displayName: "Total CPU Cores",
    providerType: ProviderType.GCP,
    schemaName: "totalCPUCores",
    unit: UnitType.STANDARD,
  },
  totalCpuCost: {
    displayName: "Total CPU Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalCPUCost",
    unit: UnitType.CURRENCY,
  },
  totalMemoryBytes: {
    displayName: "Total Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryBytes",
    unit: UnitType.BYTES,
  },
  totalMemoryCost: {
    displayName: "Total Memory Cost",
    providerType: ProviderType.GCP,
    schemaName: "totalMemoryCost",
    unit: UnitType.CURRENCY,
  },
  usedMemoryBytes: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "usedMemoryBytes",
    unit: UnitType.BYTES,
  },
};

const dimensions = {
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.GCP,
    schemaName: "clusterName",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
};

export const gcpKubernetesClusterUsageSchema = {
  dimensions,
  measures,
};
