import { ProviderType, UnitType } from "../enums";

const measures = {
  computeCost: {
    displayName: "Compute Cost",
    providerType: ProviderType.GCP,
    schemaName: "computeCost",
    unit: UnitType.CURRENCY,
  },
  egressCost: {
    displayName: "Egress Cost",
    providerType: ProviderType.GCP,
    schemaName: "egressCost",
    unit: UnitType.CURRENCY,
  },
  storageCost: {
    displayName: "Storage Cost",
    providerType: ProviderType.GCP,
    schemaName: "storageCost",
    unit: UnitType.CURRENCY,
  },
  streamingInsertCost: {
    displayName: "Streaming Insert Cost",
    providerType: ProviderType.GCP,
    schemaName: "streamingInsertCost",
    unit: UnitType.CURRENCY,
  },
} as const;

const dimensions = {
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
} as const;

export const gcpBigQueryCostSchema = { dimensions, measures };
