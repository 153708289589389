import { DataSource } from "@ternary/api-lib/analytics/enums";

type Option = {
  label: string;
  value: string;
};

type Preference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

export function filterOutHiddenPreferences(
  options: Option[],
  dataSource: DataSource,
  preferences?: Preference[]
): Option[] {
  if (
    !preferences ||
    !preferences.some((label) => label.dataSource === dataSource)
  ) {
    return options;
  }

  const dataSourcePreferences = preferences.filter(
    (preference) => preference.dataSource === dataSource
  );
  const hiddenPreference = dataSourcePreferences.find(
    (preference) => preference.category === "HIDDEN"
  );

  const filteredOptions = options.filter((option) => {
    return !hiddenPreference?.values.includes(option.value);
  });

  return filteredOptions;
}
