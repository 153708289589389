import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpCloudSpannerSchema } from "@ternary/api-lib/analytics/schemas/gcpCloudSpanner";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

type CloudSpannerResourceSummary = {
  projectId: string | null;
  region: string | null;
  cost: number | null;
  totalBackupCost: number | null;
  totalComputeCost: number | null;
  totalDataBoostComputeCost: number | null;
  totalEgressCost: number | null;
  totalStorageCost: number | null;
};

type CloudSpannerResourceSummaryEntity = {
  [gcpCloudSpannerSchema.dimensions.projectID.schemaName]: string | null;
  [gcpCloudSpannerSchema.dimensions.region.schemaName]: string | null;
  [gcpCloudSpannerSchema.measures.cost.schemaName]: number;
  [gcpCloudSpannerSchema.measures.totalBackupCost.schemaName]: number;
  [gcpCloudSpannerSchema.measures.totalComputeCost.schemaName]: number;
  [gcpCloudSpannerSchema.measures.totalDataBoostComputeCost.schemaName]: number;
  [gcpCloudSpannerSchema.measures.totalEgressCost.schemaName]: number;
  [gcpCloudSpannerSchema.measures.totalStorageCost.schemaName]: number;
};

export interface Params {
  dateRange: Date[];
}

export default function useGetCloudSpannerResourceSummary(
  params: Params,
  options?: UseQueryOptions<CloudSpannerResourceSummaryEntity[], UError>
): UseQueryResult<CloudSpannerResourceSummaryEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["cloudSpannerResourceSummary", params],
    queryFn: async () => {
      const measures = [
        gcpCloudSpannerSchema.measures.cost,
        gcpCloudSpannerSchema.measures.totalBackupCost,
        gcpCloudSpannerSchema.measures.totalComputeCost,
        gcpCloudSpannerSchema.measures.totalDataBoostComputeCost,
        gcpCloudSpannerSchema.measures.totalEgressCost,
        gcpCloudSpannerSchema.measures.totalStorageCost,
      ];
      const dimensions = [
        gcpCloudSpannerSchema.dimensions.projectID,
        gcpCloudSpannerSchema.dimensions.region,
        gcpCloudSpannerSchema.dimensions.instanceConfig,
      ];

      const query = new DataQuery({
        dataSource: DataSource.CLOUD_SPANNER,
        dateRange: params.dateRange,
        dimensions,
        measures,
      });

      const result = await client.loadData<CloudSpannerResourceSummary>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        [gcpCloudSpannerSchema.dimensions.projectID.schemaName]:
          summary.projectId,
        [gcpCloudSpannerSchema.dimensions.region.schemaName]: summary.region,
        [gcpCloudSpannerSchema.measures.totalBackupCost.schemaName]:
          summary.totalBackupCost ?? 0,
        [gcpCloudSpannerSchema.measures.totalComputeCost.schemaName]:
          summary.totalComputeCost ?? 0,
        [gcpCloudSpannerSchema.measures.totalDataBoostComputeCost.schemaName]:
          summary.totalDataBoostComputeCost ?? 0,
        [gcpCloudSpannerSchema.measures.totalEgressCost.schemaName]:
          summary.totalEgressCost ?? 0,
        [gcpCloudSpannerSchema.measures.totalStorageCost.schemaName]:
          summary.totalStorageCost ?? 0,
        [gcpCloudSpannerSchema.measures.cost.schemaName]: summary.cost ?? 0,
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasGCPIntegration,
    ...options,
  });
}
