import { ProviderType, UnitType } from "../enums";

const measures = {
  instanceCount: {
    displayName: "Instance Count",
    providerType: ProviderType.AZURE,
    schemaName: "instanceCount",
    unit: UnitType.STANDARD,
  },
  recurringCost: {
    displayName: "Recurring Cost",
    providerType: ProviderType.AZURE,
    schemaName: "recurringCost",
    unit: UnitType.CURRENCY,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.AZURE,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
  upfrontCost: {
    displayName: "Upfront Cost",
    providerType: ProviderType.AZURE,
    schemaName: "upfrontCost",
    unit: UnitType.CURRENCY,
  },
  minUtilizationPercent: {
    displayName: "Minimum Utilization Percent",
    providerType: ProviderType.AZURE,
    schemaName: "minUtilizationPercent",
    unit: UnitType.PERCENTAGE,
  },
  maxUtilizationPercent: {
    displayName: "Maximum Utilization Percent",
    providerType: ProviderType.AZURE,
    schemaName: "maxUtilizationPercent",
    unit: UnitType.PERCENTAGE,
  },
  avgUtilizationPercent: {
    displayName: "Average Utilization Percent",
    providerType: ProviderType.AZURE,
    schemaName: "avgUtilizationPercent",
    unit: UnitType.PERCENTAGE,
  },
  reservedHours: {
    displayName: "Reserved Hours",
    providerType: ProviderType.AZURE,
    schemaName: "reservedHours",
    unit: UnitType.STANDARD,
  },
  usedHours: {
    displayName: "Used Hours",
    providerType: ProviderType.AZURE,
    schemaName: "usedHours",
    unit: UnitType.STANDARD,
  },
  onDemandUsageCost: {
    displayName: "On-Demand Usage Cost",
    providerType: ProviderType.AZURE,
    schemaName: "onDemandUsageCost",
    unit: UnitType.CURRENCY,
  },
  spUnusedCost: {
    displayName: "SP Unused Cost",
    providerType: ProviderType.AZURE,
    schemaName: "spUnusedCost",
    unit: UnitType.CURRENCY,
  },
  riUnusedCost: {
    displayName: "RI Unused Cost",
    providerType: ProviderType.AZURE,
    schemaName: "riUnusedCost",
    unit: UnitType.CURRENCY,
  },
  spAmortizedCost: {
    displayName: "SP Amortized Cost",
    providerType: ProviderType.AZURE,
    schemaName: "spAmortizedCost",
    unit: UnitType.CURRENCY,
  },
  riAmortizedCost: {
    displayName: "RI Amortized Cost",
    providerType: ProviderType.AZURE,
    schemaName: "riAmortizedCost",
    unit: UnitType.CURRENCY,
  },
  onDemandUsageHours: {
    displayName: "On-Demand Usage Hours",
    providerType: ProviderType.AZURE,
    schemaName: "onDemandUsageHours",
    unit: UnitType.STANDARD,
  },
  riUsageHours: {
    displayName: "RI Usage Hours",
    providerType: ProviderType.AZURE,
    schemaName: "riUsageHours",
    unit: UnitType.STANDARD,
  },
  spUsageHours: {
    displayName: "SP Usage Hours",
    providerType: ProviderType.AZURE,
    schemaName: "spUsageHours",
    unit: UnitType.STANDARD,
  },
  riUnusedHours: {
    displayName: "RI Unused Hours",
    providerType: ProviderType.AZURE,
    schemaName: "riUnusedHours",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  appliedScopes: {
    displayName: "Applied Scopes",
    providerType: ProviderType.AZURE,
    schemaName: "appliedScopes",
  },
  appliedScopeType: {
    displayName: "Applied Scope Type",
    providerType: ProviderType.AZURE,
    schemaName: "appliedScopeType",
  },
  billingAccountId: {
    displayName: "Billing Account ID",
    providerType: ProviderType.AZURE,
    schemaName: "billingAccountId",
  },
  billingProfileId: {
    displayName: "Billing Profile ID",
    providerType: ProviderType.AZURE,
    schemaName: "billingProfileId",
  },
  billingScopeId: {
    displayName: "Billing Scope ID",
    providerType: ProviderType.AZURE,
    schemaName: "billingScopeId",
  },
  commitmentId: {
    displayName: "Commitment ID",
    providerType: ProviderType.AZURE,
    schemaName: "commitmentId",
  },
  commitmentName: {
    displayName: "Commitment Name",
    providerType: ProviderType.AZURE,
    schemaName: "commitmentName",
  },
  coverageType: {
    displayName: "Coverage Type",
    providerType: ProviderType.AZURE,
    schemaName: "coverageType",
  },
  currencyCode: {
    displayName: "Currency Code",
    providerType: ProviderType.AZURE,
    schemaName: "currencyCode",
  },
  expirationDate: {
    displayName: "Expiration Date",
    providerType: ProviderType.AZURE,
    schemaName: "expirationDate",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AZURE,
    schemaName: "instanceType",
  },
  orderId: {
    displayName: "Order ID",
    providerType: ProviderType.AZURE,
    schemaName: "orderId",
  },
  paymentOption: {
    displayName: "Payment Option",
    providerType: ProviderType.AZURE,
    schemaName: "paymentOption",
  },
  purchaseDate: {
    displayName: "Purchase Date",
    providerType: ProviderType.AZURE,
    schemaName: "purchaseDate",
  },
  recurringFrequency: {
    displayName: "Recurring Frequency",
    providerType: ProviderType.AZURE,
    schemaName: "recurringFrequency",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AZURE,
    schemaName: "region",
  },
  renew: {
    displayName: "Renew",
    providerType: ProviderType.AZURE,
    schemaName: "renew",
  },
  skuName: {
    displayName: "SKU Name",
    providerType: ProviderType.AZURE,
    schemaName: "skuName",
  },
  term: {
    displayName: "Term",
    providerType: ProviderType.AZURE,
    schemaName: "term",
  },
  type: {
    displayName: "Type",
    providerType: ProviderType.AZURE,
    schemaName: "type",
  },
} as const;

export const azureCommitmentTableSchema = { dimensions, measures };
