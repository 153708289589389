import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { azureComputeVisibilitySchema } from "@ternary/api-lib/analytics/schemas/azureComputeVisibility";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { AzureVMComputeInstance } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
  isCSV?: boolean;
}

export default function useGetAzureVMComputeInstances(
  params: Params,
  options?: UseQueryOptions<AzureVMComputeInstance[], UError>
): UseQueryResult<AzureVMComputeInstance[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    azureComputeVisibilitySchema.dimensions.blockStorageClass,
    azureComputeVisibilitySchema.dimensions.blockStorageType,
    azureComputeVisibilitySchema.dimensions.family,
    azureComputeVisibilitySchema.dimensions.instanceID,
    azureComputeVisibilitySchema.dimensions.instanceName,
    azureComputeVisibilitySchema.dimensions.instanceType,
    azureComputeVisibilitySchema.dimensions.location,
    azureComputeVisibilitySchema.dimensions.operatingSystem,
    azureComputeVisibilitySchema.dimensions.projectID,
  ];

  const measures = [
    azureComputeVisibilitySchema.measures.cost,
    azureComputeVisibilitySchema.measures.cpuUtilizationAvg,
    azureComputeVisibilitySchema.measures.cpuUtilizationMax,
    azureComputeVisibilitySchema.measures.cpuUtilizationMin,
    azureComputeVisibilitySchema.measures.diskReadBytes,
    azureComputeVisibilitySchema.measures.diskWriteBytes,
    azureComputeVisibilitySchema.measures.productMemoryBytes,
    azureComputeVisibilitySchema.measures.productVCPU,
    azureComputeVisibilitySchema.measures.ramAvailableBytesAvg,
    azureComputeVisibilitySchema.measures.ramAvailableBytesMax,
    azureComputeVisibilitySchema.measures.ramAvailableBytesMin,
    azureComputeVisibilitySchema.measures.receivedBytes,
    azureComputeVisibilitySchema.measures.sentBytes,
    azureComputeVisibilitySchema.measures.usageAmount,
  ];

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
    dateRange: params.dateRange,
    dimensions,
    measures,
    preAggFilters: params.queryFilters,
  });

  return useQuery({
    queryKey: [
      params.isCSV ? "azureComputeInstances" : "azureComputeInstancesCSV",
      {
        dateRange: params.dateRange,
        preAggFilters: params.queryFilters,
      },
    ],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const instanceGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          blockStorageClass: datum.blockStorageClass ?? "",
          blockStorageType: datum.blockStorageType ?? "",
          family: datum.family ?? "",
          instanceId: datum.instanceId ?? "",
          instanceName: datum.instanceName ?? "",
          instanceType: datum.instanceType ?? "",
          location: datum.location ?? "",
          operatingSystem: datum.operatingSystem ?? "",
          projectId: datum.projectId ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          diskReadBytes: datum.diskReadBytes ?? 0,
          diskWriteBytes: datum.diskWriteBytes ?? 0,
          productMemoryBytes: datum.productMemoryBytes ?? 0,
          productVCPU: datum.productVCPU ?? 0,
          receivedBytes: datum.receivedBytes ?? 0,
          sentBytes: datum.sentBytes ?? 0,
          usageAmount: datum.usageAmount ?? 0,

          cpuUtilizationAvg: datum.cpuUtilizationAvg ?? 0,
          cpuUtilizationMax: datum.cpuUtilizationMax ?? 0,
          cpuUtilizationMin: datum.cpuUtilizationMin ?? 0,
          ramAvailableBytesAvg: datum.ramAvailableBytesAvg ?? 0,
          ramAvailableBytesMax: datum.ramAvailableBytesMax ?? 0,
          ramAvailableBytesMin: datum.ramAvailableBytesMin ?? 0,
        } as AzureVMComputeInstance;
      });

      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
