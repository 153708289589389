import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { mspRollupSchema } from "@ternary/api-lib/analytics/schemas/mspRollup";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useGetUsersByTenantID from "../../../../api/core/hooks/useGetUsersByTenantID";
import { SideDrawer } from "../../../../components/SideDrawer";
import { useActivityTracker } from "../../../../context/ActivityTrackerProvider";
import ConfirmationModal from "../../../../ui-lib/components/ConfirmationModal";
import Form, { FormField } from "../../../../ui-lib/components/Form";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Select from "../../../../ui-lib/components/Select";
import TextInput from "../../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../../utils/alerts";
import getMergeState from "../../../../utils/getMergeState";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import { useCreateMspBillingStatement } from "../hooks/useCreateMspBillingStatement";
import { useDeleteMspBillingStatement } from "../hooks/useDeleteMspBillingStatement";
import { useGenerateMspBillingStatementCsv } from "../hooks/useGenerateMspBillingStatementCsv";
import { useGenerateMspBillingStatementPdf } from "../hooks/useGenerateMspBillingStatementPdf";
import { useGetMspBillingInfoByTenantID } from "../hooks/useGetMspBillingInfoByTenantID";
import { useGetMspBillingStatementsByTenantID } from "../hooks/useGetMspBillingStatementsByTenantID";
import { DatePickerWithSelect } from "./DatePickerWithSelect";
import MspBillingStatementTable from "./MspBillingStatementTable";

type Interaction = MspBillingStatementTable.Interaction;

type State = {
  hasClickedDownloadCsv: boolean;
  hasClickedDownloadPdf: boolean;
  searchText: string;
  selectedStatementID: string | null;
  showModal: boolean;
  showSideDrawer: boolean;
};

const initialState: State = {
  hasClickedDownloadCsv: false,
  hasClickedDownloadPdf: false,
  searchText: "",
  selectedStatementID: null,
  showModal: false,
  showSideDrawer: false,
};

const defaultBillingStatements = [];
const defaultUsers = [];

export function MspBillingStatementManagementContainer() {
  const activityTracker = useActivityTracker();
  const location = useLocation();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const { tenantID = "" } = useParams();

  const [state, setState] = useState<State>({
    ...initialState,
    ...location.state,
  });
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _billingStatements = defaultBillingStatements,
    isLoading: isLoadingBillingStatements,
  } = useGetMspBillingStatementsByTenantID(tenantID);

  const { data: customerBillingInfo, isLoading: isLoadingCustomerBillingInfo } =
    useGetMspBillingInfoByTenantID(tenantID);

  const { data: users = defaultUsers, isLoading: isLoadingUsers } =
    useGetUsersByTenantID(tenantID);

  const { data: csvBuffer, isLoading: isLoadingCsvBuffer } =
    useGenerateMspBillingStatementCsv(state.selectedStatementID as string, {
      enabled: !!state.selectedStatementID && state.hasClickedDownloadCsv,
    });

  const { data: invoicePDF, isFetching: isLoadingInvoicePDF } =
    useGenerateMspBillingStatementPdf(state.selectedStatementID as string, {
      enabled: !!state.selectedStatementID && state.hasClickedDownloadPdf,
    });

  //
  // Mutations
  //

  const {
    isPending: isCreatingMspBillingStatement,
    mutate: createMspBillingStatement,
  } = useCreateMspBillingStatement({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorCreatingBillingStatementMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showSideDrawer: false });

      queryClient.invalidateQueries({
        queryKey: queryKeys.billingStatements(tenantID),
      });

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successCreatingBillingStatementMessage,
      });
    },
  });

  const {
    isPending: isDeletingMspBillingStatement,
    mutate: deleteMspBillingStatement,
  } = useDeleteMspBillingStatement({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorDeletingBillingStatementMessage,
      });
    },
    onSuccess: () => {
      mergeState({ selectedStatementID: null, showModal: false });

      queryClient.invalidateQueries({
        queryKey: queryKeys.billingStatements(tenantID),
      });

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successDeletingBillingStatementMessage,
      });
    },
  });

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy(users, "id");

  const billingStatements = _billingStatements.map((statement) => ({
    ...statement,
    createdByEmail:
      usersKeyedByID[statement.createdByID]?.email ?? statement.createdByID,
  }));

  let filteredStatements = billingStatements;

  if (state.searchText.length > 0) {
    filteredStatements = billingStatements.filter((statement) =>
      [statement.name, statement.createdByEmail]
        .join("")
        .toLowerCase()
        .includes(state.searchText.toLowerCase())
    );
  }

  //
  // Side Effects
  //

  useEffect(() => {
    if (
      !csvBuffer ||
      !state.hasClickedDownloadCsv ||
      !state.selectedStatementID ||
      isLoadingCsvBuffer
    ) {
      return;
    }

    const selectedStatement = billingStatements.find(
      (manifest) => manifest.id === state.selectedStatementID
    );

    if (!selectedStatement) return;

    const uint8Array = new Uint8Array(csvBuffer["data"]);

    const content = new Blob([uint8Array], { type: "text/csv;charset=utf-8" });

    const encodedUri = window.URL.createObjectURL(content);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${selectedStatement?.name.replace(/ /g, "-")}-${formatDate(
        new Date(),
        "MM-dd-yyyy"
      )}`
    );

    link.click();

    mergeState({ hasClickedDownloadCsv: false, selectedStatementID: null });
  }, [state.hasClickedDownloadCsv, isLoadingCsvBuffer]);

  useEffect(() => {
    if (
      !invoicePDF ||
      !state.hasClickedDownloadPdf ||
      !state.selectedStatementID ||
      isLoadingInvoicePDF
    ) {
      return;
    }

    const selectedStatement = billingStatements.find(
      (manifest) => manifest.id === state.selectedStatementID
    );

    const uint8Array = new Uint8Array(invoicePDF);

    const content = new Blob([uint8Array], { type: "application/pdf" });

    const encodedUri = window.URL.createObjectURL(content);
    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${selectedStatement?.name.replace(/ /g, "-")}-invoice-${formatDate(
        new Date(),
        "MM-dd-yyyy"
      )}`
    );

    link.click();

    mergeState({ hasClickedDownloadPdf: false, selectedStatementID: null });
  }, [state.hasClickedDownloadPdf, invoicePDF]);

  //
  // Interaction Handlers
  //

  function handleDeleteBillingStatement() {
    if (!state.selectedStatementID) return;

    activityTracker.captureAction(actions.CLICK_MSP_BILLING_STATEMENT_DELETE);

    deleteMspBillingStatement({ statementID: state.selectedStatementID });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspBillingStatementTable.INTERACTION_DELETE_BILLING_STATEMENT_CLICKED: {
        mergeState({
          selectedStatementID: interaction.statementID,
          showModal: true,
        });
        return;
      }
      case MspBillingStatementTable.INTERACTION_EXPORT_BILLING_STATEMENT_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_MSP_BILLING_STATEMENT_EXPORT_PDF
        );

        mergeState({
          hasClickedDownloadPdf: true,
          selectedStatementID: interaction.statementID,
        });
        return;
      }
      case MspBillingStatementTable.INTERACTION_EXPORT_RAW_DATA_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_MSP_BILLING_STATEMENT_EXPORT_CSV
        );

        mergeState({
          hasClickedDownloadCsv: true,
          selectedStatementID: interaction.statementID,
        });
        return;
      }
    }
  }

  function handleSubmit(value: Value) {
    activityTracker.captureAction(actions.CLICK_MSP_BILLING_STATEMENT_CREATE);

    createMspBillingStatement({
      tenantID: tenantID,
      name: value.name,
      primaryGrouping: value.primaryGrouping,
      ...(value.endDate ? { endDate: value.endDate } : {}),
      ...(value.invoiceMonth ? { invoiceMonth: value.invoiceMonth } : {}),
      ...(value.invoiceNumber ? { invoiceNumber: value.invoiceNumber } : {}),
      ...(value.secondaryGrouping
        ? { secondaryGrouping: value.secondaryGrouping }
        : {}),
      ...(value.startDate ? { startDate: value.startDate } : {}),
    });
  }

  return (
    <Box marginTop={theme.space_md}>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isDeletingMspBillingStatement}
          message={copyText.deleteStatementConfirmationMessage}
          title={copyText.deleteStatementConfirmationTitle}
          variant="danger"
          onCancel={() =>
            mergeState({ selectedStatementID: null, showModal: false })
          }
          onConfirm={handleDeleteBillingStatement}
        />
      )}
      <SideDrawer
        isOpen={state.showSideDrawer}
        title={copyText.createStatementFormTitle}
        titleCaption={copyText.createStatementFormMessage}
        onClose={() => mergeState({ showSideDrawer: false })}
        content={() => (
          <CreateBillingStatementForm
            isProcessing={isCreatingMspBillingStatement}
            onCancel={() => mergeState({ showSideDrawer: false })}
            onSubmit={handleSubmit}
          />
        )}
      />
      <Flex justifyContent="space-between" marginBottom={theme.space_md}>
        <Text lineHeight="28px" fontSize={theme.h4_fontSize}>
          {copyText.billingStatementsTableHeader}
        </Text>
        <Flex>
          <Box marginRight={theme.space_sm} width={280}>
            <TextInput
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              value={state.searchText}
              size="medium"
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
          <Button
            iconStart={
              <Icon color={theme.text_color_secondary} icon={faPlus} />
            }
            size="small"
            onClick={() => mergeState({ showSideDrawer: true })}
          >
            {copyText.createStatementButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <MspBillingStatementTable
        billingStatements={filteredStatements}
        disableStatementExport={
          !customerBillingInfo || isLoadingCustomerBillingInfo
        }
        isLoading={
          isLoadingBillingStatements ||
          isLoadingCustomerBillingInfo ||
          isLoadingUsers
        }
        onInteraction={handleInteraction}
      />
    </Box>
  );
}

const primaryGroupings = [
  mspRollupSchema.dimensions.invoiceIssuer,
  mspRollupSchema.dimensions.providerName,
];

const secondaryGroupings = [
  mspRollupSchema.dimensions.billingAccountID,
  mspRollupSchema.dimensions.serviceCategory,
  mspRollupSchema.dimensions.region,
  mspRollupSchema.dimensions.serviceName,
  mspRollupSchema.dimensions.subAccountId,
];

const DEFAULT_PRIMARY_GROUPING =
  mspRollupSchema.dimensions.providerName.schemaName;

const genericRequiredFieldValidator = ({ value }: { value: string | null }) => {
  return !value || value.length === 0
    ? copyText.inputFieldRequiredErrorCaption
    : undefined;
};

type FormData = {
  invoiceNumber: string;
  invoicePeriod: {
    endDate: Date | null;
    invoiceMonth: Date | null;
    startDate: Date | null;
  };
  name: string;
  primaryGrouping: string;
  secondaryGrouping: string | null;
};

type Value = {
  endDate?: string;
  invoiceMonth?: string;
  invoiceNumber?: string;
  name: string;
  primaryGrouping: string;
  secondaryGrouping?: string;
  startDate?: string;
};

interface FormProps {
  isProcessing?: boolean;
  onCancel: () => void;
  onSubmit: (value: Value) => void;
}

function CreateBillingStatementForm(props: FormProps) {
  const theme = useTheme();

  const form = useForm<FormData>({
    defaultValues: {
      invoiceNumber: "",
      invoicePeriod: { endDate: null, invoiceMonth: null, startDate: null },
      name: "",
      primaryGrouping: DEFAULT_PRIMARY_GROUPING,
      secondaryGrouping: null,
    },
    onSubmit: ({ value }) => {
      props.onSubmit({
        name: value.name,
        primaryGrouping: value.primaryGrouping,
        ...(value.invoiceNumber ? { invoiceNumber: value.invoiceNumber } : {}),
        ...(value.invoicePeriod.endDate
          ? { endDate: value.invoicePeriod.endDate.toISOString() }
          : {}),
        ...(value.invoicePeriod.invoiceMonth
          ? {
              invoiceMonth: formatDate(
                value.invoicePeriod.invoiceMonth,
                "MM/yyyy"
              ),
            }
          : {}),
        ...(value.invoicePeriod.startDate
          ? { startDate: value.invoicePeriod.startDate.toISOString() }
          : {}),
        ...(value.secondaryGrouping
          ? { secondaryGrouping: value.secondaryGrouping }
          : {}),
      });
    },
  });

  const primaryGroupingOptions = Object.values(primaryGroupings).map(
    (dimension) => ({
      label: dimension.displayName,
      value: dimension.schemaName,
    })
  );

  const secondaryGroupingOptions = Object.values(secondaryGroupings).map(
    (dimension) => ({
      label: dimension.displayName,
      value: dimension.schemaName,
    })
  );

  return (
    <Flex direction="column" height="100%" paddingTop={theme.space_md}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Flex direction="column" justifyContent="space-between" height="100%">
          <Box paddingHorizontal={theme.space_lg}>
            <form.Field
              name="name"
              validators={{
                onChange: genericRequiredFieldValidator,
                onMount: genericRequiredFieldValidator,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                const errorCaption = shouldShowError
                  ? field.state.meta.errors.join(", ")
                  : undefined;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={errorCaption}
                    label={copyText.nameInputLabel}
                    required
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name="invoicePeriod"
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                const errorCaption = shouldShowError
                  ? field.state.meta.errors.join(", ")
                  : undefined;

                return (
                  <FormField
                    errorCaption={errorCaption}
                    label={copyText.invoicePeriodInputLabel}
                    required
                  >
                    <DatePickerWithSelect
                      endDate={field.state.value.endDate}
                      invoiceMonth={field.state.value.invoiceMonth}
                      startDate={field.state.value.startDate}
                      onChangeDateRange={(dateRange, isInvoiceMonth) => {
                        if (isInvoiceMonth) {
                          field.form.setFieldValue("invoicePeriod", {
                            endDate: null,
                            invoiceMonth: dateRange[0],
                            startDate: null,
                          });
                        } else {
                          field.form.setFieldValue("invoicePeriod", {
                            endDate: dateRange[1],
                            invoiceMonth: null,
                            startDate: dateRange[0],
                          });
                        }
                      }}
                    />
                  </FormField>
                );
              }}
            />
            <form.Field
              name="invoiceNumber"
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                const errorCaption = shouldShowError
                  ? field.state.meta.errors.join(", ")
                  : undefined;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={errorCaption}
                    label={copyText.invoiceNumberInputLabel}
                    optional
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <Flex gap={theme.space_sm}>
              <form.Field
                name="primaryGrouping"
                validators={{
                  onChange: genericRequiredFieldValidator,
                  onMount: genericRequiredFieldValidator,
                }}
                children={(field) => {
                  const shouldShowError =
                    field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0;

                  const errorCaption = shouldShowError
                    ? field.state.meta.errors.join(", ")
                    : undefined;

                  return (
                    <FormField
                      errorCaption={errorCaption}
                      label={copyText.primaryGroupingInputLabel}
                      required
                    >
                      <Select
                        hideSelectedOptions
                        options={primaryGroupingOptions}
                        placeholder={copyText.groupingInputLabelPlaceholder}
                        searchable
                        value={primaryGroupingOptions.find(
                          (option) => option.value === field.state.value
                        )}
                        onChange={(option) =>
                          option && field.handleChange(option?.value)
                        }
                      />
                    </FormField>
                  );
                }}
              />
              <form.Field
                name="secondaryGrouping"
                children={(field) => {
                  const shouldShowError =
                    field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0;

                  const errorCaption = shouldShowError
                    ? field.state.meta.errors.join(", ")
                    : undefined;

                  return (
                    <FormField
                      errorCaption={errorCaption}
                      label={copyText.secondaryGroupingInputLabel}
                      optional
                    >
                      <Select
                        options={secondaryGroupingOptions}
                        placeholder={copyText.groupingInputLabelPlaceholder}
                        searchable
                        value={secondaryGroupingOptions.find(
                          (option) => option.value === field.state.value
                        )}
                        onChange={(option) =>
                          option && field.handleChange(option?.value)
                        }
                      />
                    </FormField>
                  );
                }}
              />
            </Flex>
          </Box>
          <Box paddingBottom={theme.space_md}>
            <Divider margin={theme.space_md} />
            <Flex
              gap={theme.space_sm}
              justifyContent="flex-end"
              marginTop={theme.space_md}
              paddingHorizontal={theme.space_lg}
            >
              <Button type="button" width={100} onClick={props.onCancel}>
                {copyText.cancelButtonLabel}
              </Button>
              <form.Subscribe
                children={(state) => {
                  const disabled =
                    !state.canSubmit || !state.isTouched || props.isProcessing;

                  return (
                    <Button
                      disabled={disabled}
                      primary
                      type="submit"
                      width={100}
                    >
                      {props.isProcessing ? (
                        <LoadingSpinner />
                      ) : (
                        copyText.submitButtonLabel
                      )}
                    </Button>
                  );
                }}
              />
            </Flex>
          </Box>
        </Flex>
      </Form>
    </Flex>
  );
}
