import { ProviderType, UnitType } from "../enums";
import { ec2InstanceUtilizationSchema } from "./ec2InstanceUtilization";

const measures = {
  ...ec2InstanceUtilizationSchema.measures,
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  diskReadBytes: {
    displayName: "Disk Read Bytes",
    providerType: ProviderType.AWS,
    schemaName: "diskReadBytes",
    unit: UnitType.BYTES,
  },
  diskWriteBytes: {
    displayName: "Disk Write Bytes",
    providerType: ProviderType.AWS,
    schemaName: "diskWriteBytes",
    unit: UnitType.BYTES,
  },
  networkInBytes: {
    displayName: "Network In Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkInBytes",
    unit: UnitType.BYTES,
  },
  networkOutBytes: {
    displayName: "Network Out Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkOutBytes",
    unit: UnitType.BYTES,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytes",
    unit: UnitType.BYTES,
  },
  productMemoryBytesChart: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytesChart",
    unit: UnitType.BYTES,
  },
  productVCPU: {
    displayName: "Product VCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  productVCPUChart: {
    displayName: "Product VCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPUChart",
    unit: UnitType.BYTES,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.AWS,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  billPayerAccountId: {
    displayName: "Bill Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "billPayerAccountId",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.AWS,
    schemaName: "family",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
};

export const awsComputeVisibilitySchema = { dimensions, measures };
