import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpCloudSpannerUsageSchema } from "@ternary/api-lib/analytics/schemas/gcpCloudSpannerUsage";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

type CloudSpannerInstanceSummary = {
  projectId: string | null;
  region: string | null;
  instanceConfig: string | null;
  instanceId: string | null;
  avgCpuUtilization: number | null;
  backupUsedB: number | null;
  maxCpuUtilization: number | null;
  processingUnits: number | null;
  storageLimitB: number | null;
  storageUsedB: number | null;
  totalBackupGibHour: number | null;
  totalComputeHours: number | null;
  totalEgressGib: number | null;
  totalStorageGibHour: number | null;
};

type CloudSpannerInstanceSummaryEntity = {
  [gcpCloudSpannerUsageSchema.dimensions.instanceConfig.schemaName]:
    | string
    | null;
  [gcpCloudSpannerUsageSchema.dimensions.instanceID.schemaName]: string | null;
  [gcpCloudSpannerUsageSchema.dimensions.projectID.schemaName]: string | null;
  [gcpCloudSpannerUsageSchema.dimensions.region.schemaName]: string | null;
  [gcpCloudSpannerUsageSchema.measures.avgCpuUtilization.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.backupUsedB.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.maxCpuUtilization.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.storageLimitB.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.storageUsedB.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.processingUnits.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.totalBackupGibHour.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.totalComputeHours.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.totalEgressGib.schemaName]: number;
  [gcpCloudSpannerUsageSchema.measures.totalStorageGibHour.schemaName]: number;
};

export interface Params {
  dateRange: Date[];
}

export default function useGetCloudSpannerInstanceSummary(
  params: Params,
  options?: UseQueryOptions<CloudSpannerInstanceSummaryEntity[], UError>
): UseQueryResult<CloudSpannerInstanceSummaryEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["cloudSpannerInstanceSummary", params],
    queryFn: async () => {
      const measures = [
        gcpCloudSpannerUsageSchema.measures.avgCpuUtilization,
        gcpCloudSpannerUsageSchema.measures.backupUsedB,
        gcpCloudSpannerUsageSchema.measures.maxCpuUtilization,
        gcpCloudSpannerUsageSchema.measures.storageLimitB,
        gcpCloudSpannerUsageSchema.measures.storageUsedB,
        gcpCloudSpannerUsageSchema.measures.processingUnits,
        gcpCloudSpannerUsageSchema.measures.totalBackupGibHour,
        gcpCloudSpannerUsageSchema.measures.totalComputeHours,
        gcpCloudSpannerUsageSchema.measures.totalEgressGib,
        gcpCloudSpannerUsageSchema.measures.totalStorageGibHour,
      ];

      const dimensions = [
        gcpCloudSpannerUsageSchema.dimensions.instanceConfig,
        gcpCloudSpannerUsageSchema.dimensions.instanceID,
        gcpCloudSpannerUsageSchema.dimensions.projectID,
        gcpCloudSpannerUsageSchema.dimensions.region,
      ];

      const query = new DataQuery({
        dataSource: DataSource.CLOUD_SPANNER_USAGE,
        dateRange: params.dateRange,
        dimensions,
        measures,
      });
      const result = await client.loadData<CloudSpannerInstanceSummary>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        [gcpCloudSpannerUsageSchema.dimensions.instanceConfig.schemaName]:
          summary.instanceConfig,
        [gcpCloudSpannerUsageSchema.dimensions.instanceID.schemaName]:
          summary.instanceId,
        [gcpCloudSpannerUsageSchema.dimensions.projectID.schemaName]:
          summary.projectId,
        [gcpCloudSpannerUsageSchema.dimensions.region.schemaName]:
          summary.region,
        [gcpCloudSpannerUsageSchema.measures.avgCpuUtilization.schemaName]:
          summary.avgCpuUtilization ?? 0,
        [gcpCloudSpannerUsageSchema.measures.backupUsedB.schemaName]:
          summary.backupUsedB ?? 0,
        [gcpCloudSpannerUsageSchema.measures.maxCpuUtilization.schemaName]:
          summary.maxCpuUtilization ?? 0,
        [gcpCloudSpannerUsageSchema.measures.storageLimitB.schemaName]:
          summary.storageLimitB ?? 0,
        [gcpCloudSpannerUsageSchema.measures.storageUsedB.schemaName]:
          summary.storageUsedB ?? 0,
        [gcpCloudSpannerUsageSchema.measures.processingUnits.schemaName]:
          summary.processingUnits ?? 0,
        [gcpCloudSpannerUsageSchema.measures.totalBackupGibHour.schemaName]:
          summary.totalBackupGibHour ?? 0,
        [gcpCloudSpannerUsageSchema.measures.totalComputeHours.schemaName]:
          summary.totalComputeHours ?? 0,
        [gcpCloudSpannerUsageSchema.measures.totalEgressGib.schemaName]:
          summary.totalEgressGib ?? 0,
        [gcpCloudSpannerUsageSchema.measures.totalStorageGibHour.schemaName]:
          summary.totalStorageGibHour ?? 0,
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasGCPIntegration,
    ...options,
  });
}
