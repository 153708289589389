import { ProviderType, UnitType } from "../enums";

const measures = {
  cpuTotal: {
    displayName: "CPU Total",
    providerType: ProviderType.AWS,
    schemaName: "cpuTotal",
    unit: UnitType.STANDARD,
  },
  cpuUsed: {
    displayName: "CPU Used",
    providerType: ProviderType.AWS,
    schemaName: "cpuUsed",
    unit: UnitType.STANDARD,
  },
  memoryTotal: {
    displayName: "Memory Total",
    providerType: ProviderType.AWS,
    schemaName: "memoryTotal",
    unit: UnitType.STANDARD,
  },
  memoryUsed: {
    displayName: "Memory Used",
    providerType: ProviderType.AWS,
    schemaName: "memoryUsed",
    unit: UnitType.STANDARD,
  },
};

export const awsEksNodeUsageMeterHelperSchema = { measures };
