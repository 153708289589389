import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  diskReadBytes: {
    displayName: "Disk Read Bytes",
    providerType: ProviderType.GCP,
    schemaName: "diskReadBytes",
    unit: UnitType.BYTES,
  },
  diskWriteBytes: {
    displayName: "Disk Write Bytes",
    providerType: ProviderType.GCP,
    schemaName: "diskWriteBytes",
    unit: UnitType.BYTES,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "productMemoryBytes",
    unit: UnitType.BYTES,
  },
  productMemoryBytesChart: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.GCP,
    schemaName: "productMemoryBytesChart",
    unit: UnitType.BYTES,
  },
  provisionedDiskBytes: {
    displayName: "Provisioned Disk Bytes",
    providerType: ProviderType.GCP,
    schemaName: "provisionedDiskBytes",
    unit: UnitType.BYTES,
  },
  receivedBytes: {
    displayName: "Received Bytes",
    providerType: ProviderType.GCP,
    schemaName: "receivedBytes",
    unit: UnitType.BYTES,
  },
  sentBytes: {
    displayName: "Sent Bytes",
    providerType: ProviderType.GCP,
    schemaName: "sentBytes",
    unit: UnitType.BYTES,
  },
  productVCPU: {
    displayName: "Product vCPU",
    providerType: ProviderType.GCP,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  productVCPUChart: {
    displayName: "Product vCPU",
    providerType: ProviderType.GCP,
    schemaName: "productVCPUChart",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  category: {
    displayName: "Category",
    providerType: ProviderType.GCP,
    schemaName: "category",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.GCP,
    schemaName: "family",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.GCP,
    schemaName: "instanceType",
  },
  operatingSystem: {
    displayName: "Operating System",
    providerType: ProviderType.GCP,
    schemaName: "operatingSystem",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  storageClass: {
    displayName: "Storage Class",
    providerType: ProviderType.GCP,
    schemaName: "storageClass",
  },
  storageType: {
    displayName: "Storage Type",
    providerType: ProviderType.GCP,
    schemaName: "storageType",
  },
} as const;

export const gcpComputeVisibilitySchema = { dimensions, measures } as const;
