import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";
import {
  ChartType,
  DataSource,
  DurationType,
  TimeGranularity,
} from "../../analytics/enums";
import { Order } from "../../analytics/types";
import { Dimension, Filter, Measure } from "../../analytics/ui/types";
import { ResourceType } from "../../constants/enums";

type FiscalPeriodMap = {
  [key: string]: string;
};

export interface ReportSnapshotParams {
  chartType: ChartType;
  dataSource: DataSource;
  dateRange: Date[] | null;
  dimensions?: Dimension[];
  durationType: DurationType;
  filters?: Filter[];
  fiscalPeriodMap?: FiscalPeriodMap | null;
  granularity?: TimeGranularity;
  isFiscalMode?: boolean;
  limit?: number;
  measures: Measure[];
  name: string;
  order?: Order;
  overflow?: boolean;
  xAxisKey: string;
}

interface State {
  isResourceSelectionMode: boolean;
  isSideDrawerOpen: boolean;
  reportSnapshot: ReportSnapshotParams | null;
  selectedResourceID: string | null;
  selectedResourceName: string | null;
  selectedResourceType: ResourceType | null;
  // NOTE: Hardcoding budget shape for now since other resource types don't have/need context
  selectedResourceContext?: { forecast: number };
}

const initialState: State = {
  isResourceSelectionMode: false,
  isSideDrawerOpen: false,
  reportSnapshot: null,
  selectedResourceID: null,
  selectedResourceName: null,
  selectedResourceType: null,
};

interface Store {
  isResourceSelectionMode: boolean;
  isSideDrawerOpen: boolean;
  reportSnapshot: ReportSnapshotParams | null;
  selectedResourceID: string | null;
  selectedResourceName: string | null;
  selectedResourceType: ResourceType | null;
  selectedResourceContext?: { forecast: number };
  close: () => void;
  reset: () => void;
  set: (state: Partial<State>) => void;
}

const context = createContext<Store | undefined>(undefined);

export default function CaseManagementStoreProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): JSX.Element {
  const [state, setState] = useState<State>(initialState);

  const close = () =>
    setState((prevState) => ({
      ...prevState,
      isSideDrawerOpen: false,
      isResourceSelectionMode: false,
    }));

  const reset = () => setState(initialState);

  const set = (state: Partial<State>) =>
    setState((currentState) => ({ ...currentState, ...state }));

  return (
    <context.Provider value={{ ...state, close, reset, set }}>
      {children}
    </context.Provider>
  );
}

export function useCaseManagementStore(): Store {
  const store = useContext(context);

  if (!store) {
    throw new Error(
      "caseManagementStateConsumer must be wrapped by CaseManagementStateProvider"
    );
  }

  return store;
}
