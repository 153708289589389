import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { gcpCloudSqlInstanceUsageSchema } from "@ternary/api-lib/analytics/schemas/gcpCloudSqlInstanceUsage";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CloudSQLUsageDatum, CloudSQLUsageStruct } from "../types";

export interface Params {
  dateRange: Date[];
  dimensions?: Dimension[];
  latest?: boolean;
  queryFilters?: QueryFilter[];
}

export default function useGetCloudSQLUsageSummary(
  params: Params,
  options?: UseQueryOptions<CloudSQLUsageDatum[], UError>
): UseQueryResult<CloudSQLUsageDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cloudSQLUsageSummary", params],
    queryFn: async () => {
      const measures = [
        gcpCloudSqlInstanceUsageSchema.measures.maxCpuAverageUsedCores,
        gcpCloudSqlInstanceUsageSchema.measures.maxCpuReservedCores,
        gcpCloudSqlInstanceUsageSchema.measures.maxDiskSizeBytes,
        gcpCloudSqlInstanceUsageSchema.measures.maxDiskUsedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.maxRamAverageUsedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.maxRamUsedBytesWithBuffer,
        gcpCloudSqlInstanceUsageSchema.measures.networkSentBytes,
        gcpCloudSqlInstanceUsageSchema.measures.ramReservedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalCost,
        gcpCloudSqlInstanceUsageSchema.measures.totalCpuReservedCores,
        gcpCloudSqlInstanceUsageSchema.measures.totalCpuUsedCoreHours,
        gcpCloudSqlInstanceUsageSchema.measures.totalDiskSizeBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalDiskUsedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalNetworkSentBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalRamReservedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalRamUsedBytes,
        gcpCloudSqlInstanceUsageSchema.measures.totalRamUsedBytesWithBuffer,
      ];

      const start = params.dateRange[0].getTime();
      const end = params.dateRange[1].getTime();
      const diffInMS = end - start;
      let hoursInRange = diffInMS / (1000 * 60 * 60);

      if (hoursInRange === 0) {
        hoursInRange = 24;
      }

      const latestParams = params.latest
        ? { granularity: TimeGranularity.HOUR }
        : {};

      let result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.CLOUD_SQL_INSTANCE_USAGE,
          dateRange: params.dateRange,
          measures,
          queryFilters: params.queryFilters,
          dimensions: params.dimensions,
          ...latestParams,
        })
      );

      if (params.latest) {
        const latestTimestamp = result.slice(-1)[0]?.timestamp;
        result = result.filter(
          (datum) =>
            latestTimestamp !== undefined && datum.timestamp === latestTimestamp
        );

        result.forEach((datum) => {
          delete datum.timestamp;
        });
      }

      const data: CloudSQLUsageDatum[] = result.map(
        (datum): CloudSQLUsageDatum => {
          const [error, validData] = validate(datum, CloudSQLUsageStruct);
          if (error) {
            throw new UError("INVALID_CLOUD_SQL_USAGE_SUMMARY", {
              context: { error, result: datum },
            });
          }

          const avg = (value: number | null) => (value ?? 0) / hoursInRange;
          const ifLatest = (value: number | null) =>
            params.latest ? (value ?? 0) : 0;

          return {
            avgCpuReservedCores: avg(validData.totalCpuReservedCores),
            avgCpuUsedCoreHours: avg(validData.totalCpuUsedCoreHours),
            avgDiskSizeBytes: avg(validData.totalDiskSizeBytes),
            avgDiskUsedBytes: avg(validData.totalDiskUsedBytes),
            avgNetworkSentBytes: avg(validData.totalNetworkSentBytes),
            avgRamReservedBytes: avg(validData.totalRamReservedBytes),
            avgRamUsedBytes: avg(validData.totalRamUsedBytes),
            databaseId: validData.databaseId ?? "null",
            databaseType: validData.databaseType ?? "null",
            latestProvisionedCpu: ifLatest(validData.maxCpuReservedCores),
            latestProvisionedDisk: ifLatest(validData.maxDiskSizeBytes),
            latestProvisionedRam: ifLatest(validData.ramReservedBytes),
            latestUsedCpu: ifLatest(validData.maxCpuAverageUsedCores),
            latestUsedDisk: ifLatest(validData.maxDiskUsedBytes),
            latestUsedRam: ifLatest(validData.maxRamAverageUsedBytes),
            maxCpuAverageUsedCores: validData.maxCpuAverageUsedCores ?? 0,
            maxCpuReservedCores: validData.maxCpuReservedCores ?? 0,
            maxDiskSizeBytes: validData.maxDiskSizeBytes ?? 0,
            maxDiskUsedBytes: validData.maxDiskUsedBytes ?? 0,
            maxRamAverageUsedBytes: validData.maxRamAverageUsedBytes ?? 0,
            maxRamUsedBytesWithBuffer: validData.maxRamUsedBytesWithBuffer ?? 0,
            networkSentBytes: validData.networkSentBytes ?? 0,
            projectId: validData.projectId ?? "null",
            ramReservedBytes: validData.ramReservedBytes ?? 0,
            region: validData.region ?? "null",
            totalCost: validData.totalCost ?? 0,
            totalCpuReservedCores: validData.totalCpuReservedCores ?? 0,
            totalCpuUsedCoreHours: validData.totalCpuUsedCoreHours ?? 0,
            totalDiskSizeBytes: validData.totalDiskSizeBytes ?? 0,
            totalDiskUsedBytes: validData.totalDiskUsedBytes ?? 0,
            totalNetworkSentBytes: validData.totalNetworkSentBytes ?? 0,
            totalRamReservedBytes: validData.totalRamReservedBytes ?? 0,
            totalRamUsedBytes: validData.totalRamUsedBytes ?? 0,
            totalRamUsedBytesWithBuffer:
              validData.totalRamUsedBytesWithBuffer ?? 0,
          };
        }
      );

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
