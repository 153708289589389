import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  costKind: {
    displayName: "Cost Kind",
    providerType: ProviderType.GCP,
    schemaName: "costKind",
  },
  lowCarbon: {
    displayName: "Low Carbon",
    providerType: ProviderType.GCP,
    schemaName: "lowCarbon",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.GCP,
    schemaName: "projectId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.GCP,
    schemaName: "region",
  },
  storageClass: {
    displayName: "Storage Class",
    providerType: ProviderType.GCP,
    schemaName: "storageClass",
  },
} as const;

export const gcpStorageCostSchema = { dimensions, measures };
