import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { azureCommitmentTableSchema } from "@ternary/api-lib/analytics/schemas/azureCommitmentTable";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { format, startOfDay, sub } from "date-fns";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureCommitmentInventoryTotal } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
  isTotals?: boolean;
}

const ISO_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const defaultAzureCommitmentInventoryTotals = {
  avgUtilizationPercent: 0,
  instanceCount: 0,
  maxUtilizationPercent: 0,
  minUtilizationPercent: 0,
  recurringCost: 0,
  reservedHours: 0,
  totalCost: 0,
  upfrontCost: 0,
  usedHours: 0,
};

export default function useGetAzureCommitmentInventoryTotals(
  params: Params,
  options?: UseQueryOptions<AzureCommitmentInventoryTotal, UError>
): UseQueryResult<AzureCommitmentInventoryTotal, UError> {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const earliest1YPurchaseDate = sub(params.dateRange[0], {
    years: 1,
  });

  const term1YFilter: QueryFilter = {
    and: [
      {
        schemaName:
          azureCommitmentTableSchema.dimensions.purchaseDate.schemaName,
        operator: Operator.GTE,
        values: [format(earliest1YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        schemaName: azureCommitmentTableSchema.dimensions.term.schemaName,
        operator: Operator.EQUALS,
        values: ["P1Y"],
      },
    ],
  };

  const earliest3YPurchaseDate = sub(params.dateRange[0], {
    years: 3,
  });

  const dateRange = [earliest3YPurchaseDate, startOfDay(dateHelper.date)];

  const term3YFilter: QueryFilter = {
    and: [
      {
        schemaName:
          azureCommitmentTableSchema.dimensions.purchaseDate.schemaName,
        operator: Operator.GTE,
        values: [format(earliest3YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        schemaName: azureCommitmentTableSchema.dimensions.term.schemaName,
        operator: Operator.EQUALS,
        values: ["P3Y"],
      },
    ],
  };

  const queryFilters: QueryFilter[] = [
    ...(params.queryFilters ?? []),
    { or: [term1YFilter, term3YFilter] },
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: queryFilters,
    dataSource: DataSource.AZURE_COMMITMENT_TABLE,
    dateRange,
    dimensions: [azureCommitmentTableSchema.dimensions.commitmentId],
    measures: [
      azureCommitmentTableSchema.measures.avgUtilizationPercent,
      azureCommitmentTableSchema.measures.instanceCount,
      azureCommitmentTableSchema.measures.maxUtilizationPercent,
      azureCommitmentTableSchema.measures.minUtilizationPercent,
      azureCommitmentTableSchema.measures.recurringCost,
      azureCommitmentTableSchema.measures.reservedHours,
      azureCommitmentTableSchema.measures.totalCost,
      azureCommitmentTableSchema.measures.upfrontCost,
      azureCommitmentTableSchema.measures.usedHours,
    ],
  });

  return useQuery({
    queryKey: ["azureCommitmenTotals", params],
    queryFn: async () => {
      const commitmentInventory = await client.loadData(tenantID, query);

      if (commitmentInventory.response.length === 0) {
        return defaultAzureCommitmentInventoryTotals;
      }

      const commitments = commitmentInventory.response.map(
        (datum) =>
          ({
            avgUtilizationPercent: datum.avgUtilizationPercent ?? 0,
            instanceCount: datum.instanceCount ?? 0,
            maxUtilizationPercent: datum.maxUtilizationPercent ?? 0,
            minUtilizationPercent: datum.minUtilizationPercent ?? 0,
            recurringCost: datum.recurringCost ?? 0,
            reservedHours: datum.reservedHours ?? 0,
            totalCost: datum.totalCost ?? 0,
            upfrontCost: datum.upfrontCost ?? 0,
            usedHours: datum.usedHours ?? 0,
          }) as AzureCommitmentInventoryTotal
      );

      const totals = commitments.reduce((totals, commitment) => ({
        avgUtilizationPercent:
          totals.avgUtilizationPercent + commitment.avgUtilizationPercent,
        instanceCount: totals.instanceCount + commitment.instanceCount,
        maxUtilizationPercent:
          totals.maxUtilizationPercent + commitment.maxUtilizationPercent,
        minUtilizationPercent:
          totals.minUtilizationPercent + commitment.minUtilizationPercent,
        recurringCost: totals.recurringCost + commitment.recurringCost,
        reservedHours: totals.reservedHours + commitment.reservedHours,
        totalCost: totals.totalCost + commitment.totalCost,
        upfrontCost: totals.upfrontCost + commitment.upfrontCost,
        usedHours: totals.usedHours + commitment.usedHours,
      }));

      totals.avgUtilizationPercent /= commitments.length;
      totals.maxUtilizationPercent /= commitments.length;
      totals.minUtilizationPercent /= commitments.length;

      return totals;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
