import { ProviderType, UnitType } from "../enums";

const measures = {
  max_PerNodeHour_CPUReservedCapacity: {
    displayName: "max_PerNodeHour_CPUReservedCapacity",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_CPUReservedCapacity",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_CPUUtilization: {
    displayName: "max_PerNodeHour_CPUUtilization",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_CPUUtilization",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_FilesystemUtilization: {
    displayName: "max_PerNodeHour_FilesystemUtilization",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_FilesystemUtilization",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_MemoryReservedCapacity: {
    displayName: "max_PerNodeHour_MemoryReservedCapacity",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_MemoryReservedCapacity",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_MemoryUtilization: {
    displayName: "max_PerNodeHour_MemoryUtilization",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_MemoryUtilization",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_NetworkTotalBytesPerSecond: {
    displayName: "max_PerNodeHour_NetworkTotalBytesPerSecond",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_NetworkTotalBytesPerSecond",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_NumberOfRunningContainers: {
    displayName: "max_PerNodeHour_NumberOfRunningContainers",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_NumberOfRunningContainers",
    unit: UnitType.STANDARD,
  },
  max_PerNodeHour_NumberOfRunningPods: {
    displayName: "max_PerNodeHour_NumberOfRunningPods",
    providerType: ProviderType.AWS,
    schemaName: "max_PerNodeHour_NumberOfRunningPods",
    unit: UnitType.STANDARD,
  },
  sum_RemainingCPUHour: {
    displayName: "sum_RemainingCPUHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_RemainingCPUHour",
    unit: UnitType.STANDARD,
  },
  sum_RemainingMemoryByteHour: {
    displayName: "sum_RemainingMemoryByteHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_RemainingMemoryByteHour",
    unit: UnitType.BYTES,
  },
  sum_TotalCPUHour: {
    displayName: "sum_TotalCPUHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_TotalCPUHour",
    unit: UnitType.STANDARD,
  },
  sum_TotalMemoryByteHour: {
    displayName: "sum_TotalMemoryByteHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_TotalMemoryByteHour",
    unit: UnitType.BYTES,
  },
  sum_UsedCPUHour: {
    displayName: "sum_UsedCPUHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_UsedCPUHour",
    unit: UnitType.BYTES,
  },
  sum_UsedMemoryByteHour: {
    displayName: "sum_UsedMemoryByteHour",
    providerType: ProviderType.AWS,
    schemaName: "sum_UsedMemoryByteHour",
    unit: UnitType.BYTES,
  },
} as const;

const dimensions = {
  accountID: {
    displayName: "Account ID",
    providerType: ProviderType.AWS,
    schemaName: "accountId",
  },
  clusterName: {
    displayName: "Cluster Name",
    providerType: ProviderType.AWS,
    schemaName: "clusterName",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AWS,
    schemaName: "instanceId",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceTypeFamily: {
    displayName: "Instance Type Family",
    providerType: ProviderType.AWS,
    schemaName: "instanceTypeFamily",
  },
  memoryPerInstance: {
    displayName: "Memory Per Instance",
    providerType: ProviderType.AWS,
    schemaName: "memoryPerInstance",
  },
  nodeName: {
    displayName: "Node Name",
    providerType: ProviderType.AWS,
    schemaName: "nodeName",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  timestamp: {
    displayName: "Timestamp",
    providerType: ProviderType.AWS,
    schemaName: "timestamp",
  },
  vcpuPerInstance: {
    displayName: "VCPU Per Instance",
    providerType: ProviderType.AWS,
    schemaName: "vcpuPerInstance",
  },
} as const;

export const awsKubernetesNodeUsageSchema = { dimensions, measures };
