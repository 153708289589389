import { ProviderType, UnitType } from "../enums";

const measures = {
  minDailyCost: {
    displayName: "Min Daily Cost",
    providerType: ProviderType.GCP,
    schemaName: "minDailyCost",
    unit: UnitType.CURRENCY,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.GCP,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  billingAccountID: {
    displayName: "Billing Account ID",
    providerType: ProviderType.GCP,
    schemaName: "billingAccountId",
  },
  serviceDescription: {
    displayName: "Service Description",
    providerType: ProviderType.GCP,
    schemaName: "serviceDescription",
  },
};

export const gcpCudCoverableCostSchema = {
  dimensions,
  measures,
};
