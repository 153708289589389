import { Operator, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  AlertRuleDirection,
  AlertRuleOperator,
  CostAlertEventType,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleCreatedEvent } from "@ternary/api-lib/core/events/AlertRuleCreatedEvent";
import {
  AlertRuleEntity,
  AlertRuleEventEntity,
} from "@ternary/api-lib/core/types";
import { CostAlertEntity } from "@ternary/api-lib/core/types/CostAlert";
import { hasProperty } from "@ternary/api-lib/utils/typeGuards";
import { add, startOfMonth, sub } from "date-fns";
import { sortBy } from "lodash";
import copyText from "./copyText";
import {
  LEGACY_BIG_QUERY_RULE_ID,
  LEGACY_BILLING_RULE_ID,
  legacyBigQueryAlertRule,
  legacyBillingAlertRule,
} from "./defaultAlertRules";
import { CostAlertDimension, CostAlertLegacySourceRule } from "./types";

//
// Typeguards
//

export function isCostAlert(
  event: AlertRuleEventEntity | CostAlertEntity
): event is CostAlertEntity {
  return hasProperty(event, "eventValue");
}

export function isDirection(input: string): input is AlertRuleDirection {
  return Object.keys(AlertRuleDirection).some(
    (key) => AlertRuleDirection[key] === input
  );
}

export function isAlertRuleOperator(input: string): input is AlertRuleOperator {
  return Object.keys(AlertRuleOperator).some(
    (key) => AlertRuleOperator[key] === input
  );
}

//
// Helper Functions
//

export function formatAnomalyChartTimestamp(
  timestamp: string,
  dateFormat: string
): string {
  if (isNaN(Date.parse(timestamp))) return "";

  return formatDate(new Date(timestamp), dateFormat);
}

export function getAnomalyDateRange(
  eventTime: string,
  granularity: TimeGranularity
): [Date, Date] {
  const newDate = new Date(new Date(eventTime).toISOString());

  let startDate;
  let endDate;

  switch (granularity) {
    case TimeGranularity.DAY: {
      startDate = sub(newDate, { days: 75 });
      endDate = add(newDate, { days: 15 });
      break;
    }
    case TimeGranularity.HOUR: {
      startDate = sub(newDate, { hours: 12 });
      endDate = add(newDate, { hours: 12 });
      break;
    }
    case TimeGranularity.MINUTE: {
      startDate = sub(newDate, { days: 1 });
      endDate = add(newDate, { days: 1 });
      break;
    }
    case TimeGranularity.MONTH: {
      startDate = startOfMonth(newDate);
      endDate = newDate;
      break;
    }
    default: {
      startDate = sub(newDate, { days: 15 });
      endDate = add(newDate, { days: 15 });
    }
  }

  return [startDate, endDate];
}

export function getDelta(
  actual: number,
  expected: { upperBound: number; lowerBound: number }
): number {
  if (actual > expected.upperBound) {
    return actual - expected.upperBound;
  } else {
    return actual - expected.lowerBound;
  }
}

export function getSourceAlertRuleName(costAlert: {
  alertRuleID: string;
  alertRule?: { name: string };
}) {
  if (
    costAlert.alertRuleID ===
    CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
  ) {
    return copyText.stringifiedLegacyBillingAnomalySourceAlert;
  } else if (
    costAlert.alertRuleID ===
    CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML
  ) {
    return copyText.stringifiedLegacyBigQueryAnomalySourceAlert;
  } else if (costAlert.alertRule) {
    return costAlert.alertRule.name;
  } else {
    return "--";
  }
}

type AlertRuleDataSnapshot = Omit<AlertRuleCreatedEvent, "type"> & {
  type: AlertRuleEventEntity["type"];
  id: string;
};

export function populateCostAlert(
  selectedCostAlert: CostAlertEntity,
  alertRule?: AlertRuleEntity
) {
  if (selectedCostAlert.alertRuleID === LEGACY_BILLING_RULE_ID) {
    const alertRule = legacyBillingAlertRule as AlertRuleEntity;

    return {
      ...selectedCostAlert,
      sourceAlertRule: alertRule,
    };
  }

  if (selectedCostAlert.alertRuleID === LEGACY_BIG_QUERY_RULE_ID) {
    const alertRule = legacyBigQueryAlertRule as AlertRuleEntity;

    return {
      ...selectedCostAlert,
      sourceAlertRule: alertRule,
    };
  }

  const alertRuleEvents = alertRule?.events ?? [];

  const sortedAlertRuleEvents = sortBy(
    alertRuleEvents.filter(
      (alertRuleEvent) =>
        alertRuleEvent.createdAt < (selectedCostAlert?.createdAt ?? "")
    ),
    "createdAt"
  );

  const alertRuleDataSnapshot = sortedAlertRuleEvents.reduce(
    (accum, alertRuleEvent) => {
      return { ...accum, ...alertRuleEvent.data };
    },
    {} as AlertRuleDataSnapshot
  );

  return {
    ...selectedCostAlert,
    sourceAlertRule: alertRuleDataSnapshot,
  };
}

//
// String Helper Functions
//

export function getStringifiedCostAlertEventType(type: CostAlertEventType) {
  switch (type) {
    case CostAlertEventType.ANOMALY_DETECTED: {
      return copyText.stringifiedAlertTypeAnomalyDetected;
    }
    case CostAlertEventType.THRESHOLD_BREACHED: {
      return copyText.stringifiedAlertTypeThresholdBreached;
    }
  }
}

export function getStringifiedDelta(
  actual: number,
  expected: { upperBound: number; lowerBound: number }
): string {
  if (actual > expected.upperBound) {
    return `+ ${formatCurrency({
      number: actual - expected.upperBound,
    })}`;
  } else {
    return `- ${formatCurrency({
      number: expected.lowerBound - actual,
    })}`;
  }
}

export function getStringifiedDimensions(
  dimensions: CostAlertDimension[]
): string[] {
  return dimensions.map((dimension) => {
    return `${dimension.key} = ${dimension.value}`;
  });
}

export function getStringifiedRange(lower: number, upper: number): string {
  return `${formatCurrency({
    accounting: true,
    number: lower < 0 ? 0 : lower,
  })}
   - 
   ${formatCurrency({
     accounting: true,
     number: upper < 0 ? 0 : upper,
   })}`;
}

export function getOperatorText(operator?: Operator): string {
  switch (operator) {
    case Operator.CONTAINS:
      return "∋";
    case Operator.EQUALS:
      return "=";
    case Operator.NOT_CONTAINS:
      return "∌";
    case Operator.NOT_EQUALS:
      return "≠";
    case Operator.NOT_SET:
      return copyText.operatorOptionNotSetLabel;
    case Operator.SET:
      return copyText.operatorOptionSetLabel;
    default:
      return "=";
  }
}
