import { DataSource, Operator, TimeGranularity } from "../enums";
import { AnalyticsApiClient } from "./AnalyticsApiClient";
import DataQuery from "./DataQuery";

type BudgetSpendDatum = {
  cost?: number;
  netCost?: number;
  timestamp: string;
};

type BudgetScope = {
  key: string;
  values: string[];
};

type Budget = {
  measure: string;
  scopes?: BudgetScope[];
};

interface Params {
  budget: Budget;
  client: AnalyticsApiClient;
  dateRange: Date[];
  granularity?: TimeGranularity;
  tenantID: string;
}

export default async function getBudgetData(
  params: Params
): Promise<BudgetSpendDatum[]> {
  const preAggFilters = params.budget.scopes
    ? params.budget.scopes.map((scope) => ({
        schemaName: scope.key,
        operator: Operator.EQUALS,
        values: scope.values,
      }))
    : [];

  const result = await params.client.loadData<BudgetSpendDatum>(
    params.tenantID,
    new DataQuery({
      dataSource: DataSource.BILLING,
      dateRange: params.dateRange,
      granularity: params.granularity ?? TimeGranularity.DAY,
      measures: [{ schemaName: params.budget.measure }],
      preAggFilters,
    })
  );

  return result.response;
}
