import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGetDimensionPreferencesByTenantID from "@/features/admin/hooks/useGetDimensionPreferencesByTenantID";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Grid from "@/ui-lib/components/Grid";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faFileExport,
  faList,
  faLock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { UserEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import { keyBy, uniq } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import useCreateCustomLabel from "../../../api/core/hooks/useCreateCustomLabel";
import useDeleteCustomLabel from "../../../api/core/hooks/useDeleteCustomLabel";
import useGetCustomLabelsByTenantID from "../../../api/core/hooks/useGetCustomLabelsByTenantID";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import useUpdateCustomLabel from "../../../api/core/hooks/useUpdateCustomLabel";
import Card from "../../../components/Card";
import NotConfiguredPlaceholder from "../../../components/NotConfiguredPlaceholder";
import externalLinks from "../../../constants/externalLinks";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { useAvailableDimensionsByDataSourceV2 } from "../../../hooks/useAvailableDimensionsByDataSource";
import copyText from "../../../pages/CustomLabelsPage.copyText";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import { useCustomLabelsCsvData } from "../hooks/useCustomLabelsCsvData";
import CustomLabelCard, { CARD_HEIGHT } from "./CustomLabelCard";
import CustomLabelManagerModal from "./CustomLabelsManagerModal";

const MODAL_CREATE = "MODAL_CREATE";
const MODAL_DELETE = "MODAL_DELETE";
const MODAL_UPDATE = "MODAL_UPDATE";

type Interaction =
  | CustomLabelCard.Interaction
  | CustomLabelManagerModal.Interaction;

interface State {
  modalKey: string;
  searchText: string;
  selectedCustomLabelID: string | null;
  selectedLabelKey: string | null;
}

const initialState = {
  modalKey: "",
  searchText: "",
  selectedCustomLabelID: null,
  selectedLabelKey: null,
};

export default function CustomLabelsManagerContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _customLabels = [],
    isLoading: isLoadingCustomLabels,
    refetch: refetchCustomLabels,
  } = useGetCustomLabelsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  const availableDimensions = useAvailableDimensionsByDataSourceV2(
    DataSource.BILLING
  );

  const dimension = state.selectedLabelKey
    ? availableDimensions.find(
        (dimension) => dimension.schemaName === state.selectedLabelKey
      )
    : undefined;

  const {
    data: dimensionValuesMap = {},
    isFetching: isLoadingDimensionValues,
  } = useGetDimensionValuesByDataSource(
    {
      dataSource: DataSource.BILLING,
      dateRange: getCubeDateRangeFromDurationType(
        DurationType.LAST_NINETY_DAYS
      ),
      dimensions: dimension ? [dimension] : [],
    },
    { enabled: !!state.selectedLabelKey }
  );

  //
  // Mutations
  //

  const { isPending: isCreatingCustomLabel, mutate: createCustomLabel } =
    useCreateCustomLabel({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingCustomLabelMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CLICK_CUSTOM_LABEL_CREATE);
      },
      onSuccess: () => {
        postAlert({
          message: copyText.successCreatingCustomLabelMessage,
          type: AlertType.SUCCESS,
        });

        refetchCustomLabels();

        mergeState({ modalKey: "" });
      },
    });

  const { isPending: isDeletingCustomLabel, mutate: deleteCustomLabel } =
    useDeleteCustomLabel({
      onError: () => {
        postAlert({
          message: copyText.errorDeletingCustomLabelMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CLICK_CUSTOM_LABEL_DELETE);
      },
      onSuccess: () => {
        postAlert({
          message: copyText.successDeletingCustomLabelMessage,
          type: AlertType.SUCCESS,
        });

        refetchCustomLabels();

        mergeState({ modalKey: "", selectedCustomLabelID: null });
      },
    });

  const { isPending: isUpdatingCustomLabel, mutate: updateCustomLabel } =
    useUpdateCustomLabel({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingCustomLabelMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CLICK_CUSTOM_LABEL_UPDATE);
      },
      onSuccess: () => {
        postAlert({
          message: copyText.successUpdatingCustomLabelMessage,
          type: AlertType.SUCCESS,
        });

        refetchCustomLabels();

        mergeState({ modalKey: "", selectedCustomLabelID: null });
      },
    });

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy<UserEntity | undefined>(users, "id");

  const customLabels = _customLabels.map((label) => {
    const createdByUser = usersKeyedByID[label.createdByID];

    const updatedByUser = label.updatedByID
      ? usersKeyedByID[label.updatedByID]
      : undefined;

    return {
      ...label,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const csvData = useCustomLabelsCsvData(customLabels);

  const selectedCustomLabel = customLabels.find(
    (label) => label.id === state.selectedCustomLabelID
  );

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case CustomLabelCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE,
          selectedCustomLabelID: interaction.customLabelID,
        });
        return;
      }
      case CustomLabelCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE,
          selectedCustomLabelID: interaction.customLabelID,
        });
        return;
      }
      case CustomLabelManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({ modalKey: "", selectedCustomLabelID: null });
        return;
      }
      case CustomLabelManagerModal.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (state.selectedCustomLabelID) {
          updateCustomLabel({
            customLabelID: state.selectedCustomLabelID,
            matchers: [interaction.matcher],
            outputs: interaction.outputs,
          });
        } else {
          createCustomLabel({
            tenantID: authenticatedUser.tenant.fsDocID,
            matchers: [interaction.matcher],
            outputs: interaction.outputs,
          });
        }
        return;
      }
      case CustomLabelManagerModal.INTERACTION_MATCH_KEY_CHANGED: {
        mergeState({ selectedLabelKey: interaction.matchKey });
        return;
      }
    }
  }

  //
  // Render
  //

  if (!gatekeeper.isConfiguredCustomLabels) {
    return <NotConfiguredPlaceholder />;
  }

  if (!gatekeeper.canListCustomLabels) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const csvFileName =
    "custom_labels-" +
    `${authenticatedUser.tenant.name}-` +
    format(new Date(), "MM-dd-yyyy");

  const selectedDimensionValues =
    dimensionValuesMap[state.selectedLabelKey ?? ""] ?? [];

  const filteredLabels = customLabels.filter((label) => {
    const str = state.searchText.toLowerCase();
    const regex = new RegExp(str, "i");

    const matchedExistingLabel = label.matchers.some((matchedEntity) => {
      return (
        matchedEntity.key.toLowerCase().includes(str) ||
        matchedEntity.values.some((e) => regex.test(e))
      );
    });

    const matchedCustomLabel = label.outputs.some((outputEntity) => {
      return (
        outputEntity.key.toLowerCase().includes(str) ||
        outputEntity.value.toLowerCase().includes(str)
      );
    });

    return matchedExistingLabel || matchedCustomLabel;
  });

  const existingKeys = uniq(
    customLabels.reduce((accum: string[], label) => {
      const keys = label.outputs.map((output) => {
        return output.key;
      });
      return [...accum, ...keys];
    }, [])
  );

  const existingValues = uniq(
    customLabels.reduce((accum: string[], label) => {
      const values = label.outputs.map((output) => {
        return output.value;
      });

      return [...accum, ...values];
    }, [])
  );

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_CREATE: {
        return (
          <CustomLabelManagerModal
            availableDimensions={availableDimensions}
            dimensionsPreferences={dimensionPreferences}
            existingKeys={existingKeys}
            existingValues={existingValues}
            isLoading={isLoadingDimensionValues}
            isOpen
            isProcessing={isCreatingCustomLabel}
            selectedDimensionValues={selectedDimensionValues}
            title={copyText.customLabelsModalTitleCreation}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DELETE: {
        return (
          <ConfirmationModal
            isLoading={isDeletingCustomLabel}
            message={copyText.customLabelModalDeleteMessage}
            title={copyText.deleteCustomLabelConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedCustomLabelID: null })
            }
            onConfirm={() =>
              state.selectedCustomLabelID &&
              deleteCustomLabel({ customLabelID: state.selectedCustomLabelID })
            }
          />
        );
      }
      case MODAL_UPDATE: {
        return (
          <CustomLabelManagerModal
            availableDimensions={availableDimensions}
            dimensionsPreferences={dimensionPreferences}
            existingKeys={existingKeys}
            existingValues={existingValues}
            isOpen
            customLabel={selectedCustomLabel}
            isLoading={isLoadingDimensionValues}
            isProcessing={isUpdatingCustomLabel}
            selectedDimensionValues={selectedDimensionValues}
            title={copyText.modalTitleEdit}
            onInteraction={handleInteraction}
          />
        );
      }
      default:
        return null;
    }
  }

  return (
    <>
      {renderModal()}
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
      >
        <Flex
          alignItems="center"
          borderBottom={`1px solid ${theme.section_card_border}`}
          justifyContent="space-between"
          padding={theme.space_md}
        >
          <Text fontSize={theme.h4_fontSize} fontWeight={theme.h3_fontWeight}>
            {copyText.customLabelsSectionTitle}
          </Text>
          <Box width={300}>
            <TextInput
              placeholder={copyText.searchInputPlaceholder}
              value={state.searchText}
              onChange={(event) => {
                mergeState({ searchText: event.target.value });
              }}
            />
          </Box>
        </Flex>
        <Box padding={theme.space_lg}>
          <Text marginBottom={theme.space_md}>
            {copyText.customLabelsSectionInstructions}
          </Text>
          <Flex justifyContent="space-between">
            <Text>
              {copyText.learnMoreCaption + " "}
              <a
                href={externalLinks.readmeCustomLabelsDocumentation}
                rel="noreferrer"
                target="_blank"
              >
                {copyText.learnMoreLink}
              </a>
            </Text>
            <CSVLink data={csvData} filename={csvFileName}>
              <Button
                size="tiny"
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                secondary
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Box>
      </Box>
      <Box>
        {isLoadingCustomLabels ? (
          <EmptyPlaceholder
            icon={faList}
            loading={isLoadingCustomLabels}
            text={copyText.customLabelsListEmpty}
            skeletonVariant="cards"
          />
        ) : (
          <Grid
            gridGap={theme.space_lg}
            gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
          >
            {
              <Card
                height={CARD_HEIGHT}
                padding={theme.space_md}
                topOnHover="-0.2rem"
                transition="top ease 0.5s"
                width="100%"
                onClick={() => mergeState({ modalKey: MODAL_CREATE })}
              >
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  direction="column"
                  height="100%"
                  justifyContent="center"
                >
                  <Icon
                    clickable
                    color={
                      gatekeeper.canCreateCustomLabel
                        ? theme.primary_color_text
                        : theme.text_color_disabled
                    }
                    icon={gatekeeper.canCreateCustomLabel ? faPlus : faLock}
                    size={"2x"}
                  />
                  <Text
                    color={
                      gatekeeper.canCreateCustomLabel
                        ? theme.primary_color_text
                        : theme.text_color_disabled
                    }
                    fontSize={theme.h4_fontSize}
                    marginTop={theme.space_xxs}
                  >
                    {copyText.customLabelsSectionAddCard}
                  </Text>
                </Flex>
              </Card>
            }
            {filteredLabels.map((label) => (
              <CustomLabelCard
                key={label.id}
                label={label}
                loading={isLoadingCustomLabels}
                onInteraction={handleInteraction}
              />
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
}

export function compareLabels(a: { key: string }, b: { key: string }) {
  return a.key > b.key ? 1 : -1;
}
