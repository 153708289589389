import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { alertEventsSchema } from "@ternary/api-lib/analytics/schemas/alertEvents";
import { predictionBoundsSchema } from "@ternary/api-lib/analytics/schemas/predictionBounds";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export type Params = {
  dateRange: Date[];
  costAlertID?: string;
};

export type PredictionBoundData = {
  lowerBound: number;
  upperBound: number;
  timestamp: string;
};

export default function useGetBoundsData(
  params: Params,
  options?: UseQueryOptions<PredictionBoundData[], UError>
): UseQueryResult<PredictionBoundData[], UError> {
  const client = useAnalyticsApiClient();

  const filters = params.costAlertID
    ? [
        {
          schemaName: alertEventsSchema.dimensions.alertEventID.schemaName,
          operator: Operator.EQUALS,
          values: [params.costAlertID],
        },
      ]
    : [];

  const dateRange =
    params.dateRange.length === 2 ? params.dateRange : [new Date(), new Date()];

  const [tenantID, query] = useBuildDataQuery({
    preAggFilters: filters,
    dataSource: DataSource.PREDICTION_BOUNDS,
    dateRange,
    measures: [
      predictionBoundsSchema.measures.lowerBound,
      predictionBoundsSchema.measures.upperBound,
    ],
    granularity: TimeGranularity.DAY,
    ignoreGlobalFilters: true,
  });

  let enabled = options?.enabled ?? true;
  enabled = enabled && Boolean(params.costAlertID);

  return useQuery({
    queryKey: ["predictionBoundData", params],
    queryFn: async () => {
      const result = await client.loadData<PredictionBoundData>(
        tenantID,
        query
      );

      return result.response;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled,
    ...options,
  });
}
