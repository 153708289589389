import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  databaseConnections: {
    displayName: "Database Connections",
    providerType: ProviderType.AWS,
    schemaName: "databaseConnections",
    unit: UnitType.STANDARD,
  },
  networkBytes: {
    displayName: "Network Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkBytes",
    unit: UnitType.STANDARD,
  },
  productMemoryBytesChart: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytesChart",
    unit: UnitType.STANDARD,
  },
  productMemoryBytesMeasure: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytesMeasure",
    unit: UnitType.STANDARD,
  },
  productVCPUChart: {
    displayName: "Product vCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPUChart",
    unit: UnitType.STANDARD,
  },
  productVCPUMeasure: {
    displayName: "Product vCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPUMeasure",
    unit: UnitType.STANDARD,
  },
  readIOPS: {
    displayName: "Read IOPS",
    providerType: ProviderType.AWS,
    schemaName: "readIOPS",
    unit: UnitType.STANDARD,
  },
  usedDiskBytes: {
    displayName: "Used Disk Bytes",
    providerType: ProviderType.AWS,
    schemaName: "usedDiskBytes",
    unit: UnitType.STANDARD,
  },
  usedMemoryBytes: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "usedMemoryBytes",
    unit: UnitType.STANDARD,
  },
  usedMemoryBytesChart: {
    displayName: "Used Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "usedMemoryBytesChart",
    unit: UnitType.STANDARD,
  },
  usedVCPU: {
    displayName: "Used vCPU",
    providerType: ProviderType.AWS,
    schemaName: "usedVCPU",
    unit: UnitType.STANDARD,
  },
  usedVCPUChart: {
    displayName: "Used vCPU",
    providerType: ProviderType.AWS,
    schemaName: "usedVCPUChart",
    unit: UnitType.STANDARD,
  },
  writeIOPS: {
    displayName: "Write IOPS",
    providerType: ProviderType.AWS,
    schemaName: "writeIOPS",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  databaseType: {
    displayName: "Database Type",
    providerType: ProviderType.AWS,
    schemaName: "databaseType",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.AWS,
    schemaName: "family",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AWS,
    schemaName: "instanceId",
  },
  lineItemUsageAccountId: {
    displayName: "Line Item Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
  productFamily: {
    displayName: "Product Family",
    providerType: ProviderType.AWS,
    schemaName: "productFamily",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
} as const;

export const awsDatabaseVisibilitySchema = {
  measures,
  dimensions,
};
