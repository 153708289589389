import { ProviderType, UnitType } from "../enums";

const measures = {
  databaseBytes: {
    displayName: "Database Bytes",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "databaseBytes",
    unit: UnitType.BYTES,
  },
  failsafeBytes: {
    displayName: "Failsafe Bytes",
    providerType: ProviderType.SNOWFLAKE,
    schemaName: "failsafeBytes",
    unit: UnitType.BYTES,
  },
};

export const snowflakeDatabaseUsageMeterHelperSchema = {
  measures,
};
