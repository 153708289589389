import { ProviderType, UnitType } from "../enums";

const measures = {
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AWS,
    schemaName: "cost",
    unit: UnitType.STANDARD,
  },
  dataTransferCost: {
    displayName: "Data Transfer Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferCost",
    unit: UnitType.STANDARD,
  },
  dataTransferAwsInboundCost: {
    displayName: "Data Transfer AWS Inbound Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferAwsInboundCost",
    unit: UnitType.STANDARD,
  },
  dataTransferAwsOutboundCost: {
    displayName: "Data Transfer AWS Outbound Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferAwsOutboundCost",
    unit: UnitType.STANDARD,
  },
  dataTransferInterRegionInboundCost: {
    displayName: "Data Transfer Inter-Region Inbound Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferInterRegionInboundCost",
    unit: UnitType.STANDARD,
  },
  dataTransferInterRegionOutboundCost: {
    displayName: "Data Transfer Inter-Region Outbound Cost",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferInterRegionOutboundCost",
    unit: UnitType.STANDARD,
  },
  serverlessManagedStorageCost: {
    displayName: "Serverless Managed Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "serverlessManagedStorageCost",
    unit: UnitType.STANDARD,
  },
  blockStorageCost: {
    displayName: "Block Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "blockStorageCost",
    unit: UnitType.STANDARD,
  },
  instanceCost: {
    displayName: "Instance Cost",
    providerType: ProviderType.AWS,
    schemaName: "instanceCost",
    unit: UnitType.STANDARD,
  },
  serverlessComputeCost: {
    displayName: "Serverless Compute Cost",
    providerType: ProviderType.AWS,
    schemaName: "serverlessComputeCost",
    unit: UnitType.STANDARD,
  },
  dataTransfer: {
    displayName: "Data Transfer",
    providerType: ProviderType.AWS,
    schemaName: "dataTransfer",
    unit: UnitType.STANDARD,
  },
  dataTransferAwsInbound: {
    displayName: "Data Transfer AWS Inbound",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferAwsInbound",
    unit: UnitType.STANDARD,
  },
  dataTransferAwsOutbound: {
    displayName: "Data Transfer AWS Outbound",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferAwsOutbound",
    unit: UnitType.STANDARD,
  },
  dataTransferInterRegionInbound: {
    displayName: "Data Transfer Inter-Region Inbound",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferInterRegionInbound",
    unit: UnitType.STANDARD,
  },
  dataTransferInterRegionOutbound: {
    displayName: "Data Transfer Inter-Region Outbound",
    providerType: ProviderType.AWS,
    schemaName: "dataTransferInterRegionOutbound",
    unit: UnitType.STANDARD,
  },
  nodeUsage: {
    displayName: "Node Usage",
    providerType: ProviderType.AWS,
    schemaName: "nodeUsage",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  domainArn: {
    displayName: "Domain ARN",
    providerType: ProviderType.AWS,
    schemaName: "domainArn",
  },
  domainID: {
    displayName: "Domain ID",
    providerType: ProviderType.AWS,
    schemaName: "domainId",
  },
  instanceFamily: {
    displayName: "Instance Family",
    providerType: ProviderType.AWS,
    schemaName: "instanceFamily",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  instanceMemory: {
    displayName: "Instance Memory",
    providerType: ProviderType.AWS,
    schemaName: "instanceMemory",
  },
  instanceVcpu: {
    displayName: "Instance VCPU",
    providerType: ProviderType.AWS,
    schemaName: "instanceVcpu",
  },
  payerAccountID: {
    displayName: "Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "payerAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  usageAccountID: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "usageAccountId",
  },
} as const;

export const awsOpenSearchVisibilitySchema = { measures, dimensions };
