import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpCombinedCudUtilizationSchema } from "@ternary/api-lib/analytics/schemas/gcpCombinedCudUtilization";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CudInventoryDatum, CudInventoryEntity } from "../types";

export default function useGetCudInventoryData(
  options?: UseQueryOptions<CudInventoryDatum[], UError>
): UseQueryResult<CudInventoryDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudInventoryData"],
    queryFn: async () => {
      const now = new DateHelper();

      const result = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
          dimensions: [
            gcpCombinedCudUtilizationSchema.dimensions.instanceFamily,
            gcpCombinedCudUtilizationSchema.dimensions.region,
            gcpCombinedCudUtilizationSchema.dimensions.service,
          ],
          measures: [
            gcpCombinedCudUtilizationSchema.measures.amountSaved,
            gcpCombinedCudUtilizationSchema.measures.hourlyCommittedAmount,
            gcpCombinedCudUtilizationSchema.measures.reservedMemoryGiB,
            gcpCombinedCudUtilizationSchema.measures.reservedSlots,
            gcpCombinedCudUtilizationSchema.measures.reservedVCPU,
            gcpCombinedCudUtilizationSchema.measures.utilizationPercent,
          ],
        })
      )) as CudInventoryEntity[];

      const data: CudInventoryDatum[] = result.map((datum) => {
        return {
          amountSaved: datum.amountSaved,
          hourlyCommittedAmount: datum.hourlyCommittedAmount,
          instanceFamily: datum.instanceFamily ?? null,
          region: datum.region,
          reservedSlots: datum.reservedSlots,
          reservedMemoryGiB: (datum.reservedMemoryGiB ?? 0) * 1073741824,
          reservedVCPU: datum.reservedVCPU,
          service: datum.service ?? null,
          utilizationPercent: datum.utilizationPercent,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
