import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpStorageCostSchema } from "@ternary/api-lib/analytics/schemas/gcpStorageCost";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { StorageCostDatum, StorageCostStruct } from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetStorageCostSummary(
  params: Params,
  options?: UseQueryOptions<StorageCostDatum[], UError>
): UseQueryResult<StorageCostDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["storageCostSummary", params],
    queryFn: async () => {
      const dimensions = [
        gcpStorageCostSchema.dimensions.lowCarbon,
        gcpStorageCostSchema.dimensions.projectID,
        gcpStorageCostSchema.dimensions.region,
        gcpStorageCostSchema.dimensions.storageClass,
        gcpStorageCostSchema.dimensions.costKind,
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.STORAGE_COST,
          dateRange: params.dateRange,
          dimensions,
          measures: [gcpStorageCostSchema.measures.cost],
        })
      );

      const data = result.map((datum): StorageCostDatum => {
        const [error, validData] = validate(datum, StorageCostStruct);
        if (error) {
          throw new UError("INVALID_STORAGE_COST_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          cost: validData.cost,
          costKind: validData.costKind,
          lowCarbon: validData.lowCarbon,
          projectId: validData.projectId ?? "null",
          region: validData.region ?? "null",
          storageClass: validData.storageClass ?? "null",
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
