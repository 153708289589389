import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { mspRollupSchema } from "@ternary/api-lib/analytics/schemas/mspRollup";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export type TenantBillingAccountMap = { [tenantID: string]: string[] };

type BillingAccountDatum = {
  billingAccountId: string;
  tenantId: string;
};

type Params = {
  dateRange: Date[];
  parentTenantID: string;
  tenantDocIDs: string[];
};

export default function useGetMspBillingAccountsByTenantIDs(
  params: Params,
  options?: UseQueryOptions<TenantBillingAccountMap, UError>
): UseQueryResult<TenantBillingAccountMap, UError> {
  const client = useAnalyticsApiClient();

  const [_, query] = useBuildDataQuery({
    dateRange: params.dateRange,
    dataSource: DataSource.MSP_ROLLUP,
    dimensions: [
      mspRollupSchema.dimensions.billingAccountID,
      mspRollupSchema.dimensions.tenantID,
    ],
    preAggFilters: [
      {
        schemaName: mspRollupSchema.dimensions.tenantID.schemaName,
        operator: Operator.EQUALS,
        values: params.tenantDocIDs,
      },
    ],
  });

  return useQuery({
    queryKey: ["mspBillingAccounts", params],
    queryFn: async () => {
      const result = await client.loadData<BillingAccountDatum>(
        params.parentTenantID,
        query
      );

      return result.response.reduce(
        (accum: { [tenantID: string]: string[] }, _entry) => {
          if (!_entry.billingAccountId) return accum;

          if (accum[_entry.tenantId]) {
            accum[_entry.tenantId].push(_entry.billingAccountId);
          } else {
            accum[_entry.tenantId] = [_entry.billingAccountId];
          }

          return accum;
        },
        {}
      );
    },
    ...options,
  });
}
