import { useQuery } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import UError from "unilib-error";
import { SpendSummaryEntity } from "../../../api/analytics/types";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type SpendSummaryDatum = {
  cost: number | null;
  credits: number | null;
};

interface Params {
  dateRange: Date[];
  dimension: Dimension;
  durationType: DurationType;
  preAggFilters: QueryFilter[];
}

export default function useGetSpendSummaryByDimension(
  params: Params,
  options?: UseQueryOptions<SpendSummaryEntity[], UError>
): UseQueryResult<SpendSummaryEntity[], UError> {
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const { dimension, ...restParams } = params;

  const [tenantID, query] = useBuildDataQuery({
    ...restParams,
    dataSource: DataSource.BILLING,
    dimensions: [dimension],
    measures: [
      billingCucSchema.measures.cost,
      billingCucSchema.measures.credits,
    ],
  });

  return useQuery({
    queryKey: ["spendSummary", params],
    queryFn: async () => {
      const result = await client.loadData<SpendSummaryDatum>(tenantID, query);

      return result.response.map((datum) => {
        const credits = datum.credits ?? 0;
        const grossCost = datum.cost ?? 0;

        return {
          credits,
          grossCost,
          name: String(datum[params.dimension.schemaName]), // NOTE: We intentionally stringify null for display purposes here.
          netCost: credits + grossCost,
        };
      });
    },
    enabled: gatekeeper.canViewCostReports,
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
