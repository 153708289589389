import IconAWSRedshift from "@/ui-lib/icons/IconAWSRedshift";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import useGetSpendSummaries from "../../../api/analytics/useGetSpendSummaries";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useGetChangePercent from "../../../hooks/useGetChangePercent";
import IconGCPBigQuery from "../../../ui-lib/icons/IconGCPBigQuery";
import IconSnowflake from "../../../ui-lib/icons/IconSnowflake";
import copyText from "../copyText";
import NotConfiguredSectionBadge from "./NotConfiguredSectionBadge";
import ServiceSectionBadge from "./ServiceSectionBadge";

export default function DataWarehoueInsightsLandingPage() {
  const gatekeeper = useGatekeeper();

  //
  // Queries
  //

  const awsRedshiftSpendSummaries = useGetSpendSummaries({
    dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
    excludeCredits: true,
  });

  const {
    data: currentRedshiftMtdSpend,
    isLoading: isLoadingAWSRedshiftSpend,
  } = awsRedshiftSpendSummaries[0];

  const {
    data: lastRedshiftMtdSpend,
    isLoading: isLoadingAWSRedshiftMonthlySpend,
  } = awsRedshiftSpendSummaries[2];

  const redshiftMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastRedshiftMtdSpend?.grossCost ?? 0,
    value: currentRedshiftMtdSpend?.grossCost ?? 0,
  });

  const bigQuerySpendSummaries = useGetSpendSummaries({
    dataSource: DataSource.BILLING,
    queryFilters: [
      {
        schemaName: billingCucSchema.dimensions.serviceDescription.schemaName,
        operator: Operator.EQUALS,
        values: ["BigQuery", "BigQuery Reservation API"],
      },
    ],
  });

  const {
    data: currentBigQueryMtdSpend,
    isLoading: isLoadingGCPBigQuerySpend,
  } = bigQuerySpendSummaries[0];

  const {
    data: lastBigQueryMtdSpend,
    isLoading: isLoadingGCPBigQueryMonthlySpend,
  } = bigQuerySpendSummaries[2];

  const bigQueryMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastBigQueryMtdSpend?.grossCost ?? 0,
    value: currentBigQueryMtdSpend?.grossCost ?? 0,
  });

  const snowflakeSpendSummary = useGetSpendSummaries({
    dataSource: DataSource.BILLING,
    queryFilters: [
      {
        schemaName: billingCucSchema.dimensions.vendor.schemaName,
        operator: Operator.EQUALS,
        values: ["Snowflake"],
      },
      {
        schemaName: billingCucSchema.dimensions.skuDescription.schemaName,
        operator: Operator.CONTAINS,
        values: ["compute", "cloud services"],
      },
    ],
  });

  const { data: currentSnowflakeMtdSpend, isLoading: isLoadingSnowflakeSpend } =
    snowflakeSpendSummary[0];

  const {
    data: lastSnowflakeMtdSpend,
    isLoading: isLoadingSnowflakeMonthlySpend,
  } = snowflakeSpendSummary[2];

  const snowflakeMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastSnowflakeMtdSpend?.grossCost ?? 0,
    value: currentSnowflakeMtdSpend?.grossCost ?? 0,
  });

  //
  // Render
  //

  const isLoadingRedshiftSpend =
    isLoadingAWSRedshiftMonthlySpend || isLoadingAWSRedshiftSpend;

  const isLoadingBigQuerySpend =
    isLoadingGCPBigQueryMonthlySpend || isLoadingGCPBigQuerySpend;

  const isLoadingSnowflakeSpendSummary =
    isLoadingSnowflakeSpend || isLoadingSnowflakeMonthlySpend;

  //
  // Render
  //

  return (
    <Box>
      {gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">{copyText.awsServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentRedshiftMtdSpend?.grossCost ?? 0}
            isLoading={isLoadingRedshiftSpend}
            mtdSpendComparison={redshiftMtdSpendComparison}
            path={paths._insightsDataWarehousRedshift}
            serviceIcon={<IconAWSRedshift size={50} />}
            serviceName={copyText.awsRedshiftPageTitle}
          />
        </>
      )}
      {gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">{copyText.gcpServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentBigQueryMtdSpend?.grossCost ?? 0}
            isLoading={isLoadingBigQuerySpend}
            mtdSpendComparison={bigQueryMtdSpendComparison}
            path={paths._insightsBigQuery}
            serviceIcon={<IconGCPBigQuery size={50} />}
            serviceName={copyText.bigQueryPageTitle}
          />
        </>
      )}
      {gatekeeper.hasSnowflakeIntegration && (
        <>
          <Text appearance="h3">{copyText.snowflakeSerivesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentSnowflakeMtdSpend?.grossCost ?? 0}
            isLoading={isLoadingSnowflakeSpendSummary}
            mtdSpendComparison={snowflakeMtdSpendComparison}
            path={paths._insightsDataWarehouseSnowflake}
            serviceIcon={<IconSnowflake size={50} />}
            serviceName={copyText.snowflakeDataWarehousesTitle}
          />
        </>
      )}
      {!gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAWSRedshift size={50} />}
            serviceName={copyText.awsRedshiftPageTitle}
          />
        </>
      )}
      {!gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">
            {copyText.gcpServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGCPBigQuery size={50} />}
            serviceName={copyText.bigQueryPageTitle}
          />
        </>
      )}
      {!gatekeeper.hasSnowflakeIntegration && (
        <>
          <Text appearance="h3">
            {copyText.snowflakeServicesListNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.SNOWFLAKE}
            serviceIcon={<IconSnowflake size={50} />}
            serviceName={copyText.snowflakeDataWarehousesTitle}
          />
        </>
      )}
    </Box>
  );
}
