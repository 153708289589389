import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { gcpCommittedUseSchema } from "@ternary/api-lib/analytics/schemas/gcpCommittedUse";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

type HourUsageDatum = {
  cud1YearCost: number;
  cud1YearUsageAmount: number;
  cud3YearCost: number;
  cud3YearUsageAmount: number;
  cudsCreditAmount: number;
  family: string;
  region: string;
  resourceCost: number;
  resourceUsageAmount: number;
  resourceUsageUnit: string;
  sudsCreditAmount: number;
  totalResourceCreditsAmount: number;
  timestamp: string;
};

interface Params {
  cloudID: string;
  family: string;
  region: string;
}

type UseQueriesOptions = [
  [HourUsageDatum[], UError],
  [HourUsageDatum[], UError],
];

const dimensions = [
  gcpCommittedUseSchema.dimensions.family,
  gcpCommittedUseSchema.dimensions.region,
  gcpCommittedUseSchema.dimensions.resourceUsageUnit,
];

export default function useGetCUDUsageData(
  params: Params,
  options?: UseQueryOptions<HourUsageDatum[], UError>
): UseQueryResult<HourUsageDatum[], UError>[] {
  const client = useAnalyticsApiClient();

  const dateHelper = new DateHelper();
  const startDate = dateHelper.firstOfLastMonth();
  const endDate = dateHelper.lastDayLastMonth();

  return useQueries<UseQueriesOptions>({
    queries: [
      {
        queryKey: ["cudUsage", "cores", params],
        queryFn: async () => {
          const queryFilters = [
            {
              schemaName: gcpCommittedUseSchema.dimensions.cloudID.schemaName,
              operator: Operator.EQUALS,
              values: [params.cloudID],
            },
            {
              schemaName: gcpCommittedUseSchema.dimensions.region.schemaName,
              operator: Operator.EQUALS,
              values: [params.region],
            },
            {
              schemaName: gcpCommittedUseSchema.dimensions.family.schemaName,
              operator: Operator.EQUALS,
              values: [params.family],
            },
            {
              schemaName:
                gcpCommittedUseSchema.dimensions.resourceUsageUnit.schemaName,
              operator: Operator.EQUALS,
              values: ["vcore-seconds"],
            },
          ];

          const result = (await client.load(
            buildCubeQuery({
              dataSource: DataSource.COMMITTED_USE,
              dateRange: [startDate, endDate],
              dimensions,
              granularity: TimeGranularity.HOUR,
              measures: Object.values(gcpCommittedUseSchema.measures),
              queryFilters,
            })
          )) as HourUsageDatum[];

          return result.map((datum) => ({
            cud1YearCost: datum.cud1YearCost ?? 0,
            cud1YearUsageAmount: datum.cud1YearUsageAmount ?? 0,
            cud3YearCost: datum.cud3YearCost ?? 0,
            cud3YearUsageAmount: datum.cud3YearUsageAmount ?? 0,
            cudsCreditAmount: datum.cudsCreditAmount ?? 0,
            family: datum.family,
            region: datum.region,
            resourceCost: datum.resourceCost ?? 0,
            resourceUsageAmount: datum.resourceUsageAmount ?? 0,
            resourceUsageUnit: datum.resourceUsageUnit,
            sudsCreditAmount: datum.sudsCreditAmount ?? 0,
            totalResourceCreditsAmount: datum.totalResourceCreditsAmount ?? 0,
            timestamp: datum.timestamp,
          }));
        },
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      {
        queryKey: ["cudUsage", "bytes", params],
        queryFn: async () => {
          const queryFilters = [
            {
              schemaName: gcpCommittedUseSchema.dimensions.cloudID.schemaName,
              operator: Operator.EQUALS,
              values: [params.cloudID],
            },
            {
              schemaName: gcpCommittedUseSchema.dimensions.region.schemaName,
              operator: Operator.EQUALS,
              values: [params.region],
            },
            {
              schemaName: gcpCommittedUseSchema.dimensions.family.schemaName,
              operator: Operator.EQUALS,
              values: [params.family],
            },
            {
              schemaName:
                gcpCommittedUseSchema.dimensions.resourceUsageUnit.schemaName,
              operator: Operator.EQUALS,
              values: ["byte-seconds"],
            },
          ];

          const result = (await client.load(
            buildCubeQuery({
              dataSource: DataSource.COMMITTED_USE,
              dateRange: [startDate, endDate],
              dimensions,
              granularity: TimeGranularity.HOUR,
              measures: Object.values(gcpCommittedUseSchema.measures),
              queryFilters,
            })
          )) as HourUsageDatum[];

          return result.map((datum) => ({
            cud1YearCost: datum.cud1YearCost ?? 0,
            cud1YearUsageAmount: datum.cud1YearUsageAmount ?? 0,
            cud3YearCost: datum.cud3YearCost ?? 0,
            cud3YearUsageAmount: datum.cud3YearUsageAmount ?? 0,
            cudsCreditAmount: datum.cudsCreditAmount ?? 0,
            family: datum.family,
            region: datum.region,
            resourceCost: datum.resourceCost ?? 0,
            resourceUsageAmount: datum.resourceUsageAmount ?? 0,
            resourceUsageUnit: datum.resourceUsageUnit,
            sudsCreditAmount: datum.sudsCreditAmount ?? 0,
            totalResourceCreditsAmount: datum.totalResourceCreditsAmount ?? 0,
            timestamp: datum.timestamp,
          }));
        },
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
