import { useTheme } from "@emotion/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  CloudProviderType,
  RecommendationCategory,
} from "@ternary/api-lib/constants/enums";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { add, sub } from "date-fns";
import React from "react";
import useGetAnomalyDetail from "../../../api/analytics/hooks/useGetAnomalyDetail";
import paths from "../../../constants/paths";
import { RECOMMENDATION_KIND_ANOMALY } from "../../../types";
import Grid from "../../../ui-lib/components/Grid";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import copyText from "../copyText";
import useGetRecommendationByID from "../hooks/useGetRecommendationByID";
import useGetRecommendationTypeByID from "../hooks/useGetRecommendationTypeByID";
import { RecommendationDetail } from "../types";
import AnomalyChart from "./AnomalyChart";

const supportedCategories = [
  "Analytics",
  "Compute",
  "Databases",
  "Monitoring",
  "Network",
  "Storage",
];

const chartedRecommendationTypes = [
  "anomaly/category/analyticsSpend",
  "anomaly/category/computeSpend",
  "anomaly/category/databaseSpend",
  "anomaly/category/monitoringSpend",
  "anomaly/category/networkSpend",
  "anomaly/category/otherSpend",
  "anomaly/category/storageSpend",
];

interface Props {
  recommendationID: string;
}

export default function RecommendationContentContainer(
  props: Props
): JSX.Element {
  const theme = useTheme();

  const { data: recommendation, isLoading: isLoadingRecommendations } =
    useGetRecommendationByID(props.recommendationID);

  const recommendationTypeID =
    recommendation !== undefined ? recommendation.typeID : "";

  const { data: recommendationType, isLoading: isLoadingRecommendationTypes } =
    useGetRecommendationTypeByID(recommendationTypeID, {
      enabled: !!recommendation?.typeID,
    });

  const isAnomaly =
    recommendation !== undefined
      ? recommendation.kind === RECOMMENDATION_KIND_ANOMALY
      : false;

  const firstDetectedTimestamp =
    getAnomalyFirstDetectedTimestamp(recommendation);

  const anomalyDateRange = getAnomalyDateRange(firstDetectedTimestamp);

  const anomalyFilters = getAnomalyFilters(recommendation);

  const shouldLoadAnomalyData = isAnomaly && !!recommendation;

  const { data: anomalyData = [], isLoading: isLoadingAnomalyData } =
    useGetAnomalyDetail(
      {
        dataSource: DataSource.BILLING,
        dateRange: anomalyDateRange,
        queryFilters: anomalyFilters,
      },
      { enabled: shouldLoadAnomalyData }
    );

  const shouldShowChart =
    isAnomaly &&
    chartedRecommendationTypes.includes(
      recommendation ? recommendation.typeID : ""
    );

  const gcpLink = `https://console.cloud.google.com/home/dashboard?project=${recommendation?.projectID}`;

  function renderDetails(details: RecommendationDetail[] | null) {
    if (!details || details.length === 0) return null;

    return (
      <Grid
        gridTemplateColumns={"repeat(auto-fit, minmax(15rem, 1fr))"}
        padding={theme.space_md}
      >
        {details.map((detail) => (
          <Flex key={detail.key} direction="column" justifyContent="center">
            <Text bold marginRight="1rem" marginTop={theme.space_md}>
              {detail.key}
            </Text>

            <Text marginTop={theme.space_md}>{detail.value}</Text>
          </Flex>
        ))}
      </Grid>
    );
  }

  if (
    isLoadingRecommendations ||
    !recommendation ||
    isLoadingRecommendationTypes
  ) {
    return <EmptyPlaceholder loading={true} icon={faList} />;
  }

  return (
    <Flex direction="column" height="100%" justifyContent="space-between">
      <Box overflow="auto">
        {shouldShowChart &&
          (isLoadingAnomalyData ? (
            <EmptyPlaceholder
              height="10rem"
              icon={faList}
              loading={isLoadingAnomalyData}
              skeletonVariant="cartesian"
              text={copyText.noDataPlaceholderMessage}
            />
          ) : anomalyData.length !== 0 ? (
            <AnomalyChart
              data={anomalyData}
              firstDetected={
                firstDetectedTimestamp
                  ? new Date(firstDetectedTimestamp).toISOString()
                  : ""
              }
            />
          ) : null)}
        <Box>
          <MarkdownWrapper>
            {recommendationType ? recommendationType.detailTemplate : ""}
          </MarkdownWrapper>
        </Box>
        <Box>{renderDetails(recommendation.details)}</Box>
        <Flex justifyContent="flex-start" margin={theme.space_xs}>
          {!isAnomaly && (
            <Box marginRight={theme.space_jumbo}>
              <Text as="h3" appearance="h3">
                {copyText.recommendationDetailsHeaderResource}
              </Text>
              <Text>{recommendation.resource}</Text>
            </Box>
          )}
          <Box>
            <Text as="h3" appearance="h3">
              {recommendation.cloudProviderType === CloudProviderType.GCP
                ? copyText.recommendationDetailsHeaderProject
                : copyText.recommendationDetailsHeaderLinkedAccount}
            </Text>
            <Text>
              {recommendation.cloudProviderType === CloudProviderType.GCP ? (
                <a
                  href={gcpLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  {recommendation.projectID}
                </a>
              ) : (
                recommendation.projectID
              )}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent="flex-end" marginTop={theme.space_xs}>
        <Link
          to={{
            pathname: getPathFromRecommendation(recommendation),
            search: `?id=${recommendation.number}&tab=optimizations`,
          }}
        >
          {copyText.goToResourceLinkCaption}
        </Link>
      </Flex>
    </Flex>
  );
}

// NOTE: Using rec category and provider type as composite key to
// figure out which recs page to send the user back to.
const pathMap = {
  [`${RecommendationCategory.COMPUTE}:${CloudProviderType.AWS}`]:
    paths._insightsComputeEC2,
  [`${RecommendationCategory.COMPUTE}:${CloudProviderType.GCP}`]:
    paths._insightsComputeGCE,
  [`${RecommendationCategory.KUBERNETES}:${CloudProviderType.GCP}`]:
    paths._insightsKubernetesGKE,
  [`${RecommendationCategory.STORAGE}:${CloudProviderType.AWS}`]:
    paths._insightsStorageS3,
  [`${RecommendationCategory.STORAGE}:${CloudProviderType.GCP}`]:
    paths._insightsStorageGCS,
};

function getPathFromRecommendation(recommendation: {
  category: RecommendationCategory;
  cloudProviderType: CloudProviderType;
  kind: string;
}) {
  const key = `${recommendation.category}:${recommendation.cloudProviderType}`;

  const path = pathMap[key];

  return path;
}

function getAnomalyFirstDetectedTimestamp(recommendation?: {
  details: { key: string; value: string }[];
}): string {
  if (!recommendation) {
    return "";
  }

  const firstDetectedDetail = recommendation.details.find(
    (detail) => detail.key === "firstDetected"
  );

  if (!firstDetectedDetail) {
    return "";
  }

  return firstDetectedDetail.value;
}

function getAnomalyDateRange(firstDetectedTimestamp: string): [Date, Date] {
  const newDate = new Date(firstDetectedTimestamp);

  const startDate = sub(newDate, { hours: 12 });
  const endDate = add(newDate, { hours: 12 });

  return [startDate, endDate];
}

function getAnomalyFilters(selectedRecommendation): QueryFilter[] | undefined {
  if (!selectedRecommendation) {
    return;
  }

  const categoryDetail = selectedRecommendation?.details.find(
    (detail) => detail.key === "category"
  );

  if (!categoryDetail || !categoryDetail.value) {
    return;
  }

  const category = categoryDetail.value;

  const projectNumber: string = selectedRecommendation.resource
    .split(":")
    .pop() as string;

  return [
    ...(supportedCategories.includes(category)
      ? [
          {
            schemaName: billingCucSchema.dimensions.category.schemaName,
            operator: Operator.EQUALS,
            values: [category],
          },
        ]
      : [
          {
            schemaName: billingCucSchema.dimensions.category.schemaName,
            operator: Operator.NOT_EQUALS,
            values: supportedCategories,
          },
        ]),
    {
      schemaName: billingCucSchema.dimensions.projectNumber.schemaName,
      operator: Operator.EQUALS,
      values: [projectNumber],
    },
  ];
}
