import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { mspRollupSchema } from "@ternary/api-lib/analytics/schemas/mspRollup";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type MspDimensionValueRow = {
  billingAccountId: string;
  provider: string;
  region: string;
  serviceName: string;
};

interface Params {
  dateRange: Date[];
  parentTenantID: string;
}

type DimensionMap = {
  [mspRollupSchema.dimensions.billingAccountID.schemaName]: string[];
  [mspRollupSchema.dimensions.provider.schemaName]: string[];
  [mspRollupSchema.dimensions.region.schemaName]: string[];
  [mspRollupSchema.dimensions.serviceName.schemaName]: string[];
  [mspRollupSchema.dimensions.tenantID.schemaName]: string[];
};

export default function useGetMspDimensionValues(
  params: Params,
  options?: UseQueryOptions<DimensionMap, UError>
): UseQueryResult<DimensionMap, UError> {
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const { parentTenantID, ...restParams } = params;

  const [_, query] = useBuildDataQuery({
    ...restParams,
    dataSource: DataSource.MSP_ROLLUP,
    dimensions: [
      mspRollupSchema.dimensions.billingAccountID,
      mspRollupSchema.dimensions.provider,
      mspRollupSchema.dimensions.region,
      mspRollupSchema.dimensions.serviceName,
      mspRollupSchema.dimensions.tenantID,
    ],
  });

  return useQuery({
    queryKey: ["mspDimensionValues", params],
    queryFn: async () => {
      const result = await client.loadData<MspDimensionValueRow>(
        parentTenantID,
        query
      );

      return result.response.reduce(
        (accum, datum) => {
          Object.entries(datum).forEach(([key, value]) => {
            if (accum[key].includes(value)) return;

            accum[key].push(value);
          });

          return accum;
        },
        {
          [mspRollupSchema.dimensions.billingAccountID.schemaName]: [],
          [mspRollupSchema.dimensions.provider.schemaName]: [],
          [mspRollupSchema.dimensions.region.schemaName]: [],
          [mspRollupSchema.dimensions.serviceName.schemaName]: [],
          [mspRollupSchema.dimensions.tenantID.schemaName]: [],
        }
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.canViewCostReports,
    ...options,
  });
}
