import { createColumnHelper } from "@tanstack/react-table";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { AWSOpenSearchInstance } from "../types";

type Instances = {
  instanceCost: number;
  instanceMemory: string | null;
  instanceType: string | null;
  instanceVcpu: string;
  nodeUsage: number;
};

type Props = {
  intances: AWSOpenSearchInstance[];
  isLoading: boolean;
};

const columnHelper = createColumnHelper<Instances>();

export default function AWSOpenSearchInstancesTable(props: Props) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceType", {
        cell: ({ getValue }) => (getValue() ? getValue() : "--"),
        header: copyText.awsOpenSearchInstanceTableHeader_instanceType,
        sortDescFirst: true,
      }),
      columnHelper.accessor("instanceCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsOpenSearchInstanceTableHeader_instanceCost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("instanceMemory", {
        cell: ({ getValue }) => {
          return <Text>{`${getValue()} GB`}</Text>;
        },
        header: copyText.awsOpenSearchInstanceTableHeader_instanceMemory,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("instanceVcpu", {
        header: copyText.awsOpenSearchInstanceTableHeader_instanceVcpu,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("nodeUsage", {
        cell: ({ getValue }) => {
          return <Text>{`${getValue()} hrs`}</Text>;
        },
        header: copyText.awsOpenSearchInstanceTableHeader_nodeUsage,
        meta: { align: "center" },
        sortDescFirst: true,
        size: 160,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} maxHeight={500} overflowX="auto">
      <Table
        columns={columns}
        data={props.intances}
        isLoading={props.isLoading}
        showPagination
        sortable
      />
    </Box>
  );
}
