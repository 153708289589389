import { useQueryClient } from "@tanstack/react-query";
import { defaultDimensionsMap } from "@ternary/api-lib/analytics/constants";
import { DataSource, ProviderType } from "@ternary/api-lib/analytics/enums";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import { useFocusDimensions } from "./useFocusDimensions";

/**
 * Custom hook that derives all available dimensions by combining default dimensions
 * with custom dimensions for a given DataSource.
 * @param {DataSource} dataSource
 */

export function useAvailableDimensionsByDataSourceV2(
  dataSource: DataSource
): Dimension[] {
  const queryClient = useQueryClient();

  const focusDimensions = useFocusDimensions();

  // Load focus dimensions from analytics metadata.
  if (dataSource === DataSource.FOCUS_BILLING) {
    return focusDimensions;
  }

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const defaultDimensions =
    (defaultDimensionsMap[dataSource] as Dimension[]) ?? [];

  if (!labelMaps || !labelMaps[dataSource]) {
    return defaultDimensions;
  }

  let dataSourceKey = dataSource;

  // DETAILED_BILLING and BILLING data sources share the same labels
  if (dataSource === DataSource.DETAILED_BILLING) {
    dataSourceKey = DataSource.BILLING;
  }

  const labelsKeyedByDimension = labelMaps[dataSourceKey];

  const customDimensions = Object.keys(labelsKeyedByDimension).map((name) => ({
    displayName: name,
    providerType: ProviderType.TERNARY,
    schemaName: name,
  }));

  return defaultDimensions
    .concat(customDimensions)
    .sort((a, b) =>
      a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
    );
}
