import { UnitType } from "../enums";

import { ProviderType } from "../enums";

const measures = {
  cpuUtilization: {
    displayName: "CPU Utilization",
    providerType: ProviderType.AWS,
    schemaName: "cpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  diskReadBytes: {
    displayName: "Disk Read Bytes",
    providerType: ProviderType.AWS,
    schemaName: "diskReadBytes",
    unit: UnitType.BYTES,
  },
  diskWriteBytes: {
    displayName: "Disk Write Bytes",
    providerType: ProviderType.AWS,
    schemaName: "diskWriteBytes",
    unit: UnitType.BYTES,
  },
  instanceIDCount: {
    displayName: "Instance ID Count",
    providerType: ProviderType.AWS,
    schemaName: "instanceIDCount",
    unit: UnitType.STANDARD,
  },
  instanceUnits: {
    displayName: "Instance Units",
    providerType: ProviderType.AWS,
    schemaName: "instanceUnits",
    unit: UnitType.STANDARD,
  },
  networkInBytes: {
    displayName: "Network In Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkInBytes",
    unit: UnitType.BYTES,
  },
  networkOutBytes: {
    displayName: "Network Out Bytes",
    providerType: ProviderType.AWS,
    schemaName: "networkOutBytes",
    unit: UnitType.BYTES,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AWS,
    schemaName: "productMemoryBytes",
    unit: UnitType.BYTES,
  },
  productVCPU: {
    displayName: "Product VCPU",
    providerType: ProviderType.AWS,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.AWS,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  family: {
    displayName: "Family",
    providerType: ProviderType.AWS,
    schemaName: "family",
  },
  familyType: {
    displayName: "Family Type",
    providerType: ProviderType.AWS,
    schemaName: "familyType",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AWS,
    schemaName: "instanceId",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AWS,
    schemaName: "instanceType",
  },
  lineItemUsageAccountId: {
    displayName: "Line Item Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
  operatingSystem: {
    displayName: "Operating System",
    providerType: ProviderType.AWS,
    schemaName: "operatingSystem",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
};

export const awsComputeInstancesSchema = {
  dimensions,
  measures,
};
