import { ProviderType, UnitType } from "../enums";

const measures = {
  absoluteCredits: {
    displayName: "Absolute Credits",
    providerType: ProviderType.AZURE,
    schemaName: "absoluteCredits",
    unit: UnitType.CURRENCY,
  },
  cost: {
    displayName: "Cost",
    providerType: ProviderType.AZURE,
    schemaName: "cost",
    unit: UnitType.CURRENCY,
  },
  cpuUtilization: {
    displayName: "CPU Utilization",
    providerType: ProviderType.AZURE,
    schemaName: "cpuUtilization",
    unit: UnitType.PERCENTAGE,
  },
  cpuUtilizationAvg: {
    displayName: "CPU Utilization Avg",
    providerType: ProviderType.AZURE,
    schemaName: "cpuUtilizationAvg",
    unit: UnitType.PERCENTAGE,
  },
  cpuUtilizationMax: {
    displayName: "CPU Utilization Max",
    providerType: ProviderType.AZURE,
    schemaName: "cpuUtilizationMax",
    unit: UnitType.PERCENTAGE,
  },
  cpuUtilizationMin: {
    displayName: "CPU Utilization Min",
    providerType: ProviderType.AZURE,
    schemaName: "cpuUtilizationMin",
    unit: UnitType.PERCENTAGE,
  },
  diskReadBytes: {
    displayName: "Disk Read Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "diskReadBytes",
    unit: UnitType.BINARY_BYTES,
  },
  diskWriteBytes: {
    displayName: "Disk Write Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "diskWriteBytes",
    unit: UnitType.BINARY_BYTES,
  },
  instanceCount: {
    displayName: "Instance Count",
    providerType: ProviderType.AZURE,
    schemaName: "instanceCount",
    unit: UnitType.STANDARD,
  },
  productMemoryBytes: {
    displayName: "Product Memory Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "productMemoryBytes",
    unit: UnitType.BYTES,
  },
  productVCPU: {
    displayName: "Product VCPU",
    providerType: ProviderType.AZURE,
    schemaName: "productVCPU",
    unit: UnitType.STANDARD,
  },
  ramAvailableBytes: {
    displayName: "RAM Available Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "ramAvailableBytes",
    unit: UnitType.BINARY_BYTES,
  },
  ramAvailableBytesAvg: {
    displayName: "RAM Available Bytes Avg",
    providerType: ProviderType.AZURE,
    schemaName: "ramAvailableBytesAvg",
    unit: UnitType.BINARY_BYTES,
  },
  ramAvailableBytesMax: {
    displayName: "RAM Available Bytes Max",
    providerType: ProviderType.AZURE,
    schemaName: "ramAvailableBytesMax",
    unit: UnitType.BINARY_BYTES,
  },
  ramAvailableBytesMin: {
    displayName: "RAM Available Bytes Min",
    providerType: ProviderType.AZURE,
    schemaName: "ramAvailableBytesMin",
    unit: UnitType.BINARY_BYTES,
  },
  receivedBytes: {
    displayName: "Received Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "receivedBytes",
    unit: UnitType.BINARY_BYTES,
  },
  sentBytes: {
    displayName: "Sent Bytes",
    providerType: ProviderType.AZURE,
    schemaName: "sentBytes",
    unit: UnitType.BINARY_BYTES,
  },
  usageAmount: {
    displayName: "Usage Amount",
    providerType: ProviderType.AZURE,
    schemaName: "usageAmount",
    unit: UnitType.STANDARD,
  },
} as const;

const dimensions = {
  blockStorageClass: {
    displayName: "Block Storage Class",
    providerType: ProviderType.AZURE,
    schemaName: "blockStorageClass",
  },
  blockStorageType: {
    displayName: "Block Storage Type",
    providerType: ProviderType.AZURE,
    schemaName: "blockStorageType",
  },
  computeCategory: {
    displayName: "Compute Category",
    providerType: ProviderType.AZURE,
    schemaName: "computeCategory",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.AZURE,
    schemaName: "family",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.AZURE,
    schemaName: "instanceId",
  },
  instanceName: {
    displayName: "Instance Name",
    providerType: ProviderType.AZURE,
    schemaName: "instanceName",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.AZURE,
    schemaName: "instanceType",
  },
  location: {
    displayName: "Location",
    providerType: ProviderType.AZURE,
    schemaName: "location",
  },
  operatingSystem: {
    displayName: "Operating System",
    providerType: ProviderType.AZURE,
    schemaName: "operatingSystem",
  },
  projectID: {
    displayName: "Project ID",
    providerType: ProviderType.AZURE,
    schemaName: "projectId",
  },
} as const;

export const azureComputeVisibilitySchema = { dimensions, measures };
