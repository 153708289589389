import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { LabelMap, LabelMapsEntity } from "@ternary/api-lib/core/types";
import { uniq } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import { buildCubeQuery } from "./utils";

type DataSourceDimensions = {
  dataSource: DataSource;
  dimensions: Dimension[];
};

interface Params {
  dateRange: Date[];
  dataSourceDimensions: DataSourceDimensions[];
}

export type DimensionValuesMap = {
  [key: string]: string[];
};

export type DimensionValuesDataSourceMap = {
  [key in DataSource]: DimensionValuesMap;
};

export default function useGetDimensionValues(
  params: Params,
  options?: UseQueryOptions<DimensionValuesDataSourceMap, UError>
): UseQueryResult<DimensionValuesDataSourceMap, UError> {
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["dimensionValues", params.dataSourceDimensions],
    queryFn: async () => {
      const promises = params.dataSourceDimensions.map((entry) =>
        client.load(
          buildCubeQuery({
            dateRange: params.dateRange,
            dataSource: entry.dataSource,
            dimensions: entry.dimensions,
          })
        )
      );

      const results = await Promise.all(promises);

      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const dimensionValuesDataSourceMap = params.dataSourceDimensions.reduce(
        (accum: DimensionValuesDataSourceMap, entry, index) => {
          const labelMap: LabelMap = labelMaps[entry.dataSource];

          const reversedLabelMap = Object.entries(labelMap).reduce(
            (accum: { [key: string]: string }, [key, value]) => {
              accum[value] = key;
              return accum;
            },
            {}
          );

          const result = results[index];

          const dimensionValuesMap = result.reduce(
            (accum: DimensionValuesMap, datum) => {
              Object.entries(datum).forEach(([key, value]) => {
                if (typeof value !== "string") return;

                const dimension = reversedLabelMap[key]
                  ? reversedLabelMap[key]
                  : key;

                if (!accum[dimension]) {
                  accum[dimension] = [value];
                  return;
                }

                if (accum[dimension]) {
                  accum[dimension].push(value);
                  return;
                }
              }, {});

              return accum;
            },
            {}
          );

          const uniquedDimensionValuesMap = Object.entries(
            dimensionValuesMap
          ).reduce((accum: DimensionValuesMap, [key, value]) => {
            accum[key] = uniq(value);
            return accum;
          }, {});

          if (Object.entries(uniquedDimensionValuesMap).length !== 0) {
            accum[entry.dataSource] = uniquedDimensionValuesMap;
          }

          return accum;
        },
        {} as DimensionValuesDataSourceMap
      );

      return dimensionValuesDataSourceMap;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
