import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { azureCommitmentTableSchema } from "@ternary/api-lib/analytics/schemas/azureCommitmentTable";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { format, startOfDay, sub } from "date-fns";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureCommitmentInventoryDatum } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
  isTotals?: boolean;
}

const ISO_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export default function useGetAzureCommitmentInventory(
  params: Params,
  options?: UseQueryOptions<AzureCommitmentInventoryDatum[], UError>
): UseQueryResult<AzureCommitmentInventoryDatum[], UError> {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const earliest1YPurchaseDate = sub(params.dateRange[0], {
    years: 1,
  });

  const term1YFilter: QueryFilter = {
    and: [
      {
        schemaName:
          azureCommitmentTableSchema.dimensions.purchaseDate.schemaName,
        operator: Operator.GTE,
        values: [format(earliest1YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        schemaName: azureCommitmentTableSchema.dimensions.term.schemaName,
        operator: Operator.EQUALS,
        values: ["P1Y"],
      },
    ],
  };

  const earliest3YPurchaseDate = sub(params.dateRange[0], {
    years: 3,
  });

  const term3YFilter: QueryFilter = {
    and: [
      {
        schemaName:
          azureCommitmentTableSchema.dimensions.purchaseDate.schemaName,
        operator: Operator.GTE,
        values: [format(earliest3YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        schemaName: azureCommitmentTableSchema.dimensions.term.schemaName,
        operator: Operator.EQUALS,
        values: ["P3Y"],
      },
    ],
  };

  const dateRange = [earliest3YPurchaseDate, startOfDay(dateHelper.date)];

  const queryFilters: QueryFilter[] = [
    ...(params.queryFilters ?? []),
    { or: [term1YFilter, term3YFilter] },
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: queryFilters,
    dataSource: DataSource.AZURE_COMMITMENT_TABLE,
    dateRange,
    dimensions: [
      azureCommitmentTableSchema.dimensions.appliedScopes,
      azureCommitmentTableSchema.dimensions.appliedScopeType,
      azureCommitmentTableSchema.dimensions.billingAccountId,
      azureCommitmentTableSchema.dimensions.billingProfileId,
      azureCommitmentTableSchema.dimensions.billingScopeId,
      azureCommitmentTableSchema.dimensions.commitmentId,
      azureCommitmentTableSchema.dimensions.commitmentName,
      azureCommitmentTableSchema.dimensions.coverageType,
      azureCommitmentTableSchema.dimensions.currencyCode,
      azureCommitmentTableSchema.dimensions.expirationDate,
      azureCommitmentTableSchema.dimensions.instanceType,
      azureCommitmentTableSchema.dimensions.orderId,
      azureCommitmentTableSchema.dimensions.paymentOption,
      azureCommitmentTableSchema.dimensions.purchaseDate,
      azureCommitmentTableSchema.dimensions.recurringFrequency,
      azureCommitmentTableSchema.dimensions.region,
      azureCommitmentTableSchema.dimensions.renew,
      azureCommitmentTableSchema.dimensions.skuName,
      azureCommitmentTableSchema.dimensions.term,
      azureCommitmentTableSchema.dimensions.type,
    ],
    measures: [
      azureCommitmentTableSchema.measures.avgUtilizationPercent,
      azureCommitmentTableSchema.measures.instanceCount,
      azureCommitmentTableSchema.measures.maxUtilizationPercent,
      azureCommitmentTableSchema.measures.minUtilizationPercent,
      azureCommitmentTableSchema.measures.recurringCost,
      azureCommitmentTableSchema.measures.reservedHours,
      azureCommitmentTableSchema.measures.totalCost,
      azureCommitmentTableSchema.measures.upfrontCost,
      azureCommitmentTableSchema.measures.usedHours,
    ],
  });

  return useQuery({
    queryKey: ["azureCommitmentTable", params],
    queryFn: async () => {
      const commitmentInventory = await client.loadData(tenantID, query);

      return commitmentInventory.response.map((datum) => {
        return {
          instanceCount: datum.instanceCount ?? 0,
          recurringCost: datum.recurringCost ?? 0,
          totalCost: datum.totalCost ?? 0,
          upfrontCost: datum.upfrontCost ?? 0,
          minUtilizationPercent: datum.minUtilizationPercent ?? 0,
          maxUtilizationPercent: datum.maxUtilizationPercent ?? 0,
          avgUtilizationPercent: datum.avgUtilizationPercent ?? 0,
          reservedHours: datum.reservedHours ?? 0,
          usedHours: datum.usedHours ?? 0,

          purchaseDate: datum.purchaseDate ?? "",
          expirationDate: datum.expirationDate ?? "",

          billingAccountId: datum.billingAccountId ?? "",
          billingProfileId: datum.billingProfileId ?? "",
          billingScopeId: datum.billingScopeId ?? "",
          commitmentId: datum.commitmentId ?? "",
          commitmentName: datum.commitmentName ?? "",
          coverageType: datum.coverageType ?? "",
          currencyCode: datum.currencyCode ?? "",
          instanceType: datum.instanceType ?? "",
          orderId: datum.orderId ?? "",
          paymentOption: datum.paymentOption ?? "",
          region: datum.region ?? "",
          recurringFrequency: datum.recurringFrequency ?? "",
          renew: datum.renew ?? false,
          appliedScopeType: datum.appliedScopeType ?? "",
          appliedScopes: datum.appliedScopes ?? "",
          skuName: datum.skuName ?? "",
          term: datum.term ?? "",
          type: datum.type ?? "",
        } as AzureCommitmentInventoryDatum;
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
