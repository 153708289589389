import { ProviderType, UnitType } from "../enums";

const measures = {
  lowerBound: {
    displayName: "Lower Bound",
    providerType: ProviderType.TERNARY,
    schemaName: "lowerBound",
    unit: UnitType.CURRENCY,
  },
  upperBound: {
    displayName: "Upper Bound",
    providerType: ProviderType.TERNARY,
    schemaName: "upperBound",
    unit: UnitType.CURRENCY,
  },
};

export const predictionBoundsSchema = { measures };
