import { ProviderType, UnitType } from "../enums";

const measures = {
  maxIopsVolumeBytes: {
    displayName: "Max IOPS Volume Bytes",
    providerType: ProviderType.AWS,
    schemaName: "maxIopsVolumeBytes",
    unit: UnitType.BYTES,
  },
  maxThroughputVolumeBytes: {
    displayName: "Max Throughput Volume Bytes",
    providerType: ProviderType.AWS,
    schemaName: "maxThroughputVolumeBytes",
    unit: UnitType.BYTES,
  },
  maxVolumeSizeBytes: {
    displayName: "Max Volume Size Bytes",
    providerType: ProviderType.AWS,
    schemaName: "maxVolumeSizeBytes",
    unit: UnitType.BYTES,
  },
  operationsCost: {
    displayName: "Operations Cost",
    providerType: ProviderType.AWS,
    schemaName: "operationsCost",
    unit: UnitType.CURRENCY,
  },
  provisionedThroughputCost: {
    displayName: "Provisioned Throughput Cost",
    providerType: ProviderType.AWS,
    schemaName: "provisionedThroughputCost",
    unit: UnitType.CURRENCY,
  },
  snapshotCost: {
    displayName: "Snapshot Cost",
    providerType: ProviderType.AWS,
    schemaName: "snapshotCost",
    unit: UnitType.CURRENCY,
  },
  snapshotUsageBytes: {
    displayName: "Snapshot Usage Bytes",
    providerType: ProviderType.AWS,
    schemaName: "snapshotUsageBytes",
    unit: UnitType.BYTES,
  },
  storageCost: {
    displayName: "Storage Cost",
    providerType: ProviderType.AWS,
    schemaName: "storageCost",
    unit: UnitType.CURRENCY,
  },
  storageUsageBytes: {
    displayName: "Storage Usage Bytes",
    providerType: ProviderType.AWS,
    schemaName: "storageUsageBytes",
    unit: UnitType.BYTES,
  },
  totalCost: {
    displayName: "Total Cost",
    providerType: ProviderType.AWS,
    schemaName: "totalCost",
    unit: UnitType.CURRENCY,
  },
  totalReadTime: {
    displayName: "Total Read Time",
    providerType: ProviderType.AWS,
    schemaName: "totalReadTime",
    unit: UnitType.STANDARD,
  },
  totalWriteTime: {
    displayName: "Total Write Time",
    providerType: ProviderType.AWS,
    schemaName: "totalWriteTime",
    unit: UnitType.STANDARD,
  },
  volumeReadOps: {
    displayName: "Volume Read Ops",
    providerType: ProviderType.AWS,
    schemaName: "volumeReadOps",
    unit: UnitType.STANDARD,
  },
  volumeWriteOps: {
    displayName: "Volume Write Ops",
    providerType: ProviderType.AWS,
    schemaName: "volumeWriteOps",
    unit: UnitType.STANDARD,
  },
};

const dimensions = {
  billPayerAccountID: {
    displayName: "Bill Payer Account ID",
    providerType: ProviderType.AWS,
    schemaName: "billPayerAccountId",
  },
  family: {
    displayName: "Family",
    providerType: ProviderType.AWS,
    schemaName: "family",
  },
  lineItemUsageAccountID: {
    displayName: "Usage Account ID",
    providerType: ProviderType.AWS,
    schemaName: "lineItemUsageAccountId",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.AWS,
    schemaName: "region",
  },
  type: {
    displayName: "Type",
    providerType: ProviderType.AWS,
    schemaName: "type",
  },
  volumeID: {
    displayName: "Volume ID",
    providerType: ProviderType.AWS,
    schemaName: "volumeId",
  },
};

export const awsEbsVolumesSchema = { measures, dimensions };
