export type AzureCommittedUseChartData = {
  covered: number;
  estimated: number;
  timestamp: string;
  usage: number;
};

export type AzureCommitmentInventoryDatum = {
  instanceCount: number;
  recurringCost: number;
  totalCost: number;
  upfrontCost: number;
  minUtilizationPercent: number;
  maxUtilizationPercent: number;
  avgUtilizationPercent: number;
  reservedHours: number;
  usedHours: number;

  appliedScopes: string;
  appliedScopeType: string;
  billingAccountId: string;
  billingProfileId: string;
  billingScopeId: string;
  commitmentId: string;
  commitmentName: string;
  coverageType: string;
  currencyCode: string;
  expirationDate: string;
  instanceType: string;
  orderId: string;
  paymentOption: string;
  purchaseDate: string;
  recurringFrequency: string;
  region: string;
  renew: boolean;
  skuName: string;
  term: string;
  type: string;
};

export type AzureCommitmentInventoryTotal = {
  instanceCount: number;
  recurringCost: number;
  totalCost: number;
  upfrontCost: number;
  minUtilizationPercent: number;
  maxUtilizationPercent: number;
  avgUtilizationPercent: number;
  reservedHours: number;
  usedHours: number;
};

export type AzureCommitmentUsageDatum = {
  onDemandUsageCost: number;
  spUnusedCost: number;
  riUnusedCost: number;
  spAmortizedCost: number;
  riAmortizedCost: number;
  timestamp: string;
};

export const AzureCommitmentType = {
  Reservation: "Reservation",
  SavingsPlan: "Savings Plan",
} as const;

export type AzureCommitmentType =
  (typeof AzureCommitmentType)[keyof typeof AzureCommitmentType];

export const AzureCommitmentVisibilityChartMode = {
  Cost: "Cost",
  Usage: "Usage",
} as const;

export type AzureCommitmentVisibilityChartMode =
  (typeof AzureCommitmentVisibilityChartMode)[keyof typeof AzureCommitmentVisibilityChartMode];

export type AzureCommittedUseFilter = {
  payerAccountID: string;
  lookbackPeriod: string;
  coverageType: string;
  term: string;
};
