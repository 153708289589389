import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQueries } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { gcpBigQueryUsageSchema } from "@ternary/api-lib/analytics/schemas/gcpBigQueryUsage";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { validate } from "../../../../api/analytics/utils/Cubestruct";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { getPreviousPeriodDateRange } from "../../utils";
import { GcpBigQuerySpendSummaryEntity } from "../types";
import { BigQuerySpendSummaryStruct } from "./schema";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [GcpBigQuerySpendSummaryEntity, UError],
  [GcpBigQuerySpendSummaryEntity, UError],
];

export default function useGetBigQuerySpendSummaries(
  params: Params,
  options?: UseQueryOptions<GcpBigQuerySpendSummaryEntity, UError>
): UseQueryResult<GcpBigQuerySpendSummaryEntity, UError>[] {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();

  const tenantID = authenticatedUser.tenant.fsDocID;

  const measures = [gcpBigQueryUsageSchema.measures.onDemandCost];

  const previousPeriodDateRange = getPreviousPeriodDateRange(params.dateRange);

  return useQueries<UseQueriesOptions>({
    queries: [
      // Selected Period
      {
        queryFn: async () => {
          const query = new DataQuery({
            ...params,
            dataSource: DataSource.BIGQUERY_USAGE,
            dateRange: params.dateRange,
            measures,
          });

          const result = await client.loadData<GcpBigQuerySpendSummaryEntity>(
            tenantID,
            query
          );

          const [error, data] = validate(
            result.response[0],
            BigQuerySpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_BIG_QUERY_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            cost: data.onDemandCost ?? 0,
          };
        },
        queryKey: ["bigQuerySpendSummary", "selectedPeriod", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // Previous Period
      {
        queryFn: async () => {
          const query = new DataQuery({
            ...params,
            dataSource: DataSource.BIGQUERY_USAGE,
            dateRange: previousPeriodDateRange,
            measures,
          });

          const result = await client.loadData<GcpBigQuerySpendSummaryEntity>(
            tenantID,
            query
          );

          const [error, data] = validate(
            result.response[0],
            BigQuerySpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_BIG_QUERY_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            cost: data.onDemandCost ?? 0,
          };
        },
        queryKey: ["bigQuerySpendSummary", "previousPeriod", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
