import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import { buildCubeQuery } from "../../../api/analytics/utils";
import { validate } from "../../../api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { CostSpendSummaryEntity } from "../types";
import { CostSpendSummaryStruct } from "./schema";

export interface Params {
  dataSource: DataSource;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [CostSpendSummaryEntity, UError],
  [CostSpendSummaryEntity, UError],
  [CostSpendSummaryEntity, UError],
];

export default function useGetCostSpendSummaries(
  params: Params,
  options?: UseQueryOptions<CostSpendSummaryEntity, UError>
): UseQueryResult<CostSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const baseQuery = {
    ...params,
    dataSource: params.dataSource,
    measures: [billingCucSchema.measures.cost],
  };

  return useQueries<UseQueriesOptions>({
    queries: [
      // This Month To Date
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...baseQuery,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
            })
          );

          const [error, data] = validate(result[0], CostSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_COST_SPEND_SUMMARY", {
              context: { error, result: data, params: params },
            });
          }

          return {
            cost: data.cost ?? 0,
          };
        },
        queryKey: ["cloudRunSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...baseQuery,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
            })
          );

          const [error, data] = validate(result[0], CostSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_COST_SPEND_SUMMARY", {
              context: { error, result: data, params: params },
            });
          }

          return {
            cost: data.cost ?? 0,
          };
        },
        queryKey: ["cloudRunSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // LAST MONTH TO DATE
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...baseQuery,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
            })
          );

          const [error, data] = validate(result[0], CostSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_COST_SPEND_SUMMARY", {
              context: { error, result: data, params: params },
            });
          }

          return {
            cost: data.cost ?? 0,
          };
        },
        queryKey: ["cloudRunSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
