import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { isValidDetailedBillingDate } from "@ternary/api-lib/analytics/utils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { isValidRollingWindow } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import timing from "@ternary/api-lib/ui-lib/constants/timing";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { add } from "date-fns";
import { noop } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import copyText from "../../copyText";
import Form from "../Form";
import TextInput from "../TextInput";
import TextInputWithSelect from "../TextInputWithSelect";
import { DatePicker } from "./DatePicker";
import Dropdown from "./Dropdown";

type Props = React.PropsWithChildren<{
  dateRange: DateRange;
  dataSource?: DataSource;
  disabled?: boolean;
  durationType: DurationType;
  isOpen: boolean;
  maxDate?: Date;
  minDate?: Date;
  n?: number | null;
  showRollingLookback?: boolean;
  showInvoice?: boolean;
  singleMonthInvoice?: boolean;
  onChangeRollingWindow?: (
    durationType: DurationType,
    nLookback: number
  ) => void;
  onChangeDateRange: (dateRange: DateRange, isInvoiceMonth: boolean) => void;
  onClose: () => void;
  onOpen: () => void;
}>;

const Tab = {
  FIXED: "FIXED",
  ROLLING: "ROLLING",
  INVOICE: "INVOICE",
};

type Tab = (typeof Tab)[keyof typeof Tab];

interface State {
  displayMonth?: "start" | "end";
  durationType?: DurationType;
  nInput: string;
  fixedStartDate?: Date;
  fixedEndDate?: Date;
  fixedStartInput?: string;
  fixedEndInput?: string;
  invoiceStartDate?: Date;
  invoiceEndDate?: Date;
  invoiceStartInput?: string;
  invoiceEndInput?: string;
  select: "start" | "end";
}

export default function CustomDatePickerContent(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({
    fixedEndDate: props.dateRange[1],
    durationType: props.durationType,
    nInput: typeof props.n === "number" ? String(props.n) : "",
    fixedStartDate: props.dateRange[0],
    fixedStartInput: undefined,
    fixedEndInput: undefined,
    select: "start",
    invoiceStartDate: props.dateRange[0],
    invoiceEndDate: props.dateRange[1],
    invoiceStartInput: undefined,
    invoiceEndInput: undefined,
  });

  const fixedStartDateRef = useRef<HTMLInputElement>(null);
  const fixedEndDateRef = useRef<HTMLInputElement>(null);
  const invoiceStartDateRef = useRef<HTMLInputElement>(null);
  const invoiceEndDateRef = useRef<HTMLInputElement>(null);

  const mergeState = getMergeState(setState);

  useEffect(() => {
    mergeState({
      durationType: props.durationType,
      fixedEndDate: props.dateRange[1],
      fixedStartDate: props.dateRange[0],
      fixedStartInput: props.dateRange[0]
        ? formatDate(props.dateRange[0], dateFormat)
        : undefined,
      fixedEndInput: props.dateRange[1]
        ? formatDate(props.dateRange[1], dateFormat)
        : undefined,
      invoiceEndDate: props.dateRange[1],
      invoiceStartDate: props.dateRange[0],
      invoiceStartInput: props.dateRange[0]
        ? formatDate(props.dateRange[0], dateFormat)
        : undefined,
      invoiceEndInput: props.dateRange[1]
        ? formatDate(props.dateRange[1], dateFormat)
        : undefined,
    });
  }, [JSON.stringify(props.dateRange), props.durationType]);

  useEffect(() => {
    mergeState({
      nInput: typeof props.n === "number" ? String(props.n) : "",
      durationType: props.durationType,
    });
  }, [props.n, props.showRollingLookback, props.durationType]);

  const tab = useMemo(() => {
    if (
      state.durationType === DurationType.LAST_N_DAYS ||
      state.durationType === DurationType.LAST_N_MONTHS
    ) {
      return Tab.ROLLING;
    } else if (state.durationType === DurationType.INVOICE) {
      return Tab.INVOICE;
    } else {
      return Tab.FIXED;
    }
  }, [state.durationType]);

  const isDetailedBilling =
    props.dataSource && props.dataSource === DataSource.DETAILED_BILLING;

  const dateFormat = tab === Tab.FIXED ? "MM/dd/yyyy" : "LLLL yyyy";

  // Invoice mode not allowed for resource level billing
  useEffect(() => {
    if (isDetailedBilling) {
      if (tab === Tab.INVOICE) {
        mergeState({ durationType: DurationType.CUSTOM });
        fixedStartDateRef.current?.focus();
      }
    }
  }, [isDetailedBilling]);

  const startDate =
    tab === Tab.FIXED ? state.fixedStartDate : state.invoiceStartDate;
  const endDate = tab === Tab.FIXED ? state.fixedEndDate : state.invoiceEndDate;
  const startInput =
    tab === Tab.FIXED ? state.fixedStartInput : state.invoiceStartInput;
  const endInput =
    tab === Tab.FIXED ? state.fixedEndInput : state.invoiceEndInput;

  //
  // Interaction Handlers
  //

  function handleResetCustomDateRange() {
    mergeState({
      fixedStartDate: undefined,
      fixedStartInput: undefined,
      fixedEndDate: undefined,
      fixedEndInput: undefined,
      invoiceStartDate: undefined,
      invoiceStartInput: undefined,
      invoiceEndDate: undefined,
      invoiceEndInput: undefined,
      nInput: undefined,
    });

    if (tab === Tab.FIXED) {
      fixedStartDateRef.current?.focus();
    } else {
      invoiceStartDateRef.current?.focus();
    }
  }

  function handleApplyCustomDateRange() {
    if (tab === Tab.ROLLING) {
      if (!state.durationType) return;

      const nLookback: unknown = Number(state.nInput);

      if (!isValidRollingWindow(nLookback) || !props.onChangeRollingWindow) {
        return;
      }

      props.onChangeRollingWindow(state.durationType, nLookback);
    } else {
      if (!startDate || !endDate) {
        return;
      }
      let dateRange = [startDate, endDate];

      if (tab === Tab.INVOICE) {
        if (!startDate) {
          dateRange = [endDate, endDate];
        } else if (!endDate) {
          dateRange = [startDate, startDate];
        }
      }
      props.onChangeDateRange(dateRange, tab === Tab.INVOICE);
    }

    props.onClose();
  }

  function handleChangeDateInput(event, key: string) {
    const input = event.target.value;

    setState((currentState) => {
      const type = tab === Tab.FIXED ? "fixed" : "invoice";

      let newStartDate = currentState[`${type}StartDate`];

      let newEndDate = currentState[`${type}EndDate`];

      if (key === "start") {
        if (isValidDateInput(input, tab)) {
          newStartDate = getValidDate(input, tab);
        } else {
          newStartDate = undefined;
        }
      } else {
        if (isValidDateInput(input, tab)) {
          newEndDate = getValidDate(input, tab);
        } else {
          newEndDate = undefined;
        }
      }

      const formattedInput = event.target.value;
      return {
        ...currentState,
        ...(key === "start"
          ? { [`${type}StartInput`]: formattedInput }
          : { [`${type}EndInput`]: formattedInput }),
        [`${type}StartDate`]: newStartDate,
        [`${type}EndDate`]: newEndDate,
      };
    });
  }

  function handleChangeDate(selectedDate: Date | null): void {
    if (!selectedDate) return;

    setState((currentState) => {
      const type = tab === Tab.FIXED ? "fixed" : "invoice";

      let startDate: Date | undefined = currentState[`${type}StartDate`];
      let endDate: Date | undefined = currentState[`${type}EndDate`];
      let newFixedEndInput = currentState.fixedEndInput;
      let newSelect: "start" | "end" = "start";

      // The following code determines which date should be changing on the current selection and which date should be changing on the next selection.
      // By default, using "selectsRange" with react-datepicker will change the start date and reset the end date if both the start and end date are already populated.
      // We need to manually determine what steps to take in the case that the end input is focused on.
      if (currentState.select === "start") {
        if (endDate && endDate?.getTime() < selectedDate.getTime()) {
          endDate = undefined;
        }

        if (isDetailedBilling) {
          const isValid = isValidDetailedBillingDate(
            selectedDate,
            state.fixedEndDate
          );

          if (!isValid) {
            let next30 = new Date(selectedDate);
            next30 = add(next30, { days: 30 });
            endDate = next30;
          }
        }

        startDate = selectedDate;

        // If only showing single month, we need to stay in "start" mode
        if (props.singleMonthInvoice && tab === Tab.INVOICE) {
          newSelect = "start";
        } else {
          newSelect = "end";
        }
      } else if (currentState.select === "end") {
        if (!startDate || startDate?.getTime() > selectedDate.getTime()) {
          startDate = selectedDate;
          endDate = undefined;
          newFixedEndInput = undefined;
          newSelect = "end";
        } else {
          endDate = selectedDate;
          newSelect = "start";
        }
      }

      // If the start and end date are the same, get the last second of the day
      if (
        tab === Tab.FIXED &&
        endDate &&
        startDate?.getTime() === endDate.getTime()
      ) {
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
      }

      if (tab === Tab.INVOICE) {
        if (startDate && !endDate) {
          endDate = startDate;
        } else if (endDate && !startDate) {
          startDate = endDate;
        }
      }

      if (tab === Tab.FIXED) {
        if (newSelect === "start") {
          fixedStartDateRef.current?.focus();
        } else {
          fixedEndDateRef.current?.focus();
        }
      } else if (tab === Tab.INVOICE) {
        if (newSelect === "start") {
          invoiceStartDateRef.current?.focus();
        } else {
          invoiceEndDateRef.current?.focus();
        }
      }

      return {
        ...currentState,
        [`${type}StartDate`]: startDate,
        [`${type}EndDate`]: endDate,
        fixedEndInput: newFixedEndInput,
        select: newSelect,
      };
    });
  }

  function canReset() {
    switch (tab) {
      case Tab.FIXED:
        return state.fixedStartDate || state.fixedEndDate;
      case Tab.ROLLING:
        return (
          state.nInput || state.durationType === DurationType.LAST_N_MONTHS
        );
      case Tab.INVOICE:
        return state.invoiceStartDate || state.invoiceEndDate;
    }
  }

  function canSave() {
    let isValidStart = false;
    let isValidEnd = false;

    if (tab === Tab.FIXED) {
      isValidStart =
        state.fixedStartDate instanceof Date &&
        typeof state.fixedStartDate.getMonth === "function";

      isValidEnd =
        state.fixedEndDate instanceof Date &&
        typeof state.fixedEndDate.getMonth === "function";

      if (isDetailedBilling) {
        if (!state.fixedStartDate || !state.fixedEndDate) {
          return false;
        }
        return isValidDetailedBillingDate(
          state.fixedStartDate,
          state.fixedEndDate
        );
      }
      return isValidStart && isValidEnd;
    } else if (tab === Tab.INVOICE) {
      isValidStart =
        state.invoiceStartDate instanceof Date &&
        typeof state.invoiceStartDate.getMonth === "function";

      isValidEnd =
        state.invoiceEndDate instanceof Date &&
        typeof state.invoiceEndDate.getMonth === "function";

      return isValidStart || isValidEnd;
    }

    if (tab === Tab.ROLLING) {
      return isValidRollingWindow(Number(state.nInput));
    }
  }

  let next30FromStartDate = state.fixedStartDate
    ? new Date(state.fixedStartDate)
    : new Date();

  next30FromStartDate = add(next30FromStartDate, { days: 31 });

  function renderTabs() {
    return (
      <>
        <Flex
          borderBottom={`1px solid ${theme.border_color}`}
          marginBottom={theme.space_sm}
          justifyContent="space-between"
          scrollable
        >
          <Flex
            key={Tab.FIXED}
            alignItems="center"
            borderBottom={
              tab === Tab.FIXED
                ? `2px solid ${theme.primary_color_background}`
                : "none"
            }
            cursor="pointer"
            flex="1"
            justifyContent="center"
            transition={`width ${timing.selectedDuration}ms`}
            onClick={() => mergeState({ durationType: DurationType.CUSTOM })}
          >
            <Text
              bold={tab === Tab.FIXED}
              color={
                tab === Tab.FIXED
                  ? theme.primary_color_text
                  : theme.tab_text_color
              }
              lineHeight={2}
            >
              {copyText.datePickerTabFixed}
            </Text>
          </Flex>
          {/* Rolling */}
          {props.showRollingLookback && (
            <Flex
              key={Tab.ROLLING}
              alignItems="center"
              borderBottom={
                tab === Tab.ROLLING
                  ? `2px solid ${theme.primary_color_background}`
                  : "none"
              }
              cursor="pointer"
              flex="1"
              justifyContent="center"
              transition={`width ${timing.selectedDuration}ms`}
              onClick={() =>
                mergeState({
                  durationType: DurationType.LAST_N_DAYS,
                })
              }
            >
              <Text
                bold={tab === Tab.ROLLING}
                color={
                  tab === Tab.ROLLING
                    ? theme.primary_color_text
                    : theme.tab_text_color
                }
                lineHeight={2}
              >
                {copyText.datePickerTabRolling}
              </Text>
            </Flex>
          )}
          {/* Invoice */}
          {props.showInvoice && (
            <Flex
              key={Tab.INVOICE}
              alignItems="center"
              borderBottom={
                tab === Tab.INVOICE
                  ? `2px solid ${theme.primary_color_background}`
                  : "none"
              }
              cursor="pointer"
              flex="1"
              justifyContent="center"
              transition={`width ${timing.selectedDuration}ms`}
              onClick={() =>
                isDetailedBilling
                  ? noop
                  : mergeState({ durationType: DurationType.INVOICE })
              }
            >
              <Text
                bold={tab === Tab.INVOICE}
                color={
                  isDetailedBilling
                    ? theme.text_color_disabled
                    : tab === Tab.INVOICE
                      ? theme.primary_color_text
                      : theme.tab_text_color
                }
                lineHeight={2}
              >
                {copyText.datePickerTabInvoice}
              </Text>
            </Flex>
          )}
        </Flex>
      </>
    );
  }

  function renderFixedRangePicker() {
    const displayedStartDate = startDate
      ? formatDate(startDate, dateFormat)
      : startInput
        ? startInput
        : "";

    const displayedEndDate = endDate
      ? formatDate(endDate, dateFormat)
      : endInput
        ? endInput
        : "";

    const selected =
      state.displayMonth === "start"
        ? startDate
        : state.displayMonth === "end"
          ? endDate
          : startDate;

    return (
      <>
        <Box>
          <Flex direction="column" justifyContent="center">
            {/* Fixed and invoice pickers must be different inputs for ref/autofocus to work properly */}
            {tab === Tab.FIXED ? (
              <>
                <Box paddingBottom={theme.space_xs} width="256px">
                  <TextInput
                    placeholder={copyText.datePickerSelectStartPlaceholderText}
                    inputRef={fixedStartDateRef}
                    value={displayedStartDate}
                    onFocus={() => mergeState({ select: "start" })}
                    onChange={(e) => handleChangeDateInput(e, "start")}
                    onClick={() => mergeState({ displayMonth: "start" })}
                  />
                </Box>
                <Box paddingBottom={theme.space_xxs} width="256px">
                  <TextInput
                    placeholder={copyText.datePickerSelectEndPlaceholderText}
                    inputRef={fixedEndDateRef}
                    value={displayedEndDate}
                    onFocus={() => mergeState({ select: "end" })}
                    onChange={(e) => handleChangeDateInput(e, "end")}
                    onClick={() => mergeState({ displayMonth: "end" })}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box paddingBottom={theme.space_xs} width="256px">
                  <TextInput
                    placeholder={
                      props.singleMonthInvoice
                        ? copyText.datePickerSelectSingleMonthPlaceholderText
                        : copyText.datePickerSelectStartPlaceholderText
                    }
                    inputRef={invoiceStartDateRef}
                    value={displayedStartDate}
                    onFocus={() => mergeState({ select: "start" })}
                    onChange={(e) => handleChangeDateInput(e, "start")}
                    onClick={() => mergeState({ displayMonth: "start" })}
                  />
                </Box>
                {props.singleMonthInvoice ? null : (
                  <Box paddingBottom={theme.space_xxs} width="256px">
                    <TextInput
                      placeholder={copyText.datePickerSelectEndPlaceholderText}
                      inputRef={invoiceEndDateRef}
                      value={displayedEndDate}
                      onFocus={() => mergeState({ select: "end" })}
                      onChange={(e) => handleChangeDateInput(e, "end")}
                      onClick={() => mergeState({ displayMonth: "end" })}
                    />
                  </Box>
                )}
              </>
            )}
            <DatePicker
              dateFormat={tab === Tab.INVOICE ? "MM/yyyy" : undefined}
              inline
              maxDate={isDetailedBilling ? next30FromStartDate : props.maxDate}
              minDate={props.minDate}
              selected={selected}
              startDate={startDate}
              endDate={
                props.singleMonthInvoice && tab === Tab.INVOICE
                  ? undefined
                  : endDate
              }
              select={
                props.singleMonthInvoice && tab === Tab.INVOICE
                  ? undefined
                  : state.select
              }
              showMonthYearPicker={tab === Tab.INVOICE}
              onChange={(date) => handleChangeDate(date)}
            />
          </Flex>
        </Box>
      </>
    );
  }

  const rollingWindowOptions = [
    {
      value: DurationType.LAST_N_DAYS,
      label: copyText.datePickerModalDays,
    },
    {
      value: DurationType.LAST_N_MONTHS,
      label: copyText.datePickerModalMonths,
    },
  ];

  function renderRollingWindowPicker() {
    return (
      <Flex alignItems="center" width="100%">
        <Box>
          <Text marginBottom={theme.space_xxs}>
            {copyText.datePickerModalLast}
          </Text>
          <Box marginBottom={theme.space_xxs}>
            <TextInputWithSelect
              autoSize
              hideSelectedValue
              min="0"
              placeholder="00"
              selectValue={
                rollingWindowOptions.some(
                  (option) => option.value === state.durationType
                )
                  ? state.durationType
                  : DurationType.LAST_N_DAYS
              }
              size="small"
              selectOptions={[
                {
                  value: DurationType.LAST_N_DAYS,
                  label: copyText.datePickerModalDays,
                },
                {
                  value: DurationType.LAST_N_MONTHS,
                  label: copyText.datePickerModalMonths,
                },
              ]}
              type="number"
              value={state.nInput ?? ""}
              width="256px"
              onChange={(e) => {
                if (isDetailedBilling) {
                  const maxInput = +e.currentTarget.value;
                  if (maxInput <= 31) {
                    mergeState({ nInput: e.currentTarget.value });
                  }
                } else {
                  mergeState({ nInput: e.currentTarget.value });
                }
              }}
              onChangeSelect={(option) => {
                mergeState({ durationType: option.value });
              }}
            />
          </Box>
          <Tooltip
            hide={props.dataSource !== DataSource.DETAILED_BILLING}
            content={copyText.disableDateControlTooltipCaption}
          ></Tooltip>
          <Text marginTop={theme.space_xxs} color={theme.text_color_secondary}>
            {state.durationType === DurationType.LAST_N_MONTHS
              ? copyText.rollingWindowHelpMessageMonth
              : copyText.rollingWindowHelpMessageDay}
          </Text>
        </Box>
      </Flex>
    );
  }

  function renderActiveTab() {
    switch (tab) {
      case Tab.FIXED:
        return renderFixedRangePicker();
      case Tab.ROLLING:
        return renderRollingWindowPicker();
      case Tab.INVOICE:
        return renderFixedRangePicker();
    }
  }

  function renderDropdown() {
    return (
      <Box>
        {renderTabs()}
        <Box paddingBottom={theme.space_sm}>
          <Form>{renderActiveTab()}</Form>
        </Box>
      </Box>
    );
  }

  return (
    <Dropdown
      cancelButtonDisabled={!canReset()}
      cancelButtonText={copyText.datePickerButtonReset}
      disabled={props.disabled}
      placement="bottom-end"
      renderContent={renderDropdown}
      submitButtonDisabled={!canSave()}
      submitButtonText={copyText.datePickerButtonApply}
      onCancel={handleResetCustomDateRange}
      onClose={props.onClose}
      onOpen={props.onOpen}
      onSubmit={handleApplyCustomDateRange}
    >
      {props.children}
    </Dropdown>
  );
}

// Checks if user inputted string is a valid date. only accepts 4 digit year.
function isValidDateInput(inputString: string, tab: Tab): boolean {
  const [month = "", day = "", year = ""] = inputString.split("/");
  const [invoiceMonth = "", invoiceYear = ""] = inputString.split("/");

  let isValidDate = true;
  let isValidInvoiceDate = true;

  if (tab === Tab.INVOICE) {
    if (invoiceMonth.length < 1 || invoiceYear.length < 4) {
      isValidInvoiceDate = false;
    }
  }

  if (month.length < 1 || day.length < 1 || year.length < 4) {
    isValidDate = false;
  }

  const potentialInvoiceDate = new Date(
    `${invoiceMonth} / 01 / ${invoiceYear}`
  );

  if (
    potentialInvoiceDate instanceof Date &&
    isNaN(potentialInvoiceDate.valueOf())
  ) {
    isValidInvoiceDate = false;
  }

  const potentialDate = new Date(inputString);

  if (potentialDate instanceof Date && isNaN(potentialDate.valueOf())) {
    isValidDate = false;
  }

  return tab === Tab.INVOICE ? isValidDate || isValidInvoiceDate : isValidDate;
}

function getValidDate(inputString: string, tab: Tab) {
  const [invoiceMonth = "", invoiceYear = ""] = inputString.split("/");

  return tab === Tab.FIXED
    ? new Date(inputString)
    : new Date(`${invoiceMonth} / 01 / ${invoiceYear}`);
}
