import { useQueryClient } from "@tanstack/react-query";
import { AnalyticsSchema } from "@ternary/api-lib/analytics/api/AnalyticsApiClient";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import useAuthenticatedUser from "./useAuthenticatedUser";

/**
 * Custom hook that gets all available FOCUS dimensions by combining default dimensions
 * with column lineage surfaced from the analytics metadata.
 */

export function useFocusDimensions(): Dimension[] {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  const metadataArray = queryClient.getQueryData<AnalyticsSchema[]>([
    "analyticsMetadata",
    authenticatedUser.tenant.id,
  ]);

  if (!metadataArray) return [];

  const metadata = metadataArray.find(
    (metadata) => metadata.schemaName === DataSource.FOCUS_BILLING
  );

  if (!metadata) return [];

  const dimensions = metadata.dimensions.map((dimension) => ({
    displayName: dimension.displayName,
    providerType: dimension.providerName,
    schemaName: dimension.schemaFieldName,
  }));

  return dimensions.sort((a, b) => a.schemaName.localeCompare(b.schemaName));
}
