import { ProviderType, UnitType } from "../enums";

const measures = {
  amortizedCost: {
    displayName: "Amortized Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "amortizedCost",
    unit: UnitType.CURRENCY,
  },
  countDistinctARN: {
    displayName: "Count Distinct ARN",
    providerType: ProviderType.TERNARY,
    schemaName: "countDistinctARN",
    unit: UnitType.STANDARD,
  },
  coveredUsageHours: {
    displayName: "Covered Usage Hours",
    providerType: ProviderType.TERNARY,
    schemaName: "coveredUsageHours",
    unit: UnitType.STANDARD,
  },
  effectiveCost: {
    displayName: "Effective Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "effectiveCost",
    unit: UnitType.CURRENCY,
  },
  lineItemBlendedCost: {
    displayName: "Line Item Blended Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "lineItemBlendedCost",
    unit: UnitType.CURRENCY,
  },
  lineItemUnblendedCost: {
    displayName: "Line Item Unblended Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "lineItemUnblendedCost",
    unit: UnitType.CURRENCY,
  },
  netEffectiveCost: {
    displayName: "Net Effective Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "netEffectiveCost",
    unit: UnitType.CURRENCY,
  },
  riNetAmortizedUpfrontCostForUsage: {
    displayName: "RI Net Amortized Upfront Cost for Usage",
    providerType: ProviderType.TERNARY,
    schemaName: "riNetAmortizedUpfrontCostForUsage",
    unit: UnitType.CURRENCY,
  },
  riNetRecurringFeeForUsage: {
    displayName: "RI Net Recurring Fee for Usage",
    providerType: ProviderType.TERNARY,
    schemaName: "riNetRecurringFeeForUsage",
    unit: UnitType.CURRENCY,
  },
  riRecurringFeeForUsage: {
    displayName: "RI Recurring Fee for Usage",
    providerType: ProviderType.TERNARY,
    schemaName: "riRecurringFeeForUsage",
    unit: UnitType.CURRENCY,
  },
  spCoveredUsageCost: {
    displayName: "SP Covered Usage Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "spCoveredUsageCost",
    unit: UnitType.CURRENCY,
  },
  spNetAmortizedUpfrontCommitmentForBillingPeriod: {
    displayName: "SP Net Amortized Upfront Commitment for Billing Period",
    providerType: ProviderType.TERNARY,
    schemaName: "spNetAmortizedUpfrontCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  spNetRecurringCommitmentForBillingPeriod: {
    displayName: "SP Net Recurring Commitment for Billing Period",
    providerType: ProviderType.TERNARY,
    schemaName: "spNetRecurringCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  spRecurringCommitmentForBillingPeriod: {
    displayName: "SP Recurring Commitment for Billing Period",
    providerType: ProviderType.TERNARY,
    schemaName: "spRecurringCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
};

const dimensions = {
  billPayerAccountID: {
    displayName: "Bill Payer Account ID",
    providerType: ProviderType.TERNARY,
    schemaName: "billPayerAccountId",
  },
  commitmentARN: {
    displayName: "Commitment ARN",
    providerType: ProviderType.TERNARY,
    schemaName: "commitmentARN",
  },
  commitmentType: {
    displayName: "Commitment Type",
    providerType: ProviderType.TERNARY,
    schemaName: "commitmentType",
  },
  coverageType: {
    displayName: "Coverage Type",
    providerType: ProviderType.TERNARY,
    schemaName: "coverageType",
  },
  instanceID: {
    displayName: "Instance ID",
    providerType: ProviderType.TERNARY,
    schemaName: "instanceId",
  },
  lineItemUsageAccountID: {
    displayName: "Line Item Usage Account ID",
    providerType: ProviderType.TERNARY,
    schemaName: "lineItemUsageAccountId",
  },
  offeringType: {
    displayName: "Offering Type",
    providerType: ProviderType.TERNARY,
    schemaName: "offeringType",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.TERNARY,
    schemaName: "region",
  },
};

export const awsCommitmentAllocationSchema = { dimensions, measures };
