import { ProviderType, UnitType } from "../enums";

const measures = {
  effectiveSavingsRate: {
    displayName: "Effective Savings Rate",
    providerType: ProviderType.TERNARY,
    schemaName: "effectiveSavingsRate",
    unit: UnitType.PERCENTAGE,
  },
  realizedSavings: {
    displayName: "Realized Savings",
    providerType: ProviderType.TERNARY,
    schemaName: "realizedSavings",
    unit: UnitType.CURRENCY,
  },
  unrealizedSavings: {
    displayName: "Unrealized Savings",
    providerType: ProviderType.TERNARY,
    schemaName: "unrealizedSavings",
    unit: UnitType.CURRENCY,
  },
  utilizationPercentage: {
    displayName: "Utilization Percentage",
    providerType: ProviderType.TERNARY,
    schemaName: "utilizationPercentage",
    unit: UnitType.PERCENTAGE,
  },
};

const dimensions = {
  availabilityZone: {
    displayName: "Availability Zone",
    providerType: ProviderType.TERNARY,
    schemaName: "availabilityZone",
  },
  cloudID: {
    displayName: "Cloud ID",
    providerType: ProviderType.TERNARY,
    schemaName: "cloudId",
  },
  commitmentID: {
    displayName: "Commitment ID",
    providerType: ProviderType.TERNARY,
    schemaName: "commitmentId",
  },
  expirationDate: {
    displayName: "Expiration Date",
    providerType: ProviderType.TERNARY,
    schemaName: "expirationDate",
  },
  instanceCount: {
    displayName: "Instance Count",
    providerType: ProviderType.TERNARY,
    schemaName: "instance_count",
  },
  instanceType: {
    displayName: "Instance Type",
    providerType: ProviderType.TERNARY,
    schemaName: "instance_type",
  },
  offeringClass: {
    displayName: "Offering Class",
    providerType: ProviderType.TERNARY,
    schemaName: "offeringClass",
  },
  offeringType: {
    displayName: "Offering Type",
    providerType: ProviderType.TERNARY,
    schemaName: "offeringType",
  },
  operatingSystem: {
    displayName: "Operating System",
    providerType: ProviderType.TERNARY,
    schemaName: "operatingSystem",
  },
  purchaseDate: {
    displayName: "Purchase Date",
    providerType: ProviderType.TERNARY,
    schemaName: "purchaseDate",
  },
  region: {
    displayName: "Region",
    providerType: ProviderType.TERNARY,
    schemaName: "region",
  },
  scope: {
    displayName: "Scope",
    providerType: ProviderType.TERNARY,
    schemaName: "scope",
  },
  serviceDescription: {
    displayName: "Service Description",
    providerType: ProviderType.TERNARY,
    schemaName: "serviceDescription",
  },
  state: {
    displayName: "State",
    providerType: ProviderType.TERNARY,
    schemaName: "state",
  },
  term: {
    displayName: "Term",
    providerType: ProviderType.TERNARY,
    schemaName: "term",
  },
  type: {
    displayName: "Type",
    providerType: ProviderType.TERNARY,
    schemaName: "type",
  },
  upfrontCost: {
    displayName: "Upfront Cost",
    providerType: ProviderType.TERNARY,
    schemaName: "upfrontCost",
  },
  usagePrice: {
    displayName: "Usage Price",
    providerType: ProviderType.TERNARY,
    schemaName: "usagePrice",
  },
};

export const awsCommitmentTableSchema = { dimensions, measures };
